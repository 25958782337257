import { useMemo } from 'react';

import { useUpdateAdmin as useUpdateAdminApi } from 'api/requests';
import { PermissionType } from 'api/types/entity';
import { ErrorCode } from 'api/types/error';
import { UpdateAdminParams } from 'api/types/params';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

import { AdminFormType, FormErrors } from '../forms/adminForm';

export const useUpdateAdmin = (id: string) => {
  const {
    updateAdmin,
    loading: submitLoading,
    error: updateError,
  } = useUpdateAdminApi();

  const { checkPermission } = usePermissions();
  const { t } = useTranslation();
  const notify = useNotify();

  const onSubmit: FormSubmit<AdminFormType> = async (
    values: AdminFormType,
    helpers
  ) => {
    const input: UpdateAdminParams = {
      ...values,
      roleName: values.role.value,
    };

    const permission = checkPermission(PermissionType.AdminEdit, {
      showNotify: true,
    });

    if (permission && (await updateAdmin(id, input))) {
      notify.info(t('common.successSave'));
      helpers.setReadOnly(true);
    }
  };

  const formError = useMemo((): FormErrors | undefined => {
    if (updateError?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return {
        email: updateError?.message,
      };
    }

    return undefined;
  }, [updateError]);

  return {
    onSubmit,
    formError,
    submitLoading,
  };
};
