import { FC } from 'react';

import { Stack } from 'libs/ui';
import { SvgProps } from 'libs/ui/Icon/icons/types';
import { Colors } from 'libs/ui/theme';

export const renderIcon = (Icon: FC<SvgProps>) => (
  <Stack
    sx={{
      padding: '0 2px',
    }}
  >
    <Icon color={Colors.gray200} size={20} />
  </Stack>
);
