import { useState, MouseEvent } from 'react';

import {
  Menu,
  MenuItem,
  MenuOption,
  MenuProps,
  Pressed,
  Stack,
  Typography,
} from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

interface Props<T extends string | number>
  extends Omit<MenuProps<T>, 'open' | 'anchorEl' | 'onClose'> {
  options: MenuOption<T>[];
  label: string;
  onClickOption: (option: MenuItem<T>) => void;
}

export const ActionMenu = <T extends string | number>({
  options,
  label,
  onClickOption,
  ...menuProps
}: Props<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (option: MenuItem<T>) => {
    onClickOption(option);
    handleClose();
  };

  return (
    <>
      <Pressed onClick={handleClick}>
        <Stack direction="row" spacing={4}>
          <Typography variant="bodyBold">{label}</Typography>
          {open ? <Icon.ArrowUp /> : <Icon.ArrowDown />}
        </Stack>
      </Pressed>

      <Menu
        {...menuProps}
        anchorEl={anchorEl}
        open={open}
        options={options}
        onClickOption={handleClickOption}
        onClose={handleClose}
      />
    </>
  );
};
