import {
  LegalClientCategoryType,
  LegalClientEconomicSectorType,
} from 'api/types/entity';
import { ObjectSchema, string, object, mixed, TypedForm } from 'libs/form';
import {
  BaseGeneralFormType,
  baseGeneralSchema,
} from 'modules/client/common/forms/baseGeneralForm';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export interface GeneralFormType {
  general: BaseGeneralFormType & {
    category?: Maybe<SelectType<LegalClientCategoryType>>;
    economicSector?: Maybe<SelectType<LegalClientEconomicSectorType>>;
  };
}

export const generalSchema: ObjectSchema<GeneralFormType> = object({
  general: object({
    category: object({
      value: mixed<LegalClientCategoryType>()
        .oneOf(Object.values(LegalClientCategoryType))
        .required(),
      label: string().required(),
    })
      .nullable()
      .default(null),

    economicSector: object({
      value: mixed<LegalClientEconomicSectorType>()
        .oneOf(Object.values(LegalClientEconomicSectorType))
        .required(),
      label: string().required(),
    })
      .nullable()
      .default(null),
  }).concat(baseGeneralSchema),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<GeneralFormType>();
