import { useUpdateLegalClientLegalHead as useUpdateLegalClientLegalHeadApi } from 'api/requests';
import { LegalClientLegalHeadEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { LegalClientLegalHeadFormType } from '../forms/legalClientLegalHeadForm';
import { getLegalClientLegalHeadSubmitData } from '../helpers';

export const useUpdateLegalClientLegalHead = () => {
  const { updateLegalClientLegalHead, loading, error } =
    useUpdateLegalClientLegalHeadApi();

  const notify = useNotify();
  const { t } = useTranslation();

  const handleUpdate = async (
    legalClientLegalHead: LegalClientLegalHeadEntity,
    values: LegalClientLegalHeadFormType
  ) => {
    if (legalClientLegalHead.versionNumber !== undefined) {
      const data = getLegalClientLegalHeadSubmitData(
        legalClientLegalHead.versionNumber,
        values
      );
      if (await updateLegalClientLegalHead(legalClientLegalHead.id, data)) {
        notify.info(t('common.successSave'));
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientLegalHead: handleUpdate,
  };
};
