import { Box } from '../Box';
import { styled } from '../styled';

/**
 * Pressed component.
 */
export const Pressed = styled(Box)({
  cursor: 'pointer',
  display: 'inline-flex',
});
