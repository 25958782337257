import { useUpdateLegalClientBranch as useUpdateLegalClientBranchApi } from 'api/requests';
import { LegalClientBranchEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { LegalClientBranchFormType } from '../forms/legalClientBranchForm';
import { getLegalClientBranchSubmitData } from '../helpers';

export const useUpdateLegalClientBranch = () => {
  const { updateLegalClientBranch, loading, error } =
    useUpdateLegalClientBranchApi();

  const notify = useNotify();
  const { t } = useTranslation();

  const handleUpdate = async (
    legalClientBranch: LegalClientBranchEntity,
    values: LegalClientBranchFormType
  ) => {
    if (legalClientBranch.versionNumber !== undefined) {
      const data = getLegalClientBranchSubmitData(
        legalClientBranch.versionNumber,
        values
      );
      if (await updateLegalClientBranch(legalClientBranch.id, data)) {
        notify.info(t('common.successSave'));
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientBranch: handleUpdate,
  };
};
