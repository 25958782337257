import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import {
  PositionInCompanyFormType,
  getPositionInCompanyFormSchema,
} from './positionInCompanyForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import {
  ResidentialAddressFormType,
  residentialAddressSchema,
} from './residentialAddressForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';

export interface LegalClientFirstHeadFormType
  extends PositionInCompanyFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    PepConnectionFormType,
    ReasonChangeFormType {}

export const getSchema = (
  t: TFunc
): ObjectSchema<LegalClientFirstHeadFormType> =>
  object()
    .concat(getPersonalDataSchema(t))
    .concat(citizenshipsSchema)
    .concat(taxResidencesSchema)
    .concat(registrationAddressSchema)
    .concat(residentialAddressSchema)
    .concat(getPositionInCompanyFormSchema(t))
    .concat(getPepConnectionSchema(t))
    .concat(reasonChangeSchema);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientFirstHeadFormType>();

export type FormErrors = FormErrorsBase<LegalClientFirstHeadFormType>;
