// eslint-disable-next-line no-restricted-imports
import { FormControlLabel, Radio as RadioBase } from '@mui/material';

import { Typography } from '../Typography';
import { Colors } from '../theme';

import { RadioProps } from './types';

/**
 * Radio component.
 */
export const Radio = ({ label, ...rest }: RadioProps) => (
  <FormControlLabel
    control={
      <RadioBase
        {...rest}
        disableRipple
        sx={{
          color: Colors.gray200,
          '&.Mui-checked': {
            color: Colors.black,
          },
        }}
      />
    }
    label={<Typography variant="bodyBold">{label}</Typography>}
  />
);
