import { FC, useCallback } from 'react';

import { AvailableClientStatusesData } from 'api/types/data';
import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
} from 'api/types/entity';
import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { DialogChangeStatus } from 'modules/client/common/components';

import { LegalClientForm, LegalClientFormProps } from '../components';

export interface LegalClientViewProps
  extends Omit<
    LegalClientFormProps,
    'onChangeOnboardingStatus' | 'onChangeAccountStatus'
  > {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  fetchStatusesForChange: () => void;
  statusesForChange?: AvailableClientStatusesData;
  loadingStatuses?: boolean;
  submitLoadingStatus?: boolean;
  onChangeOnboardingStatus: (
    value: ClientOnboardingStatusType,
    result?: string
  ) => Promise<void>;
  onChangeAccountStatus: (value: ClientAccountStatusType) => Promise<void>;
}

export const LegalClientView: FC<LegalClientViewProps> = ({
  breadcrumbsTitles,
  fetchStatusesForChange,
  onChangeOnboardingStatus,
  statusesForChange,
  loadingStatuses,
  submitLoadingStatus,
  onChangeAccountStatus,
  ...formProps
}) => {
  const { isOpen, onClose, open } = useDialog();
  const onCloseDialogChangeStatus = useCallback(() => {
    if (!submitLoadingStatus) {
      formProps.unlockClient?.();
      onClose();
    }
  }, [formProps, onClose, submitLoadingStatus]);

  const onOpenDialogChangeStatus = useCallback(async () => {
    const isPossibleEdit = await formProps.lockClientIfPossible?.();
    if (isPossibleEdit) {
      open();
    }
  }, [formProps, open]);

  const onChangeOnboardingStatusHandler = useCallback(
    async (value: ClientOnboardingStatusType, result?: string) => {
      await onChangeOnboardingStatus(value, result);
      onClose();
    },
    [onChangeOnboardingStatus, onClose]
  );

  const onChangeAccountStatusHandler = useCallback(
    async (value: ClientAccountStatusType) => {
      await onChangeAccountStatus(value);
      onClose();
    },
    [onChangeAccountStatus, onClose]
  );

  return (
    <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
      <LegalClientForm
        {...formProps}
        onChangeAccountStatus={onOpenDialogChangeStatus}
        onChangeOnboardingStatus={onOpenDialogChangeStatus}
      />
      {formProps.currentOnboardingStatus ===
      ClientOnboardingStatusType.AccountOpened ? (
        <DialogChangeStatus
          currentStatus={formProps.currentAccountStatus}
          fetchStatuses={fetchStatusesForChange}
          isOpen={isOpen}
          loading={loadingStatuses}
          statuses={statusesForChange?.account}
          statusType="account"
          submitLoading={submitLoadingStatus}
          onChange={onChangeAccountStatusHandler}
          onClose={onCloseDialogChangeStatus}
        />
      ) : (
        <DialogChangeStatus
          currentStatus={formProps.currentOnboardingStatus}
          fetchStatuses={fetchStatusesForChange}
          isOpen={isOpen}
          loading={loadingStatuses}
          statuses={statusesForChange?.onboarding}
          statusType="onboarding"
          submitLoading={submitLoadingStatus}
          onChange={onChangeOnboardingStatusHandler}
          onClose={onCloseDialogChangeStatus}
        />
      )}
    </LayoutWithNavigationBreadcrumbs>
  );
};
