import { Submit } from 'libs/form/Submit';
import { useTranslation } from 'libs/i18n';
import { Button, Stack, Typography } from 'libs/ui';

import { HeaderStyled } from './Header.styles';

interface Props {
  onBack?: () => void;
  title: string;
  submitLoading?: boolean;
}

export const Header = ({ title, submitLoading, onBack }: Props) => {
  const { t } = useTranslation();
  return (
    <HeaderStyled
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <Typography className="addHeader-title" variant="title">
        {title}
      </Typography>
      <Stack alignItems="center" direction="row" spacing={36}>
        <Button label={t('common.back')} mode="text" onClick={onBack} />
        <Submit label={t('common.add')} loading={submitLoading} />
      </Stack>
    </HeaderStyled>
  );
};
