import { yesOrNotSelectValidation } from 'helpers';
import { TypedForm, ObjectSchema, string, object, email } from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface CompanyContactsFormType {
  companyContacts: {
    phone?: string;
    email?: string;
    site?: string;
    disclosureInformation: Maybe<YesOrNoSelectType>;
    disclosureInformationResource?: string;
  };
}

export const phoneMaxLength = 200;
export const siteMaxLength = 200;
export const disclosureInformationResourceMaxLength = 200;

export const getCompanyContactsSchema = (
  t: TFunc
): ObjectSchema<CompanyContactsFormType> =>
  object({
    companyContacts: object({
      phone: string().max(phoneMaxLength),
      email: email(t),
      site: string().max(siteMaxLength),
      disclosureInformation: yesOrNotSelectValidation.default(null).required(),
      disclosureInformationResource: string().when('disclosureInformation', {
        is: (v: YesOrNoSelectType) => v?.value === YesOrNoType.Yes,
        then: (schema) =>
          schema.required().max(disclosureInformationResourceMaxLength),
      }),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyContactsFormType>();
