// eslint-disable-next-line no-restricted-imports
import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useAvailableLegalClientStatusesLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseAvailableLegalClientStatusesData } from 'api/helpers/legal/parseAvailableLegalClientStatusesData';

export const useAvailableLegalClientStatusesLazy = () => {
  const [
    getAvailableLegalClientStatusesApi,
    { data, loading, error: apolloError, refetch },
  ] = useAvailableLegalClientStatusesLazyQuery();

  const error = useError(apolloError);

  const availableStatuses = data?.availableClientStatuses
    ? parseAvailableLegalClientStatusesData(data)
    : null;

  const getAvailableLegalClientStatuses = useCallback(
    async (legalClientId: string) => {
      const response = await getAvailableLegalClientStatusesApi({
        variables: { id: legalClientId },
      });
      return parseAvailableLegalClientStatusesData(response.data);
    },
    [getAvailableLegalClientStatusesApi]
  );

  return {
    getAvailableLegalClientStatuses,
    availableStatuses,
    loading,
    error,
    refetch,
  };
};
