import { TFunc } from 'libs/i18n';
import {
  parseAddressValue,
  parseTaxResidencesValue,
  parsePepConnectionInitialValue,
} from 'modules/client/common/helpers';

import { ControlOverFormType } from '../../forms/controlOverForm';
import { LegalClientBeneficiaryFormType } from '../../forms/legalClientBeneficiaryForm';

export const getLegalClientBeneficiaryInitialValue = (
  t: TFunc
): LegalClientBeneficiaryFormType => ({
  reasonChange: { reason: '' },

  personalData: {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: undefined,
  },

  citizenships: [null],

  taxResidences: parseTaxResidencesValue(),

  registrationAddress: parseAddressValue(),
  residentialAddress: parseAddressValue(),

  pepConnection: parsePepConnectionInitialValue(undefined, t),

  controlOver: {} as ControlOverFormType['controlOver'],

  placeOfBirth: {
    country: null,
    locality: '',
  },
});
