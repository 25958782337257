import { FC } from 'react';

import { useTranslation } from 'libs/i18n';
import { ToggleButton, ToggleButtonProps } from 'libs/ui';

import { LegalClientType } from '../../types';

interface Props
  extends Pick<ToggleButtonProps<LegalClientType>, 'value' | 'onChange'> {}

export const LegalClientTypeToggleButton: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <ToggleButton
      buttons={[
        {
          label: t('client.client.toggleButtons.legal'),
          value: LegalClientType.legal,
        },
        {
          label: t('client.client.toggleButtons.legalRepresentative'),
          value: LegalClientType.representative,
        },
      ]}
      value={value}
      onChange={onChange}
    />
  );
};
