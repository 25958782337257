import { UAParser } from 'ua-parser-js';

export const parseUserAgent = (ua: string) => {
  const { browser, device, os } = UAParser(ua);

  return {
    browserName: browser.name,
    browserVersion: browser.version,
    isMobile: device.type,
    deviceModel: device.model,

    osName: os.name,
    osVestion: os.version,
  };
};
