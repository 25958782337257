import { useMemo } from 'react';

import { ErrorCode, ServerError } from 'api/types/error';

import { FormErrors } from '../forms/accountCodesForm';

export const useAccountCodesFormError = (error: ServerError | undefined) => {
  const formError = useMemo((): FormErrors | undefined => {
    if (error?.code === ErrorCode.CODE_ALREADY_TAKEN) {
      return {
        accountCodes: { code: error?.message },
      };
    }

    if (
      error?.code === ErrorCode.CENTRAL_DEPOSITORY_ACCOUNT_NUMBER_ALREADY_TAKEN
    ) {
      return {
        accountCodes: { centralDepositoryAccountNumber: error?.message },
      };
    }

    return undefined;
  }, [error]);

  return formError;
};
