import { UpdateLegalClientBeneficiaryParams } from 'api/types/params';
import { yesOrNot } from 'helpers';
import {
  parseAddressParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
  parseAdditionalInformationFileIdsParam,
  parseAdditionalDocumentFileIdsParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { LegalClientBeneficiaryFormType } from '../../forms/legalClientBeneficiaryForm';

export const getLegalClientBeneficiarySubmitData = (
  versionNumber: number,
  values: LegalClientBeneficiaryFormType
): UpdateLegalClientBeneficiaryParams => {
  const addressParam = parseAddressParam(values);

  return {
    versionNumber,
    reason: values.reasonChange.reason,
    firstName: values.personalData.firstName,
    lastName: values.personalData.lastName,
    middleName: values.personalData.middleName ?? null,
    dateOfBirth: values.personalData.dateOfBirth,
    citizenships: parseCitizenshipsParam(values),

    registrationAddress: addressParam.registrationAddress,
    residentialAddress: addressParam.residentialAddress,
    placeOfBirth: addressParam.placeOfBirth,

    taxResidences: parseTaxResidencyParam(values),

    pepConnection: values.pepConnection.relation?.value!,
    connectedPepInfo: parsePepConnectionParam(values),

    directCapitalParticipationPercentage: Number(
      values.controlOver.directCapitalParticipationPercentage
    ),

    financialConditionAmount: values.controlOver.financialConditionAmount,

    hasControlOverLegalClient:
      yesOrNot(values.controlOver.hasControlOverLegalClient?.value) ?? false,

    indirectCapitalParticipationPercentage: Number(
      values.controlOver.indirectCapitalParticipationPercentage
    ),
    additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
      values.additionalDocumentsFiles
    ),
    additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
      values.documentsFiles
    ),

    identityDocument: parseIdentityDocumentParam(values),
  };
};
