import { PropsWithChildren } from 'react';

import {
  NavigationBreadcrumbs,
  NavigationBreadcrumbsProps,
} from 'components/NavigationBreadcrumbs';
import { Stack } from 'libs/ui';

import { LayoutOwerlay } from './styles';

interface Props extends NavigationBreadcrumbsProps, PropsWithChildren {}

export const LayoutWithNavigationBreadcrumbs = ({
  children,
  titles,
}: Props) => (
  <>
    <LayoutOwerlay />
    <Stack spacing={36}>
      <NavigationBreadcrumbs titles={titles} />
      {children}
    </Stack>
  </>
);
