import { useMemo } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Button } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import { Section } from 'modules/client/common/components';
import { getToday } from 'modules/client/common/helpers';

import { Field } from '../../../forms/companyConstituentDocs';

import { HeadAddDialog } from './HeadAddDialog';
import { HeadItem, HeadType, HeadsTable } from './HeadsTable';

interface Props {
  heads: LegalClientInnerItemEntity[];
  legalHeads: LegalClientInnerItemEntity[];
  onClickHead: (id: string) => void;
  onClickAddHead?: () => void;
  onClickAddLegalHead?: () => void;
  onClickLegalHead: (id: string) => void;
}

export const CompanyHeadsSection = ({
  heads,
  legalHeads,
  onClickHead,
  onClickAddHead,
  onClickLegalHead,
  onClickAddLegalHead,
}: Props) => {
  const { t } = useTranslation();

  const { readOnly } = useFormContext();

  const { open: openAddHeadDilog, ...addHeadDialogProps } = useDialog();

  const items: HeadItem[] = useMemo(() => {
    const result = heads
      .map((h) => ({
        id: h.id,
        name: h.name,
        city: h.registrationCity || '',
        type: HeadType.person,
        createdAt: h.createdAt,
      }))
      .concat(
        legalHeads.map((h) => ({
          id: h.id,
          name: h.name,
          city: h.registrationCity || '',
          type: HeadType.legal,
          createdAt: h.createdAt,
        }))
      );

    result.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

    return result;
  }, [heads, legalHeads]);

  const onCellClick = (item: HeadItem) => {
    if (item.type === HeadType.person) {
      onClickHead(item.id);
    } else {
      onClickLegalHead(item.id);
    }
  };
  const onClickPerson = () => {
    addHeadDialogProps.onClose();
    onClickAddHead?.();
  };

  const onClickLegal = () => {
    addHeadDialogProps.onClose();
    onClickAddLegalHead?.();
  };

  return (
    <Section
      headerRight={
        !readOnly &&
        onClickAddHead && (
          <Button
            label={t('client.legal.companyHeads.addHead')}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={openAddHeadDilog}
          />
        )
      }
      title={t('client.legal.companyHeads.title')}
    >
      <Field.DatePicker
        label={t('client.legal.companyConstituentDocs.dateOfLastChange.label')}
        maxDate={getToday()}
        name="constituentDocs.dateOfLastChange"
        placeholder={t(
          'client.legal.companyConstituentDocs.dateOfLastChange.placeholder'
        )}
      />
      <HeadsTable items={items} onCellClick={onCellClick} />

      {onClickAddHead && (
        <HeadAddDialog
          {...addHeadDialogProps}
          onClickLegal={onClickLegal}
          onClickPerson={onClickPerson}
        />
      )}
    </Section>
  );
};
