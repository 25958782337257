import {
  BrokerRelationshipPurposeType,
  LegalFinancingSourcesEntity,
  PlannedOperationType,
} from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { CompanyFinancingSourcesFormType } from '../../forms/companyFinancingSourcesForm';

export const BrokerRelationshipPurposePath: Record<
  BrokerRelationshipPurposeType,
  TranslationPath
> = {
  [BrokerRelationshipPurposeType.SecuritiesBrokerage]:
    'client.client.financingSources.securitiesBrokerage',
  [BrokerRelationshipPurposeType.TrustAssetManagement]:
    'client.client.financingSources.trustAssetManagement',
  [BrokerRelationshipPurposeType.Other]:
    'client.client.financingSources.otherLabel',
};

export const PlannedOperationTypePath: Record<
  PlannedOperationType,
  TranslationPath
> = {
  [PlannedOperationType.ForeignCurrencyTransactions]:
    'client.client.financingSources.foreignCurrencyTransactions',
  [PlannedOperationType.ForeignIssuersStockTransactions]:
    'client.client.financingSources.foreignIssuersStockTransactions',
  [PlannedOperationType.ForeignIssuersBondsTransactions]:
    'client.client.financingSources.foreignIssuersBondsTransactions',
  [PlannedOperationType.KazakhIssuersBondsTransactions]:
    'client.client.financingSources.kazakhIssuersBondsTransactions',
  [PlannedOperationType.KazakhIssuersStockTransactions]:
    'client.client.financingSources.kazakhIssuersStockTransactions',
  [PlannedOperationType.OptionsFuturesETCDerivatives]:
    'client.client.financingSources.optionsFuturesETCDerivatives',
  [PlannedOperationType.RepoOperations]:
    'client.client.financingSources.repoOperations',
  [PlannedOperationType.SecuritiesTransferFromOtherBroker]:
    'client.client.financingSources.securitiesTransferFromOtherBroker',

  [PlannedOperationType.Other]: 'client.client.financingSources.otherLabel',
};

export const parseFinancingSourcesValue = (
  t: TFunc,
  financingSources: LegalFinancingSourcesEntity | undefined
) => {
  const values:
    | CompanyFinancingSourcesFormType['financingSources']
    | undefined = {
    ...financingSources,

    incomeSources: financingSources?.incomeSources
      ? financingSources?.incomeSources?.map((v) => ({
          value: v,
          label: t(
            `client.legal.companyFinancingSources.incomeSourcesOptions.${v}`
          ),
        }))
      : [],

    financingSources: financingSources?.financingSources
      ? financingSources?.financingSources?.map((v) => ({
          value: v,
          label: t(
            `client.legal.companyFinancingSources.financingSourcesOptions.${v}`
          ),
        }))
      : [],

    auditedFinancialReportFiles:
      financingSources?.lastYearAuditFinancialReportFiles ?? [],
    recommendationLetterFiles:
      financingSources?.recommendationLetterFiles ?? [],
  };

  return values;
};
