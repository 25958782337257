import { useCallback, useState } from 'react';

import { lodash } from 'helpers';
import { useNavigate } from 'libs/navigation';
import { TableSort } from 'libs/ui';

import { useScrollToTop } from './useScrollToTop';

export type UseTableFilterParam = Record<string, any>;

export interface UseTableArgument<
  S extends string = string,
  F extends UseTableFilterParam = {}
> {
  defaultSort?: TableSort<S>;
  defaultFilter?: F;
  filterParams?: (keyof F)[];
}

const getInitialSort = <S extends string = string>(
  searchParams: URLSearchParams,
  defaultSort?: TableSort<S>
) => {
  const querySortField = searchParams.get('sortField');
  const querySortDirection = searchParams.get('sortDirection');

  const querySort: TableSort<S> | undefined =
    !!querySortField && !!querySortDirection
      ? {
          field: querySortField as S,
          sort: querySortDirection === 'desc' ? 'desc' : 'asc',
        }
      : undefined;

  const initialSort = querySort ?? defaultSort;

  return initialSort;
};

const getInitialFilter = <F extends UseTableFilterParam, P extends keyof F>(
  searchParams: URLSearchParams,
  defaultFilter?: F,
  filterParams?: P[]
) => {
  const filter: Record<P, any> = { ...defaultFilter } as F;

  filterParams?.forEach((param) => {
    const searchParam = searchParams.get(param.toString());
    if (searchParam) {
      let value = decodeURIComponent(searchParam);
      try {
        value = JSON.parse(value);
      } catch (error) {
        /* empty */
      }
      filter[param] = value;
    }
  });

  return filter;
};

const parseFilterForParam = <F extends UseTableFilterParam>(filter?: F) => {
  const newFilter: Record<string, string> = {};

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      newFilter[key] =
        typeof value === 'object'
          ? encodeURIComponent(JSON.stringify(value))
          : value;
    });
  }
  return newFilter;
};

export const useTable = <
  S extends string = string,
  F extends UseTableFilterParam = {}
>(
  args?: UseTableArgument<S, F>
) => {
  const { setSearchParams, searchParams } = useNavigate();

  const page = lodash.defaultTo([Number(searchParams.get('page'))], 0);
  const sort = getInitialSort(searchParams, args?.defaultSort);

  const [filter, setFilter] = useState(
    getInitialFilter(searchParams, args?.defaultFilter, args?.filterParams)
  );

  const handleFilter = useCallback(
    (filter?: F) => {
      const filterForParams = parseFilterForParam(filter);
      setFilter(filter as F);
      setSearchParams(
        lodash.compactObject({
          page: 0,
          sortField: sort?.field,
          sortDirection: sort?.sort,
          ...filterForParams,
        })
      );
    },
    [sort?.field, sort?.sort, setSearchParams]
  );

  const onSortChange = useCallback(
    (s?: TableSort<S>) => {
      const sort = s ?? args?.defaultSort;

      setSearchParams((prev) => {
        if (sort?.field) {
          prev.set('sortField', sort?.field);
        } else {
          prev.delete('sortField');
        }
        if (sort?.sort) {
          prev.set('sortDirection', sort?.sort);
        } else {
          prev.delete('sortDirection');
        }
        return prev;
      });
    },
    [args?.defaultSort, setSearchParams]
  );

  const onPaginationChange = useCallback(
    (page: number) => {
      setSearchParams((prev) => {
        if (page) {
          prev.set('page', page.toString());
        } else {
          prev.delete('page');
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  useScrollToTop([page]);

  return {
    sort,
    onSortChange,
    page,
    onPaginationChange,
    filter,
    setFilter: handleFilter,
  };
};
