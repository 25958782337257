import { useLegalClients as useLegalClientsApi } from 'api/requests';
import { scrollToTop } from 'helpers';
import { useQueryListData } from 'hooks';
import { ROUTES, useNavigate } from 'libs/navigation';

import { ClientsListItem } from '../types';

const LIMIT = 50;

export const useLegalClients = () => {
  const { navigate } = useNavigate();

  const {
    list: clients,
    rowCount,
    loading,
    setFilter,
    sort,
    page,
    filter,
    onPaginationChange,
    onSortChange,
  } = useQueryListData(useLegalClientsApi, {
    limit: LIMIT,
    filterParams: [
      'search',
      'statuses',
      'riskLevels',
      'locationCountries',
      'registrationCountries',
    ],
  });

  const onCellClick = (client: ClientsListItem) => {
    navigate(ROUTES.clients.legalClients.legalClient.fullPath, {
      legalClientId: client.id,
    });
    scrollToTop();
  };

  return {
    clients,
    loading,
    sort,
    page,
    rowCount,
    limit: LIMIT,
    filter,
    setFilter,
    onCellClick,
    onSortChange,
    onPaginationChange,
  };
};
