import { lodash } from 'helpers';

import { OnboardingData } from '../types/data';

export type OnboardingDataApi =
  | {
      totalRoutes: number;
      traversedRoutes: (string | null)[];
      isCompleted?: boolean;
    }
  | undefined
  | null;

export const parseOboardingData = (
  onboarding?: OnboardingDataApi
): OnboardingData | undefined =>
  onboarding
    ? {
        totalRoutes: onboarding.totalRoutes,
        traversedRoutes: lodash.compact(onboarding.traversedRoutes),
        isCompleted: onboarding.isCompleted ?? false,
      }
    : undefined;
