import { lodash } from 'helpers';
import { usePermissions } from 'libs/permissions';
import { useUser } from 'libs/providers';
import { ErrorFallback } from 'modules/App/ErrorFallback';
// eslint-disable-next-line no-restricted-imports
import {
  RouteObject,
  createBrowserRouter,
  RouterProvider as BaseRouterProvider,
} from 'react-router-dom';

import { PAGES } from './config/pages';
import { pagesPermissions } from './config/pagesPermissions';
import { ROUTES_CONFIG } from './config/routesConfig';
import { mergeMapElement } from './helpers';
import { Route } from './types';

/**
 * Router using the provided routes and pages configuration.
 * @param {RouteConfig} routesConfig - The configuration of routes.
 */
const router = (config: typeof ROUTES_CONFIG) =>
  (() => {
    const route = mergeMapElement(PAGES, config) as unknown as RouteObject;
    route.errorElement = <ErrorFallback />;
    return createBrowserRouter([route]);
  })();

/**
 * Provides the router to the application.
 */
export const RouterProvider = () => {
  const { checkPermissions } = usePermissions();
  const { user } = useUser();

  const recurseCheckPermissions = (children: Route[]) =>
    lodash.compact(
      children.map((c) => {
        const authorizationIdRequired = c.authorizationIdRequired ?? true;
        if (authorizationIdRequired && !user) {
          return undefined;
        }

        const permissions = pagesPermissions[c.id];
        const isAllowed = permissions
          ? checkPermissions(permissions.permissions, 'or')
          : true;
        if (!isAllowed) return undefined;

        if (c.children) {
          c.children = lodash.compact(recurseCheckPermissions(c.children));
        }
        return c;
      })
    );

  const config = Object.assign(ROUTES_CONFIG);

  config.children = recurseCheckPermissions(config.children);

  return <BaseRouterProvider router={router(config)} />;
};
