import { useEffect, useState, ChangeEvent } from 'react';

import { Input } from '../Input';

import {
  formatNumber,
  parseNumber,
  isFloat,
  adjustInputCursor,
} from './helpers';
import { NumberInputProps } from './types';

export const NumberInput = ({
  parse = parseNumber,
  format = formatNumber,
  checkNumber = isFloat,
  onChangeText,
  ...props
}: NumberInputProps) => {
  const [value, setValue] = useState(format(props.value));

  useEffect(() => {
    if (props.value === undefined || parse(value) !== +props.value) {
      setValue(format(props.value));
    }
  }, [format, parse, props.value, value]);

  const handleChange = (
    newValue: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!checkNumber(newValue)) return;

    const value = /[,.]0?$/.test(newValue)
      ? newValue.replace('.', ',')
      : format(parse(newValue));

    setValue(value);

    onChangeText?.(parse(newValue));
    adjustInputCursor(event, value, newValue);
  };

  return <Input {...props} value={value} onChangeText={handleChange} />;
};
