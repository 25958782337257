import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { AddressFields, Section } from 'modules/client/common/components';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const CompanyRegistrationAddressSection = ({
  countriesLoading,
  countries,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('client.legal.companyRegistrationAddress.titleRegistration')}
    >
      <AddressFields
        countries={countries}
        countriesLoading={countriesLoading}
        variant="companyRegistrationAddress"
      />
    </Section>
  );
};
