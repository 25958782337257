import { useUpdateLegalClientHead as useUpdateLegalClientHeadApi } from 'api/requests';
import { LegalClientHeadEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { LegalClientHeadFormType } from '../forms/legalClientHeadForm';
import { getLegalClientHeadSubmitData } from '../helpers';

export const useUpdateLegalClientHead = () => {
  const { updateLegalClientHead, loading, error } =
    useUpdateLegalClientHeadApi();

  const notify = useNotify();
  const { t } = useTranslation();

  const handleUpdate = async (
    legalClientHead: LegalClientHeadEntity,
    values: LegalClientHeadFormType
  ) => {
    if (legalClientHead.versionNumber !== undefined) {
      const data = getLegalClientHeadSubmitData(
        legalClientHead.versionNumber,
        values
      );
      if (await updateLegalClientHead(legalClientHead.id, data)) {
        notify.info(t('common.successSave'));
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientHead: handleUpdate,
  };
};
