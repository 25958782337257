import { FC } from 'react';

import { ManagerData, OnboardingDataStatus } from 'api/types/data';
import { CountryEntity, FileEntity } from 'api/types/entity';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { Stack } from 'libs/ui';
import {
  CompanyNameSection,
  DebtsSection,
  WithdrawalAccountsSection,
  CompanyRegistrationAddressSection,
  CompanyResidentialAddressSection,
  CompanyStateRegistrationSection,
  AccountCodesSection,
  ActivitySection,
  OpenAccountOrderSection,
  DocumentsSection,
  AdditionalDocumentsSection,
  ServiceContractSection,
  RiskLevelSection,
  ResultCheckSection,
} from 'modules/client/common/components/sections';
import { LockAndUnlockReadOnlyProps } from 'modules/client/common/hooks';

import {
  CompanyTaxResidencySection,
  CompanyActivitiesSection,
  CompanyBankAccountsSection,
  CompanyBeneficiariesSection,
  CompanyBranchesSection,
  CompanyContactsSection,
  CompanyFinancingSourcesSection,
  CompanyHeadsSection,
  CompanyLicenseSection,
  CompanyOrganizationalAndLegalFormSection,
  CompanyRegistrationPlaceSection,
  CompanyStructureSection,
  CompanyFinancialMonitoringSection,
  CompanyOrderSubmissionContactsSection,
  CompanyBusinessRelationshipSection,
  GeneralSection,
} from '../sections';

export interface LegalClientSectionsProps extends LockAndUnlockReadOnlyProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  heads: LegalClientInnerItemEntity[];
  legalHeads: LegalClientInnerItemEntity[];
  beneficiary: {
    unidentifiableBeneficiaryLetterFiles?: FileEntity[];
    beneficiaries: LegalClientInnerItemEntity[];
  };
  branches: LegalClientInnerItemEntity[];
  hideActivitySection?: boolean;
  managers: ManagerData[];
  managersLoading?: boolean;
  visibleOnboardingStatuses?: OnboardingDataStatus[];
  onClickHead: (id: string) => void;
  onClickAddHead?: () => void;
  onClickAddBeneficiary?: () => void;
  onClickAddLegalHead?: () => void;
  onClickLegalHead: (id: string) => void;
  onClickBeneficiary: (id: string) => void;
  onClickBranch: (id: string) => void;
}

export const LegalClientSections: FC<LegalClientSectionsProps> = ({
  countries,
  countriesLoading,
  heads,
  legalHeads,
  beneficiary,
  branches,
  managers,
  managersLoading,
  visibleOnboardingStatuses,
  hideActivitySection,
  onClickHead,
  onClickLegalHead,
  onClickAddHead,
  onClickAddBeneficiary,
  onClickAddLegalHead,
  onClickBeneficiary,
  onClickBranch,
}) => (
  <Stack spacing={72}>
    <ResultCheckSection hideKyc statuses={visibleOnboardingStatuses} />
    <GeneralSection />
    <OpenAccountOrderSection />
    <ServiceContractSection />
    <RiskLevelSection />
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <AccountCodesSection
      managers={managers}
      managersLoading={managersLoading}
    />
    <CompanyNameSection showEn />
    <CompanyTaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyRegistrationPlaceSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyRegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyResidentialAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyStateRegistrationSection />
    <CompanyOrganizationalAndLegalFormSection />
    <CompanyActivitiesSection />

    <CompanyLicenseSection />

    <CompanyStructureSection />

    <CompanyHeadsSection
      heads={heads}
      legalHeads={legalHeads}
      onClickAddHead={onClickAddHead}
      onClickAddLegalHead={onClickAddLegalHead}
      onClickHead={onClickHead}
      onClickLegalHead={onClickLegalHead}
    />
    <CompanyBeneficiariesSection
      beneficiary={beneficiary}
      onClickAddBeneficiary={onClickAddBeneficiary}
      onClickBeneficiary={onClickBeneficiary}
    />

    <CompanyContactsSection />

    <CompanyBranchesSection branches={branches} onClickBranch={onClickBranch} />

    <CompanyBankAccountsSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyFinancingSourcesSection />

    <DebtsSection isEntrepreneur />

    <CompanyFinancialMonitoringSection hasBranches={!!branches.length} />

    <CompanyBusinessRelationshipSection />

    <WithdrawalAccountsSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyOrderSubmissionContactsSection />

    <DocumentsSection />
    <AdditionalDocumentsSection />
  </Stack>
);
