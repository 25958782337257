import { string, object, ObjectSchema, TypedForm, date } from 'libs/form';
import { Maybe } from 'types/maybe';

export interface OpenAccountOrderFormType {
  openAccountOrder: {
    number?: Maybe<string>;
    date?: Maybe<Date>;
    orderReceivedDate?: Maybe<Date>;
  };
}

export const openAccountOrderSchema: ObjectSchema<OpenAccountOrderFormType> =
  object({
    openAccountOrder: object({
      number: string().nullable().max(200),
      date: date().nullable(),
      orderReceivedDate: date().nullable(),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OpenAccountOrderFormType>();
