import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { range } from 'helpers';
import { Input, Pressed, Stack, Typography } from 'libs/ui';
import { cx } from 'libs/ui/theme';

import { ContainerStyled } from './VerificationCode.styles';
import { VerificationCodeProps } from './types';

export const VerificationCode = ({
  value,
  length,
  validChars = '0-9',
  placeholder,
  isError,
  onChange,
  onFocus,
  onBlur,
  onChangeText,
  onComplete,
}: VerificationCodeProps) => {
  const [localValue, setLocalValue] = useState(value as string);
  const [isActive, setActive] = useState(false);

  const inputRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    inputRef.current?.focus();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)
    ) {
      // do not allow to change cursor position
      event.preventDefault();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newInputVal = event.target.value.replace(/\s/g, '');

    if (RegExp(`^[${validChars}]{0,${length}}$`).test(newInputVal)) {
      if (onChange) {
        onChange?.(event);
        onChangeText?.(newInputVal, event);
      }
      setLocalValue(newInputVal);

      if (newInputVal.length === length) {
        onComplete?.(newInputVal);
      }
    }
  };

  const isCharacterSelected = (i: number) => {
    const value = localValue ?? '';
    return (
      (value.length === i || (value.length === i + 1 && length === i + 1)) &&
      isActive
    );
  };

  useEffect(() => {
    setLocalValue(String(value ?? ''));
  }, [value]);

  return (
    <ContainerStyled onClick={handleClick}>
      <Input
        ref={(node) => {
          inputRef.current = node;
        }}
        autoFocus
        autoComplete="one-time-code"
        className="input"
        value={localValue}
        onBlur={(e) => {
          setActive(false);
          onBlur?.(e);
        }}
        onChange={handleInputChange}
        onFocus={(e) => {
          setActive(true);
          onFocus?.(e);
        }}
        onKeyDown={handleKeyDown}
      />
      <Stack direction="row" spacing={16}>
        {range(length).map((i) => (
          <Pressed
            key={i}
            className={cx(
              'text',
              localValue?.[i] && 'filled',
              isCharacterSelected(i) && 'focused',
              isError && 'error'
            )}
            id={`field-${i}`}
            onClick={handleClick}
          >
            <Typography variant="headline">
              {localValue?.[i] || placeholder}
            </Typography>
          </Pressed>
        ))}
      </Stack>
    </ContainerStyled>
  );
};
