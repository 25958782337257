// eslint-disable-next-line no-restricted-imports
import { Query_UserController_GetList_AllOf_1_List_Items_Status as StatusApi1 } from 'api/generated/graphql';
import { AdminStatusType } from 'api/types/entity/';

type StatusApi = StatusApi1;

const statusMap: Record<StatusApi, AdminStatusType> = {
  [StatusApi1.Active]: AdminStatusType.Active,
  [StatusApi1.Deactivated]: AdminStatusType.Deactivated,
};

export const parseAdminStatus = (status: StatusApi): AdminStatusType =>
  statusMap[status];
