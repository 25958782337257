import { AddressesParam } from 'api/types/params';

import { CompanyRegistrationAddressFormType } from '../../forms/companyRegistrationAddressForm';
import { CompanyResidentialAddressFormType } from '../../forms/companyResidentialAddressForm';

export const parseCompanyAddressParam = (
  values: CompanyRegistrationAddressFormType & CompanyResidentialAddressFormType
): {
  legalAddress: AddressesParam;
  actualAddress: AddressesParam;
} => {
  const legalAddress = {
    country: values.companyRegistrationAddress.country?.id!,
    region: values.companyRegistrationAddress.region,
    apartment: values.companyRegistrationAddress.apartment,
    building: values.companyRegistrationAddress.building,
    district: values.companyRegistrationAddress.district,
    house: values.companyRegistrationAddress.house,
    locality: values.companyRegistrationAddress.locality,
    postalCode: values.companyRegistrationAddress.postalCode,
    street: values.companyRegistrationAddress.street,
  };
  const result = {
    legalAddress,

    actualAddress: values.companyResidentialAddress.residentialAddressIsSame!
      ? legalAddress
      : {
          country: values.companyResidentialAddress.country?.id!,
          region: values.companyResidentialAddress.region!,
          apartment: values.companyResidentialAddress.apartment!,
          building: values.companyResidentialAddress.building,
          district: values.companyResidentialAddress.district,
          house: values.companyResidentialAddress.house!,
          locality: values.companyResidentialAddress.locality!,
          postalCode: values.companyResidentialAddress.postalCode!,
          street: values.companyResidentialAddress.street!,
        },
  };

  return result;
};
