/* eslint-disable no-restricted-imports */
import { useDeactivateAdminMutation } from 'api/generated/graphql';
import { parseAdminEntity, useError } from 'api/helpers';

export const useDeactivateAdmin = () => {
  const [deactivateAdmin, { data, loading, error: serverError }] =
    useDeactivateAdminMutation();

  const error = useError(serverError);

  return {
    admin: data?.deactivateAdmin
      ? parseAdminEntity(data.deactivateAdmin)
      : undefined,
    loading,
    error,
    deactivateAdmin: async (id: string) => {
      const res = await deactivateAdmin({ variables: { id } });

      return res.data?.deactivateAdmin
        ? parseAdminEntity(res.data?.deactivateAdmin)
        : undefined;
    },
  };
};
