import { ReactNode } from 'react';

import { Icon } from '../Icon';
import { Stack } from '../Stack';
import { Colors } from '../theme';

import { BreadcrumbsProps } from './types';

/**
 * Breadcrumbs component.
 */
export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const breadcrumbsWithIcon: ReactNode[] = [];

  breadcrumbs.forEach((element, index) => {
    if (index !== 0) {
      breadcrumbsWithIcon.push(<Icon.Right />);
    }
    breadcrumbsWithIcon.push(element);
  });

  return (
    <Stack
      alignItems="center"
      color={Colors.gray300}
      direction="row"
      flexWrap="wrap"
      spacing={4}
    >
      {breadcrumbsWithIcon}
    </Stack>
  );
};
