// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientController_UpdateById_Input_InformationUpdateFrequency as InformationUpdateFrequencyTypeApi,
  MutationInput_LegalClientController_UpdateById_Input_InformationUpdateFrequency as InformationUpdateFrequencyTypeApi2,
} from 'api/generated/graphql';
import { InformationUpdateFrequencyType } from 'api/types/entity';

const InformationUpdateFrequencyTypePath: Record<
  InformationUpdateFrequencyType,
  InformationUpdateFrequencyTypeApi | InformationUpdateFrequencyTypeApi2
> = {
  [InformationUpdateFrequencyType.OnceAMonth]:
    InformationUpdateFrequencyTypeApi.OnceAMonth,
  [InformationUpdateFrequencyType.OnceAYear]:
    InformationUpdateFrequencyTypeApi.OnceAYear,
  [InformationUpdateFrequencyType.Semiannually]:
    InformationUpdateFrequencyTypeApi.Semiannually,
};

export const parseInformationUpdateFrequency = <
  T extends
    | InformationUpdateFrequencyTypeApi
    | InformationUpdateFrequencyTypeApi2
>(
  value: InformationUpdateFrequencyType | null
) => {
  if (!value) return null;

  return InformationUpdateFrequencyTypePath[value] as T;
};
