// eslint-disable-next-line no-restricted-imports
import { UpdateLegalClientHeadByAdminDto_Input } from 'api/generated/graphql';
import { PositionInCompanyParam } from 'api/types/params/positionInCompanyParam';
import { formatToISODate } from 'helpers';

export const parsePositionInCompanyParam = (
  positionInCompany: PositionInCompanyParam
): UpdateLegalClientHeadByAdminDto_Input['positionInCompany'] => ({
  positionConfirmationDocument: {
    dateOfExpiration: formatToISODate(
      positionInCompany.authorityConfirmationDocument.dateOfExpiration
    ),
    fileIds: positionInCompany.authorityConfirmationDocument.fileIds,
  },
  positionInCompany: positionInCompany.position,
});
