import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCreateLegalClientHeadMutation } from 'api/generated/graphql';
import {
  parseCreateLegalClientHeadParams,
  parseLegalClientHeadEntity,
  useError,
} from 'api/helpers';
import { CreateLegalClientHeadParams } from 'api/types/params';

export const useCreateLegalClientHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useCreateLegalClientHeadMutation();

  const error = useError(apolloError);

  return {
    legalClientHead: data?.createLegalClientHead
      ? parseLegalClientHeadEntity(data.createLegalClientHead)
      : undefined,
    loading,
    error,
    createLegalClientHead: useCallback(
      async (params: CreateLegalClientHeadParams) => {
        const input = parseCreateLegalClientHeadParams(params);
        const res = await handle({ variables: { input } });
        return res.data?.createLegalClientHead
          ? parseLegalClientHeadEntity(res.data?.createLegalClientHead)
          : undefined;
      },
      [handle]
    ),
  };
};
