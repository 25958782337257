import { PermissionType } from 'api/types/entity';

import { PermissionCheckType } from '../types';

export const checkPermission = (
  userPermissions: PermissionType[],
  permission: PermissionType
) => userPermissions.includes(permission) ?? false;

export const checkPermissions = (
  userPermissions: PermissionType[],
  permissions: PermissionType[],
  type: PermissionCheckType = 'and'
) =>
  type === 'and'
    ? permissions.every((p) => userPermissions.includes(p))
    : permissions.some((p) => userPermissions.includes(p));
