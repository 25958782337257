// eslint-disable-next-line no-restricted-imports
import { LegalClientLegalHeadSnapshotFragment } from 'api/generated/graphql';
import { LegalClientLegalHeadSnapshotEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { parseLegalClientLegalHeadEntity } from './parseLegalClientLegalHeadEntity';

export const parseLegalClientLegalHeadSnapshotEntity = (
  data: Maybe<LegalClientLegalHeadSnapshotFragment>
): LegalClientLegalHeadSnapshotEntity | undefined =>
  data
    ? {
        createdAt: new Date(data.createdAt),
        id: data.id,
        value: parseLegalClientLegalHeadEntity(data.value),
      }
    : undefined;
