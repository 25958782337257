import { TwoFactorForm, TwoFactorFormProps } from '../components/TwoFactorForm';

import { ContentStyled } from './styles';

interface Props extends TwoFactorFormProps {}

export const TwoFactorConnectionView = ({ ...formProps }: Props) => (
  <ContentStyled>
    <TwoFactorForm {...formProps} />
  </ContentStyled>
);
