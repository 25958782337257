/**
 * Defines a set of custom colors.
 */
export enum Colors {
  white = '#ffffff',
  black = '#081A35',
  red = '#D84315',

  red50 = '#F9CCCC',
  red100 = '#D84315',
  red150 = '#BD3911',
  red200 = '#972E0E',

  gray50 = '#F5F9FE',
  gray100 = '#CBD2E0',
  gray150 = '#C0D2E9',
  gray200 = '#A0ABC0',
  gray250 = '#787F89',
  gray300 = '#384D6C',

  blue50 = '#C0DCFF',
  blue100 = '#A8C0F1',
  blue150 = '#1A5FEA',
  blue200 = '#1958D6',
  blue250 = '#2755BE',

  green50 = '#C3EECA',
  green100 = '#0E591A',

  yellow50 = '#F0E6B0',
  yellow100 = '#78691B',
}
