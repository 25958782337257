import { UpdateLegalClientHeadParams } from 'api/types/params';
import { yesOrNot } from 'helpers';
import {
  parseAddressParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
  parseAdditionalInformationFileIdsParam,
  parseAdditionalDocumentFileIdsParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { LegalClientHeadFormType } from '../../forms/legalClientHeadForm';

export const getLegalClientHeadSubmitData = (
  versionNumber: number,
  values: LegalClientHeadFormType
): UpdateLegalClientHeadParams => {
  const addressParam = parseAddressParam(values);

  return {
    versionNumber,
    reason: values.reasonChange.reason,
    firstName: values.personalData.firstName,
    lastName: values.personalData.lastName,
    middleName: values.personalData.middleName ?? null,
    dateOfBirth: values.personalData.dateOfBirth,
    citizenships: parseCitizenshipsParam(values),
    legalClientBodyId: values.headInfo.body?.value!,

    registrationAddress: addressParam.registrationAddress,
    residentialAddress: addressParam.residentialAddress,

    taxResidences: parseTaxResidencyParam(values),

    pepConnection: values.pepConnection.relation?.value!,
    connectedPepInfo: parsePepConnectionParam(values),

    isFirstHead: yesOrNot(values.headInfo.firstHead?.value) ?? false,
    positionInCompany: {
      position: values.positionInCompany.position,
      authorityConfirmationDocument: {
        dateOfExpiration: values.positionInCompany.dateOfExpiration,
        fileIds: values.positionInCompany.files.map(({ id }) => id!),
      },
    },
    additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
      values.additionalDocumentsFiles
    ),
    additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
      values.documentsFiles
    ),

    identityDocument: parseIdentityDocumentParam(values),
  };
};
