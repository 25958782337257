import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

interface CheckedIntdeterminateProps extends SvgProps {
  rectColor?: Colors;
  strokeColor?: Colors;
}

export const CheckedIntdeterminate = ({
  size = 24,
  rectColor = Colors.black,
  strokeColor = Colors.white,
  className,
}: CheckedIntdeterminateProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill={rectColor} height="18" rx="4" width="18" x="3" y="3" />
      <path d="M6.5 12H17.5" stroke={strokeColor} strokeWidth="2" />
    </svg>
  </SvgIcon>
);
