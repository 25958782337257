// eslint-disable-next-line no-restricted-imports
import {
  ClientsQueryVariables,
  QueryInput_ClientController_GetList_RiskLevels_Items as RiskLevelsApi,
  QueryInput_ClientController_GetList_OnboardingStatuses_Items as ClientOnboardingStatusTypeApi,
} from 'api/generated/graphql';
import { ClientsFilters } from 'api/types/data';
import {
  ClientOnboardingStatusType,
  RiskLevelFilterType,
} from 'api/types/entity';
import { lodash } from 'helpers';

type Result = Pick<
  ClientsQueryVariables,
  | 'search'
  | 'riskLevels'
  | 'onboardingStatuses'
  | 'registrationCountries'
  | 'residentialCountries'
>;

const riskLevels: Record<RiskLevelFilterType, RiskLevelsApi> = {
  [RiskLevelFilterType.Unspecified]: RiskLevelsApi.NotSpecified,
  [RiskLevelFilterType.Low]: RiskLevelsApi.Low,
  [RiskLevelFilterType.Medium]: RiskLevelsApi.Medium,
  [RiskLevelFilterType.High]: RiskLevelsApi.High,
};

const statusMap: Record<
  ClientOnboardingStatusType,
  ClientOnboardingStatusTypeApi | undefined
> = {
  [ClientOnboardingStatusType.FillingOut]:
    ClientOnboardingStatusTypeApi.FillingOut,
  [ClientOnboardingStatusType.AccountOpened]:
    ClientOnboardingStatusTypeApi.AccountOpened,
  [ClientOnboardingStatusType.ComplianceCheck]:
    ClientOnboardingStatusTypeApi.ComplianceCheck,
  [ClientOnboardingStatusType.AccountOpeningDenied]:
    ClientOnboardingStatusTypeApi.AccountOpeningDenied,
  [ClientOnboardingStatusType.AmlCftCheck]:
    ClientOnboardingStatusTypeApi.AmlCftCheck,
  [ClientOnboardingStatusType.AccountOpening]:
    ClientOnboardingStatusTypeApi.AccountOpening,
  [ClientOnboardingStatusType.AwaitingAccountOpening]:
    ClientOnboardingStatusTypeApi.AwaitingAccountOpening,
  [ClientOnboardingStatusType.AwaitingAmlCftCheck]:
    ClientOnboardingStatusTypeApi.AwaitingAmlCftCheck,
  [ClientOnboardingStatusType.AwaitingComplianceCheck]:
    ClientOnboardingStatusTypeApi.AwaitingComplianceCheck,
  [ClientOnboardingStatusType.AwaitingValidation]:
    ClientOnboardingStatusTypeApi.AwaitingValidation,
  [ClientOnboardingStatusType.BoardReview]:
    ClientOnboardingStatusTypeApi.BoardReview,
  [ClientOnboardingStatusType.ValidationCheck]:
    ClientOnboardingStatusTypeApi.ValidationCheck,
  [ClientOnboardingStatusType.PendingBoardReview]:
    ClientOnboardingStatusTypeApi.PendingBoardReview,
  [ClientOnboardingStatusType.SigningDocuments]:
    ClientOnboardingStatusTypeApi.SigningDocuments,
  [ClientOnboardingStatusType.AwaitingPaymentConfirmation]: undefined,
};

export const parseClientFiltersParam = (filter?: ClientsFilters): Result => ({
  search: filter?.search || undefined,
  registrationCountries: filter?.registrationCountries,
  residentialCountries: filter?.locationCountries,
  riskLevels: filter?.riskLevels?.length
    ? filter.riskLevels.map((r) => riskLevels[r])
    : undefined,
  onboardingStatuses: filter?.onboardingStatuses?.length
    ? lodash.compact(
        filter?.onboardingStatuses?.map((status) => statusMap[status])
      )
    : undefined,
});
