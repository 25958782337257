import { Form, FormProps } from 'libs/form/Form';
import { Submit } from 'libs/form/Submit';
import { useTranslation } from 'libs/i18n';
import { Button, Typography } from 'libs/ui';

import {
  ContentStyled,
  FieldsStyled,
  FooterStyled,
  HeaderStyled,
} from './FormBody.styles';

export interface FormBodyProps<Values extends object>
  extends FormProps<Values> {
  title: string;
  subTitle?: string;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  canGoBack?: boolean;
  onGoBack?: () => void;
}

export const FormBody = <Values extends object>({
  title,
  subTitle,
  children,
  canGoBack,
  submitLoading,
  submitDisabled,
  onGoBack,
  ...formProps
}: FormBodyProps<Values>) => {
  const { t } = useTranslation();

  return (
    <ContentStyled>
      <HeaderStyled>
        <Typography className="formContainer-title" variant="title">
          {title}
        </Typography>
        {!!subTitle && <Typography>{subTitle}</Typography>}
      </HeaderStyled>

      <Form {...formProps}>
        <FieldsStyled>{children}</FieldsStyled>
        <FooterStyled>
          {canGoBack && (
            <Button label={t('common.back')} mode="text" onClick={onGoBack} />
          )}
          <Submit
            disabled={submitDisabled}
            label={t('common.next')}
            loading={submitLoading}
          />
        </FooterStyled>
      </Form>
    </ContentStyled>
  );
};
