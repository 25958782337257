import { Pressed, Typography } from 'libs/ui';
import { Colors } from 'libs/ui/theme';

import { DocumentItemType } from '../types';

export const renderName = (
  item: DocumentItemType,
  onClickName?: (item: DocumentItemType) => void
) => (
  <Pressed className="ellipsisText" onClick={() => onClickName?.(item)}>
    <Typography
      color={onClickName ? Colors.blue150 : undefined}
      title={item.name}
    >
      {item.name}
    </Typography>
  </Pressed>
);
