import { boolean, object, ObjectSchema, string, TypedForm } from 'libs/form';

import { companyRegistrationAddressObject } from './companyRegistrationAddressForm';
import { AddressFormType } from './registrationAddressForm';

export interface CompanyResidentialAddressFormType {
  companyResidentialAddress: Partial<AddressFormType> & {
    residentialAddressIsSame?: boolean;
  };
}

export const companyResidentialAddressSchema: ObjectSchema<CompanyResidentialAddressFormType> =
  object({
    companyResidentialAddress: object({
      country: object({
        id: string().required(),
        name: string().required(),
      })
        .when('residentialAddressIsSame', {
          is: false,
          then: (schema) => schema.required(),
        })
        .default(null)
        .nullable(),

      locality: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      region: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      area: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      street: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      house: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      building: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      apartment: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      postalCode: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),

      residentialAddressIsSame: boolean().default(false),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyResidentialAddressFormType>();

export { companyRegistrationAddressObject };
