import { useEffect } from 'react';

import { ConfirmationDialog } from 'components/dialogs';
import { useTranslation } from 'libs/i18n';
import { DialogProps } from 'libs/ui';

import { NotificationSettingsTable } from './NotificationSettingsTable';
import { useNotificationSettings } from './hooks';

interface Props extends DialogProps {
  onClose: () => void;
}

export const DialogNotificationSettingsFeature = ({
  isOpen,
  onClose,
}: Props) => {
  const {
    items,
    updateLoading,
    onChangeEmailEnabled,
    onChangeBellEnabled,
    onConfirm,
    resetSettings,
  } = useNotificationSettings(onClose);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) {
      resetSettings();
    }
  }, [isOpen, resetSettings]);

  return (
    <ConfirmationDialog
      headerCentered
      confirmButtonLabel={t('common.save')}
      isOpen={isOpen}
      loading={updateLoading}
      maxWidth="lg"
      title={t('components.notificationSettings.notificationSettingsLabel')}
      onBack={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <NotificationSettingsTable
        items={items}
        onChangeBellEnabled={onChangeBellEnabled}
        onChangeEmailEnabled={onChangeEmailEnabled}
      />
    </ConfirmationDialog>
  );
};
