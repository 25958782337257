import { IdentityDocumentEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { IdentityDocumentFormType } from '../../forms/identityDocumentForm';

export const parseIdentityDocumentInitialValue = (
  t: TFunc,
  identityDocument?: IdentityDocumentEntity
) => {
  const values: IdentityDocumentFormType['identityDocument'] = identityDocument
    ? {
        authority: identityDocument.authority,
        dateOfIssue: identityDocument?.dateOfIssue,
        documentType: identityDocument.documentType
          ? {
              value: identityDocument.documentType,
              label: t(
                `client.identityDocument.documenyType.variants.${identityDocument.documentType}`
              ),
            }
          : null,
        expirationDate: identityDocument.expirationDate,
        number: identityDocument.number,
        countryOfIssue: identityDocument.сountryOfIssue,
      }
    : ({} as IdentityDocumentFormType['identityDocument']);

  return values;
};
