import { useTranslation } from 'libs/i18n';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import { useFormContext } from 'modules/client/common/forms/documentsForm';

export interface FormContentProps {
  readOnly?: boolean;
}

export const FormContent = ({ readOnly }: FormContentProps) => {
  const { readOnly: contextReadOnly } = useFormContext();
  const { t } = useTranslation();
  return (
    <DocumentsTableFeature
      name="documentsFiles"
      readOnly={readOnly ?? contextReadOnly}
      title={t('client.client.documents.title')}
    />
  );
};
