import { OccupationType } from 'api/types/entity';
import { BiographyParam } from 'api/types/params';

import { BiographyFormType } from '../../forms/biographyForm';

export const parseBiographyParam = ({ biography }: BiographyFormType) => {
  const isCompany =
    biography.occupation?.value === OccupationType.businessOwner ||
    biography.occupation?.value === OccupationType.employee;

  const isEntrepreneur =
    biography.occupation?.value === OccupationType.entrepreneur;

  let fields = {};

  if (isCompany) {
    fields = {
      companyName: biography.companyName,
      position: biography.position,
      workAddress: biography.workAddress,
    };
  } else if (isEntrepreneur) {
    fields = {
      entrepreneurName: biography.entrepreneurName,
      registrationNumber: biography.registrationNumber,
      registrationDate: biography.registrationDate,
      registrationPlace: biography.registrationPlace,
      typeOfServices: biography.typeOfServices,
    };
  }

  const result: BiographyParam = {
    ...fields,

    maritalStatus: biography.maritalStatus!.value,
    occupation: biography.occupation!.value,
  };

  return result;
};
