import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSnapshotQuery } from 'api/generated/graphql';
import { useError, parseLegalClientHeadSnapshotEntity } from 'api/helpers';

import { RequestOptions } from '../types';

export const useLegalClientHeadSnapshot = (
  headId: string,
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientHeadSnapshotQuery({
    variables: { id: headId, versionId },
    ...options,
  });

  const error = useError(apolloError, false);

  const snapshot = useMemo(
    () =>
      data?.legalClientHeadSnapshot
        ? parseLegalClientHeadSnapshotEntity(data.legalClientHeadSnapshot)
        : null,
    [data?.legalClientHeadSnapshot]
  );

  return {
    snapshot,
    loading,
    error,
    isError: !!apolloError,
    refetch,
  };
};
