import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientVersionsQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { lodash } from 'helpers';

import { parseClientVersionEntity } from '../../helpers/client/parseEntity/parseClientVersionEntity';

export const useClientVersions = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useClientVersionsQuery({ variables: { id } });

  const error = useError(apolloError);

  const versions = useMemo(
    () =>
      data?.clientVersions
        ? lodash.compact(data.clientVersions.map(parseClientVersionEntity))
        : null,
    [data?.clientVersions]
  );

  return {
    versions,
    loading,
    error,
    refetch,
  };
};
