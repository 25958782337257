// eslint-disable-next-line no-restricted-imports
import { ConstitutionalDocumentFragment } from 'api/generated/graphql';
import { parseFileDocumentEntity } from 'api/helpers/client/parseEntity/parseFileEntity';
import { LegalClientConstitutionalDocumentEntity } from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

export const parseLegalClientConstitutionalDocument = (
  constitutionalDocument: Maybe<ConstitutionalDocumentFragment>
): LegalClientConstitutionalDocumentEntity | undefined => {
  if (!constitutionalDocument) {
    return undefined;
  }

  return {
    dateOfLastChange: new Date(constitutionalDocument.dateOfLastChange),
    files: lodash
      .compact(constitutionalDocument.documents)
      .map(parseFileDocumentEntity),
  };
};
