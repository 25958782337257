import {
  BrokerRelationshipPurposeType,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import {
  Field,
  useFormContext,
} from 'modules/client/client/forms/financingSourcesForm';
import {
  BrokerRelationshipPurposePath,
  IncomeSourcesPath,
  PlannedOperationTypePath,
} from 'modules/client/client/helpers/getClientInitialValue/parseFinancingSourcesValue';
import { DocumentsTableFeature } from 'modules/client/common/feature';

export interface FormContentProps {}

export const FormContent = () => {
  const { values, readOnly } = useFormContext();
  const { t } = useTranslation();

  const isOtherIncomeSource =
    values.financingSources.incomeSources?.find(
      (v) => v?.value === IncomeSourcesType.Other
    ) !== undefined;

  const isOtherBrokerRelationshipPurpose =
    values.financingSources.brokerRelationshipPurpose?.find(
      (v) => v?.value === BrokerRelationshipPurposeType.Other
    ) !== undefined;

  const isOtherPlannedOperationType =
    values.financingSources.plannedOperationTypes?.find(
      (v) => v?.value === PlannedOperationType.Other
    ) !== undefined;

  return (
    <>
      <Field.Autocomplete
        multiple
        label={t('client.client.financingSources.incomeSources.label')}
        name="financingSources.incomeSources"
        optionLabelKey="label"
        options={Object.values(IncomeSourcesType).map((v) => ({
          label: t(IncomeSourcesPath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.client.financingSources.incomeSources.placeholder'
        )}
        variant="select"
      />

      {isOtherIncomeSource && (
        <Field.Text
          label={t('client.client.financingSources.other.label')}
          name="financingSources.otherIncomeSource"
          placeholder={t('client.client.financingSources.other.placeholder')}
        />
      )}

      <Field.Price
        label={t('client.client.financingSources.incomeAmount.label')}
        name="financingSources.incomeAmount"
        placeholder={t(
          'client.client.financingSources.incomeAmount.placeholder'
        )}
      />

      <Field.Autocomplete
        multiple
        label={t(
          'client.client.financingSources.brokerRelationshipPurpose.label'
        )}
        name="financingSources.brokerRelationshipPurpose"
        optionLabelKey="label"
        options={Object.values(BrokerRelationshipPurposeType).map((v) => ({
          label: t(BrokerRelationshipPurposePath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.client.financingSources.brokerRelationshipPurpose.placeholder'
        )}
        variant="select"
      />

      {isOtherBrokerRelationshipPurpose && (
        <Field.Text
          label={t('client.client.financingSources.otherLabel')}
          name="financingSources.otherBrokerRelationshipPurpose"
          placeholder={t('client.client.financingSources.other.placeholder')}
        />
      )}

      <Field.Price
        label={t('client.client.financingSources.plannedAssetAmount.label')}
        name="financingSources.plannedAssetAmount"
        placeholder={t(
          'client.client.financingSources.plannedAssetAmount.placeholder'
        )}
      />

      <Field.Autocomplete
        multiple
        label={t('client.client.financingSources.plannedOperationTypes.label')}
        name="financingSources.plannedOperationTypes"
        optionLabelKey="label"
        options={Object.values(PlannedOperationType).map((v) => ({
          label: t(PlannedOperationTypePath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.client.financingSources.plannedOperationTypes.placeholder'
        )}
        variant="select"
      />

      {isOtherPlannedOperationType && (
        <Field.Text
          label={t('client.client.financingSources.other.label')}
          name="financingSources.otherPlannedOperationType"
          placeholder={t('client.client.financingSources.other.placeholder')}
        />
      )}

      <DocumentsTableFeature
        name="financingSources.incomeSourceFile"
        readOnly={readOnly}
      />
    </>
  );
};
