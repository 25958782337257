// eslint-disable-next-line no-restricted-imports
import { TablePaginationProps } from '@mui/material';
import {
  GridPagination,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

import { Pagination } from '../../Pagination';

function PaginationComponent({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();
  const limit = useGridSelector(apiRef, gridPageSizeSelector);
  if (limit >= rowCount) {
    return null;
  }
  const count = Math.ceil(rowCount / limit);

  return (
    <Pagination
      className={className}
      count={count}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

export function CustomPagination(props: any) {
  return (
    <GridPagination
      ActionsComponent={PaginationComponent}
      {...props}
      labelDisplayedRows={() => null}
    />
  );
}
