import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSnapshotQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClientSnapshotEntity } from 'api/helpers/legal/parseEntity/parseLegalClientSnapshotEntity';

import { RequestOptions } from '../types';

export const useLegalClientSnapshot = (
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientSnapshotQuery({ variables: { versionId }, ...options });

  const error = useError(apolloError);

  const snapshot = useMemo(
    () =>
      data?.legalClientSnapshot
        ? parseLegalClientSnapshotEntity(data.legalClientSnapshot)
        : null,
    [data?.legalClientSnapshot]
  );

  return {
    snapshot,
    loading,
    error,
    refetch,
  };
};
