import { string, object, ObjectSchema, TypedForm } from 'libs/form';

import { AddressFormType } from './registrationAddressForm';

export interface CompanyRegistrationAddressFormType {
  companyRegistrationAddress: AddressFormType;
}

export const companyRegistrationAddressObject = object({
  country: object({
    id: string().required(),
    name: string().required(),
  })
    .required()
    .default(null)
    .notRequired(),
  locality: string().required().max(200),
  region: string().required().max(200),
  area: string().max(200),
  street: string().required().max(200),
  house: string().required().max(200),
  building: string().max(200),
  apartment: string().max(200),
  postalCode: string().required().max(200),
});

export const companyRegistrationAddressSchema: ObjectSchema<CompanyRegistrationAddressFormType> =
  object({
    companyRegistrationAddress: companyRegistrationAddressObject,
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyRegistrationAddressFormType>();
