import { Body } from 'components';
import { styled } from 'libs/ui/styled';

export const HeaderStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  '.formContainer-title': {
    whiteSpace: 'pre-line',
  },
});

export const ContentStyled = styled(Body)(() => ({
  marginTop: 32,
}));

export const FooterStyled = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  marginTop: 32,
  alignItems: 'center',
  gap: 40,
});

export const FieldsStyled = styled('div')({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});
