import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useToggleNotificationReadMutation } from 'api/generated/graphql';
import { parseNotificationEntity, useError } from 'api/helpers';

export const useToggleNotificationRead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useToggleNotificationReadMutation();

  const error = useError(apolloError);

  const toggleNotificationRead = useCallback(
    async (notificationId: string) => {
      const res = await handle({ variables: { notificationId } });
      if (res.data?.toggleNotificationRead) {
        return parseNotificationEntity(res.data.toggleNotificationRead);
      }
      return undefined;
    },
    [handle]
  );

  return { error, loading, data, toggleNotificationRead };
};
