import { useCallback, useRef } from 'react';

interface Args {
  loading?: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
}

export const useInfiniteScroll = ({ loading, hasMore, onLoadMore }: Args) => {
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: Element) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      if (!hasMore) return;

      observer.current = new IntersectionObserver((entries) => {
        if (hasMore && entries[0].isIntersecting) {
          onLoadMore();
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore, loading, onLoadMore]
  );

  return { lastElementRef };
};
