// eslint-disable-next-line no-restricted-imports
import { AdminListItemFragment } from 'api/generated/graphql';
import { AdminEntity } from 'api/types/entity';

import { parseAdminStatus } from './parseAdminStatus';

export const parseAdminsListItemData = (
  user: AdminListItemFragment
): AdminEntity => ({
  id: user.id,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  middleName: user.middleName ?? undefined,
  status: parseAdminStatus(user.status),
  position: user.position ?? undefined,
});
