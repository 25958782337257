import { useCallback, useMemo, useState } from 'react';

import { useUserUpdateNotificationSettings } from 'api/requests';
import { NotificationSettingsData } from 'api/types/data';
import { useTranslation } from 'libs/i18n';
import { useUser } from 'libs/providers';

import { ChangeCheckboxFunction } from '../NotificationSettingsTable/types';

export const useNotificationSettings = (onClose: () => void) => {
  const { user } = useUser();

  const { loading: updateLoading, updateNotificationSettings } =
    useUserUpdateNotificationSettings();

  const { t } = useTranslation();

  const userNotifications = useMemo(
    () =>
      user?.settings.notifications?.map((n) => ({
        id: n.type,
        isEmailEnabled: n.isEmailEnabled,
        isBellEnabled: n.isBellEnabled,
        name: t(`components.notificationSettings.types.${n.type}`),
      })) ?? [],
    [t, user?.settings.notifications]
  );

  const [items, setItems] = useState(userNotifications);

  const onConfirm = async () => {
    const data: NotificationSettingsData[] = items.map((i) => ({
      type: i.id,
      isEmailEnabled: i.isEmailEnabled,
      isBellEnabled: i.isBellEnabled,
    }));
    if (await updateNotificationSettings(data)) {
      onClose();
    }
  };

  const onChangeEmailEnabled: ChangeCheckboxFunction = (item, checked) => {
    setItems((items) =>
      items.map((i) =>
        i.id === item.id ? { ...i, isEmailEnabled: checked } : i
      )
    );
  };

  const onChangeBellEnabled: ChangeCheckboxFunction = (item, checked) => {
    setItems((items) =>
      items.map((i) =>
        i.id === item.id ? { ...i, isBellEnabled: checked } : i
      )
    );
  };

  const resetSettings = useCallback(() => {
    setItems(userNotifications);
  }, [userNotifications]);

  return {
    items,
    updateLoading,
    resetSettings,
    onChangeBellEnabled,
    onChangeEmailEnabled,
    onConfirm,
  };
};
