import { PepConnectionEntity, RelationType } from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { PepConnectionFormType } from '../../forms/pepConnectionForm';

export const PepConnectionRelationPath: Record<RelationType, TranslationPath> =
  {
    [RelationType.not]: 'client.client.pepConnection.relationNo',
    [RelationType.yesIAm]: 'client.client.pepConnection.relationYesIAm',
    [RelationType.yesSpouses]: 'client.client.pepConnection.relationYesSpouses',
  };

export const parsePepConnectionInitialValue = (
  pepConnection: PepConnectionEntity | undefined,
  t: TFunc
) => {
  const values: PepConnectionFormType['pepConnection'] = {
    ...pepConnection,
    relation: pepConnection?.relation
      ? {
          value: pepConnection?.relation,
          label: t(PepConnectionRelationPath[pepConnection.relation]),
        }
      : null,
  };

  return values;
};
