import { object } from 'libs/form';
import {
  CompanyRegistrationAddressFormType as CompanyRegistrationAddressFormTypeBase,
  companyRegistrationAddressObject,
} from 'modules/client/common/forms/companyRegistrationAddressForm';

export {
  Field,
  Form,
  Submit,
  useFormContext,
} from 'modules/client/common/forms/companyRegistrationAddressForm';

export interface CompanyRegistrationAddressFormType {
  companyRegistrationAddress?: CompanyRegistrationAddressFormTypeBase['companyRegistrationAddress'];
}

export const companyRegistrationAddressSchema = object({
  companyRegistrationAddress: companyRegistrationAddressObject
    .optional()
    .default(undefined),
});
