import { RelationType } from 'api/types/entity';
import { ConnectedPepInfoParam } from 'api/types/params';

import { PepConnectionFormType } from '../../forms/pepConnectionForm';

export const parsePepConnectionParam = (
  values: PepConnectionFormType
): ConnectedPepInfoParam | null =>
  values.pepConnection.relation?.value === RelationType.yesSpouses
    ? {
        employerName: values.pepConnection.position!,
        firstName: values.pepConnection.firstname!,
        jobTitle: values.pepConnection.workName!,
        middleName: values.pepConnection.surname,
        lastName: values.pepConnection.lastname!,
      }
    : null;
