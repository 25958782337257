// eslint-disable-next-line no-restricted-imports
import { AvailableClientStatusesQuery } from 'api/generated/graphql';
import { AvailableClientStatusesData } from 'api/types/data';
import { lodash } from 'helpers';

import { parseClientAccountStatus } from './parseEntity/parseClientAccountStatus';
import { parseClientOnboardingStatus } from './parseEntity/parseClientOnboardingStatus';

export const parseAvailableClientStatusesData = (
  data: AvailableClientStatusesQuery | undefined
): AvailableClientStatusesData | undefined =>
  data
    ? {
        account: data.availableClientStatuses?.account
          ? lodash.compact(
              data.availableClientStatuses.account.map(parseClientAccountStatus)
            )
          : [],
        onboarding: data.availableClientStatuses?.onboarding
          ? lodash
              .compact(data.availableClientStatuses.onboarding)
              .map(parseClientOnboardingStatus)
          : [],
      }
    : undefined;
