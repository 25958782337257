import { SetStateAction, useCallback, useContext } from 'react';

import { useFormikContext, yupToFormErrors } from 'formik';
import { ObjectSchema } from 'yup';

import { FormContext } from './formContext';
import { FormErrors } from './types';

interface UserFormReturn<Values extends object> {
  /** Function that triggers form submission. */
  handleSubmit: () => void;
  validateForm: (values?: Partial<Values>) => Promise<FormErrors<Values>>;
  validateField: (
    field: Extract<keyof Values, string>
  ) => Promise<boolean | undefined>;
  setErrors: (formError: FormErrors<Values>) => void;
  setValues: (
    values: SetStateAction<Values>,
    shouldValidate?: boolean
  ) => Promise<void | FormErrors<Values>>;
  setFieldValue: (
    field: Extract<keyof Values, string>,
    value: Values[Extract<keyof Values, string>],
    shouldValidate?: boolean
  ) => Promise<void | FormErrors<Values>>;
  values: Values;
  initialValues: Values;
  errors: FormErrors<Values>;

  resetForm: () => void;
  dirty?: boolean;
  readOnly?: boolean;
  isSubmitting: boolean;
  isValidating: boolean;
  submitDisabled?: boolean;
  setSubmitDisabled: (v: boolean) => void;
  setReadOnly: (v: boolean) => void;
}
/**
 * Hook for accessing the Form context and retrieving the form submission handler.
 *
 * @template Values - The form values type.
 * @returns {UserFormReturn} An object containing the form submission handler.
 */
export const useFormContext = <
  Values extends object
>(): UserFormReturn<Values> => {
  const { readOnly, setReadOnly, submitDisabled, setSubmitDisabled } =
    useContext(FormContext);

  const {
    handleSubmit: formikHandleSubmit,
    validateForm,
    validationSchema,
    validateField,
    values,
    errors,
    setErrors: setErrorsBase,
    setValues,
    setFieldValue,
    resetForm,
    isSubmitting,
    isValidating,
    dirty,
    initialValues,
  } = useFormikContext<Values>();

  const handleSubmit = useCallback(
    () => formikHandleSubmit(),
    [formikHandleSubmit]
  );

  const handleValidateField = useCallback(
    async (field: Extract<keyof Values, string>) => {
      // formik not get field error! https://github.com/jaredpalmer/formik/issues/2021
      await validateField(field).catch();

      const res = await (validationSchema as ObjectSchema<Values>)
        ?.validate(values, { abortEarly: false })
        .catch((err) => {
          const errors = yupToFormErrors(err) as FormErrors<Values>;
          return !errors[field];
        });
      return !!res;
    },
    [validateField, validationSchema, values]
  );

  const setErrors = useCallback(
    (formError: FormErrors<Values>) => {
      setErrorsBase(formError);
    },
    [setErrorsBase]
  );

  return {
    handleSubmit,
    validateForm,
    validateField: handleValidateField,
    setErrors,
    setValues,
    setFieldValue,
    resetForm,
    dirty,
    values,
    initialValues,
    errors: errors as FormErrors<Values>,
    readOnly,
    isSubmitting,
    isValidating,
    submitDisabled,
    setReadOnly,
    setSubmitDisabled,
  };
};
