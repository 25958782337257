import { ArrowDown } from '../Icon/icons';

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './Accordion.styles';
import { AccordionProps } from './types';

/**
 * Accordion component.
 */
export const Accordion = ({
  content,
  label,
  defaultExpanded,
}: AccordionProps) => (
  <StyledAccordion
    disableGutters
    square
    defaultExpanded={defaultExpanded}
    elevation={0}
  >
    <StyledAccordionSummary expandIcon={<ArrowDown />}>
      {label}
    </StyledAccordionSummary>
    <StyledAccordionDetails>{content}</StyledAccordionDetails>
  </StyledAccordion>
);
