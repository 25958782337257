import { FC } from 'react';

import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';

import { RepresentativeForm, RepresentativeFormProps } from '../components';

export interface RepresentativeClientViewProps extends RepresentativeFormProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
}

export const RepresentativeClientView: FC<RepresentativeClientViewProps> = ({
  breadcrumbsTitles,
  ...formProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <RepresentativeForm {...formProps} />
  </LayoutWithNavigationBreadcrumbs>
);
