import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Download = ({
  size = 32,
  className,
  color = Colors.blue150,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 24V20C28 19.7348 27.8946 19.4804 27.7071 19.2929C27.5196 19.1054 27.2652 19 27 19C26.7348 19 26.4804 19.1054 26.2929 19.2929C26.1054 19.4804 26 19.7348 26 20V24C26 24.2652 25.8946 24.5196 25.7071 24.7071C25.5196 24.8946 25.2652 25 25 25H7C6.73478 25 6.48043 24.8946 6.29289 24.7071C6.10536 24.5196 6 24.2652 6 24V20C6 19.7348 5.89464 19.4804 5.70711 19.2929C5.51957 19.1054 5.26522 19 5 19C4.73478 19 4.48043 19.1054 4.29289 19.2929C4.10536 19.4804 4 19.7348 4 20V24C4 24.7957 4.31607 25.5587 4.87868 26.1213C5.44129 26.6839 6.20435 27 7 27H25C25.7957 27 26.5587 26.6839 27.1213 26.1213C27.6839 25.5587 28 24.7957 28 24ZM21.62 18.78L16.62 22.78C16.4435 22.9195 16.225 22.9954 16 22.9954C15.775 22.9954 15.5565 22.9195 15.38 22.78L10.38 18.78C10.1978 18.608 10.0868 18.3739 10.069 18.1241C10.0511 17.8742 10.1277 17.6267 10.2836 17.4306C10.4395 17.2344 10.6633 17.104 10.9108 17.065C11.1583 17.026 11.4114 17.0813 11.62 17.22L15 19.92V6C15 5.73478 15.1054 5.48043 15.2929 5.29289C15.4804 5.10536 15.7348 5 16 5C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6V19.92L20.38 17.22C20.4807 17.1249 20.6 17.0518 20.7305 17.0053C20.8609 16.9588 20.9996 16.9399 21.1377 16.9497C21.2758 16.9596 21.4104 16.9981 21.5329 17.0626C21.6553 17.1272 21.7631 17.2166 21.8492 17.325C21.9354 17.4334 21.9981 17.5585 22.0334 17.6924C22.0687 17.8263 22.0758 17.966 22.0542 18.1028C22.0327 18.2396 21.983 18.3704 21.9082 18.487C21.8335 18.6036 21.7353 18.7033 21.62 18.78Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
