import { getYesOrNoOptions } from 'helpers/yesOrNo';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/common/forms/debtsForm';

export interface FormContentProps {
  isEntrepreneur: boolean;
  isBase?: boolean;
}

export const FormContent = ({ isEntrepreneur, isBase }: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Price
        label={t('client.client.debts.taxes.label')}
        name="debts.taxes"
        placeholder={t('client.client.debts.taxes.placeholder')}
      />
      <Field.Price
        label={t('client.client.debts.fees.label')}
        name="debts.fees"
        placeholder={t('client.client.debts.fees.placeholder')}
      />
      <Field.Price
        label={t('client.client.debts.penalties.label')}
        name="debts.penalties"
        placeholder={t('client.client.debts.penalties.placeholder')}
      />
      <Field.Price
        label={t('client.client.debts.finesForViolation.label')}
        name="debts.finesForViolation"
        placeholder={t('client.client.debts.finesForViolation.placeholder')}
      />
      {isEntrepreneur && (
        <>
          <Field.Autocomplete
            label={t('client.client.debts.bankruptcyProceedings.label')}
            name="debts.bankruptcyProceedings"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t(
              'client.client.debts.bankruptcyProceedings.placeholder'
            )}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'client.client.debts.delaysOrNonFulfillmentOfObligations.label'
            )}
            name="debts.delaysOrNonFulfillmentOfObligations"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t(
              'client.client.debts.delaysOrNonFulfillmentOfObligations.placeholder'
            )}
            variant="select"
          />
        </>
      )}

      {!isBase && (
        <Field.Autocomplete
          label={t('client.client.debts.marginTrades.label')}
          name="debts.marginTrades"
          optionLabelKey="label"
          options={getYesOrNoOptions(t)}
          optionValueKey="value"
          placeholder={t('client.client.debts.marginTrades.placeholder')}
          variant="select"
        />
      )}
    </>
  );
};
