import { ComponentType } from 'react';

import { NestedKeyOf } from 'types/nestedKeyOf';

import { Form } from './Form';
import { Submit } from './Submit';
import { fields } from './fields';
import { useFormContext } from './useFormContext';

/**
 * A utility function for creating a typed form with enhanced fields.
 *
 * @template Value - The type of form values.
 * @returns {object} An object with properties for the Form, Submit component, Field components,
 * and the useFormContext hook, all properly typed.
 */
export const TypedForm = <Value extends object>() => ({
  Form: Form<Value>,
  Submit,
  Field: fields as TypedFormField<NestedKeyOf<Value>>,
  useFormContext: () => useFormContext<Value>(),
});

type EnhancedFields<Fields, Keys> = {
  [K in keyof Fields]: Fields[K] extends ComponentType<infer Props>
    ? ComponentType<Props & { name: Keys }>
    : Fields[K];
};

export type TypedFormField<Keys> = EnhancedFields<typeof fields, Keys>;
