import { NavigationLinkClasses } from 'components/NavigationLink';
import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContainerStyled = styled(Stack)(({ theme }) => ({
  [`.${NavigationLinkClasses.activeLink}`]: {
    '.navigationMenu-link': {
      color: Colors.blue150,
      ...theme.typography.bodyBold,
    },
  },
}));
