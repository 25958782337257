// eslint-disable-next-line no-restricted-imports
import { useLegalClientBranchQuery } from 'api/generated/graphql';
import { parseLegalClientBranchEntity, useError } from 'api/helpers';

export const useLegalClientBranch = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientBranchQuery({ variables: { id } });

  const error = useError(apolloError);

  const legalClientBranch = data?.legalClientBranch
    ? parseLegalClientBranchEntity(data.legalClientBranch)
    : null;

  return {
    legalClientBranch,
    loading,
    error,
    refetch,
  };
};
