import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_UserController_GetList_Status as StatusApi,
  QueryInput_UserController_GetList_RoleName as RoleNameApi,
  useAdminsQuery,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { lodash } from 'helpers';

export const useManagersForClients = () => {
  const {
    data,
    loading,
    error: apolloError,
  } = useAdminsQuery({
    variables: {
      roleName: RoleNameApi.Admin,
      status: StatusApi.Active,
    },
  });

  const error = useError(apolloError);

  const managers = useMemo(
    () =>
      lodash.compact(data?.admins?.list).map((admin) => ({
        id: admin.id,
        firstName: admin.firstName,
        lastName: admin.lastName,
        middleName: admin.middleName,
      })),
    [data?.admins?.list]
  );

  return {
    managers,
    loading,
    error,
  };
};
