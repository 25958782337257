import { CountryEntity } from 'api/types/entity';
import {
  string,
  object,
  array,
  boolean,
  ObjectSchema,
  TypedForm,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface TaxResidencyFormTaxValue {
  taxNumberIsMissing?: boolean;
  taxNumber?: string;
  reasonForAbsence?: string;
  country: Maybe<CountryEntity>;
}

export interface TaxResidencyFormType {
  taxResidences: TaxResidencyFormTaxValue[];
}

export const taxResidencesSchema: ObjectSchema<TaxResidencyFormType> = object({
  taxResidences: array()
    .of(
      object({
        taxNumberIsMissing: boolean().default(false),
        taxNumber: string().when('taxNumberIsMissing', {
          is: false,
          then: (schema) => schema.required().max(50),
        }),
        reasonForAbsence: string().when('taxNumberIsMissing', {
          is: true,
          then: (schema) => schema.required().max(2000),
        }),
        country: object({
          id: string().required(),
          name: string().required(),
        }).required(),
      }).required()
    )
    .required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<TaxResidencyFormType>();
