// eslint-disable-next-line no-restricted-imports
import { useTwoFactorVerifyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useTwoFactorVerify = () => {
  const [handle, { data, loading, error: apolloError }] =
    useTwoFactorVerifyMutation();

  const error = useError(apolloError);

  return {
    data: data?.OtpController_verify,
    loading,
    error,
    verify: async (code: string) => {
      const res = await handle({ variables: { token: code } });

      return res.data?.OtpController_verify;
    },
  };
};
