import { DebtsEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers/yesOrNo';
import { TFunc } from 'libs/i18n';

import { DebtsFormType } from '../../forms/debtsForm';

export const parseDebtsInitialValue = (
  debsEntity: DebtsEntity | undefined,
  t: TFunc
) => {
  const bankruptcyProceedings = yesOrNotOption(
    t,
    debsEntity?.hasBankruptcyProceedings
  );
  const delaysOrNonFulfillmentOfObligations = yesOrNotOption(
    t,
    debsEntity?.hasDelaysOrNonFulfillmentOfObligations
  );

  const marginTrades = yesOrNotOption(t, debsEntity?.hasMarginTrades);

  const values: DebtsFormType['debts'] = {
    ...debsEntity,

    bankruptcyProceedings,
    delaysOrNonFulfillmentOfObligations,
    marginTrades,
  };

  return values;
};
