import {
  RiskLevelType,
  InformationUpdateFrequencyType,
} from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { RiskLevelFormType } from '../../forms/riskLevelForm';

interface Client {
  informationUpdateFrequency?: InformationUpdateFrequencyType;
  riskLevel?: RiskLevelType;
  riskLevelReviewDate?: Date;
  lastInformationUpdateDate?: Date;
  lastOperationMonitoringResults?: string;
  usedServices?: string;
}

export const parseRiskLevelInitialValue = (client: Client, t: TFunc) => {
  const values: RiskLevelFormType['riskLevel'] = {
    informationUpdateFrequency: client.informationUpdateFrequency
      ? {
          label: t(
            `client.riskLevel.informationUpdateFrequencyType.${client.informationUpdateFrequency}`
          ),
          value: client.informationUpdateFrequency,
        }
      : null,

    riskLevel: client.riskLevel
      ? {
          label: t(`client.riskLevel.riskLevelType.${client.riskLevel}`),
          value: client.riskLevel,
        }
      : null,

    riskLevelReviewDate: client.riskLevelReviewDate,
    lastInformationUpdateDate: client.lastInformationUpdateDate,
    lastOperationMonitoringResults: client.lastOperationMonitoringResults,
    usedServices: client.usedServices,
  };

  return values;
};
