import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Bell = ({
  size = 26,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="26"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1256 22.75C13.9544 24.1944 11.1062 24.1944 8.93505 22.75M4.94713 10.8333C4.94713 6.64517 8.3423 3.25 12.5305 3.25C16.7186 3.25 20.1138 6.64517 20.1138 10.8333V13.6259C20.1138 15.337 20.6203 17.0097 21.5694 18.4333L21.719 18.6579C21.959 19.0178 21.701 19.5 21.2683 19.5H3.79258C3.35995 19.5 3.10191 19.0178 3.34188 18.6579L3.49157 18.4333C4.44067 17.0097 4.94713 15.337 4.94713 13.6259V10.8333Z"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);
