import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientVersionsQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClientVersionEntity } from 'api/helpers/legal/parseEntity/parseLegalClientVersionEntity';
import { lodash } from 'helpers';

export const useLegalClientVersions = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientVersionsQuery({ variables: { id } });

  const error = useError(apolloError);

  const versions = useMemo(
    () =>
      data?.legalClientVersions
        ? lodash.compact(
            data.legalClientVersions.map(parseLegalClientVersionEntity)
          )
        : null,
    [data?.legalClientVersions]
  );

  return {
    versions,
    loading,
    error,
    refetch,
  };
};
