import { OrganizationType } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  Field,
  isCommercial,
  useFormContext,
} from 'modules/client/legal/forms/companyOrganizationalAndLegalFormForm';

export const FormContent = () => {
  const { values } = useFormContext();

  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      <Field.Autocomplete
        label={t(
          'client.legal.organizationalAndLegalForm.organizationTypeInput.label'
        )}
        name="organizationalAndLegalForm.organizationType"
        optionLabelKey="label"
        options={Object.values(OrganizationType).map((v) => ({
          label: t(
            `client.legal.organizationalAndLegalForm.organizationType.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.legal.organizationalAndLegalForm.organizationTypeInput.placeholder'
        )}
        variant="select"
      />

      {isCommercial(values.organizationalAndLegalForm?.organizationType) && (
        <>
          <Field.Autocomplete
            label={t(
              'client.legal.organizationalAndLegalForm.isFinancialInstitutionLabel'
            )}
            name="organizationalAndLegalForm.isFinancialInstitution"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'client.legal.organizationalAndLegalForm.isUnincorporatedStructureLabel'
            )}
            name="organizationalAndLegalForm.isUnincorporatedStructure"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />
        </>
      )}

      <Field.Text
        label={t(
          'client.legal.organizationalAndLegalForm.organizationalAndLegalFormLabel'
        )}
        name="organizationalAndLegalForm.organizationalAndLegalForm"
        placeholder={t(
          'client.legal.organizationalAndLegalForm.organizationalAndLegalFormPlaceholder'
        )}
      />
    </Stack>
  );
};
