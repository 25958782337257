import { FC } from 'react';

import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';

import {
  LegalClientFirstHeadForm,
  LegalClientFirstHeadFormProps,
} from '../components';

export interface LegalClientFirstHeadViewProps
  extends LegalClientFirstHeadFormProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
}

export const LegalClientFirstHeadView: FC<LegalClientFirstHeadViewProps> = ({
  breadcrumbsTitles,
  ...formProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <LegalClientFirstHeadForm {...formProps} />
  </LayoutWithNavigationBreadcrumbs>
);
