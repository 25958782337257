import { SvgIcon } from 'libs/ui/Icon';
import { SvgProps } from 'libs/ui/Icon/icons/types';
import { Colors } from 'libs/ui/theme';

export const ReadIcon = ({
  className,
  size = { width: 14, height: 11 },
  color = Colors.gray250,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="11"
      viewBox="0 0 14 11"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 5L5 9L13 1" stroke={color} strokeWidth="2" />
    </svg>
  </SvgIcon>
);
