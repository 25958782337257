import { LayoutClasses } from 'components/Layout/types';
import { GlobalStyles } from 'libs/ui/styled';

export const LayoutOwerlay = () => (
  <GlobalStyles
    styles={{
      [`.${LayoutClasses.content}`]: {
        marginTop: '20px !important',
      },
    }}
  />
);
