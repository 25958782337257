import {
  AssetType,
  BusinessRelationshipGoalType,
  BusinessRelationshipNatureType,
  OperationType,
} from 'api/types/entity/legal';
import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  mixed,
  array,
  priceAndCurrency,
} from 'libs/form';
import { PriceFieldValue } from 'libs/form/fields/PriceField';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';

export interface CompanyBusinessRelationshipFormType {
  businessRelationship: {
    plannedAssetTypes: SelectType<AssetType>[];
    plannedOperationTypes: SelectType<OperationType>[];
    otherPlannedOperationType?: string;
    plannedAssetSize: PriceFieldValue;
    businessRelationshipGoals: SelectType<BusinessRelationshipGoalType>[];
    otherBusinessRelationshipGoal?: string;
    businessRelationshipNatures: SelectType<BusinessRelationshipNatureType>[];
    otherBusinessRelationshipNature?: string;
  };
}

const isOtherPlannedOperationTypes = (values: SelectType<OperationType>[]) =>
  values?.find((v) => v?.value === OperationType.Other) !== undefined;

const isOtherBusinessRelationshipGoals = (
  values: SelectType<BusinessRelationshipGoalType>[]
) =>
  values?.find((v) => v?.value === BusinessRelationshipGoalType.Other) !==
  undefined;

const isOtherBusinessRelationshipNatures = (
  values: SelectType<BusinessRelationshipNatureType>[]
) =>
  values?.find((v) => v?.value === BusinessRelationshipNatureType.Other) !==
  undefined;

export const getPlannedAssetTypesOptions = (t: TFunc) =>
  Object.values(AssetType).map((v) => ({
    label: t(`client.legal.companyBusinessRelationship.assetTypeOption.${v}`),
    value: v,
  }));

export const getPlannedOperationTypesOptions = (t: TFunc) =>
  Object.values(OperationType).map((v) => ({
    label: t(
      `client.legal.companyBusinessRelationship.plannedOperationTypesOption.${v}`
    ),
    value: v,
  }));

export const getBusinessRelationshipGoalTypeOption = (t: TFunc) =>
  Object.values(BusinessRelationshipGoalType).map((v) => ({
    label: t(
      `client.legal.companyBusinessRelationship.businessRelationshipGoalTypeOption.${v}`
    ),
    value: v,
  }));

export const getBusinessRelationshipNatureTypeOption = (t: TFunc) =>
  Object.values(BusinessRelationshipNatureType).map((v) => ({
    label: t(
      `client.legal.companyBusinessRelationship.businessRelationshipNatureTypeOption.${v}`
    ),
    value: v,
  }));

export const getCompanyBusinessRelationshipSchema = (
  t: TFunc
): ObjectSchema<CompanyBusinessRelationshipFormType> =>
  object({
    businessRelationship: object({
      plannedAssetTypes: array()
        .of(
          object({
            value: mixed<AssetType>()
              .oneOf(Object.values(AssetType))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),

      plannedOperationTypes: array()
        .of(
          object({
            value: mixed<OperationType>()
              .oneOf(Object.values(OperationType))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),

      plannedAssetSize: priceAndCurrency().required(),

      businessRelationshipGoals: array()
        .of(
          object({
            value: mixed<BusinessRelationshipGoalType>()
              .oneOf(Object.values(BusinessRelationshipGoalType))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),

      businessRelationshipNatures: array()
        .of(
          object({
            value: mixed<BusinessRelationshipNatureType>()
              .oneOf(Object.values(BusinessRelationshipNatureType))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),

      otherPlannedOperationType: string().when('plannedOperationTypes', {
        is: isOtherPlannedOperationTypes,
        then: (schema) => schema.required().max(2000),
      }),

      otherBusinessRelationshipGoal: string().when(
        'businessRelationshipGoals',
        {
          is: isOtherBusinessRelationshipGoals,
          then: (schema) => schema.required().max(2000),
        }
      ),

      otherBusinessRelationshipNature: string().when(
        'businessRelationshipNatures',
        {
          is: isOtherBusinessRelationshipNatures,
          then: (schema) => schema.required().max(2000),
        }
      ),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyBusinessRelationshipFormType>();
