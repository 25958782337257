import {
  LegalClientStateRegistrationParam,
  LegalClientBaseStateRegistrationParam,
} from 'api/types/params/';
import { formatToISODate } from 'helpers';

import {
  CompanyBaseStateRegistrationFormType,
  CompanyStateRegistrationFormType,
} from '../../forms/companyStateRegistrationForm';

export const parseBaseStateRegistrationParam = (
  values: CompanyBaseStateRegistrationFormType
): LegalClientBaseStateRegistrationParam => ({
  initialStateRegistrationDate: formatToISODate(
    values.companyStateRegistration.date
  ),
  registrationAuthorityName: values.companyStateRegistration.nameAuthority,
  registrationNumber: values.companyStateRegistration.number,
  registrationDocumentName:
    values.companyStateRegistration.registrationDocumentName,
  stateRegistrationConfirmationDocument: {
    dateOfIssue: formatToISODate(values.companyStateRegistration.dateOfIssue),
  },
});

export const parseStateRegistrationParam = (
  values: CompanyStateRegistrationFormType
): LegalClientStateRegistrationParam => {
  const baseData = parseBaseStateRegistrationParam(values);
  return {
    ...baseData,
    stateRegistrationConfirmationDocument: {
      ...baseData.stateRegistrationConfirmationDocument,
      fileIds: values.companyStateRegistration.files.map(({ id }) => id!),
    },
  };
};
