import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUnlockClientMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useUnlockClient = () => {
  const [handle, { loading, error: apolloError }] = useUnlockClientMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    unlock: useCallback(
      async (id: string) => {
        const res = await handle({ variables: { id } });
        return !!res.data?.unlock;
      },
      [handle]
    ),
  };
};
