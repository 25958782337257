import { FC } from 'react';

import { parseFullName } from 'helpers';
import { useFormErrorFocus } from 'hooks';
import { FormContentWrapper } from 'modules/client/common/components';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { useFormContext } from '../../../forms/legalClientBeneficiaryForm';
import {
  LegalClientBeneficiarySections,
  LegalClientBeneficiarySectionsProps,
} from '../../LegalClientBeneficiarySections';

export interface FormContentProps
  extends LockAndUnlockReadOnlyProps,
    LegalClientBeneficiarySectionsProps {
  submitLoading?: boolean;
  onShowVersionHistory?: () => void;
}

export const FormContent: FC<FormContentProps> = ({
  submitLoading,
  unlockClient,
  lockClientIfPossible,
  onShowVersionHistory,
  ...sectionProps
}) => {
  const { resetForm, values, dirty, setReadOnly } = useFormContext();

  const fullName = values ? parseFullName(values.personalData) : '';

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  useFormErrorFocus();

  return (
    <FormContentWrapper
      content={<LegalClientBeneficiarySections {...sectionProps} />}
      dirty={dirty}
      fullName={fullName}
      resetForm={resetForm}
      shownTabBar={false}
      submitLoading={submitLoading}
      onEdit={onDisableReadOnly}
      onEnableReadOnly={onEnableReadOnly}
      onShowVersionHistory={onShowVersionHistory}
    />
  );
};
