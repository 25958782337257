import { LegalClientOnboardingData } from 'api/types/data/legalClientOnboardingData';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

// eslint-disable-next-line no-restricted-imports
import {
  ManagerFragment,
  Query_LegalClientController_GetById_Onboarding_Statuses_Items_Value as OnboardingStatusValue,
  Query_LegalClientController_GetList_AllOf_1_List_Items_Onboarding_Statuses_Items_Value as OnboardingStatusValue2,
} from '../../generated/graphql';
import { parseManagerData } from '../parseManagerData';

import { parseLegalClientOnboardingStatus } from './parseEntity/parseLegalClientOnboardingStatus';

type LegalClientOnboardingDataStatusApi = {
  value: OnboardingStatusValue | OnboardingStatusValue2;
  createdBy?: ManagerFragment | null;
  createdAt: string;
  result?: string | null;
};

export type ClientOnboardingDataApi =
  | {
      statuses?: Maybe<LegalClientOnboardingDataStatusApi>[] | null;
    }
  | undefined
  | null;

export const parseLegalClientOboardingData = (
  onboarding?: ClientOnboardingDataApi
): LegalClientOnboardingData | undefined => ({
  statuses: onboarding?.statuses
    ? lodash
        .compact(onboarding.statuses)
        .map(({ createdAt, value, createdBy, result }) => ({
          value: parseLegalClientOnboardingStatus(value),
          createdBy: createdBy ? parseManagerData(createdBy) : null,
          createdAt: new Date(createdAt),
          result: result ?? '',
        }))
    : undefined,
});
