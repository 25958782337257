import { FC } from 'react';

import { useTwoFactorConnection } from '../hooks';
import { TwoFactorConnectionView } from '../views/TwoFactorConnectionView';

export type TwoFactorConnectionContainerProps = {};

export const TwoFactorConnectionContainer: FC<
  TwoFactorConnectionContainerProps
> = () => {
  const { submitLoading, formError, onSubmit, qrData, qrLoading } =
    useTwoFactorConnection();

  return (
    <TwoFactorConnectionView
      formError={formError}
      qrData={qrData}
      qrLoading={qrLoading}
      submitLoading={submitLoading}
      onSubmit={onSubmit}
    />
  );
};
