import { CountryEntity } from 'api/types/entity';
import { yesOrNotSelectValidation } from 'helpers';
import { TypedForm, ObjectSchema, object, array, string } from 'libs/form';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType } from 'types/yesOrNo';

export interface CompanyBankAccountsFormValue {
  swiftCode: string;
  country: Maybe<CountryEntity>;
  hasPermanentManagementInCountry: Maybe<YesOrNoSelectType>;
  onTerritoryWithNoAML_CFT: Maybe<YesOrNoSelectType>;
}

export interface CompanyBankAccountsFormType {
  bankAccounts: CompanyBankAccountsFormValue[];
}

export const companyBankAccountsSchema: ObjectSchema<CompanyBankAccountsFormType> =
  object({
    bankAccounts: array()
      .of(
        object({
          swiftCode: string().required().max(200),
          country: object({
            id: string().required(),
            name: string().required(),
          }).required(),
          hasPermanentManagementInCountry: yesOrNotSelectValidation.required(),
          onTerritoryWithNoAML_CFT: yesOrNotSelectValidation.required(),
        })
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyBankAccountsFormType>();
