import { VersionEntityBase } from './versionEntity';

export interface ClientVersionEntity extends VersionEntityBase {
  updatedEntity: ClientVersionUpdatedEntity;
}

export interface ClientVersionUpdatedEntity {
  type: ClientVersionUpdatedEntityType;
  id: string;
  fullName: string;
}

export enum ClientVersionUpdatedEntityType {
  Client = 'Client',
  ClientRepresentative = 'ClientRepresentative',
}
