import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import { ControlOverFormType, controlOverSchema } from './controlOverForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import { PlaceOfBirthFormType, placeOfBirthSchema } from './placeOfBirthForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import {
  ResidentialAddressFormType,
  residentialAddressSchema,
} from './residentialAddressForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';

export interface LegalClientBeneficiaryFormType
  extends ControlOverFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    PlaceOfBirthFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    PepConnectionFormType,
    ReasonChangeFormType {}

export const getSchema = (
  t: TFunc
): ObjectSchema<LegalClientBeneficiaryFormType> =>
  object()
    .concat(getPersonalDataSchema(t))
    .concat(citizenshipsSchema)
    .concat(taxResidencesSchema)
    .concat(placeOfBirthSchema)
    .concat(registrationAddressSchema)
    .concat(residentialAddressSchema)
    .concat(controlOverSchema)
    .concat(getPepConnectionSchema(t))
    .concat(reasonChangeSchema);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientBeneficiaryFormType>();

export type FormErrors = FormErrorsBase<LegalClientBeneficiaryFormType>;
