// eslint-disable-next-line no-restricted-imports
import { UserSettingsFragment } from 'api/generated/graphql';
import { NotificationSettingsType } from 'api/types/data';
import { UserEntity } from 'api/types/entity';
import { lodash } from 'helpers';

export const parseUserSettings = (
  settings: UserSettingsFragment
): UserEntity['settings'] =>
  ({
    timezone: settings.timezone ?? undefined,
    notifications: lodash.compact(settings.notifications).map((v) => ({
      isEmailEnabled: v.isEmailEnabled,
      isBellEnabled: v.isBellEnabled,
      type: NotificationSettingsType[v.type],
    })),
  } as UserEntity['settings']);
