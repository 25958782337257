import { RoleType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Box, Stack } from 'libs/ui';

import { Field } from '../../../forms/createAdminForm';

export const FormContent = () => {
  const { t } = useTranslation();

  // remove super-admin role
  const roles = Object.values(RoleType).filter(
    (v) => v !== RoleType.SuperAdmin
  );
  return (
    <Stack spacing={24}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        spacing={24}
      >
        <Field.Text
          fullWidth
          autoComplete="off"
          label={t('admin.fields.email.label')}
          name="email"
          placeholder={t('admin.fields.email.placeholder')}
        />

        <Field.Text
          fullWidth
          label={t('admin.fields.lastName.label')}
          name="lastName"
          placeholder={t('admin.fields.lastName.placeholder')}
        />
      </Stack>

      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        spacing={24}
      >
        <Field.Text
          fullWidth
          label={t('admin.fields.position.label')}
          name="position"
          placeholder={t('admin.fields.position.placeholder')}
        />

        <Field.Text
          fullWidth
          label={t('admin.fields.firstName.label')}
          name="firstName"
          placeholder={t('admin.fields.firstName.placeholder')}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between" spacing={24}>
        <Box flex={1}>
          <Field.Autocomplete
            helperTextAbsolute
            hideClearIcon
            label={t('admin.fields.role.label')}
            name="role"
            optionLabelKey="label"
            options={roles.map((v) => ({
              label: t(`admin.adminRoles.${v}`),
              value: v,
            }))}
            optionValueKey="value"
            placeholder={t('admin.fields.role.placeholder')}
            variant="select"
          />
        </Box>
        <Box flex={1}>
          <Field.Text
            fullWidth
            helperTextAbsolute
            label={t('admin.fields.middleName.label')}
            name="middleName"
            placeholder={t('admin.fields.middleName.placeholder')}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
