import { UpdateLegalClientBranchParams } from 'api/types/params';
import {
  parseCompanyAddressParam,
  parseBaseLicenseParam,
  parseBaseStateRegistrationParam,
  parseCompanyTaxResidencyParam,
  parseAdditionalInformationFileIdsParam,
} from 'modules/client/common/helpers';

import { LegalClientBranchFormType } from '../../forms/legalClientBranchForm';

export const getLegalClientBranchSubmitData = (
  versionNumber: number,
  values: LegalClientBranchFormType
): UpdateLegalClientBranchParams => {
  const addressParam = parseCompanyAddressParam(values);

  return {
    versionNumber,
    reason: values.reasonChange.reason,
    fullName: values.companyName.fullName,
    legalAddress: addressParam.legalAddress,
    actualAddress: addressParam.actualAddress,
    email: values.contacts.email ?? null,
    phone: values.contacts.phone ?? null,
    license: parseBaseLicenseParam(values),
    stateRegistration: parseBaseStateRegistrationParam(values),
    taxResidences: parseCompanyTaxResidencyParam(values),
    additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
      values.additionalDocumentsFiles
    ),
  };
};
