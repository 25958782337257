// eslint-disable-next-line no-restricted-imports
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';

import { CSSObject, styled } from '../styled';
import { Colors } from '../theme';

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      border: 0,
      borderRadius: 40,
      paddingRight: 32,
      paddingLeft: 32,
      paddingTop: 9,
      paddingBottom: 9,

      borderColor: Colors.blue100,
      borderWidth: 1,
      borderStyle: 'solid',
    } as CSSObject,

    '& .toggleButton-button': {
      ...theme.typography.body,
      textTransform: 'none',
      color: Colors.black,
      height: 44,

      '&.Mui-selected': {
        backgroundColor: Colors.blue50,
        color: Colors.blue150,
        ...theme.typography.bodyBold,
      } as CSSObject,

      '&:hover': {
        backgroundColor: Colors.blue100,
      },
    } as CSSObject,
  })
);
