import { useUpdateLegalClientRepresentative as useUpdateLegalClientRepresentativeApi } from 'api/requests';
import { LegalClientRepresentativeEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { LegalRepresentativeFormType } from '../forms/legalRepresentativeForm';
import { getLegalRepresentativeSubmitData } from '../helpers';

export const useUpdateLegalClientRepresentative = () => {
  const { updateLegalClientRepresentative, loading, error } =
    useUpdateLegalClientRepresentativeApi();

  const notify = useNotify();
  const { t } = useTranslation();

  const handleUpdate = async (
    legalRepresentative: LegalClientRepresentativeEntity,
    values: LegalRepresentativeFormType,
    client?: LegalClientRepresentativeEntity | null
  ) => {
    if (legalRepresentative.versionNumber !== undefined) {
      const data = getLegalRepresentativeSubmitData(
        legalRepresentative.versionNumber,
        values,
        client
      );
      if (await updateLegalClientRepresentative(legalRepresentative.id, data)) {
        notify.info(t('common.successSave'));
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientRepresentative: handleUpdate,
  };
};
