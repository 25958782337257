// eslint-disable-next-line no-restricted-imports
import { NotificationListItemFragment } from 'api/generated/graphql';
import { NotificationSettingsType } from 'api/types/data';
import { NotificationEntity } from 'api/types/entity';

import { parseLegalClientOnboardingStatus } from '../legal/parseEntity/parseLegalClientOnboardingStatus';

export const parseNotificationEntity = (
  notification: NotificationListItemFragment
): NotificationEntity => ({
  id: notification.id,
  isRead: !!notification.readAt,
  createdAt: new Date(notification.createdAt),
  type: NotificationSettingsType[notification.type],
  payload: {
    clientFullName: notification.payload.clientFullName,
    clientId: notification.payload.clientId,
    clientType: notification.payload.clientType,
    currentOnboardingStatus: parseLegalClientOnboardingStatus(
      notification.payload.currentOnboardingStatus
    ),
    prevOnboardingStatus: parseLegalClientOnboardingStatus(
      notification.payload.prevOnboardingStatus
    ),
  },
});
