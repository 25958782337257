import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useAdminResetPasswordMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useAdminResetPassword = () => {
  const [resetPassword, { data, loading, error: apolloError }] =
    useAdminResetPasswordMutation();

  const error = useError(apolloError);

  const admin = data?.resetPassword ?? null;

  return {
    admin,
    loading,
    error,
    resetPassword: useCallback(
      async (id: string) => {
        const res = await resetPassword({ variables: { id } });

        return res.data?.resetPassword;
      },
      [resetPassword]
    ),
  };
};
