export enum ClientAccountStatusType {
  /** Счет открыт */
  Opened = 'Opened',
  /** Счет заморожен */
  Frozen = 'Frozen',
  /** Идет закрытие счета */
  ClosingInProgress = 'ClosingInProgress',
  /** Счет закрыт */
  Closed = 'Closed',
  /** Счет открыт с ограничениями */
  OpenedWithRestrictions = 'OpenedWithRestrictions',
}
