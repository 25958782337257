// eslint-disable-next-line no-restricted-imports
import { DebtsDto_Input } from 'api/generated/graphql';
import { BaseDebtsEntity, DebtsEntity } from 'api/types/entity';

import { parsePriceParam } from '../../parseParam';

export const parseBaseDebtsParam = (data: BaseDebtsEntity) => ({
  hasBankruptcyProceedings: data.hasBankruptcyProceedings,
  hasDelaysOrNonFulfillmentOfObligations:
    data.hasDelaysOrNonFulfillmentOfObligations,
  fees: data.fees ? parsePriceParam(data.fees) : undefined,
  finesForViolation: data.finesForViolation
    ? parsePriceParam(data.finesForViolation)
    : undefined,
  penalties: data.penalties ? parsePriceParam(data.penalties) : undefined,
  taxes: data.taxes ? parsePriceParam(data.taxes) : undefined,
});

export const parseDebtsParam = (data: DebtsEntity): DebtsDto_Input => ({
  ...parseBaseDebtsParam(data),
  hasMarginTrades: data.hasMarginTrades,
});
