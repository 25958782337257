import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps {}

export const ResultCheckSection = ({ ...formProps }: Props) => {
  const { t } = useTranslation();

  if (!formProps.statuses?.length && formProps.hideKyc) {
    return null;
  }

  return (
    <Section title={t('client.client.resultCheckSection.title')}>
      <FormContent {...formProps} />
    </Section>
  );
};
