// eslint-disable-next-line no-restricted-imports
import { MutationInput_LegalClientController_SaveOnboardingStatus_Input_Value as LegalClientOnboardingStatusTypeApi } from 'api/generated/graphql';
import { ClientOnboardingStatusType } from 'api/types/entity';

const statusMap: Record<
  ClientOnboardingStatusType,
  LegalClientOnboardingStatusTypeApi
> = {
  [ClientOnboardingStatusType.FillingOut]:
    LegalClientOnboardingStatusTypeApi.FillingOut,
  [ClientOnboardingStatusType.AccountOpened]:
    LegalClientOnboardingStatusTypeApi.AccountOpened,
  [ClientOnboardingStatusType.ComplianceCheck]:
    LegalClientOnboardingStatusTypeApi.ComplianceCheck,
  [ClientOnboardingStatusType.AccountOpeningDenied]:
    LegalClientOnboardingStatusTypeApi.AccountOpeningDenied,
  [ClientOnboardingStatusType.AmlCftCheck]:
    LegalClientOnboardingStatusTypeApi.AmlCftCheck,
  [ClientOnboardingStatusType.AccountOpening]:
    LegalClientOnboardingStatusTypeApi.AccountOpening,
  [ClientOnboardingStatusType.AwaitingAccountOpening]:
    LegalClientOnboardingStatusTypeApi.AwaitingAccountOpening,
  [ClientOnboardingStatusType.AwaitingAmlCftCheck]:
    LegalClientOnboardingStatusTypeApi.AwaitingAmlCftCheck,
  [ClientOnboardingStatusType.AwaitingComplianceCheck]:
    LegalClientOnboardingStatusTypeApi.AwaitingComplianceCheck,
  [ClientOnboardingStatusType.AwaitingValidation]:
    LegalClientOnboardingStatusTypeApi.AwaitingValidation,
  [ClientOnboardingStatusType.BoardReview]:
    LegalClientOnboardingStatusTypeApi.BoardReview,
  [ClientOnboardingStatusType.ValidationCheck]:
    LegalClientOnboardingStatusTypeApi.ValidationCheck,
  [ClientOnboardingStatusType.PendingBoardReview]:
    LegalClientOnboardingStatusTypeApi.PendingBoardReview,
  [ClientOnboardingStatusType.SigningDocuments]:
    LegalClientOnboardingStatusTypeApi.SigningDocuments,
  [ClientOnboardingStatusType.AwaitingPaymentConfirmation]:
    LegalClientOnboardingStatusTypeApi.AwaitingPaymentConfirmation,
};

export const parseLegalClientOnboardingStatusParam = (
  onboardingStatus: ClientOnboardingStatusType
): LegalClientOnboardingStatusTypeApi => statusMap[onboardingStatus];
