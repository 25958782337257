import {
  CivilLawSubjectType,
  RoleInCompanyType,
} from 'api/types/entity/legalClientRepresentative/roleInCompanyEntity';
import { useTranslation } from 'libs/i18n';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/client/common/helpers';

import { useFormContext, Field } from '../../../../forms/roleInCompanyForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();
  const { values, readOnly } = useFormContext();
  return (
    <>
      <Field.Autocomplete
        readOnly
        label={t('client.legalRepresentative.roleInCompany.role.label')}
        name="roleInCompany.role"
        optionLabelKey="label"
        options={Object.values(RoleInCompanyType).map((v) => ({
          label: t(`client.legalRepresentative.roleInCompany.roles.${v}`),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.legalRepresentative.roleInCompany.role.placeholder'
        )}
        variant="select"
      />

      {values.roleInCompany.role?.value ===
        RoleInCompanyType.AuthorizedRepresentative && (
        <Field.Autocomplete
          readOnly
          label={t(
            'client.legalRepresentative.roleInCompany.civilLawSubject.label'
          )}
          name="roleInCompany.civilLawSubject"
          optionLabelKey="label"
          options={Object.values(CivilLawSubjectType).map((v) => ({
            label: t(
              `client.legalRepresentative.roleInCompany.civilLawSubjects.${v}`
            ),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t(
            'client.legalRepresentative.roleInCompany.civilLawSubject.placeholder'
          )}
          variant="select"
        />
      )}

      <Field.Text
        label={t('client.legalRepresentative.roleInCompany.number.label')}
        name="roleInCompany.number"
        placeholder={t(
          'client.legalRepresentative.roleInCompany.number.placeholder'
        )}
      />

      <Field.DatePicker
        label={t('client.legalRepresentative.roleInCompany.dateOfIssue.label')}
        maxDate={getToday()}
        name="roleInCompany.dateOfIssue"
        placeholder={t(
          'client.legalRepresentative.roleInCompany.dateOfIssue.placeholder'
        )}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t(
          'client.legalRepresentative.roleInCompany.dateOfExpiration.label'
        )}
        minDate={getTodayWithResetHours()}
        name="roleInCompany.dateOfExpiration"
        placeholder={t(
          'client.legalRepresentative.roleInCompany.dateOfExpiration.placeholder'
        )}
      />

      <DocumentsTableFeature name="roleInCompany.files" readOnly={readOnly} />
    </>
  );
};
