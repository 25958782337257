// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetList_AllOf_1_List_Items_FinancingSources_IncomeAmount_Currency as CurrencyApi,
  AmountFragment,
} from 'api/generated/graphql';
import { CurrencyType } from 'api/types/entity';
import { PriceEntity } from 'api/types/entity/priceEntity';

const currency: Record<CurrencyType, CurrencyApi> = {
  [CurrencyType.AED]: CurrencyApi.Aed,
  [CurrencyType.CHF]: CurrencyApi.Chf,
  [CurrencyType.CNY]: CurrencyApi.Cny,
  [CurrencyType.EUR]: CurrencyApi.Eur,
  [CurrencyType.GBP]: CurrencyApi.Gbp,
  [CurrencyType.HKD]: CurrencyApi.Hkd,
  [CurrencyType.IDR]: CurrencyApi.Idr,
  [CurrencyType.INR]: CurrencyApi.Inr,
  [CurrencyType.JPY]: CurrencyApi.Jpy,
  [CurrencyType.KZT]: CurrencyApi.Kzt,
  [CurrencyType.RUB]: CurrencyApi.Rub,
  [CurrencyType.USD]: CurrencyApi.Usd,
};

export const parsePriceParam = (v: PriceEntity): AmountFragment => ({
  currency: currency[v.currency],
  value: v.price,
});
