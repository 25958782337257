// eslint-disable-next-line no-restricted-imports
import { Drawer } from '@mui/material';

import { CSSObject, styled } from '../styled';

export const DrawerStyled = styled(Drawer)({
  '.MuiPaper-root': {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  } as CSSObject,
});
