import { FC, useEffect, useMemo } from 'react';

import { PermissionType, RoleType } from 'api/types/entity';
import { ActionMenu, ContentHeader, SendCodeTimer } from 'components';
import { lodash, parseFullName } from 'helpers';
import { useWindowUnload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePrompt } from 'libs/navigation';
import { PermissionWrapper, usePermissions } from 'libs/permissions';
import { Button, Chip, MenuItem, Stack } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { Submit, useFormContext } from '../../forms/adminForm';

interface Props {
  submitLoading?: boolean;
  isActive?: boolean;
  isDeactivated?: boolean;
  resendTime?: number;
  onResetPassword: () => void;
  onResendInvite: () => void;
  onActivate: () => void;
  onDeactivate: () => void;
}

enum ActionType {
  activate = 'activate',
  deactivate = 'deactivate',
  resetPassword = 'resetPassword',
  edit = 'edit',
}

export const AdminFormHeader: FC<Props> = ({
  submitLoading,
  isActive,
  resendTime,
  isDeactivated,
  onResetPassword,
  onResendInvite,
  onActivate,
  onDeactivate,
}) => {
  const {
    readOnly,
    setReadOnly,
    resetForm,
    dirty,
    submitDisabled,
    initialValues,
  } = useFormContext();

  const isSuperadmin = initialValues?.role.value === RoleType.SuperAdmin;

  const { goBack } = useNavigate();

  const { t } = useTranslation();

  const editable = !readOnly;

  const confirmIsChanged = (f: () => void) => {
    if (!dirty) {
      f();
      // eslint-disable-next-line no-alert
    } else if (window.confirm(t('confirm.cancelSaveMessage'))) {
      f();
    }
  };

  const disableEditable = () => {
    confirmIsChanged(() => setReadOnly(true));
  };

  useEffect(() => {
    if (!editable) {
      resetForm();
    }
  }, [editable, resetForm]);

  useWindowUnload((e) => {
    if (dirty && editable) {
      e.preventDefault();
      e.returnValue = '';
    }
  });

  usePrompt(t('confirm.cancelSaveMessage'), !!dirty);

  const { checkPermissions } = usePermissions();

  const menuAction = useMemo(
    () =>
      isDeactivated
        ? lodash.compact([
            checkPermissions([PermissionType.AdminActivate]) && {
              value: ActionType.activate,
              label: t('admin.admin.activate'),
              Icon: Icon.Edit,
            },
          ])
        : lodash.compact([
            checkPermissions([PermissionType.AdminEdit]) && {
              value: ActionType.edit,
              label: t('common.edit'),
              Icon: Icon.Edit,
            },
            isActive &&
              checkPermissions([PermissionType.AdminResetPassword]) && {
                value: ActionType.resetPassword,
                label: t('admin.admin.resetPassword'),
                Icon: Icon.Refresh,
              },

            checkPermissions([PermissionType.AdminDeactivate]) && {
              value: ActionType.deactivate,
              label: t('admin.admin.deactivate'),
              Icon: Icon.Bucket,
              color: Colors.red100,
            },
          ]),
    [checkPermissions, isActive, isDeactivated, t]
  );

  const onClickOption = (action: MenuItem<ActionType>) => {
    switch (action.value) {
      case ActionType.edit:
        setReadOnly(false);
        break;
      case ActionType.resetPassword:
        onResetPassword();
        break;
      case ActionType.activate:
        onActivate();
        break;
      case ActionType.deactivate:
        onDeactivate();
        break;
      default:
        break;
    }
  };

  return (
    <Stack spacing={32}>
      <ContentHeader
        canBack
        backPath={ROUTES.admins.fullPath}
        header={parseFullName(initialValues)}
        rightContent={
          <Stack alignItems="center" direction="row" spacing={36}>
            {editable ? (
              <>
                <Button
                  label={t('common.cancel')}
                  mode="text"
                  onClick={disableEditable}
                />
                <PermissionWrapper permissions={[PermissionType.AdminEdit]}>
                  <Submit
                    disabled={submitDisabled}
                    label={t('common.save')}
                    loading={submitLoading}
                  />
                </PermissionWrapper>
              </>
            ) : (
              !isSuperadmin && (
                <>
                  {!isActive && !isDeactivated && (
                    <PermissionWrapper
                      permissions={[PermissionType.AdminResendInvite]}
                    >
                      <SendCodeTimer
                        buttonLabel={t('admin.admin.resendInvite')}
                        buttonMode="text"
                        seconds={resendTime}
                        onClickSend={onResendInvite}
                      />
                    </PermissionWrapper>
                  )}
                  <ActionMenu
                    anchorOrigin={{ horizontal: 'right', vertical: 40 }}
                    label={t('common.actionLabel')}
                    options={menuAction}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClickOption={onClickOption}
                  />
                </>
              )
            )}
          </Stack>
        }
        onBack={goBack}
      />

      {isDeactivated && (
        <Chip text={t('admin.admin.deactivated')} variant="red" />
      )}
    </Stack>
  );
};
