// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_RiskLevel as RiskLevelTypeApi,
  Query_LegalClientController_GetList_AllOf_1_List_Items_RiskLevel as RiskLevelTypeApi2,
  Query_LegalClientController_GetById_RiskLevel as RiskLevelTypeApi3,
  Query_ClientController_GetList_AllOf_1_List_Items_RiskLevel as RiskLevelTypeApi4,
} from 'api/generated/graphql';
import { RiskLevelType } from 'api/types/entity';

type Level =
  | RiskLevelTypeApi
  | RiskLevelTypeApi2
  | RiskLevelTypeApi3
  | RiskLevelTypeApi4;

const riskLevelMap: Record<Level, RiskLevelType> = {
  [RiskLevelTypeApi.High]: RiskLevelType.High,
  [RiskLevelTypeApi.Low]: RiskLevelType.Low,
  [RiskLevelTypeApi.Medium]: RiskLevelType.Medium,
};

export const parseRiskLevel = (rick: Level) => riskLevelMap[rick];
