import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { Fields as BaseFields } from 'modules/client/common/components/OrderSubmissionSection/FormContent/components/Fields';
import {
  Field,
  maxLengthName,
  maxLengthPosition,
} from 'modules/client/legal/forms/companyOrderSubmissionContactsForm';

interface Props {
  index: number;
}

export const Fields = ({ index }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack alignItems="flex-end" direction="row" spacing={24}>
        <BaseFields index={index} />
      </Stack>

      <Field.Text
        label={t('client.legal.companyOrderSubmissionContacts.firstName.label')}
        maxLength={maxLengthName}
        name={`orderSubmissionContacts.${index}.firstName`}
        placeholder={t(
          'client.legal.companyOrderSubmissionContacts.firstName.placeholder'
        )}
      />
      <Field.Text
        label={t('client.legal.companyOrderSubmissionContacts.lastName.label')}
        maxLength={maxLengthName}
        name={`orderSubmissionContacts.${index}.lastName`}
        placeholder={t(
          'client.legal.companyOrderSubmissionContacts.lastName.placeholder'
        )}
      />
      <Field.Text
        label={t(
          'client.legal.companyOrderSubmissionContacts.middleName.label'
        )}
        maxLength={maxLengthName}
        name={`orderSubmissionContacts.${index}.middleName`}
        placeholder={t(
          'client.legal.companyOrderSubmissionContacts.middleName.placeholder'
        )}
      />
      <Field.Text
        label={t('client.legal.companyOrderSubmissionContacts.position.label')}
        maxLength={maxLengthPosition}
        name={`orderSubmissionContacts.${index}.position`}
        placeholder={t(
          'client.legal.companyOrderSubmissionContacts.position.placeholder'
        )}
      />
    </>
  );
};
