import { PasswordValidation } from 'helpers/validations/password';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  password,
  ObjectSchema,
  object,
  string,
} from 'libs/form';
import { FormSubmit as FormSubmitBase } from 'libs/form/Form';
import { TFunc } from 'libs/i18n';

export interface PasswordFormType {
  password: string;
  otpCode?: string;
}
export const CODE_LENGTH = 6;

export const getSchema = (
  t: TFunc,
  passwordValidation: PasswordValidation
): ObjectSchema<PasswordFormType> =>
  object({
    password: password(passwordValidation, t),
    otpCode: string().min(CODE_LENGTH),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PasswordFormType>();

export type FormErrors = FormErrorsBase<PasswordFormType>;

export type FormSubmit = FormSubmitBase<PasswordFormType>;
