import {
  CivilLawSubjectType,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import {
  parseActivityInitialValue,
  parseAddressValue,
  parseBiographyInitialValue,
  parseCompanyStateRegistrationValue,
  parseIdentityDocumentInitialValue,
  parsePepConnectionInitialValue,
  parseTaxResidencesValue,
} from 'modules/client/common/helpers';

import { LegalRepresentativeFormType } from '../../forms/legalRepresentativeForm';

import { parseLegalClientRepresentativeDocumentsFiles } from './parseLegalClientRepresentativeDocumentsFiles';
import { parseRoleInCompanyValue } from './parseRoleInCompanyValue';

export const getLegalRepresentativeInitialValue = (
  t: TFunc,
  client: LegalClientRepresentativeEntity
): LegalRepresentativeFormType => {
  const isCompany =
    client.roleInCompany?.civilLawSubject ===
    CivilLawSubjectType.JuridicalPerson;

  return {
    reasonChange: { reason: '' },
    contacts: {
      email: client.email,
      phone: client.phone,
    },
    personalData: {
      firstName: client.firstName,
      lastName: client.lastName,
      middleName: client.middleName,
      dateOfBirth: client.dateOfBirth,
    },

    registrationAddress: parseAddressValue(client.registrationAddress),
    residentialAddress: parseAddressValue(client.residentialAddress),

    placeOfBirth: {
      country: client.placeOfBirth?.country ?? null,
      locality: client.placeOfBirth?.locality ?? '',
    },

    citizenships: client.citizenships ?? [null],
    taxResidences: parseTaxResidencesValue(client.taxResidences),
    biography: parseBiographyInitialValue(t, client.biography),

    pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

    roleInCompany: parseRoleInCompanyValue(t, client.roleInCompany),

    companyName: isCompany
      ? {
          fullName: client.company?.fullName ?? '',
          shortName: client.company?.shortName,
        }
      : undefined,

    companyRegistrationAddress: isCompany
      ? parseAddressValue(client.company?.legalAddress)
      : undefined,
    companyResidentialAddress: isCompany
      ? parseAddressValue(client.company?.actualAddress)
      : undefined,

    companyTaxResidences: isCompany
      ? client.company?.taxResidences?.map((v) => ({
          country: v.country || null,
          taxNumber: v.taxId,
        })) || [{ country: null, taxNumber: '' }]
      : undefined,

    companyStateRegistration: isCompany
      ? parseCompanyStateRegistrationValue(client.company?.stateRegistration)
      : undefined,

    activity: client.user
      ? parseActivityInitialValue({ ...client, user: client.user })
      : {},

    identityDocument: parseIdentityDocumentInitialValue(
      t,
      client.identityDocument
    ),
    additionalDocumentsFiles: client.additionalInformationFiles,
    documentsFiles: parseLegalClientRepresentativeDocumentsFiles(client),
  };
};
