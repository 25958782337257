import { DefaultNamespace, ParseKeys, TFunction } from 'i18next';

import ru from './locales/ru.json';

/**
 * Enum representing language codes for different languages.
 */
export enum Langs {
  RU = 'ru',
}

export type TranslationPath = ParseKeys<DefaultNamespace, typeof ru, undefined>;

export type TFunc = TFunction<DefaultNamespace, undefined>;
