// eslint-disable-next-line no-restricted-imports
import { useMemo, useRef } from 'react';

import { useClientVersions } from 'api/requests';
import { ClientVersionUpdatedEntityType } from 'api/types/entity';

export const useRepresentativeClientVersions = (
  clientId: string,
  versionId?: string
) => {
  const savedVersionId = useRef(versionId).current;
  const { loading, versions, error } = useClientVersions(clientId);

  const filteredVersions = useMemo(() => {
    const currentVersion = versions
      ? Math.max(...versions.map((v) => v.number))
      : undefined;

    return versions?.filter((version) => {
      if (
        version.updatedEntity.type ===
        ClientVersionUpdatedEntityType.ClientRepresentative
      ) {
        return true;
      }

      return (
        version.number === currentVersion ||
        version.number === 1 ||
        version.id === savedVersionId
      );
    });
  }, [savedVersionId, versions]);

  return { loading, versions: filteredVersions, error };
};
