import { TwoFactorQrCodeGenerateData } from 'api/types/data';
import { Typography } from 'libs/ui';

import {
  ContainerStyled,
  ImageContainerStyled,
  SercetStyled,
  SkeletonStyled,
} from './QrCode.styles';

interface Props {
  data?: TwoFactorQrCodeGenerateData;
  loading?: boolean;
}

export const QrCode = ({ data, loading }: Props) => {
  const isLoading = !data || loading;

  return (
    <ContainerStyled spacing={12}>
      {isLoading ? (
        <SkeletonStyled animation="wave" variant="rounded" />
      ) : (
        <>
          <ImageContainerStyled>
            <img alt="qrCode" src={data.qrUrl} />
          </ImageContainerStyled>

          <SercetStyled>
            <Typography variant="captionSemiBold">{data.secret}</Typography>
          </SercetStyled>
        </>
      )}
    </ContainerStyled>
  );
};
