import { ClientOnboardingStatusType } from 'api/types/entity';
import { ServerError } from 'api/types/error';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

interface QueryResult {
  loading?: boolean;
  lock: (clientId: string, versionNumber: number) => Promise<boolean>;
  error?: ServerError | undefined;
}

interface Client {
  id?: string;
  versionNumber?: number;
  onboardingStatus?: ClientOnboardingStatusType;
}

export const useLockClientIfPossible = <C extends Client>(
  client: C | null,
  useLock: () => QueryResult
) => {
  const { lock, error: lockClientError } = useLock();

  const notify = useNotify();
  const { t } = useTranslation();

  const lockClientIfPossible = async () => {
    if (
      !client ||
      !client.id ||
      client.onboardingStatus === ClientOnboardingStatusType.FillingOut ||
      client.versionNumber === undefined
    ) {
      notify.show('error', t('client.disabledEditableText'));
      return false;
    }

    try {
      return await lock(client?.id, client?.versionNumber);
    } catch (error) {
      return false;
    }
  };

  useServerErrorNotify(lockClientError);

  return { lockClientIfPossible };
};
