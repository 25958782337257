import { useTranslation } from 'libs/i18n';
import { Table } from 'libs/ui';

import { useColumns } from './columns';
import { BeneficiaryItem } from './types';

interface Props {
  items: BeneficiaryItem[];
  onCellClick: (row: BeneficiaryItem) => void;
}

export const BeneficiariesTable = ({ items, onCellClick }: Props) => {
  const columns = useColumns();

  const { t } = useTranslation();

  return (
    <Table
      hideFooter
      columns={columns}
      noRowsLabel={t('client.legal.companyBeneficiaries.noRowsLabel')}
      rows={items}
      onCellClick={onCellClick}
    />
  );
};
