import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSaveClientAccountStatusMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseClientEntity } from 'api/helpers/client/parseClientEntity';
import { SaveAccountStatusParam } from 'api/types/params';

import { parseSaveClientAccountStatusParam } from '../../helpers/client/parseParam';

export const useSaveClientAccountStatus = () => {
  const [handle, { data, loading, error: apolloError }] =
    useSaveClientAccountStatusMutation();

  const error = useError(apolloError);

  const client = data?.saveAccountStatus
    ? parseClientEntity(data.saveAccountStatus)
    : undefined;

  const saveClientAccountStatus = useCallback(
    async (id: string, params: SaveAccountStatusParam) => {
      const input = parseSaveClientAccountStatusParam(params);
      const res = await handle({ variables: { id, input } });
      return res.data?.saveAccountStatus
        ? parseClientEntity(res.data?.saveAccountStatus)
        : undefined;
    },
    [handle]
  );

  return {
    client,
    loading,
    error,
    saveClientAccountStatus,
  };
};
