// eslint-disable-next-line no-restricted-imports
import {
  ManagerFragment,
  Query_ClientController_GetById_Onboarding_Statuses_Items_Value as OnboardingStatusValue,
  Query_ClientController_GetList_AllOf_1_List_Items_Onboarding_Statuses_Items_Value as OnboardingStatusValue2,
} from 'api/generated/graphql';
import { ClientOnboardingData } from 'api/types/data';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseManagerData } from '../parseManagerData';
import { OnboardingDataApi, parseOboardingData } from '../parseOboardingData';

import { parseClientOnboardingStatus } from './parseEntity/parseClientOnboardingStatus';

type ClientOnboardingDataStatusApi = {
  value: OnboardingStatusValue | OnboardingStatusValue2;
  createdBy?: ManagerFragment | null;
  createdAt: string;
  result?: string | null;
};

export type ClientOnboardingDataApi =
  | (OnboardingDataApi & {
      statuses?: Maybe<ClientOnboardingDataStatusApi>[] | null;
    })
  | undefined
  | null;

interface Client {
  onboarding?: ClientOnboardingDataApi | null;
  representative?: { onboarding?: OnboardingDataApi | null } | null;
}

export const parseClientOnboardingData = (
  client?: Client
): ClientOnboardingData | undefined => {
  const baseOnboardingData = parseOboardingData(client?.onboarding);

  if (!baseOnboardingData) {
    return undefined;
  }

  const statuses = client?.onboarding?.statuses
    ? lodash
        .compact(client.onboarding.statuses)
        .map(({ createdAt, createdBy, value, result }) => ({
          value: parseClientOnboardingStatus(value),
          createdBy: createdBy ? parseManagerData(createdBy) : null,
          createdAt: new Date(createdAt),
          result: result ?? '',
        }))
    : undefined;

  return {
    ...baseOnboardingData,
    statuses,
  };
};
