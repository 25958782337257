// eslint-disable-next-line no-restricted-imports
import { FieldMergeFunction } from '@apollo/client';
import { lodash } from 'helpers';

/**
 * Just fork of https://github.com/apollographql/apollo-client/blob/7767f466bbbf695c5908cf97284d9af4303af5d6/src/utilities/policies/pagination.ts#L28
 * with merge `list` supporting
 */
export const mergeListQuery: FieldMergeFunction = (
  existing,
  incoming,
  { args }
) => {
  const existingItems = existing?.list || [];
  const incomingItems = incoming?.list || [];
  const merged = existing ? existingItems.slice(0) : [];
  if (args) {
    const offset = (args.page - 1) * args.limit;
    for (let i = 0; i < incomingItems.length; i += 1) {
      merged[offset + i] = incomingItems[i];
    }
  } else {
    // eslint-disable-next-line prefer-spread
    merged.push.apply(merged, incomingItems);
  }

  return {
    ...incoming,
    list: lodash.uniqby(merged, '__ref'),
  };
};
