import { PropsWithChildren } from 'react';

import { useTranslation } from 'libs/i18n';
import { Box, Button, Stack } from 'libs/ui';

import { FiltersStyled } from './styles';

interface Props extends PropsWithChildren {
  countFilter: number;
  onApply: () => void;
  onReset: () => void;
}

export const Filters = ({ children, countFilter, onApply, onReset }: Props) => {
  const { t } = useTranslation();
  return (
    <FiltersStyled>
      <Box className="filters-content">{children}</Box>
      <Stack
        alignItems="center"
        className="filters-footer"
        direction="row"
        justifyContent="space-between"
      >
        <Button
          label={t('common.apply') + (countFilter ? ` (${countFilter})` : '')}
          mode="outlined-small"
          onClick={onApply}
        />
        <Button label={t('common.reset')} mode="text" onClick={onReset} />
      </Stack>
    </FiltersStyled>
  );
};
