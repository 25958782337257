// eslint-disable-next-line no-restricted-imports
import MuiAlert from '@mui/material/Alert';

import { styled } from '../styled';
import { Colors } from '../theme';

export const AlertStyled = styled(MuiAlert)(({ theme, severity }) => ({
  alignItems: 'center',

  backgroundColor:
    severity === 'error' ? theme.palette.error.main : Colors.black,

  '.MuiAlert-action': {
    padding: 0,
    paddingLeft: 16,
  },
}));
