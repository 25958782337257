import { CountryEntity } from 'api/types/entity';
import { string, object, ObjectSchema, TypedForm } from 'libs/form';
import { Maybe } from 'types/maybe';

export interface PlaceOfBirthFormType {
  placeOfBirth: {
    country: Maybe<CountryEntity>;
    locality: string;
  };
}

export const placeOfBirthSchema: ObjectSchema<PlaceOfBirthFormType> = object({
  placeOfBirth: object({
    country: object({
      id: string().required(),
      name: string().required(),
    })
      .required()
      .default(null),
    locality: string().required().max(200),
  }),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PlaceOfBirthFormType>();
