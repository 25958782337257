import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCreateLegalClientFirstHeadMutation } from 'api/generated/graphql';
import {
  parseCreateLegalClientFirstHeadParams,
  parseLegalClientFirstHeadEntity,
  useError,
} from 'api/helpers';
import { CreateLegalClientFirstHeadParams } from 'api/types/params';

export const useCreateLegalClientFirstHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useCreateLegalClientFirstHeadMutation();

  const error = useError(apolloError);

  return {
    legalClientFirstHead: data?.createLegalClientFirstHead
      ? parseLegalClientFirstHeadEntity(data.createLegalClientFirstHead)
      : undefined,
    loading,
    error,
    createLegalClientFirstHead: useCallback(
      async (params: CreateLegalClientFirstHeadParams) => {
        const input = parseCreateLegalClientFirstHeadParams(params);
        const res = await handle({ variables: { input } });
        return res.data?.createLegalClientFirstHead
          ? parseLegalClientFirstHeadEntity(
              res.data?.createLegalClientFirstHead
            )
          : undefined;
      },
      [handle]
    ),
  };
};
