import { RoleType } from 'api/types/entity';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { Button, Dialog, Stack, Typography } from 'libs/ui';

import {
  Form,
  getSchema,
  Submit,
  FormErrors,
  CreateAdminFormType,
} from '../../forms/createAdminForm';

import { ContainerStyled } from './CreateAdminDialog.styles';
import { FormContent } from './FormContent';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  createLoading?: boolean;
  formError?: FormErrors;
  onSubmit: FormSubmit<CreateAdminFormType>;
}

export const CreateAdminDialog = ({
  isOpen,
  formError,
  createLoading,
  onSubmit,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog
      disableBackdropClick
      isOpen={isOpen}
      maxWidth={false}
      onClose={onClose}
    >
      <Form
        initialErrors={formError}
        initialValues={{
          role: {
            value: RoleType.Admin,
            label: t('admin.adminRoles.Admin'),
          },
        }}
        validationSchema={getSchema(t)}
        onSubmit={onSubmit}
      >
        <ContainerStyled>
          <Stack spacing={36}>
            <Typography variant="title">
              {t('admin.createAdminDialog.title')}
            </Typography>
            <FormContent />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={36}
            >
              <Button label={t('common.back')} mode="text" onClick={onClose} />
              <Submit label={t('common.create')} loading={createLoading} />
            </Stack>
          </Stack>
        </ContainerStyled>
      </Form>
    </Dialog>
  );
};
