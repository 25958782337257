// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetList_AllOf_1_List_Items_ServiceContract_Type as ServiceContractTypeApi1,
  ServiceContractFragment,
} from 'api/generated/graphql';
import { ServiceContractEntity, ServiceContractType } from 'api/types/entity';

type ServiceContractTypeApi = ServiceContractTypeApi1;

const serviceContract: Record<ServiceContractTypeApi, ServiceContractType> = {
  [ServiceContractTypeApi1.AdditionalAgreementToCustodialContract]:
    ServiceContractType.AdditionalAgreementToCustodialContract,
  [ServiceContractTypeApi1.BrokerageServicesContract]:
    ServiceContractType.BrokerageServicesContract,
  [ServiceContractTypeApi1.BrokerageServicesContractWithNominalHolding]:
    ServiceContractType.BrokerageServicesContractWithNominalHolding,
  [ServiceContractTypeApi1.ConsultancyServicesContract]:
    ServiceContractType.ConsultancyServicesContract,
  [ServiceContractTypeApi1.CustodialContract]:
    ServiceContractType.CustodialContract,
  [ServiceContractTypeApi1.InvestmentPortfolioManagementContract]:
    ServiceContractType.InvestmentPortfolioManagementContract,
  [ServiceContractTypeApi1.MarketMakerServicesContract]:
    ServiceContractType.MarketMakerServicesContract,
  [ServiceContractTypeApi1.SupplementaryAgreement]:
    ServiceContractType.SupplementaryAgreement,
  [ServiceContractTypeApi1.UnderwritingServicesContract]:
    ServiceContractType.UnderwritingServicesContract,
};

export const parseServiceContractEntity = (
  data: ServiceContractFragment | undefined | null
): ServiceContractEntity | undefined => ({
  type: data?.type ? serviceContract[data.type] : undefined,
  number: data?.number ?? undefined,
  conclusionDate: data?.conclusionDate
    ? new Date(data.conclusionDate)
    : undefined,
  terminationDate: data?.terminationDate
    ? new Date(data.terminationDate)
    : undefined,
});
