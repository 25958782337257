import { useTranslation } from 'libs/i18n';
import { Typography } from 'libs/ui';

interface Props {
  index: number;
}

export const Header = ({ index }: Props) => {
  const { t } = useTranslation();
  return (
    <Typography variant="subtitle">
      {t(
        index === 0
          ? 'client.client.bankAccounts.account'
          : 'client.client.bankAccounts.accountOther'
      )}
    </Typography>
  );
};
