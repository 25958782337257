import {
  useLegalClient,
  useLegalClientFirstHead as useLegalClientFirstHeadApi,
  useLegalClientLegalHead,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId, ROUTES } from 'libs/navigation';

import { LegalClientFirstHeadFormType } from '../forms/legalClientFirstHeadForm';
import { getLegalClientFirstHeadInitialValue } from '../helpers';

import { useFirstHeadPageTitle } from './useFirstHeadPageTitle';
import { useLockLegalClientFirstHead } from './useLockLegalClientFirstHead';
import { useUpdateLegalClientFirstHead } from './useUpdateLegalClientFirstHead';

type NavigateParams = {
  firstHeadId: string;
  headId: string;
  legalClientId: string;
};

export const useLegalClientFirstHead = () => {
  const { t } = useTranslation();

  const { navigate, params } = useNavigate<NavigateParams>();

  const { loading, legalClientFirstHead, error } = useLegalClientFirstHeadApi(
    params.firstHeadId!
  );

  const { loading: submitLoading, updateLegalClientFirstHead } =
    useUpdateLegalClientFirstHead();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);
  const { legalClientLegalHead } = useLegalClientLegalHead(params.headId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientFirstHead(
    legalClient,
    legalClientFirstHead
  );

  useFirstHeadPageTitle(
    legalClient,
    legalClientLegalHead,
    legalClientFirstHead
  );

  const onSubmit: FormSubmit<LegalClientFirstHeadFormType> = async (
    values,
    helpers
  ) => {
    if (await updateLegalClientFirstHead(legalClientFirstHead!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const initialValues = legalClientFirstHead
    ? getLegalClientFirstHeadInitialValue(legalClientFirstHead, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientFirstHead]: legalClientFirstHead
      ? parseFullName(legalClientFirstHead)
      : '',
    [RoutesId.legalClientLegalHead]: legalClientLegalHead?.fullName,
    [RoutesId.legalClient]: legalClient?.fullName,
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientFirstHead?.versionNumber &&
    legalClientFirstHead?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientLegalHeadVersion.legalClientFirstHeadVersion.fullPath,
            {
              legalClientId: legalClient.id,
              headId: params.headId!,
              versionId: legalClientFirstHead.versionId!,
              firstHeadId: legalClientFirstHead.id,
            }
          );
        }
      : undefined;

  return {
    legalClientFirstHead,
    loading: !legalClientFirstHead && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
  };
};
