import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUserUpdateTimezoneMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseUserEntity } from 'api/helpers/parseEntity';
import { TimezoneEntity } from 'api/types/entity';

export const useUserUpdateTimezone = () => {
  const [handle, { loading, error: apolloError }] =
    useUserUpdateTimezoneMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    updateTimezone: useCallback(
      async (timezone?: TimezoneEntity) => {
        const input = {
          name: timezone?.name || null,
        };

        const res = await handle({ variables: { input } });
        if (res.data?.userUpdateTimezone) {
          return parseUserEntity(res.data?.userUpdateTimezone);
        }

        return undefined;
      },
      [handle]
    ),
  };
};
