import { Dispatch, SetStateAction } from 'react';

import { CountryEntity } from 'api/types/entity';

import { CheckboxFilter } from '../CheckboxFilter';

export interface CountryFilterProps {
  title: string;
  countries: CountryEntity[];
  selectedCountries: string[];
  setSelectedCountries: Dispatch<SetStateAction<string[]>>;
}

export const CountryFilter = ({
  countries,
  title,
  selectedCountries,
  setSelectedCountries,
}: CountryFilterProps) => {
  const items = countries.map((v) => ({
    label: v.name,
    value: v.id,
  }));

  return (
    <CheckboxFilter
      showSearch
      items={items}
      selectedItems={selectedCountries}
      setItems={setSelectedCountries}
      title={
        title +
        (selectedCountries.length ? ` (${selectedCountries.length})` : '')
      }
    />
  );
};
