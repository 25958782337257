// eslint-disable-next-line no-restricted-imports
import { UserFragment } from 'api/generated/graphql';
import { UserEntity } from 'api/types/entity';

import { parseUserRoleEntity } from './parseUserRoleEntity';
import { parseUserSettings } from './parseUserSettings';

export const parseUserEntity = (user: UserFragment): UserEntity => ({
  id: user.id,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  middleName: user.middleName ?? undefined,
  otp: user.otp,
  role: user.role ? parseUserRoleEntity(user.role) : undefined,
  isPasswordUpdateRequired: user.isPasswordUpdateRequired,
  settings: parseUserSettings(user.settings),
});
