// eslint-disable-next-line no-restricted-imports
import { LegalClientSnapshotFragment } from 'api/generated/graphql';
import { LegalClientSnapshotEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { parseLegalClient } from '../parseLegalClient';

export const parseLegalClientSnapshotEntity = (
  data: Maybe<LegalClientSnapshotFragment>
): LegalClientSnapshotEntity | undefined =>
  data
    ? {
        legalClientId: data.legalClientId,
        createdAt: new Date(data.createdAt),
        id: data.id,
        value: parseLegalClient(data.value),
      }
    : undefined;
