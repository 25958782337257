import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClient } from 'api/helpers/legal/parseLegalClient';
import { parseUpdateLegalClientParams } from 'api/helpers/legal/parseUpdateLegalClientParams';
import { UpdateLegalClientParams } from 'api/types/params';

export const useUpdateLegalClient = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientMutation();

  const error = useError(apolloError);

  return {
    legalClient: data?.updateLegalClient
      ? parseLegalClient(data.updateLegalClient)
      : undefined,
    loading,
    error,
    updateLegalClient: useCallback(
      async (id: string, params: UpdateLegalClientParams) => {
        const input = parseUpdateLegalClientParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateLegalClient
          ? parseLegalClient(res.data?.updateLegalClient)
          : undefined;
      },
      [handle]
    ),
  };
};
