import { LayoutClasses } from 'components';
import { Box } from 'libs/ui';
import { styled, GlobalStyles } from 'libs/ui/styled';

export const ContainerStyled = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  width: 504,
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const LayoutOwerlay = () => (
  <GlobalStyles
    styles={{
      [`.${LayoutClasses.content}`]: {
        marginTop: '0px !important',
      },
    }}
  />
);
