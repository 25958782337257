// eslint-disable-next-line no-restricted-imports
import { Tooltip as TooltipBase, TooltipProps } from '@mui/material';

import { styled } from '../styled';
import { Colors } from '../theme';

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <TooltipBase {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: Colors.blue50,
    color: Colors.black,
    padding: '8px 16px',
    borderRadius: 12,
    marginTop: 8,
    maxWidth: 600,
  },

  '.MuiTooltip-arrow': {
    color: Colors.blue50,
  },

  '.tooltip-typography': {
    color: theme.palette.text.primary,
  },
}));
