import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import { AddressFields, Section } from 'modules/client/common/components';
import {
  Field,
  useFormContext,
} from 'modules/client/common/forms/residentialAddressForm';

import { FormContent as PlaceOfBirthFormContent } from '../PlaceOfBirthSection/FormContent';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const PlaceOfBirthAndRegistrationAddressSection = ({
  countriesLoading,
  countries,
}: Props) => {
  const { t } = useTranslation();

  const { values } = useFormContext();

  return (
    <Section
      title={t('client.client.placeOfBirthAndRegistrationAddress.title')}
    >
      <Stack spacing={32}>
        <Stack spacing={24}>
          <Typography variant="subtitle">
            {t('client.client.placeOfBirth.title')}
          </Typography>
          <PlaceOfBirthFormContent
            countries={countries}
            countriesLoading={countriesLoading}
          />
        </Stack>
        <Stack spacing={24}>
          <Typography variant="subtitle">
            {t('client.client.registrationAddress.title')}
          </Typography>
          <AddressFields
            countries={countries}
            countriesLoading={countriesLoading}
            variant="registrationAddress"
          />
        </Stack>
        <Stack spacing={24}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle">
              {t('client.client.residentialAddress.title')}
            </Typography>
            <Stack
              alignItems="center"
              direction="row"
              flexWrap="wrap"
              justifyContent="space-between"
              spacing={16}
            >
              <Typography variant="bodyMedium">
                {t(
                  'client.client.placeOfBirthAndRegistrationAddress.residencelabel'
                )}
              </Typography>
              <Field.Switch name="residentialAddress.residentialAddressIsSame" />
            </Stack>
          </Stack>
          {!values.residentialAddress.residentialAddressIsSame && (
            <AddressFields
              countries={countries}
              countriesLoading={countriesLoading}
              variant="residentialAddress"
            />
          )}
        </Stack>
      </Stack>
    </Section>
  );
};
