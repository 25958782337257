import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  LegalClientBranchFormType,
  getSchema,
} from '../../forms/legalClientBranchForm';

import { FormContent, FormContentProps } from './FormContent';

export interface LegalClientBranchFormProps extends FormContentProps {
  initialValues?: LegalClientBranchFormType;
  countriesLoading?: boolean;
  countries: CountryEntity[];
  onSubmit: FormSubmit<LegalClientBranchFormType>;
}

export const LegalClientBranchForm: FC<LegalClientBranchFormProps> = ({
  initialValues,
  onSubmit,
  ...formProps
}) => {
  const { t } = useTranslation();

  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </Form>
  );
};
