import { useUpdateRepresentativeClient as useUpdateRepresentativeClientApi } from 'api/requests/';
import { RepresentativeClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { RepresentativeFormType } from '../forms/representativeForm';
import { getSubmitData } from '../helpers';

export const useUpdateRepresentativeClient = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const {
    updateClient,
    loading: submitLoading,
    error,
  } = useUpdateRepresentativeClientApi();

  const handleUpdateClient = async (
    representativeClient: RepresentativeClientEntity,
    values: RepresentativeFormType
  ) => {
    if (representativeClient.versionNumber !== undefined) {
      const params = getSubmitData(representativeClient.versionNumber, values);
      if (await updateClient(representativeClient.id, params)) {
        notify.info(t('common.successSave'));
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    submitLoading,
    updateClient: handleUpdateClient,
    error,
  };
};
