import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { FormContent } from './FormContent';

export const CompanyOrganizationalAndLegalFormSection = () => {
  const { t } = useTranslation();

  return (
    <Section title={t('client.legal.organizationalAndLegalForm.title')}>
      <FormContent />
    </Section>
  );
};
