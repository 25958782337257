import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useFileGetPreviewLinkByIdLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useFilePreview = () => {
  const [handle, { data, loading, error: apolloError }] =
    useFileGetPreviewLinkByIdLazyQuery();

  const error = useError(apolloError);

  const getFileLink = useCallback(
    async (fileId: string) => {
      const res = await handle({ variables: { id: fileId } });
      return res.data?.fileGetDownloadLinkById?.url;
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    getFileLink,
  };
};
