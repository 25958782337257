import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateAdminMutation } from 'api/generated/graphql';
import {
  parseAdminEntity,
  parseUpdateAdminParams,
  useError,
} from 'api/helpers';
import { UpdateAdminParams } from 'api/types/params';

export const useUpdateAdmin = () => {
  const [updateAdmin, { data, loading, error: apolloError }] =
    useUpdateAdminMutation();

  const error = useError(apolloError);

  const admin = data?.updateAdmin ? parseAdminEntity(data.updateAdmin) : null;

  return {
    admin,
    loading,
    error,
    updateAdmin: useCallback(
      async (id: string, params: UpdateAdminParams) => {
        const input = parseUpdateAdminParams(params);
        const res = await updateAdmin({ variables: { id, input } });

        return res.data?.updateAdmin
          ? parseAdminEntity(res.data?.updateAdmin)
          : undefined;
      },
      [updateAdmin]
    ),
  };
};
