import { useRef, useLayoutEffect } from 'react';

import { TranslationPath, useTranslation } from 'libs/i18n';

const NGDEM_TITLE = 'NGDEM Finance';
const POSTFIX_TITLE = ` | ${NGDEM_TITLE}`;

interface Prarams {
  /**
   * Whether to return previous title when unmounting
   * @default true
   */
  previousOnUnmount: boolean;
}
/**
 * Set document title
 * @param {string} title - The document title
 * @param {boolean} previousOnUnmount - Whether to return previous title when unmounting
 */
export const usePageTitle = (title: string, params?: Prarams) => {
  const prevTitle = useRef(document.title);

  const previousOnUnmount = params?.previousOnUnmount ?? true;

  useLayoutEffect(() => {
    if (title) {
      document.title = title + POSTFIX_TITLE;
    } else {
      document.title = NGDEM_TITLE;
    }
  }, [title]);

  useLayoutEffect(
    () => () => {
      if (previousOnUnmount) {
        document.title = prevTitle.current;
      }
    },
    [previousOnUnmount]
  );
};

export const usePageTitleTranslation = (
  title: TranslationPath,
  params?: Prarams
) => {
  const { t } = useTranslation();

  usePageTitle(t(title), params);
};
