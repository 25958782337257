import { yesOrNotSelectValidation } from 'helpers/yesOrNo';
import {
  ObjectSchema,
  object,
  priceAndCurrencyOptional,
  TypedForm,
} from 'libs/form';
import { PriceFieldValue } from 'libs/form/fields/PriceField';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoType } from 'types/yesOrNo';

export type BaseDebtsOptionalPrice = Maybe<Partial<PriceFieldValue>>;

export interface BaseDebtsFormType {
  debts: {
    taxes?: BaseDebtsOptionalPrice;
    fees?: BaseDebtsOptionalPrice;
    penalties?: BaseDebtsOptionalPrice;
    finesForViolation?: BaseDebtsOptionalPrice;
    bankruptcyProceedings?: Maybe<SelectType<YesOrNoType>>;
    delaysOrNonFulfillmentOfObligations?: Maybe<SelectType<YesOrNoType>>;
  };
}

export const getBaseDebtsItemSchema = (
  isEntrepreneur?: boolean
): ObjectSchema<BaseDebtsFormType['debts']> =>
  object({
    taxes: priceAndCurrencyOptional().nullable(),
    fees: priceAndCurrencyOptional().nullable(),
    penalties: priceAndCurrencyOptional().nullable(),
    finesForViolation: priceAndCurrencyOptional().nullable(),

    bankruptcyProceedings: yesOrNotSelectValidation
      .when('delaysOrNonFulfillmentOfObligations', {
        is: () => isEntrepreneur,
        then: (schema) => schema.default(null).required(),
      })

      .default(isEntrepreneur ? null : undefined),

    delaysOrNonFulfillmentOfObligations: yesOrNotSelectValidation
      .when('finesForViolation', {
        is: () => isEntrepreneur,
        then: (schema) => schema.default(null).required(),
      })
      .default(isEntrepreneur ? null : undefined),
  });

export const getBaseDebtsSchema = (
  isEntrepreneur?: boolean
): ObjectSchema<BaseDebtsFormType> =>
  object({
    debts: getBaseDebtsItemSchema(isEntrepreneur),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<BaseDebtsFormType>();
