import { useDeactivateAdmin } from 'api/requests';
import { PermissionType } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

export const useDeactivate = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { deactivateAdmin, error } = useDeactivateAdmin();
  const { checkPermission } = usePermissions();

  const deactivate = async (id: string) => {
    const permission = checkPermission(PermissionType.AdminDeactivate, {
      showNotify: true,
    });

    if (permission && (await deactivateAdmin(id))) {
      notify.info(t('admin.admin.successDeactivated'));
    }
  };

  useServerErrorNotify(error);

  return { deactivate, error };
};
