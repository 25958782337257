import { CountryEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import { useFormContext } from 'modules/client/client/forms/withdrawalAccountsForm';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import { MAX_FILES } from 'modules/client/common/forms/withdrawalAccountsForm';

import { Header, Fields, Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { values, readOnly } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.withdrawalAccounts.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24}>
            <Header index={index} />

            <Fields
              countries={countries}
              countriesLoading={countriesLoading}
              index={index}
            />
          </Stack>

          <DocumentsTableFeature
            maxDocumentCount={MAX_FILES}
            name={`withdrawalAccounts.${index}.ownerConfirmationFiles`}
            readOnly={readOnly}
          />

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
