import {
  parseAddressValue,
  parseCompanyStateRegistrationValue,
} from 'modules/client/common/helpers';

import { LegalClientLegalHeadFormType } from '../../forms/legalClientLegalHeadForm';

export const getLegalClientLegalHeadInitialValue =
  (): LegalClientLegalHeadFormType => ({
    reasonChange: { reason: '' },

    companyTaxResidences: [{ country: null, taxNumber: '' }],

    companyRegistrationAddress: parseAddressValue(),
    companyResidentialAddress: parseAddressValue(),

    headInfo: {
      body: null,
      firstHead: null,
      legalFiles: [],
    },

    companyName: { fullName: '', shortName: '' },

    companyStateRegistration: parseCompanyStateRegistrationValue(undefined),
  });
