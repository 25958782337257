import { LocalFileEntity } from '../../types';

export interface DocumentItemType extends LocalFileEntity {
  number: number;
  loading?: boolean;
}

export enum DocumentOptionType {
  download,
  delete,
}

export interface DocumentColumnArgs {
  editable?: boolean;
  onClickOption: (item: DocumentItemType, option: DocumentOptionType) => void;
  onClickName?: (item: DocumentItemType) => void;
}
