import { ManagerData } from 'api/types/data';

import { AccountCodesFormType } from '../../forms/accountCodesForm';

interface Client {
  code?: string;
  centralDepositoryAccountNumber?: string;
  centralDepositoryAccountNumberOpeningDate?: Date;
  internalAccountNumber?: string;
  internalAccountNumberOpeningDate?: Date;
  manager?: ManagerData;
}

export const parseAccountCodesInitialValue = (client: Client) => {
  const values: AccountCodesFormType['accountCodes'] = {
    code: client.code,
    centralDepositoryAccountNumber: client.centralDepositoryAccountNumber,
    centralDepositoryAccountNumberOpeningDate:
      client.centralDepositoryAccountNumberOpeningDate ?? null,
    internalAccountNumber: client.internalAccountNumber,
    internalAccountNumberOpeningDate: client.internalAccountNumberOpeningDate,
    manager: client.manager,
  };

  return values;
};
