import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  array,
  date,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
  localFileScheme,
} from 'modules/client/common/helpers';
import { Maybe } from 'types/maybe';

import { LocalFileEntity } from '../types';

export const MAX_FILES = 10;

export interface PowerOfAttorneyFormType {
  powerOfAttorney: {
    documentNumber?: Maybe<string>;
    dateOfIssue: Date;
    dateOfExpiration: Date;
    files: LocalFileEntity[];
    notaryFullName?: string;
    notaryLicenseInfo?: string;
  };
}

export const getPowerOfAttorneySchema = (
  t: TFunc
): ObjectSchema<PowerOfAttorneyFormType> =>
  object({
    powerOfAttorney: object({
      documentNumber: string().max(200),
      dateOfIssue: date().max(getToday(), t('validation.maxDate')).required(),
      dateOfExpiration: date()
        .min(getTodayWithResetHours(), t('validation.minDate'))
        .required(),
      files: array().of(localFileScheme).required().min(1).max(MAX_FILES),
      notaryFullName: string().max(200),
      notaryLicenseInfo: string().max(200),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PowerOfAttorneyFormType>();
