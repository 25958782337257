import { CountryEntity } from 'api/types/entity';
import { string, object, array, ObjectSchema, TypedForm } from 'libs/form';
import { Maybe } from 'types/maybe';

export interface BankAccountsFormValue {
  swiftCode: string;
  country: Maybe<CountryEntity>;
}

export interface BankAccountsFormType {
  bankAccounts: BankAccountsFormValue[];
}

export const bankAccountsSchema: ObjectSchema<BankAccountsFormType> = object({
  bankAccounts: array()
    .of(
      object({
        swiftCode: string().required().max(200),
        country: object({
          id: string().required(),
          name: string().required(),
        }).required(),
      }).required()
    )
    .required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<BankAccountsFormType>();
