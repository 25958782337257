import { LegalClientEntity, LegalClientHeadEntity } from 'api/types/entity';
import { useLockLegalClient } from 'modules/client/legal/hooks/useLockLegalClient';

export const useLockLegalClientHead = (
  legalClient: LegalClientEntity | null,
  head: LegalClientHeadEntity | null
) => {
  const { lockClientIfPossible, unlockClient } = useLockLegalClient(
    legalClient
      ? {
          ...legalClient,
          versionNumber: head?.versionNumber,
        }
      : null
  );

  return { lockClientIfPossible, unlockClient };
};
