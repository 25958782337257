// eslint-disable-next-line no-restricted-imports
import { BreakpointsOptions } from '@mui/material';

export const breakpoints: BreakpointsOptions = {
  unit: 'px',
  values: {
    xs: 0,
    sm: 600,
    // medium
    md: 720,
    // large
    lg: 895,
    // extra-large
    xl: 1200,
  },
};
