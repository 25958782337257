// eslint-disable-next-line no-restricted-imports
import { useClientQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseClientEntity } from 'api/helpers/client/parseClientEntity';

export const useClient = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useClientQuery({ variables: { id } });

  const error = useError(apolloError);

  const client = data?.client ? parseClientEntity(data.client) : null;

  return {
    client,
    loading,
    error,
    refetch,
  };
};
