import { useTranslation } from 'libs/i18n';
import {
  CompanyTaxResidencySection as CompanyTaxResidencySectionBase,
  FormContentProps,
} from 'modules/client/common/components/sections/CompanyTaxResidencySection';

interface Props extends FormContentProps {}

export const CompanyTaxResidencySection = ({ ...formProps }: Props) => {
  const { t } = useTranslation();
  return (
    <CompanyTaxResidencySectionBase
      title={t('client.client.taxResidency.title')}
      {...formProps}
    />
  );
};
