import { useNavigate } from 'libs/navigation';

import { useSetPassword } from '../hooks';
import { SetPasswordView } from '../views/SetPasswordView';

export const SetPasswordContainer = () => {
  const { state } = useNavigate<{}, { otpRequired: boolean }>();

  const {
    formErrors,
    submitLoading,
    isOpenVerificationDialog,
    onSubmit,
    onCloseVerificationDialog,
  } = useSetPassword(state?.otpRequired);

  if (!state) {
    window.location.replace('/');
    return null;
  }

  return (
    <SetPasswordView
      formErrors={formErrors}
      isOpenVerificationDialog={isOpenVerificationDialog}
      submitLoading={submitLoading}
      onCloseVerificationDialog={onCloseVerificationDialog}
      onSubmit={onSubmit}
    />
  );
};
