import { LoadingLayout, ErrorLayout } from 'components';
import { useTranslation } from 'libs/i18n';

interface Props {
  isError: boolean;
}
export const LoginByTokenView = ({ isError }: Props) => {
  const { t } = useTranslation();

  if (isError) {
    return <ErrorLayout subtitle={t('auth.loginByToken.subtitle')} />;
  }
  return <LoadingLayout />;
};
