import { ClientEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import { parseBaseGeneralValue } from 'modules/client/common/helpers';

import { GeneralFormType } from '../../forms/generalForm';

export const parseGeneralValue = (client: ClientEntity, t: TFunc) => {
  const values: GeneralFormType['general'] = {
    ...parseBaseGeneralValue(client, t),
    economicSector: client.economicSector
      ? {
          label: t(
            `client.client.generalSection.economicSector.types.${client.economicSector}`
          ),
          value: client.economicSector,
        }
      : null,

    category: client.category
      ? {
          label: t(
            `client.client.generalSection.category.types.${client.category}`
          ),
          value: client.category,
        }
      : null,
  };

  return values;
};
