// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_ManagementType as ManagementTypeApi1,
  Query_LegalClientController_GetList_AllOf_1_List_Items_ManagementType as ManagementTypeApi2,
  Query_LegalClientController_GetById_ManagementType as ManagementTypeApi3,
  Query_ClientController_GetList_AllOf_1_List_Items_ManagementType as ManagementTypeApi4,
} from 'api/generated/graphql';
import { ManagementType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type ManagementTypeApi =
  | ManagementTypeApi1
  | ManagementTypeApi2
  | ManagementTypeApi3
  | ManagementTypeApi4;

const managementType: Record<ManagementTypeApi, ManagementType> = {
  [ManagementTypeApi1.Underwriting]: ManagementType.Underwriting,
  [ManagementTypeApi1.BondholderRepresentative]:
    ManagementType.BondholderRepresentative,
  [ManagementTypeApi1.BrokerageOnFuturesMarket]:
    ManagementType.BrokerageOnFuturesMarket,
  [ManagementTypeApi1.BrokerageWithNominalHolding]:
    ManagementType.BrokerageWithNominalHolding,
  [ManagementTypeApi1.BrokerageWithoutNominalHolding]:
    ManagementType.BrokerageWithoutNominalHolding,
  [ManagementTypeApi1.FinancialConsulting]: ManagementType.FinancialConsulting,
  [ManagementTypeApi1.MarketMaker]: ManagementType.MarketMaker,
  [ManagementTypeApi1.NominalHolding]: ManagementType.NominalHolding,
  [ManagementTypeApi1.None]: ManagementType.None,
  [ManagementTypeApi1.OtherServices]: ManagementType.OtherServices,
  [ManagementTypeApi1.TrustManagement]: ManagementType.TrustManagement,
};

export const parseManagementType = (
  data: Maybe<ManagementTypeApi>
): ManagementType | undefined => (data ? managementType[data] : undefined);
