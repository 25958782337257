// eslint-disable-next-line no-restricted-imports
import { Query_LegalClientController_GetList_AllOf_1_List_Items_Category as CategoryApi } from 'api/generated/graphql';
import { LegalClientCategoryType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

const category: Record<LegalClientCategoryType, CategoryApi> = {
  [LegalClientCategoryType.BrokerDealers]: CategoryApi.BrokerDealers,
  [LegalClientCategoryType.InsuranceOrganizations]:
    CategoryApi.InsuranceOrganizations,
  [LegalClientCategoryType.InvestmentFund]: CategoryApi.InvestmentFund,
  [LegalClientCategoryType.InvestmentFunds]: CategoryApi.InvestmentFunds,
  [LegalClientCategoryType.LegalEntityNonResident]:
    CategoryApi.LegalEntityNonResident,
  [LegalClientCategoryType.LegalEntityResident]:
    CategoryApi.LegalEntityResident,
  [LegalClientCategoryType.NominalHoldersResident]:
    CategoryApi.NominalHoldersResident,
  [LegalClientCategoryType.OtherFinancialMarketLicensees]:
    CategoryApi.OtherFinancialMarketLicensees,
  [LegalClientCategoryType.OtherLegalEntitiesNonResident]:
    CategoryApi.OtherLegalEntitiesNonResident,
  [LegalClientCategoryType.OtherLegalEntitiesResident]:
    CategoryApi.OtherLegalEntitiesResident,
  [LegalClientCategoryType.PensionFundOwnAssets]:
    CategoryApi.PensionFundOwnAssets,
  [LegalClientCategoryType.PensionFundPensionAssets]:
    CategoryApi.PensionFundPensionAssets,
  [LegalClientCategoryType.SecondTierBank]: CategoryApi.SecondTierBank,
};

export const parseCategoryTypeParam = (
  data: Maybe<LegalClientCategoryType>
): CategoryApi | null => (data ? category[data] : null);
