import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSaveLegalClientAccountStatusMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseSaveLegalClientAccountStatusParam } from 'api/helpers/legal/parseParam';
import { SaveAccountStatusParam } from 'api/types/params';

import { parseLegalClient } from '../../helpers/legal/parseLegalClient';

export const useSaveLegalClientAccountStatus = () => {
  const [handle, { data, loading, error: apolloError }] =
    useSaveLegalClientAccountStatusMutation();

  const error = useError(apolloError);

  const client = data?.saveAccountStatus
    ? parseLegalClient(data.saveAccountStatus)
    : undefined;

  const saveLegalClientAccountStatus = useCallback(
    async (id: string, params: SaveAccountStatusParam) => {
      const input = parseSaveLegalClientAccountStatusParam(params);
      const res = await handle({ variables: { id, input } });
      return res.data?.saveAccountStatus
        ? parseLegalClient(res.data?.saveAccountStatus)
        : undefined;
    },
    [handle]
  );

  return {
    client,
    loading,
    error,
    saveLegalClientAccountStatus,
  };
};
