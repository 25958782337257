import { LegalBankAccountParam } from 'api/types/params';
import { lodash, yesOrNot } from 'helpers';

import { CompanyBankAccountsFormType } from '../../forms/companyBankAccountsForm';

export const getBankAccountsParam = (
  values: CompanyBankAccountsFormType
): LegalBankAccountParam[] =>
  lodash.compact(
    values.bankAccounts.map((v) => ({
      swiftCode: v.swiftCode!,
      country: v.country?.id!,
      hasPermanentManagementInCountry: yesOrNot(
        v.hasPermanentManagementInCountry?.value
      )!,
      onTerritoryWithNoAML_CFT: yesOrNot(v.onTerritoryWithNoAML_CFT?.value)!,
    }))
  );
