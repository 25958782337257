import {
  ObjectSchema,
  string,
  object,
  array,
  TypedForm,
  number,
  date,
} from 'libs/form';

import { LocalFileEntity } from '../types';

export interface AdditionalDocumentsFormType {
  additionalDocumentsFiles?: LocalFileEntity[];
}

export const getAdditionalDocumentsSchema =
  (): ObjectSchema<AdditionalDocumentsFormType> =>
    object({
      additionalDocumentsFiles: array().of(
        object({
          id: string(),
          name: string().required(),
          size: number().required(),
          createdAt: date().required(),
        }).required()
      ),
    });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<AdditionalDocumentsFormType>();
