import { CSSObject } from 'libs/ui/styled';
import { Colors, Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

const getColorPrimary = () => ({
  bgColor: Colors.gray50,
  borderColor: Colors.gray150,
  textColor: Colors.black,

  hoverBgColor: Colors.gray150,
  hoverBorderColor: Colors.blue100,
  hoverTextColor: Colors.gray300,

  focusBgColor: Colors.gray150,
  focusBorderColor: Colors.gray150,
  focusTextColor: Colors.black,

  disableBorderColor: Colors.gray200,
  disableBgColor: Colors.gray200,
  disableTextColor: Colors.gray150,
});

const getColorRed = () => ({
  bgColor: Colors.gray50,
  borderColor: Colors.gray150,
  textColor: Colors.black,

  hoverBgColor: Colors.gray150,
  hoverBorderColor: Colors.blue100,
  hoverTextColor: Colors.gray300,

  focusBgColor: Colors.gray150,
  focusBorderColor: Colors.gray150,
  focusTextColor: Colors.black,

  disableBorderColor: Colors.gray200,
  disableBgColor: Colors.gray200,
  disableTextColor: Colors.gray250,
});

export const getSmallMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => {
  const colors = colorVariant === 'primary' ? getColorPrimary() : getColorRed();

  return {
    ...theme.typography.captionBold,
    borderRadius: 8,
    border: `1px solid ${colors.borderColor};`,
    background: colors.bgColor,
    height: 30,
    paddingLeft: 12,
    paddingRight: 12,
    color: colors.textColor,

    '&:hover': {
      borderColor: colors.hoverBorderColor,
      backgroundColor: colors.hoverBgColor,
      color: colors.hoverTextColor,
    } as CSSObject,
    '&:active': {
      borderColor: colors.focusBorderColor,
      backgroundColor: colors.focusBgColor,
      color: colors.focusTextColor,
    } as CSSObject,
    '&:disabled': {
      borderColor: colors.disableBorderColor,
      backgroundColor: colors.disableBgColor,
      color: colors.disableTextColor,
    } as CSSObject,
  };
};
