import { PropsWithChildren } from 'react';

import { DrawerStyled } from './Drawer.styles';
import { DrawerProps } from './types';

/**
 * Drawer component.
 */
export const Drawer = ({
  isOpen,
  children,
  onClose,
}: DrawerProps & PropsWithChildren) => (
  <DrawerStyled anchor="right" open={isOpen} onClose={onClose}>
    {children}
  </DrawerStyled>
);
