// eslint-disable-next-line no-restricted-imports
import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useAvailableClientStatusesLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseAvailableClientStatusesData } from '../../helpers/client/parseAvailableClientStatusesData';

export const useAvailableClientStatusesLazy = () => {
  const [
    getAvailableClientStatusesApi,
    { data, loading, error: apolloError, refetch },
  ] = useAvailableClientStatusesLazyQuery();

  const error = useError(apolloError);

  const availableStatuses = data?.availableClientStatuses
    ? parseAvailableClientStatusesData(data)
    : null;

  const getAvailableClientStatuses = useCallback(
    async (clientId: string) => {
      const response = await getAvailableClientStatusesApi({
        variables: { id: clientId },
      });
      return parseAvailableClientStatusesData(response.data);
    },
    [getAvailableClientStatusesApi]
  );

  return {
    getAvailableClientStatuses,
    availableStatuses,
    loading,
    error,
    refetch,
  };
};
