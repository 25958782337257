import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { DocumentsTableFeature } from 'modules/client/common/feature';

import { Field } from '../../../forms/legalHeadTypeForm';

export interface LegalHeadTypeSectionProps {
  bodies: LegalClientBodyEntity[];
}

export const LegalHeadTypeSection = ({ bodies }: LegalHeadTypeSectionProps) => {
  const { t } = useTranslation();

  return (
    <Section title={t('client.legal.legalClientHead.headInfo.title')}>
      <Row>
        <Box flex={1}>
          <Field.Autocomplete
            hideClearIcon
            label={t('client.legal.legalClientHead.headInfo.body.label')}
            name="headInfo.body"
            optionLabelKey="label"
            options={bodies.map((b) => ({
              value: b.id,
              label: b.name,
            }))}
            optionValueKey="value"
            placeholder={t(
              'client.legal.legalClientHead.headInfo.body.placeholder'
            )}
            variant="select"
          />
        </Box>

        <Box flex={1}>
          <Field.Autocomplete
            fullWidth
            label={t('client.legal.legalClientHead.headInfo.firstHead.label')}
            name="headInfo.firstHead"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t(
              'client.legal.legalClientHead.headInfo.firstHead.placeholder'
            )}
            variant="select"
          />
        </Box>
      </Row>
      <DocumentsTableFeature name="headInfo.legalFiles" />
    </Section>
  );
};
