import {
  BrokerRelationshipPurposeType,
  FinancingSourcesEntity,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { FinancingSourcesFormType } from '../../forms/financingSourcesForm';

export const IncomeSourcesPath: Record<IncomeSourcesType, TranslationPath> = {
  [IncomeSourcesType.BusinessIncome]:
    'client.client.financingSources.businessIncome',
  [IncomeSourcesType.ExpenseSavings]:
    'client.client.financingSources.expenseSavings',
  [IncomeSourcesType.FutureIncome]:
    'client.client.financingSources.futureIncome',
  [IncomeSourcesType.GiftContract]:
    'client.client.financingSources.giftContract',
  [IncomeSourcesType.Inheritance]: 'client.client.financingSources.inheritance',
  [IncomeSourcesType.InvestmentIncome]:
    'client.client.financingSources.investmentIncome',
  [IncomeSourcesType.Loan]: 'client.client.financingSources.loan',
  [IncomeSourcesType.Other]: 'client.client.financingSources.otherLabel',
  [IncomeSourcesType.PropertySale]:
    'client.client.financingSources.propertySale',
  [IncomeSourcesType.Salary]: 'client.client.financingSources.salary',
  [IncomeSourcesType.Savings]: 'client.client.financingSources.savings',
};

export const BrokerRelationshipPurposePath: Record<
  BrokerRelationshipPurposeType,
  TranslationPath
> = {
  [BrokerRelationshipPurposeType.SecuritiesBrokerage]:
    'client.client.financingSources.securitiesBrokerage',
  [BrokerRelationshipPurposeType.TrustAssetManagement]:
    'client.client.financingSources.trustAssetManagement',
  [BrokerRelationshipPurposeType.Other]:
    'client.client.financingSources.otherLabel',
};

export const PlannedOperationTypePath: Record<
  PlannedOperationType,
  TranslationPath
> = {
  [PlannedOperationType.ForeignCurrencyTransactions]:
    'client.client.financingSources.foreignCurrencyTransactions',
  [PlannedOperationType.ForeignIssuersStockTransactions]:
    'client.client.financingSources.foreignIssuersStockTransactions',
  [PlannedOperationType.ForeignIssuersBondsTransactions]:
    'client.client.financingSources.foreignIssuersBondsTransactions',
  [PlannedOperationType.KazakhIssuersBondsTransactions]:
    'client.client.financingSources.kazakhIssuersBondsTransactions',
  [PlannedOperationType.KazakhIssuersStockTransactions]:
    'client.client.financingSources.kazakhIssuersStockTransactions',
  [PlannedOperationType.OptionsFuturesETCDerivatives]:
    'client.client.financingSources.optionsFuturesETCDerivatives',
  [PlannedOperationType.RepoOperations]:
    'client.client.financingSources.repoOperations',
  [PlannedOperationType.SecuritiesTransferFromOtherBroker]:
    'client.client.financingSources.securitiesTransferFromOtherBroker',

  [PlannedOperationType.Other]: 'client.client.financingSources.otherLabel',
};

export const parseFinancingSourcesValue = (
  financingSources: FinancingSourcesEntity | undefined,
  t: TFunc
) => {
  const values: FinancingSourcesFormType['financingSources'] | undefined = {
    ...financingSources,

    incomeSources: financingSources?.incomeSources
      ? financingSources?.incomeSources?.map((v) => ({
          value: v,
          label: t(IncomeSourcesPath[v]),
        }))
      : [],

    brokerRelationshipPurpose: financingSources?.brokerRelationshipPurpose
      ? financingSources.brokerRelationshipPurpose?.map((v) => ({
          value: v,
          label: t(BrokerRelationshipPurposePath[v]),
        }))
      : [],

    plannedOperationTypes: financingSources?.plannedOperationTypes
      ? financingSources.plannedOperationTypes?.map((v) => ({
          value: v,
          label: t(PlannedOperationTypePath[v]),
        }))
      : [],

    incomeAmount: financingSources?.incomeAmount ?? null,
    plannedAssetAmount: financingSources?.plannedAssetAmount ?? null,

    incomeSourceFile: financingSources?.incomeSourceFile ?? [],
  };

  return values;
};
