import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLockClientMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLockClient = () => {
  const [handle, { loading, error: apolloError }] = useLockClientMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    lock: useCallback(
      async (id: string, versionNumber: number) => {
        const res = await handle({ variables: { id, versionNumber } });
        return !!res.data?.lock;
      },
      [handle]
    ),
  };
};
