// eslint-disable-next-line no-restricted-imports
import { SaveLegalClientOnboardingStatusDto_Input } from 'api/generated/graphql';
import { SaveOnboardingStatusParam } from 'api/types/params';

import { parseLegalClientOnboardingStatusParam } from './parseLegalClientOnboardingStatusParam';

export const parseSaveLegalClientOnboardingStatusParam = (
  param: SaveOnboardingStatusParam
): SaveLegalClientOnboardingStatusDto_Input => ({
  value: parseLegalClientOnboardingStatusParam(param.value),
  versionNumber: param.versionNumber,
  result: param.result,
});
