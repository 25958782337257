import { FC, useEffect } from 'react';

import { CountryEntity } from 'api/types/entity';
import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { useFormErrorFocus } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { Stack } from 'libs/ui';
import {
  CitizenshipSection,
  PepConnectionSection,
  TaxResidencySection,
  LegalClientHeadPositionInCompanySection,
  ReasonAddSection,
  RegistrationAndResidentialAddressSection,
} from 'modules/client/common/components/sections';

import { useFormContext } from '../../forms/legalClientHeadForm';
import { HeadTypeSection, PersonalDataSection } from '../sections';

export interface LegalClientAddHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  bodies: LegalClientBodyEntity[];
}

export const LegalClientAddHeadSections: FC<
  LegalClientAddHeadSectionsProps
> = ({ countries, countriesLoading, bodies }) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { errors, setErrors } = useFormContext();

  useEffect(() => {
    if (errors.positionInCompany?.files) {
      notify.show('error', t('validation.files'));
      delete errors.positionInCompany.files;
      setErrors(errors);
    }
  }, [errors, errors.positionInCompany?.files, notify, setErrors, t]);

  useFormErrorFocus('center');

  return (
    <Stack spacing={48}>
      <ReasonAddSection />
      <PersonalDataSection />
      <HeadTypeSection bodies={bodies} />
      <CitizenshipSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <TaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <RegistrationAndResidentialAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <PepConnectionSection />
      <LegalClientHeadPositionInCompanySection />
    </Stack>
  );
};
