import { Typography } from '../Typography';
import { TypographyVariant } from '../theme/typography';

import { ChipStyled } from './Chip.styles';
import { ChipProps, ChipSize } from './types';

/**
 * Chip component.
 */

const typographyVariant: Record<ChipSize, TypographyVariant> = {
  medium: 'bodySemiBold',
  small: 'captionMedium',
  smedium: 'bodySemiBold',
};

export const Chip = ({
  variant,
  text,
  uppercase = false,
  size = 'medium',
}: ChipProps) => (
  <ChipStyled
    className="chip"
    size={size}
    uppercase={uppercase}
    variant={variant}
  >
    <Typography className="chip-text" variant={typographyVariant[size]}>
      {text}
    </Typography>
  </ChipStyled>
);
