// eslint-disable-next-line no-restricted-imports
import { BankAccountFragment } from 'api/generated/graphql';
import { BankAccountEntity } from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseCountryEntity } from '../../parseCountryEntity';

export const parseBankAccounts = (
  bankAccounts: Maybe<BankAccountFragment>[] | undefined | null
): BankAccountEntity[] | undefined =>
  bankAccounts
    ? lodash.compact(bankAccounts).map((v) => ({
        swiftCode: v.swiftCode,
        country: parseCountryEntity(v.country),
      }))
    : undefined;
