import { FC } from 'react';

import { ClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { LayoutWithNavigationBreadcrumbs } from 'components/LayoutWithNavigationBreadcrumbs';
import { Form } from 'libs/form/Form';
import { ClientTypeToggleButton } from 'modules/client/common/components';
import { VersionView } from 'modules/client/common/views';

import {
  RepresentativeSections,
  RepresentativeSectionsProps,
} from '../components';
import { RepresentativeFormType } from '../forms/representativeForm';
import { ClientType } from '../types';

export interface RepresentativeClientVersionViewProps
  extends RepresentativeSectionsProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  versions: ClientVersionEntity[];
  initialValues?: RepresentativeFormType;
  fullName: string;
  versionId: string;
  snapshotLoading?: boolean;
  onChangeVersion: (version: ClientVersionEntity) => void;
  onChangeClientType: () => void;
  onCloseVersionHistory: () => void;
  onBack: () => void;
}

export const RepresentativeClientVersionView: FC<
  RepresentativeClientVersionViewProps
> = ({
  breadcrumbsTitles,
  versions,
  fullName,
  initialValues,
  snapshotLoading,
  versionId,
  onBack,
  onCloseVersionHistory,
  onChangeClientType,
  onChangeVersion,
  ...sectionsProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <VersionView
      canBack
      content={
        initialValues && (
          <Form
            initialReadOnly
            initialValues={initialValues}
            onSubmit={() => {}}
          >
            <RepresentativeSections hideActivitySection {...sectionsProps} />
          </Form>
        )
      }
      loading={snapshotLoading || !initialValues}
      name={fullName}
      topContent={
        <ClientTypeToggleButton
          value={ClientType.representative}
          onChange={onChangeClientType}
        />
      }
      versionId={versionId}
      versions={versions}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  </LayoutWithNavigationBreadcrumbs>
);
