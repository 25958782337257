import { RoleType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { Field, useFormContext } from '../../forms/adminForm';

export const AdminFormContent = () => {
  const { t } = useTranslation();

  // remove super-admin role
  const roles = Object.values(RoleType).filter(
    (v) => v !== RoleType.SuperAdmin
  );

  const { initialValues, readOnly } = useFormContext();

  const isSuperadmin = initialValues?.role.value === RoleType.SuperAdmin;

  return (
    <Stack spacing={24}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        spacing={24}
      >
        {/* left */}
        <Stack flex={1} spacing={24}>
          <Field.Text
            fullWidth
            readOnly
            label={t('admin.fields.email.label')}
            name="email"
            placeholder={t('admin.fields.email.placeholder')}
          />
          <Field.Text
            fullWidth
            label={t('admin.fields.position.label')}
            name="position"
            placeholder={t('admin.fields.position.placeholder')}
          />
          <Field.Autocomplete
            helperTextAbsolute
            hideClearIcon
            label={t('admin.fields.role.label')}
            name="role"
            optionLabelKey="label"
            options={roles.map((v) => ({
              label: t(`admin.adminRoles.${v}`),
              value: v,
            }))}
            optionValueKey="value"
            placeholder={t('admin.fields.role.placeholder')}
            readOnly={isSuperadmin || readOnly}
            variant="select"
          />
        </Stack>
        {/* right */}
        <Stack flex={1} spacing={24}>
          <Field.Text
            fullWidth
            label={t('admin.fields.lastName.label')}
            name="lastName"
            placeholder={t('admin.fields.lastName.placeholder')}
          />
          <Field.Text
            fullWidth
            label={t('admin.fields.firstName.label')}
            name="firstName"
            placeholder={t('admin.fields.firstName.placeholder')}
          />
          <Field.Text
            fullWidth
            helperTextAbsolute
            label={t('admin.fields.middleName.label')}
            name="middleName"
            placeholder={t('admin.fields.middleName.placeholder')}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
