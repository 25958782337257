import { useAdmins as useAdminsApi } from 'api/requests';
import { scrollToTop } from 'helpers';
import { useQueryListData } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { ROUTES, useNavigate } from 'libs/navigation';

import { CreateAdminFormType } from '../forms/createAdminForm';
import { AdminsListItem } from '../types';

import { useCreateAdmin } from './useCreateAdmin';

const LIMIT = 50;

export const useAdmins = () => {
  const { onSubmitCreate, createLoading, createFormError } = useCreateAdmin();

  const { navigate } = useNavigate();

  const {
    list: admins,
    rowCount,
    loading,
    setFilter,
    sort,
    page,
    filter,
    refetch,
    error,
    onPaginationChange,
    onSortChange,
  } = useQueryListData(useAdminsApi, {
    limit: LIMIT,
    filterParams: ['search'],
  });

  const onCellClick = (admin: AdminsListItem) => {
    navigate(ROUTES.admins.admin.fullPath, { adminId: admin.id });
    scrollToTop();
  };

  const handleSubmitCreate: FormSubmit<CreateAdminFormType> = async (
    values,
    helpers
  ) => {
    if (await onSubmitCreate(values, helpers)) {
      refetch();
      return true;
    }
    return false;
  };

  return {
    admins,
    loading,
    sort,
    page,
    rowCount,
    limit: LIMIT,
    filter,
    createLoading,
    error,
    createFormError,
    onSubmitCreate: handleSubmitCreate,
    setFilter,
    onCellClick,
    onSortChange,
    onPaginationChange,
  };
};
