import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  useFormContext,
  Field,
} from 'modules/client/legal/forms/companyStructureForm';

export const FormContent = () => {
  const { t } = useTranslation();
  const { values } = useFormContext();

  if (!values.companyStructure.length) {
    return (
      <Typography>{t('client.legal.companyStructure.emptyText')}</Typography>
    );
  }

  return (
    <Stack spacing={32}>
      {values.companyStructure.map((value, index) => (
        <Field.Text
          readOnly
          label={value.type}
          name={`companyStructure.${index}.name`}
        />
      ))}
    </Stack>
  );
};
