import { ReactNode } from 'react';

import { NotificationSettingsType } from 'api/types/data';
import { NotificationEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';

import { NotificationWrapper } from './components';
import { useNotificationItem } from './hooks';
import { NotificationChangeOnboardingStatus } from './templates';

export interface NotificationItemFeatureProps {
  notification: NotificationEntity;
  onClose: () => void;
}

export const NotificationItemFeature = ({
  notification,
  onClose,
}: NotificationItemFeatureProps) => {
  const { loadingToggleRead, onToggleNotificationRead, onClickClientName } =
    useNotificationItem(notification, onClose);

  const { t } = useTranslation();

  let content: ReactNode = null;

  if (notification.type === NotificationSettingsType.ChangeOnboardingStatus) {
    content = (
      <NotificationChangeOnboardingStatus
        notification={notification}
        onClickClientName={onClickClientName}
      />
    );
  }

  return (
    <NotificationWrapper
      date={notification.createdAt}
      isRead={notification.isRead}
      loadingToggleRead={loadingToggleRead}
      title={t(`components.dialogNotifications.types.${notification.type}`)}
      onToggleRead={onToggleNotificationRead}
    >
      {content}
    </NotificationWrapper>
  );
};
