import { TimezoneEntity } from 'api/types/entity';
import { format } from 'date-fns';

/**
 * ISO date format.
 * @example '2024-09-12'
 */
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Local date format.
 * @example '12.09.2024'
 */
export const DATE_FORMAT = 'dd.MM.yyyy';

/**
 * Date and time format with seconds.
 * @example '12.09.2024 15:30:45'
 */
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';

/**
 * Date and time format without seconds.
 * @example '12.09.2024 15:30'
 */
export const DATE_TIME_SHORT_FORMAT = 'dd.MM.yyyy HH:mm';

/**
 * Time format with seconds.
 * @example '15:30:45'
 */
export const TIME_FORMAT = 'HH:mm:ss';

/**
 * Formats a given date to ISO format (yyyy-MM-dd).
 * @example '2024-09-12'
 */
export const formatToISODate = (date: Date): string =>
  format(date, ISO_DATE_FORMAT);

/**
 * Formats a given date to local format (dd.MM.yyyy).
 * @example '12.09.2024'
 */
export const formatToDate = (date: Date): string => format(date, DATE_FORMAT);

/**
 * Formats a given date to date and time format with seconds (dd.MM.yyyy HH:mm:ss).
 * @example '12.09.2024 15:30:45'
 */
export const formatToDateTime = (date: Date): string =>
  format(date, DATE_TIME_FORMAT);

/**
 * Formats a given date to date and time format without seconds (dd.MM.yyyy HH:mm).
 * @example '12.09.2024 15:30'
 */
export const formatToDateTimeShort = (date: Date): string =>
  format(date, DATE_TIME_SHORT_FORMAT);

/**
 * Formats a given date to time format with seconds (HH:mm:ss).
 * @example '15:30:45'
 */
export const formatToTime = (date: Date): string => format(date, TIME_FORMAT);

/**
 * Converts a given date to UTC time.
 */
export const getUtcTime = (date: Date): Date => {
  const d = new Date(date);
  d.setTime(d.getTime() + d.getTimezoneOffset() * 60000);
  return d;
};

/**
 * Converts a given date to the specified timezone.
 * @returns {Date} The date converted to the given timezone.
 */
export const convertDateByTimezone = (
  date: Date,
  timezone: TimezoneEntity
): Date => {
  const utcDate = getUtcTime(date);
  utcDate.setTime(utcDate.getTime() + timezone.gmtOffset * 1000);
  return utcDate;
};
