// eslint-disable-next-line no-restricted-imports
import {
  LegalContactDto_Input,
  Query_LegalClientController_GetList_AllOf_1_List_Items_OrderSubmissionContacts_Items_Type,
} from 'api/generated/graphql';
import { LegalClientOrderSubmissionContactParam } from 'api/types/params';
import { lodash } from 'helpers';

export const parseLegalClientOrderSubmissionContactParam = ({
  type,
  ...orderSubmissionContactOtherFields
}: LegalClientOrderSubmissionContactParam): LegalContactDto_Input => ({
  ...orderSubmissionContactOtherFields,
  type: Query_LegalClientController_GetList_AllOf_1_List_Items_OrderSubmissionContacts_Items_Type[
    type
  ],
});

export const parseLegalClientOrderSubmissionContactsParam = (
  orderSubmissionContacts: LegalClientOrderSubmissionContactParam[]
): LegalContactDto_Input[] =>
  lodash
    .compact(orderSubmissionContacts)
    .map(parseLegalClientOrderSubmissionContactParam);
