import { useState, MouseEvent, useCallback } from 'react';

import { parseFullName, parseInitials } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { useUser } from 'libs/providers';
import { Box, Pressed, Stack, Typography } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { DialogNotificationSettingsFeature } from './DialogNotificationSettingsFeature';
import { DialogUserTimezoneSettingsFeature } from './DialogUserTimezoneSettingsFeature';
import { PopoverContentStyled, PopoverStyled } from './styles';

export const User = () => {
  const { user, logout } = useUser();

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { open: timezoneOpen, ...timezoneDialogProps } = useDialog();
  const { open: notificationOpen, ...notificationDialogProps } = useDialog();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const closureEvent = useCallback(
    <T extends Function>(f: T) =>
      () => {
        handleClose();
        return f();
      },
    [handleClose]
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <Pressed onClick={handleClick}>
        <Stack alignItems="center" direction="row" spacing={12}>
          <Typography variant="bodyBold">{parseInitials(user)}</Typography>
          <Icon.User />
        </Stack>
      </Pressed>

      <PopoverStyled
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <PopoverContentStyled className="headerUser-popoverContent">
          <Stack className="headerUser-popoverContent-wrapper">
            <Typography
              className="ellipsisText"
              title={parseFullName(user)}
              variant="bodyBold"
            >
              {parseFullName(user)}
            </Typography>
            <Typography color={Colors.gray300}>{user.email}</Typography>
          </Stack>
          <Box className="headerUser-popoverContent-line" />
          {/* notification */}
          <Stack className="headerUser-popoverContent-wrapper">
            <Pressed onClick={closureEvent(notificationOpen)}>
              <Typography className="ellipsisText">
                {t('components.notificationSettings.notificationSettingsLabel')}
              </Typography>
            </Pressed>
          </Stack>
          <Box className="headerUser-popoverContent-line2" />
          {/* timezone */}
          <Stack className="headerUser-popoverContent-wrapper">
            <Pressed onClick={closureEvent(timezoneOpen)}>
              <Typography className="ellipsisText">
                {t('common.timezone')}
              </Typography>
            </Pressed>
          </Stack>
          <Box className="headerUser-popoverContent-line2" />
          <Stack className="headerUser-popoverContent-wrapper">
            <Pressed onClick={logout}>
              <Typography color={Colors.red100}>
                {t('common.logout')}
              </Typography>
            </Pressed>
          </Stack>
        </PopoverContentStyled>
      </PopoverStyled>

      <DialogUserTimezoneSettingsFeature {...timezoneDialogProps} />
      <DialogNotificationSettingsFeature {...notificationDialogProps} />
    </>
  );
};
