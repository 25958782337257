/* eslint-disable no-restricted-imports */
import { useCreateAdminMutation } from 'api/generated/graphql';
import {
  parseAdminEntity,
  parseCreateAdminParams,
  useError,
} from 'api/helpers';
import { CreateAdminParams } from 'api/types/params';

export const useCreateAdmin = () => {
  const [createAdmin, { data, loading, error: serverError }] =
    useCreateAdminMutation();

  const error = useError(serverError);

  return {
    admin: data?.createAdmin ? parseAdminEntity(data.createAdmin) : undefined,
    loading,
    error,
    createAdmin: async (params: CreateAdminParams) => {
      const input = parseCreateAdminParams(params);
      const res = await createAdmin({ variables: { input } });

      return res.data?.createAdmin
        ? parseAdminEntity(res.data?.createAdmin)
        : undefined;
    },
  };
};
