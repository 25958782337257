import { OrderSubmissionContactEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import {
  getTypes,
  initialOrderSubmissionContactType,
} from '../../forms/orderSubmissionContactsForm';

export const parseOrderSubmissionContactInitialValue = (
  t: TFunc,
  orderSubmissionContact: OrderSubmissionContactEntity
) => {
  const { type, value } = orderSubmissionContact;

  const formContactType = getTypes(t).find(({ value }) => type === value);

  if (!formContactType) {
    throw new Error(`Unknown contact type: ${type}`);
  }

  return {
    type: formContactType,
    value,
  };
};

export const parseOrderSubmissionContactsInitialValue = (
  t: TFunc,
  orderSubmissionContacts?: OrderSubmissionContactEntity[]
) =>
  orderSubmissionContacts
    ? orderSubmissionContacts.map((v) =>
        parseOrderSubmissionContactInitialValue(t, v)
      )
    : [
        {
          type: initialOrderSubmissionContactType,
          value: '',
        },
      ];
