import { useCallback } from 'react';

import { useFilePreview } from 'api/requests';

export const useGetFileLinkPreview = () => {
  const { getFileLink, loading } = useFilePreview();

  const handleGetFileLink = useCallback(
    async (fileId: string) => {
      const link = await getFileLink(fileId);

      return link;
    },
    [getFileLink]
  );

  return {
    getFileLink: handleGetFileLink,
    loading,
  };
};
