import { OnboardingData } from 'api/types/data';
import { lodash } from 'helpers';

export const getOnboardingProgressPercent = (
  onboardingData?: OnboardingData
) => {
  if (onboardingData?.isCompleted) {
    return 100;
  }

  const traversedRoutesLength = lodash.uniq(
    onboardingData?.traversedRoutes ?? []
  ).length;

  const totalRoutes = onboardingData?.totalRoutes ?? 0;

  if (!totalRoutes || !traversedRoutesLength) {
    return 0;
  }

  return Math.min(
    Math.floor(((traversedRoutesLength - 1) / totalRoutes) * 100),
    100
  );
};
