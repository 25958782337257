import { MoreMenu } from 'components/MoreMenu';
import { lodash } from 'helpers';
import { TFunc } from 'libs/i18n';
import { CircularProgress } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { DocumentItemType, DocumentOptionType } from '../types';

export const renderMore = (
  t: TFunc,
  item: DocumentItemType,
  editable: boolean,
  onClickOption: (item: DocumentItemType, option: DocumentOptionType) => void
) =>
  item.loading ? (
    <CircularProgress size={16} />
  ) : (
    <MoreMenu
      options={lodash.compact([
        {
          value: DocumentOptionType.download,
          label: t('common.download'),
          Icon: Icon.Download,
        },
        editable &&
          item.uploadedByAdmin && {
            value: DocumentOptionType.delete,
            label: t('common.delete'),
            Icon: Icon.Close,
          },
      ])}
      variant="horizontal"
      onClickOption={(option) => onClickOption(item, option.value)}
    />
  );
