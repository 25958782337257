import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSaveLegalClientOnboardingStatusMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClient } from 'api/helpers/legal/parseLegalClient';
import { parseSaveLegalClientOnboardingStatusParam } from 'api/helpers/legal/parseParam/parseSaveLegalClientOnboardingStatusParam';
import { SaveOnboardingStatusParam } from 'api/types/params';

export const useSaveLegalClientOnboardingStatus = () => {
  const [handle, { data, loading, error: apolloError }] =
    useSaveLegalClientOnboardingStatusMutation();

  const error = useError(apolloError);

  const saveLegalClientOnboardingStatus = useCallback(
    async (id: string, params: SaveOnboardingStatusParam) => {
      const input = parseSaveLegalClientOnboardingStatusParam(params);
      const res = await handle({ variables: { id, input } });
      return res.data?.saveOnboardingStatus
        ? parseLegalClient(res.data?.saveOnboardingStatus)
        : undefined;
    },
    [handle]
  );

  const legalClient = data?.saveOnboardingStatus
    ? parseLegalClient(data.saveOnboardingStatus)
    : undefined;

  return {
    legalClient,
    loading,
    error,
    saveLegalClientOnboardingStatus,
  };
};
