// eslint-disable-next-line no-restricted-imports
import { BaseLegalClientRepresentativeFragment } from 'api/generated/graphql';
import { parseLegalClientConstitutionalDocument } from 'api/helpers/legal/parseEntity/parseConstitutionalDocument';
import { parseLegalClientStateRegistration } from 'api/helpers/legal/parseEntity/parseLegalClientStateRegistration';
import { parseLegalClientTaxResidency } from 'api/helpers/legal/parseEntity/parseLegalClientTaxResidency';
import { LegalClientRepresentativeCompanyEntity } from 'api/types/entity/legalClientRepresentative/legalClientRepresentativeCompanyEntity';
import { lodash } from 'helpers';

export const parseCompanyEntity = (data: {
  company?: BaseLegalClientRepresentativeFragment['company'];
}): LegalClientRepresentativeCompanyEntity | undefined =>
  data.company
    ? {
        fullName: data.company.fullName ?? undefined,
        shortName: data.company.shortName ?? undefined,
        legalAddress: data.company.legalAddress,
        actualAddress: data.company.actualAddress,

        taxResidences: data.company.taxResidences
          ? lodash
              .compact(data.company.taxResidences)
              .map(parseLegalClientTaxResidency)
          : undefined,
        stateRegistration: parseLegalClientStateRegistration(data.company),
        constitutionalDocument: parseLegalClientConstitutionalDocument(
          data.company.constitutionalDocument
        ),
      }
    : undefined;
