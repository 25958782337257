import {
  useLegalClientBranch,
  useLegalClient,
  useLegalClientBranchSnapshot,
} from 'api/requests';
import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getLegalClientBranchInitialValue } from '../helpers';

import { useLegalClientBranchVersions } from './useLegalClientBranchVersions';

type ContainerParams = {
  legalClientId: string;
  branchId: string;
  versionId?: string;
};

export const useLegalClientBranchVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ContainerParams>();

  const { loading: loadingBranchClient, legalClientBranch } =
    useLegalClientBranch(params.branchId!);

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: versionsLoading, versions } = useLegalClientBranchVersions(
    params.legalClientId!,
    params.branchId!,
    params.versionId
  );

  const {
    snapshot,
    loading: snapshotLoading,
    error,
  } = useLegalClientBranchSnapshot(params.branchId!, params.versionId!, {
    fetchPolicy: 'no-cache',
  });

  const initialValues = snapshot?.value
    ? getLegalClientBranchInitialValue(snapshot.value, t)
    : undefined;

  const fullName = legalClientBranch?.fullName ?? '';
  const clientFullName = legalClient?.fullName;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: clientFullName,
    [RoutesId.legalClientVersion]: t('client.client.versions.header', {
      name: clientFullName,
    }),
    [RoutesId.legalClientBranchVersion]: t('client.client.versions.header', {
      name: fullName,
    }),
  };

  const loading =
    (!legalClientBranch && loadingBranchClient) ||
    (!versions && versionsLoading);

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientBranch.fullPath,
      {
        legalClientId: params.legalClientId!,
        branchId: params.branchId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: LegalClientVersionEntity) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientBranchVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        branchId: params.branchId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
  };
};
