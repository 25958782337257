import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCreateLegalClientLegalHeadMutation } from 'api/generated/graphql';
import { parseLegalClientLegalHeadEntity, useError } from 'api/helpers';
import { CreateLegalClientLegalHeadParams } from 'api/types/params';

export const useCreateLegalClientLegalHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useCreateLegalClientLegalHeadMutation();

  const error = useError(apolloError);

  return {
    legalClientHead: data?.createLegalClientLegalHead
      ? parseLegalClientLegalHeadEntity(data.createLegalClientLegalHead)
      : undefined,
    loading,
    error,
    createLegalClientLegalHead: useCallback(
      async (params: CreateLegalClientLegalHeadParams) => {
        const res = await handle({ variables: { input: params } });
        return res.data?.createLegalClientLegalHead
          ? parseLegalClientLegalHeadEntity(
              res.data?.createLegalClientLegalHead
            )
          : undefined;
      },
      [handle]
    ),
  };
};
