// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_InformationUpdateFrequency as InformationUpdateFrequencyApi,
  Query_LegalClientController_GetList_AllOf_1_List_Items_InformationUpdateFrequency as InformationUpdateFrequencyApi2,
  Query_LegalClientController_GetById_InformationUpdateFrequency as InformationUpdateFrequencyApi3,
  Query_ClientController_GetList_AllOf_1_List_Items_InformationUpdateFrequency as InformationUpdateFrequencyApi4,
} from 'api/generated/graphql';
import { InformationUpdateFrequencyType } from 'api/types/entity';

type InformationUpdateFrequencyApiType =
  | InformationUpdateFrequencyApi
  | InformationUpdateFrequencyApi2
  | InformationUpdateFrequencyApi3
  | InformationUpdateFrequencyApi4;

const informationUpdateFrequencyMap: Record<
  InformationUpdateFrequencyApiType,
  InformationUpdateFrequencyType
> = {
  [InformationUpdateFrequencyApi.OnceAMonth]:
    InformationUpdateFrequencyType.OnceAMonth,
  [InformationUpdateFrequencyApi.OnceAYear]:
    InformationUpdateFrequencyType.OnceAYear,
  [InformationUpdateFrequencyApi.Semiannually]:
    InformationUpdateFrequencyType.Semiannually,
};

export const parseInformationUpdateFrequency = (
  value: InformationUpdateFrequencyApiType
) => informationUpdateFrequencyMap[value];
