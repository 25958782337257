import { FC, useEffect } from 'react';

import { CountryEntity } from 'api/types/entity';
import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { useFormErrorFocus } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { Stack } from 'libs/ui';
import {
  ReasonAddSection,
  CompanyNameSection,
  CompanyStateRegistrationSection,
  CompanyRegistrationAndResidentialAddressSection,
} from 'modules/client/common/components/sections';
import { CompanyTaxResidencySection } from 'modules/client/legal/components/sections';

import { useFormContext } from '../../forms/legalClientLegalHeadForm';
import { LegalHeadTypeSection } from '../sections';

export interface LegalClientAddLegalHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  bodies: LegalClientBodyEntity[];
}

export const LegalClientAddLegalHeadSections: FC<
  LegalClientAddLegalHeadSectionsProps
> = ({ countries, countriesLoading, bodies }) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { errors, setErrors } = useFormContext();

  useEffect(() => {
    if (errors.companyStateRegistration?.files) {
      notify.show('error', t('validation.files'));
      delete errors.companyStateRegistration.files;
      setErrors(errors);
    }
    if (errors.headInfo?.legalFiles) {
      notify.show('error', t('validation.files'));
      delete errors.headInfo.legalFiles;
      setErrors(errors);
    }
  }, [errors, errors.companyStateRegistration?.files, notify, setErrors, t]);

  useFormErrorFocus('center');

  return (
    <Stack spacing={48}>
      <ReasonAddSection />

      <CompanyNameSection />

      <LegalHeadTypeSection bodies={bodies} />

      <CompanyTaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <CompanyRegistrationAndResidentialAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <CompanyStateRegistrationSection />
    </Stack>
  );
};
