import { FC } from 'react';

import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';

import {
  LegalClientLegalHeadForm,
  LegalClientLegalHeadFormProps,
} from '../components';

export interface LegalClientLegalHeadViewProps
  extends LegalClientLegalHeadFormProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
}

export const LegalClientLegalHeadView: FC<LegalClientLegalHeadViewProps> = ({
  initialValues,
  breadcrumbsTitles,
  onSubmit,
  ...formProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <LegalClientLegalHeadForm
      {...formProps}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  </LayoutWithNavigationBreadcrumbs>
);
