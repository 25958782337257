import { TaxResidenceParam } from 'api/types/params';
import { lodash } from 'helpers';

import { TaxResidencyFormType } from '../../forms/taxResidencyForm';

export const parseTaxResidencyParam = (
  values: TaxResidencyFormType
): TaxResidenceParam[] => {
  const result: TaxResidenceParam[] = lodash.compact(
    values.taxResidences.map((v) => ({
      country: v.country?.id!,
      taxId: !v.taxNumberIsMissing ? v.taxNumber : undefined,
      reasonForMissingTaxId: v.taxNumberIsMissing
        ? v.reasonForAbsence
        : undefined,
    }))
  );

  return result;
};
