import { useCallback, useEffect } from 'react';

import { useTwoFactorGenerate, useTwoFactorVerify } from 'api/requests';
import { useObjectState } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { TwoFactorFormType, FormErrors } from '../forms/twoFactorForm';

export const useTwoFactorConnection = () => {
  const { t } = useTranslation();

  const {
    generate,
    data: qrData,
    loading: qrLoading,
    error: qrError,
  } = useTwoFactorGenerate();

  const { verify, loading: submitLoading, error } = useTwoFactorVerify();

  useEffect(() => {
    generate();
  }, [generate]);

  const onSubmit = useCallback(
    async (values: TwoFactorFormType) => {
      if (values.code) {
        await verify(values.code);
        window.location.replace('/');
      }
    },
    [verify]
  );

  const [formError, setFormError] = useObjectState<FormErrors>({});

  useEffect(() => {
    if (error) {
      setFormError({ code: t('common.incorrectCode') });
    } else {
      setFormError({ code: undefined });
    }
  }, [error, setFormError, t]);

  return { onSubmit, formError, qrData, submitLoading, qrError, qrLoading };
};
