import { Colors, PaletteMode } from '../../theme';
import { ChipVariant } from '../types';

export const TEXT_COLORS: Record<ChipVariant, Record<PaletteMode, Colors>> = {
  blue: {
    light: Colors.blue250,
    dark: Colors.blue250,
  },
  red: {
    light: Colors.red200,
    dark: Colors.red200,
  },
  yellow: {
    light: Colors.yellow100,
    dark: Colors.yellow100,
  },
  green: {
    light: Colors.green100,
    dark: Colors.green100,
  },
};

export const BG_COLORS: Record<ChipVariant, Record<PaletteMode, Colors>> = {
  blue: {
    light: Colors.blue50,
    dark: Colors.blue50,
  },
  red: {
    light: Colors.red50,
    dark: Colors.red50,
  },
  yellow: {
    light: Colors.yellow50,
    dark: Colors.yellow50,
  },
  green: {
    light: Colors.green50,
    dark: Colors.green50,
  },
};
