import {
  LegalClientEntity,
  LegalClientBeneficiaryEntity,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { DialogStateProps } from 'libs/ui';
import { AddDialogForm } from 'modules/client/common/components';

import { LegalClientAddBeneficiarySections } from '../components';
import { useLegalClientAddBeneficiary } from '../hooks';

interface Props extends DialogStateProps {
  legalClient: LegalClientEntity;
  onSuccess: (legalClientBeneficiary: LegalClientBeneficiaryEntity) => void;
}

export const LegalClientAddBeneficiaryDialogFeature = ({
  legalClient,
  isOpen,
  onClose,
  onSuccess,
}: Props) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    validationSchema,
    formError,
    submitLoading,
    onSubmit,
  } = useLegalClientAddBeneficiary(legalClient, onSuccess);

  const { t } = useTranslation();

  return (
    <AddDialogForm
      initialErrors={formError}
      initialValues={initialValues}
      isOpen={isOpen}
      submitLoading={submitLoading}
      title={t('client.legal.legalClientBeneficiary.addDialog.title')}
      validationSchema={validationSchema}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <LegalClientAddBeneficiarySections
        countries={countries ?? []}
        countriesLoading={countriesLoading}
      />
    </AddDialogForm>
  );
};
