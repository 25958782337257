// eslint-disable-next-line no-restricted-imports
import { Dialog as DialogBase } from '@mui/material';

import { styled } from '../styled';
import { Colors } from '../theme';

export const DialogStyled = styled(DialogBase)(({ fullScreen }) => ({
  '.MuiBackdrop-root': {
    backgroundColor: `${Colors.gray300}A5`,
  },
  '.MuiPaper-root': {
    borderRadius: fullScreen ? 0 : 24,
    boxShadow: '0px 24px 38px 0px rgba(45, 54, 72, 0.02)',
  },
}));
