import { ServiceContractType } from 'api/types/entity';
import {
  ObjectSchema,
  string,
  object,
  mixed,
  date,
  TypedForm,
} from 'libs/form';
import { SelectType } from 'types/selectType';
import { Maybe } from 'yup';

export interface ServiceContractFormType {
  serviceContract: {
    type?: Maybe<SelectType<ServiceContractType>>;
    number?: Maybe<string>;
    conclusionDate?: Maybe<Date>;
    terminationDate?: Maybe<Date>;
  };
}

export const serviceContractSchema: ObjectSchema<ServiceContractFormType> =
  object({
    serviceContract: object({
      type: object({
        value: mixed<ServiceContractType>()
          .oneOf(Object.values(ServiceContractType))
          .required(),
        label: string().required(),
      })
        .nullable()
        .default(null),

      number: string().max(200).nullable().default(null),
      conclusionDate: date().nullable().default(null),
      terminationDate: date().nullable().default(null),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ServiceContractFormType>();
