import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';

export const FiltersStyled = styled(Stack)({
  minWidth: 380,
  paddingTop: 32,
  paddingBottom: 32,
  justifyContent: 'space-between',
  flex: 1,
  overflow: 'hidden',
  gap: 32,

  '.filters-content': {
    overflow: 'auto',
    paddingLeft: 32,
    paddingRight: 32,
  },

  '.filters-footer': {
    paddingLeft: 32,
    paddingRight: 32,
  },
});
