import { CountryEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import { useFormContext } from 'modules/client/legal/forms/companyBankAccountsForm';

import { Header, Fields, Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.bankAccounts.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24}>
            <Header index={index} />

            <Fields
              countries={countries}
              countriesLoading={countriesLoading}
              index={index}
            />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
