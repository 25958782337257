import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { SearchBarWithFilter } from 'components';
import { lodash } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Box, Stack, Typography } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { PersonAndLegalClientsToggleButton } from 'modules/client/common/components';

import { ClientsTableProps, ClientsTable, ClientsFilters } from '../components';
import { ClientFilterType } from '../types';

export interface ClientsViewProps extends ClientsTableProps {
  filter: ClientFilterType;
  setFilter: (filter?: ClientFilterType | undefined) => void;
  registrationCountries?: CountryEntity[];
  locationCountries?: CountryEntity[];
}

export const ClientsView: FC<ClientsViewProps> = ({
  setFilter,
  filter,
  registrationCountries,
  locationCountries,
  ...tableProps
}) => {
  const { t } = useTranslation();
  const { isOpen, onClose, open } = useDialog();

  const { search, ...restFilter } = filter;

  const countFilter =
    lodash.defaultTo([filter?.onboardingStatuses?.length], 0) +
    lodash.defaultTo([filter?.riskLevels?.length], 0) +
    lodash.defaultTo([filter?.locationCountries?.length], 0) +
    lodash.defaultTo([filter?.registrationCountries?.length], 0);

  const noRowsLabel =
    (!!countFilter && t('components.table.filterEmptyData')) ||
    (!!search && t('components.table.searchEmptyData')) ||
    undefined;

  return (
    <Stack spacing={32}>
      <Typography variant="title">
        {t('client.clients.personal.title')}
      </Typography>
      <Stack direction="row" justifyContent="space-between" spacing={32}>
        <PersonAndLegalClientsToggleButton value="person" />
        <Box flex={1} maxWidth={831}>
          <SearchBarWithFilter
            filterContent={
              <ClientsFilters
                filter={restFilter}
                locationCountries={locationCountries}
                registrationCountries={registrationCountries}
                onApply={(newFilter) => {
                  setFilter({ search, ...newFilter });
                  onClose();
                }}
              />
            }
            filterCount={countFilter}
            isFilterOpen={isOpen}
            search={search ?? ''}
            onFilterClose={onClose}
            onFilterOpen={open}
            onSearch={(v) => {
              setFilter({ ...restFilter, search: v });
            }}
          />
        </Box>
      </Stack>
      <ClientsTable {...tableProps} noRowsLabel={noRowsLabel} />
    </Stack>
  );
};
