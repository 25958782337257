import { useMemo } from 'react';

import {
  DatePicker as DatePickerBase,
  LocalizationProvider,
} from '@mui/x-date-pickers/';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { mapI18nLocalToDateFns } from 'helpers';
import { useTranslation } from 'libs/i18n';

import { Icon } from '../Icon';
import { Input } from '../Input';

import { DatePickerProps } from './types';

const CloseIcon = () => <Icon.Close size={16} />;

/**
 * DatePicker component.
 */
export const DatePicker = ({
  helperText,
  error,
  placeholder,
  readOnly,
  fullWidth,
  ...props
}: DatePickerProps) => {
  const { language } = useTranslation();

  const adapterLocale = useMemo(
    () => mapI18nLocalToDateFns(language),
    [language]
  );

  return (
    <LocalizationProvider
      adapterLocale={adapterLocale}
      dateAdapter={AdapterDateFns}
    >
      <DatePickerBase
        disableFuture
        disableOpenPicker={readOnly}
        readOnly={readOnly}
        slotProps={{
          field: { clearable: true },
          textField: { helperText, error, placeholder, fullWidth },
          clearButton: { disableRipple: true },
          openPickerIcon: { disableRipple: true },
        }}
        slots={{ textField: Input, clearIcon: CloseIcon }}
        {...props}
      />
    </LocalizationProvider>
  );
};
