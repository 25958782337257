import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from 'modules/client/common/forms/additionalDocumentsForm';
import {
  CompanyBaseLicenseFormType,
  getCompanyBaseLicenseSchema,
} from 'modules/client/common/forms/companyLicenseForm';
import {
  CompanyRegistrationAddressFormType,
  companyRegistrationAddressSchema,
} from 'modules/client/common/forms/companyRegistrationAddressForm';
import {
  CompanyResidentialAddressFormType,
  companyResidentialAddressSchema,
} from 'modules/client/common/forms/companyResidentialAddressForm';
import {
  CompanyBaseStateRegistrationFormType,
  getCompanyBaseStateRegistrationSchema,
} from 'modules/client/common/forms/companyStateRegistrationForm';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { CompanyNameFormType, companyNameSchema } from './companyNameForm';
import {
  CompanyTaxResidencyFormType,
  companyTaxResidencySchema,
} from './companyTaxResidencyForm';
import { ContactsFormType, getContactsSchema } from './contactsForm';

export interface LegalClientBranchFormType
  extends ContactsFormType,
    CompanyNameFormType,
    CompanyTaxResidencyFormType,
    CompanyRegistrationAddressFormType,
    CompanyResidentialAddressFormType,
    CompanyBaseStateRegistrationFormType,
    CompanyBaseLicenseFormType,
    AdditionalDocumentsFormType,
    ReasonChangeFormType {}

export const getSchema = (t: TFunc): ObjectSchema<LegalClientBranchFormType> =>
  object()
    .concat(getContactsSchema(t))
    .concat(companyTaxResidencySchema)
    .concat(companyRegistrationAddressSchema)
    .concat(companyResidentialAddressSchema)
    .concat(companyNameSchema)
    .concat(reasonChangeSchema)
    .concat(getCompanyBaseLicenseSchema(t))
    .concat(getCompanyBaseStateRegistrationSchema(t))
    .concat(getAdditionalDocumentsSchema());

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientBranchFormType>();

export type FormErrors = FormErrorsBase<LegalClientBranchFormType>;
