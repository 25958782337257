export enum ServiceContractType {
  UnderwritingServicesContract = 'UnderwritingServicesContract', // Договор на оказание андррайтинговых услуг
  MarketMakerServicesContract = 'MarketMakerServicesContract', // Договор на оказание услуг маркет-мейкера
  InvestmentPortfolioManagementContract = 'InvestmentPortfolioManagementContract', // Договор на управление инвестиционным портфелем
  BrokerageServicesContract = 'BrokerageServicesContract', // Договор об оказании брокерских услуг
  BrokerageServicesContractWithNominalHolding = 'BrokerageServicesContractWithNominalHolding', // Договор об оказании брокерских услуг (с номинальным держанием)
  ConsultancyServicesContract = 'ConsultancyServicesContract', // Договор по оказанию консультационных услуг
  AdditionalAgreementToCustodialContract = 'AdditionalAgreementToCustodialContract', // Доп. соглашение к каст. договору
  SupplementaryAgreement = 'SupplementaryAgreement', // Дополнительное соглашение
  CustodialContract = 'CustodialContract', // Кастодиальный договор
}

export interface ServiceContractEntity {
  type?: ServiceContractType;
  number?: string;
  conclusionDate?: Date;
  terminationDate?: Date;
}
