import { useMemo } from 'react';

import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  LoginFormType,
  Field,
  Form,
  Submit,
  FormErrors,
  getSchema,
} from '../../forms/loginFormForm';

export interface LoginFormProps {
  onSubmit: (values: LoginFormType) => void;
  loading?: boolean;
  formError?: FormErrors;
}

export const LoginForm = ({ loading, formError, onSubmit }: LoginFormProps) => {
  const { t } = useTranslation();
  const scheme = useMemo(() => getSchema(t), [t]);

  return (
    <Form
      initialErrors={{ ...formError }}
      initialValues={{ rememberMe: true }}
      validationSchema={scheme}
      onSubmit={onSubmit}
    >
      <Stack spacing={48}>
        <Stack spacing={24}>
          <Field.Text
            allowSubmit
            helperTextAbsolute
            label={t('auth.login.emailLabel')}
            name="email"
            placeholder={t('auth.login.emailPlaceholder')}
            type="email"
          />
          <Field.Password
            allowSubmit
            helperTextAbsolute
            label={t('auth.login.passwordLabel')}
            name="password"
            placeholder={t('auth.login.passwordPlaceholder')}
          />
        </Stack>
        <Stack spacing={20}>
          <Submit label={t('auth.login.submitButton')} loading={loading} />
        </Stack>
      </Stack>
    </Form>
  );
};
