import { BaseDebtsParam } from 'api/types/params';
import {
  BaseDebtsFormType,
  BaseDebtsOptionalPrice,
} from 'modules/client/common/forms/baseDebtsForm';
import { YesOrNoType } from 'types/yesOrNo';

const getPrice = (v?: BaseDebtsOptionalPrice) =>
  v && v.currency && v.price !== undefined && v.price !== null
    ? { currency: v.currency!, price: v.price! }
    : null;

export const getDebtsParam = (
  { debts }: BaseDebtsFormType,
  isEntrepreneur: boolean
): BaseDebtsParam => ({
  fees: getPrice(debts.fees),
  finesForViolation: getPrice(debts.finesForViolation),
  penalties: getPrice(debts.penalties),
  taxes: getPrice(debts.taxes),

  hasBankruptcyProceedings: isEntrepreneur
    ? debts.bankruptcyProceedings?.value === YesOrNoType.Yes
    : undefined,

  hasDelaysOrNonFulfillmentOfObligations: isEntrepreneur
    ? debts.delaysOrNonFulfillmentOfObligations?.value === YesOrNoType.Yes
    : undefined,
});
