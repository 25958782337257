import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { FilterCheckBox, FilterContent } from 'components';
import { lodash } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Input, Typography } from 'libs/ui';

interface Props<T extends string | number> {
  items: { label: string; value: T }[];
  selectedItems: T[];
  title: string;
  setItems: Dispatch<SetStateAction<T[]>>;
  showSearch?: boolean;
}

export const CheckboxFilter = <T extends string | number>({
  items,
  selectedItems,
  title,
  showSearch,
  setItems,
}: Props<T>) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const itemsFiltered = useMemo(
    () => lodash.searchBy(items, search, (v) => v.label),
    [items, search]
  );

  return (
    <FilterContent title={title}>
      {showSearch && items.length > 4 && (
        <Input
          mode="compact"
          placeholder={t('common.search')}
          value={search}
          onChangeText={setSearch}
        />
      )}
      {itemsFiltered.length ? (
        <FilterCheckBox
          items={itemsFiltered}
          limit={4}
          selectedItems={selectedItems}
          onChange={(value, isSelect) => {
            if (isSelect) {
              setItems((v) => [...v, value]);
            } else {
              setItems((v) => v.filter((i) => i !== value));
            }
          }}
        />
      ) : (
        search && (
          <Typography variant="bodyMedium">{t('common.notFound')}</Typography>
        )
      )}
    </FilterContent>
  );
};
