import { useEffect } from 'react';

import { VerificationCodeField } from 'libs/form/fields/VerificationCodeField';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { DialogProps } from 'libs/ui';

import { ConfirmationDialog } from '../ConfirmationDialog';

interface Props<Values extends object> extends DialogProps {
  codeFieldName: Extract<keyof Values, string>;
  length?: number;
  loading?: boolean;
}

export const VerificationCodeFormContentDialog = <Values extends object>({
  isOpen,
  codeFieldName,
  length = 6,
  loading,
  onClose,
}: Props<Values>) => {
  const { t } = useTranslation();

  const { handleSubmit, setValues, values, errors } = useFormContext<Values>();

  useEffect(() => {
    const codeValue = values[codeFieldName];

    if (codeValue && String(codeValue).length === length) {
      handleSubmit();
    }
  }, [codeFieldName, handleSubmit, length, values]);

  useEffect(() => {
    const codeValue = values[codeFieldName];
    if (
      codeValue &&
      errors &&
      String(codeValue).length === length &&
      errors[codeFieldName]
    ) {
      setValues({ ...values, [codeFieldName]: '' });
    }
  }, [codeFieldName, errors, length, setValues, values]);

  return (
    <ConfirmationDialog
      headerCentered
      hideButtonConfirm
      isOpen={isOpen}
      loading={loading}
      maxWidth="md"
      subtitle={t('components.verificationLogin.subtitle')}
      title={t('components.verificationLogin.title')}
      onBack={onClose}
    >
      <VerificationCodeField length={length} name={codeFieldName} />
    </ConfirmationDialog>
  );
};
