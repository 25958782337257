import { CountryEntity } from 'api/types/entity';
import { TypedForm, ObjectSchema, string, object, array } from 'libs/form';
import { Maybe } from 'types/maybe';

export interface CitizenshipFormType {
  citizenships: Maybe<CountryEntity>[];
}

export const citizenshipsSchema: ObjectSchema<CitizenshipFormType> = object({
  citizenships: array()
    .of(
      object({
        id: string().required(),
        name: string().required(),
      }).required()
    )
    .required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CitizenshipFormType>();
