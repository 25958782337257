import { PropsWithChildren } from 'react';

import { LinkStyled } from './Link.styles';
import { LinkProps } from './types';

/**
 * Link component.
 */
export const Link = ({
  href,
  target,
  disabled,
  children,
  className,
  mode = 'default',
  onClick,
}: LinkProps & PropsWithChildren) => (
  <LinkStyled
    className={className}
    disabled={disabled}
    href={href}
    mode={mode}
    target={target}
    onClick={!disabled ? onClick : undefined}
  >
    {children}
  </LinkStyled>
);
