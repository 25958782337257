// eslint-disable-next-line no-restricted-imports
import { SaveConstitutionalDocumentDto_Input } from 'api/generated/graphql';
import { LegalClientConstitutionalDocumentParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

export const parseConstitutionalDocumentParam = (
  constitutionalDocument: LegalClientConstitutionalDocumentParam | undefined
): SaveConstitutionalDocumentDto_Input | undefined =>
  constitutionalDocument
    ? {
        fileIds: constitutionalDocument.fileIds,
        dateOfLastChange: formatToISODate(
          constitutionalDocument.dateOfLastChange
        ),
      }
    : undefined;
