import {
  RiskLevelType,
  InformationUpdateFrequencyType,
} from 'api/types/entity';
import { yesOrNotOption } from 'helpers/yesOrNo';
import { TFunc } from 'libs/i18n';

import { StatusesFormType } from '../../forms/statusesForm';

interface Client {
  accreditedInvestorStatus?: boolean;
  informationUpdateFrequency?: InformationUpdateFrequencyType;
  riskLevel?: RiskLevelType;
}

export const parseStatusesInitialValue = (client: Client, t: TFunc) => {
  const values: StatusesFormType['statuses'] = {
    accreditedInvestorStatus:
      yesOrNotOption(t, client.accreditedInvestorStatus ?? undefined) ?? null,
  };

  return values;
};
