// eslint-disable-next-line no-restricted-imports
import { ClientsListItemFragment } from 'api/generated/graphql';
import { ClientsListItemData } from 'api/types/data';

import { parseClientOnboardingData } from '../client/parseClientOnboardingData';
import { parseClientAccountStatus } from '../client/parseEntity/parseClientAccountStatus';
import { parseClientOnboardingStatus } from '../client/parseEntity/parseClientOnboardingStatus';

export const parseClientsListItemData = (
  clients: ClientsListItemFragment[]
): ClientsListItemData[] =>
  clients.map((client) => ({
    id: client.id,
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName ?? undefined,
    email: client.email ?? undefined,
    phone: client.phoneNumber ?? undefined,
    onboardingStatus: parseClientOnboardingStatus(client.onboardingStatus),
    accountStatus: parseClientAccountStatus(client.accountStatus),

    countryRegistration: client.registrationAddress?.country.name,
    location: client.residentialAddress?.country.name,

    onboarding: parseClientOnboardingData(client),
  }));
