import { Route, DeepReadonly } from '../types';

import { RoutesId } from './routesId';

/** Configuration for the application routes */
export const ROUTES_CONFIG = {
  id: RoutesId.root,
  path: '',
  children: [
    {
      id: RoutesId.home,
      index: true,
    },
    {
      id: RoutesId.notFound,
      path: '*',
      authorizationIdRequired: false,
    },

    {
      id: RoutesId.login,
      path: 'auth',
      authorizationIdRequired: false,
    },
    {
      id: RoutesId.loginByToken,
      path: 'auth/token',
      authorizationIdRequired: false,
    },
    {
      id: RoutesId.setPassword,
      path: 'auth/set-password',
      authorizationIdRequired: false,
    },
    {
      id: RoutesId.twoFactorConnection,
      path: 'two-factor',
    },

    {
      id: RoutesId.admins,
      path: 'admins',
      children: [
        {
          id: RoutesId.admin,
          path: ':adminId',
        },
      ],
    },
    {
      id: RoutesId.clients,
      path: 'clients',
      children: [
        {
          id: RoutesId.personClients,
          path: 'persons',
          children: [
            {
              id: RoutesId.personClient,
              path: ':clientId/',
              children: [
                {
                  id: RoutesId.personClientVersion,
                  path: 'version/:versionId',
                  children: [
                    {
                      id: RoutesId.representativeClientVersion,
                      path: 'representative/:representativeId',
                    },
                  ],
                },
                {
                  id: RoutesId.representativeClient,
                  path: 'representative/:representativeId',
                },
              ],
            },
          ],
        },
        {
          id: RoutesId.legalClients,
          path: 'companies',
          children: [
            {
              id: RoutesId.legalClient,
              path: ':legalClientId',
              children: [
                {
                  id: RoutesId.legalClientVersion,
                  path: 'version/:versionId',

                  children: [
                    {
                      id: RoutesId.legalClientRepresentativeVersion,
                      path: 'representative/:legalClientRepresentativeId',
                    },
                    {
                      id: RoutesId.legalClientHeadVersion,
                      path: 'executives/persons/:headId',
                    },
                    {
                      id: RoutesId.legalClientLegalHeadVersion,
                      path: 'executives/companies/:headId',
                      children: [
                        {
                          id: RoutesId.legalClientFirstHeadVersion,
                          path: 'executives/:firstHeadId',
                        },
                      ],
                    },
                    {
                      id: RoutesId.legalClientBeneficiaryVersion,
                      path: 'beneficiaries/:beneficiaryId',
                    },
                    {
                      id: RoutesId.legalClientBranchVersion,
                      path: 'branches/:branchId',
                    },
                  ],
                },
                {
                  id: RoutesId.legalRepresentative,
                  path: 'representative/:legalClientRepresentativeId',
                },
                {
                  id: RoutesId.legalClientHead,
                  path: 'executives/persons/:headId',
                },
                {
                  id: RoutesId.legalClientLegalHead,
                  path: 'executives/companies/:headId',
                  children: [
                    {
                      id: RoutesId.legalClientFirstHead,
                      path: 'executives/:firstHeadId',
                    },
                  ],
                },
                {
                  id: RoutesId.legalClientBeneficiary,
                  path: 'beneficiaries/:beneficiaryId',
                },
                {
                  id: RoutesId.legalClientBranch,
                  path: 'branches/:branchId',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
} as const satisfies DeepReadonly<Route>;
