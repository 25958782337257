import { PropsWithChildren } from 'react';

import { Stack } from 'libs/ui';

interface Props extends PropsWithChildren {}

export const Row = ({ children }: Props) => (
  <Stack direction="row" justifyContent="space-between" spacing={32}>
    {children}
  </Stack>
);
