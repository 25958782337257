import { TypedForm, ObjectSchema, string, object } from 'libs/form';

export interface ReasonChangeFormType {
  reasonChange: {
    reason: string;
  };
}

export const reasonChangeSchema: ObjectSchema<ReasonChangeFormType> = object({
  reasonChange: object({
    reason: string().required(),
  }).required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ReasonChangeFormType>();
