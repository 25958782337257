import { FC } from 'react';

import { TableSortProps, Table, TablePaginationProps } from 'libs/ui';

import { ClientsListItem, SortField } from '../../types';

import { useColumns } from './columns';

export interface ClientsTableProps
  extends TableSortProps<SortField>,
    TablePaginationProps {
  clients: ClientsListItem[];
  loading?: boolean;
  noRowsLabel?: string;
  onCellClick?: (row: ClientsListItem) => void;
}

export const ClientsTable: FC<ClientsTableProps> = ({
  clients,
  loading,
  sort,
  limit,
  rowCount,
  page,
  noRowsLabel,
  onCellClick,
  onSortChange,
  onPaginationChange,
}) => {
  const columns = useColumns();
  return (
    <Table
      columns={columns}
      limit={limit}
      loading={loading}
      noRowsLabel={noRowsLabel}
      page={page}
      rowCount={rowCount}
      rows={clients}
      sort={sort}
      onCellClick={onCellClick}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
    />
  );
};
