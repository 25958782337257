import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCreateLegalClientBeneficiaryMutation } from 'api/generated/graphql';
import {
  parseCreateLegalClientBeneficiaryParams,
  parseLegalClientBeneficiaryEntity,
  useError,
} from 'api/helpers';
import { CreateLegalClientBeneficiaryParams } from 'api/types/params';

export const useCreateLegalClientBeneficiary = () => {
  const [handle, { data, loading, error: apolloError }] =
    useCreateLegalClientBeneficiaryMutation();

  const error = useError(apolloError);

  return {
    legalClientBeneficiary: data?.createLegalClientBeneficiary
      ? parseLegalClientBeneficiaryEntity(data.createLegalClientBeneficiary)
      : undefined,
    loading,
    error,
    createLegalClientBeneficiary: useCallback(
      async (params: CreateLegalClientBeneficiaryParams) => {
        const input = parseCreateLegalClientBeneficiaryParams(params);
        const res = await handle({ variables: { input } });
        return res.data?.createLegalClientBeneficiary
          ? parseLegalClientBeneficiaryEntity(
              res.data?.createLegalClientBeneficiary
            )
          : undefined;
      },
      [handle]
    ),
  };
};
