import { ServiceContractType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Field } from 'modules/client/common/forms/serviceContractForm';

export const ServiceContractSection = () => {
  const { t } = useTranslation();

  return (
    <Section title={t('client.serviceContractSection.title')}>
      <Field.Autocomplete
        fullWidth
        label={t('client.serviceContractSection.type.label')}
        name="serviceContract.type"
        optionLabelKey="label"
        options={Object.values(ServiceContractType).map((v) => ({
          label: t(`client.serviceContractSection.type.types.${v}`),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('client.serviceContractSection.type.placeholder')}
        variant="select"
      />

      <Field.Text
        label={t('client.serviceContractSection.number.label')}
        name="serviceContract.number"
        placeholder={t('client.serviceContractSection.number.placeholder')}
      />

      <Field.DatePicker
        disableFuture={false}
        label={t('client.serviceContractSection.conclusionDate.label')}
        name="serviceContract.conclusionDate"
        placeholder={t(
          'client.serviceContractSection.conclusionDate.placeholder'
        )}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('client.serviceContractSection.terminationDate.label')}
        name="serviceContract.terminationDate"
        placeholder={t(
          'client.serviceContractSection.terminationDate.placeholder'
        )}
      />
    </Section>
  );
};
