import { format } from 'date-fns';
import { mapI18nLocalToDateFns } from 'helpers';
import { Langs } from 'libs/i18n';

export interface GroupedData<T> {
  [date: string]: T[];
}

interface Item {
  createdAt: Date;
}

export const groupByDays = <T extends Item>(
  data: T[],
  language: Langs,
  dateFormat?: string
): GroupedData<T> =>
  data.reduce((acc: GroupedData<T>, item: T) => {
    const date = format(item.createdAt, dateFormat || 'dd MMMM yyyy', {
      locale: mapI18nLocalToDateFns(language),
    });
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});
