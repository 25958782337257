// eslint-disable-next-line no-restricted-imports
import { LegalClientBranchForLegalClientFragment } from 'api/generated/graphql';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';

export const parseLegalClientBranchItemEntity = (
  data: LegalClientBranchForLegalClientFragment
): LegalClientInnerItemEntity => ({
  id: data.id,
  name: data.fullName ?? '',
  registrationCity: data.legalAddress?.country.name,
  location: data.actualAddress?.country.name,
  createdAt: new Date(data.createdAt),
});
