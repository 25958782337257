// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientController_UpdateById_Input_IdentityDocument_Type as IdentityDocumentTypeApi,
  SaveIdentityDocumentDto_Input,
} from 'api/generated/graphql';
import { IdentityDocumentType } from 'api/types/entity';
import { IdentityDocumentParam } from 'api/types/params/identityDocumentParam';
import { formatToISODate } from 'helpers';

const docType: Record<IdentityDocumentType, IdentityDocumentTypeApi> = {
  [IdentityDocumentType.IdCard]: IdentityDocumentTypeApi.IdCard,
  [IdentityDocumentType.Passport]: IdentityDocumentTypeApi.Passport,
};

export const parseIdentityDocumentParam = (
  data: IdentityDocumentParam
): SaveIdentityDocumentDto_Input => ({
  expirationDate: data.expirationDate
    ? formatToISODate(data.expirationDate)
    : null,
  issueDate: data.dateOfIssue ? formatToISODate(data.dateOfIssue) : null,
  issuingAuthority: data.authority ?? null,
  number: data.number ?? null,
  issuingCountry: data.сountryOfIssue?.id ?? null,
  type: data.documentType ? docType[data.documentType] : null,
});
