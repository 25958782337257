import { FC } from 'react';

import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  LegalClientLegalHeadFormType,
  getSchema,
} from '../../forms/legalClientLegalHeadForm';

import { FormContent, FormContentProps } from './FormContent';

export interface LegalClientLegalHeadFormProps extends FormContentProps {
  initialValues?: LegalClientLegalHeadFormType;
  onSubmit: FormSubmit<LegalClientLegalHeadFormType>;
}

export const LegalClientLegalHeadForm: FC<LegalClientLegalHeadFormProps> = ({
  initialValues,
  onSubmit,
  ...formProps
}) => {
  const { t } = useTranslation();

  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </Form>
  );
};
