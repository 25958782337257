import { CountryEntity, CurrencyType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/client/forms/withdrawalAccountsForm';

interface Props {
  index: number;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({ index, countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  const name = `withdrawalAccounts.${index}`;

  return (
    <>
      <Field.Autocomplete
        label={t('client.client.withdrawalAccounts.bankCountry.label')}
        loading={countriesLoading}
        name={`${name}.bankCountry` as 'withdrawalAccounts'}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t(
          'client.client.withdrawalAccounts.bankCountry.placeholder'
        )}
      />

      <Field.Autocomplete
        label={t('client.client.withdrawalAccounts.currency.label')}
        loading={countriesLoading}
        name={`${name}.currency` as 'withdrawalAccounts'}
        optionLabelKey="label"
        options={Object.values(CurrencyType).map((v) => ({
          value: v,
          label: v,
        }))}
        optionValueKey="value"
        placeholder={t('client.client.withdrawalAccounts.currency.placeholder')}
        variant="select"
      />

      <Field.Text
        label={t('client.client.withdrawalAccounts.swiftCodeLabel.label')}
        name={`${name}.swiftCode` as 'withdrawalAccounts'}
        placeholder={t(
          'client.client.withdrawalAccounts.swiftCodeLabel.placeholder'
        )}
      />

      <Field.Text
        label={t('client.client.withdrawalAccounts.bankName.label')}
        name={`${name}.bankName` as 'withdrawalAccounts'}
        placeholder={t('client.client.withdrawalAccounts.bankName.placeholder')}
      />

      <Field.Text
        label={t('client.client.withdrawalAccounts.bankAddress.label')}
        name={`${name}.bankAddress` as 'withdrawalAccounts'}
        placeholder={t(
          'client.client.withdrawalAccounts.bankAddress.placeholder'
        )}
      />

      <Field.Text
        label={t('client.client.withdrawalAccounts.checkingAccount.label')}
        name={`${name}.checkingAccount` as 'withdrawalAccounts'}
        placeholder={t(
          'client.client.withdrawalAccounts.checkingAccount.placeholder'
        )}
      />

      <Field.Text
        label={t('client.client.withdrawalAccounts.correspondentAccount.label')}
        name={`${name}.correspondentAccount` as 'withdrawalAccounts'}
        placeholder={t(
          'client.client.withdrawalAccounts.correspondentAccount.label'
        )}
      />
    </>
  );
};
