import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSaveClientOnboardingStatusMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseClientEntity } from 'api/helpers/client/parseClientEntity';
import { SaveOnboardingStatusParam } from 'api/types/params';

import { parseSaveClientOnboardingStatusParam } from '../../helpers/client/parseParam';

export const useSaveClientOnboardingStatus = () => {
  const [handle, { data, loading, error: apolloError }] =
    useSaveClientOnboardingStatusMutation();

  const error = useError(apolloError);

  const client = data?.saveOnboardingStatus
    ? parseClientEntity(data.saveOnboardingStatus)
    : undefined;

  const saveClientOnboardingStatus = useCallback(
    async (id: string, params: SaveOnboardingStatusParam) => {
      const input = parseSaveClientOnboardingStatusParam(params);
      const res = await handle({ variables: { id, input } });
      return res.data?.saveOnboardingStatus
        ? parseClientEntity(res.data?.saveOnboardingStatus)
        : undefined;
    },
    [handle]
  );

  return {
    client,
    loading,
    error,
    saveClientOnboardingStatus,
  };
};
