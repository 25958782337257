import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContentStyled = styled(Stack)(() => ({
  gap: 12,

  '.notificationChangeOnboardingStatus-clientName': {
    textDecoration: 'underline',
    '&:hover': {
      color: Colors.blue150,
    },
  } as CSSObject,

  '.notificationChangeOnboardingStatus-prevStatus': {
    textDecoration: 'line-through',
    color: Colors.gray250,
  } as CSSObject,
}));
