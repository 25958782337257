import { useTranslation } from 'libs/i18n';
import { Section, Row } from 'modules/client/common/components';
import { Field } from 'modules/client/common/forms/contactsForm';

import { phoneValidation } from '../../../helpers/validations';

interface Props {
  hidePhoneField?: boolean;
  title?: string;
  readOnly?: boolean;
}

export const ContactsSection = ({
  title,
  hidePhoneField,
  readOnly = true,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Section title={title ?? t('client.client.contacts.title')}>
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.contacts.email.label')}
          name="contacts.email"
          placeholder={t('client.client.contacts.email.placeholder')}
          readOnly={readOnly}
          type="email"
        />
        {!hidePhoneField && (
          <Field.Text
            fullWidth
            checkValue={phoneValidation}
            label={t('client.client.contacts.phone.label')}
            name="contacts.phone"
            placeholder={t('client.client.contacts.phone.placeholder')}
            readOnly={readOnly}
          />
        )}
      </Row>
    </Section>
  );
};
