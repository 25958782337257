import { object } from 'libs/form';
import {
  CompanyNameFormType as CompanyNameFormTypeBase,
  companyNameObject,
} from 'modules/client/common/forms/companyNameForm';

export {
  Field,
  Form,
  Submit,
  useFormContext,
} from 'modules/client/common/forms/companyNameForm';

export interface CompanyNameFormType {
  companyName?: CompanyNameFormTypeBase['companyName'];
}

export const companyNameSchema = object({
  companyName: companyNameObject.optional().default(undefined),
});
