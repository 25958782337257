import { Pressed, Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContentStyled = styled(Stack)(() => ({
  '.clients-sidebar': {
    minWidth: 384,
    maxWidth: 384,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  } as CSSObject,

  '.clients-content': {
    '&:not(:first-child)': {
      borderLeft: `1px solid ${Colors.gray100}`,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    overflow: 'hidden',
  } as CSSObject,
}));

export const CloseButtonStyled = styled(Pressed)(() => ({
  textWrap: 'nowrap',
  whiteSpace: 'nowrap',
}));

export const ChangesContentStyled = styled(Stack)(() => ({
  border: `1px solid ${Colors.gray150}`,
  borderRadius: 24,
  padding: 24,
  marginBottom: 72,
}));

export const LoadingContentStyled = styled(Stack)(() => ({
  height: '100%',
}));
