// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetList_AllOf_1_List_Items_OrganizationalAndLegalForm_OrganizationType as OrganizationTypeApi,
  OrganizationalAndLegalFormFragment,
} from 'api/generated/graphql';
import {
  OrganizationalAndLegalFormEntity,
  OrganizationType,
} from 'api/types/entity';

const organizationTypeMap: Record<OrganizationTypeApi, OrganizationType> = {
  [OrganizationTypeApi.Commercial]: OrganizationType.Commercial,
  [OrganizationTypeApi.NonCommercial]: OrganizationType.NonCommercial,
};

export const parseOrganizationalAndLegalFormEntity = (
  data: OrganizationalAndLegalFormFragment | undefined
): OrganizationalAndLegalFormEntity | undefined =>
  data
    ? {
        isFinancialInstitution: data.isFinancialInstitution ?? undefined,
        isUnincorporatedStructure: data.isUnincorporatedStructure ?? undefined,
        organizationalAndLegalForm: data.organizationalAndLegalForm,
        organizationType: organizationTypeMap[data.organizationType],
      }
    : undefined;
