import { ManagerData } from 'api/types/data';
import { formatToDateTime } from 'helpers';
import { useConvertDateToUserTimezone } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Input } from 'libs/ui';
import { Row } from 'modules/client/common/components/Row';

interface Props {
  createdBy: ManagerData | null;
  createdAt: Date;
  result?: string | null;
}

export const StatusFields = ({ createdAt, createdBy, result }: Props) => {
  const { t } = useTranslation();

  const { convertDateToUserTimezone } = useConvertDateToUserTimezone();

  return (
    <>
      {result && (
        <Input
          readOnly
          label={t('client.client.resultCheckSection.resultLabel')}
          value={result}
        />
      )}

      <Row>
        {createdBy && (
          <Input
            fullWidth
            readOnly
            label={t('client.client.resultCheckSection.createdByLabel')}
            value={createdBy.name}
          />
        )}

        <Input
          fullWidth
          readOnly
          label={t('client.client.resultCheckSection.createdAtLabel')}
          value={formatToDateTime(convertDateToUserTimezone(createdAt))}
        />
      </Row>
    </>
  );
};
