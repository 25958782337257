import { useToggleNotificationRead } from 'api/requests';
import { NotificationEntity } from 'api/types/entity';
import { ROUTES, useNavigate } from 'libs/navigation';

export const useNotificationItem = (
  notification: NotificationEntity,
  onClose: () => void
) => {
  const { toggleNotificationRead, loading: loadingToggleRead } =
    useToggleNotificationRead();

  const { navigate } = useNavigate();

  const onToggleNotificationRead = () => {
    toggleNotificationRead(notification.id);
  };

  const onClickClientName = () => {
    if (!notification.isRead) {
      onToggleNotificationRead();
    }
    if (notification.payload.clientType === 'Client') {
      navigate(ROUTES.clients.personClients.personClient.fullPath, {
        clientId: notification.payload.clientId,
      });
    } else if (notification.payload.clientType === 'LegalClient') {
      navigate(ROUTES.clients.legalClients.legalClient.fullPath, {
        legalClientId: notification.payload.clientId,
      });
    }
    onClose();
  };

  return {
    loadingToggleRead,
    onToggleNotificationRead,
    onClickClientName,
  };
};
