import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContentStyled = styled('div')(() => ({
  outline: 'none',
}));

export const LineStyled = styled('div')(() => ({
  backgroundColor: Colors.blue100,
  height: 1,
}));
