import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import {
  useFormContext,
  Field,
  disclosureInformationResourceMaxLength,
  phoneMaxLength,
  siteMaxLength,
} from 'modules/client/legal/forms/companyContactsForm';
import { YesOrNoType } from 'types/yesOrNo';

import { phoneValidation } from '../../../../../common/helpers/validations';

export const FormContent = () => {
  const { values } = useFormContext();

  const { t } = useTranslation();

  return (
    <>
      <Field.Text
        checkValue={phoneValidation}
        label={t('client.legal.companyContacts.phone.label')}
        maxLength={phoneMaxLength}
        name="companyContacts.phone"
        placeholder={t('client.legal.companyContacts.phone.placeholder')}
      />

      <Field.Text
        label={t('client.legal.companyContacts.email.label')}
        name="companyContacts.email"
        placeholder={t('client.legal.companyContacts.email.placeholder')}
        type="email"
      />

      <Field.Text
        label={t('client.legal.companyContacts.site.label')}
        maxLength={siteMaxLength}
        name="companyContacts.site"
        placeholder={t('client.legal.companyContacts.site.placeholder')}
      />

      <Field.Autocomplete
        label={t('client.legal.companyContacts.disclosureInformation.label')}
        name="companyContacts.disclosureInformation"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.selectPlaceholder')}
        variant="select"
      />

      {values.companyContacts.disclosureInformation &&
        values.companyContacts.disclosureInformation.value ===
          YesOrNoType.Yes && (
          <Field.Text
            label={t(
              'client.legal.companyContacts.disclosureInformationResource.label'
            )}
            maxLength={disclosureInformationResourceMaxLength}
            name="companyContacts.disclosureInformationResource"
            placeholder={t(
              'client.legal.companyContacts.disclosureInformationResource.placeholder'
            )}
          />
        )}
    </>
  );
};
