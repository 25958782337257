import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { FormContent } from './FormContent';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const PlaceOfBirthSection = ({ countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  return (
    <Section title={t('client.client.placeOfBirth.title')}>
      <FormContent countries={countries} countriesLoading={countriesLoading} />
    </Section>
  );
};
