// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_LegalClientController_UpdateById_Input_FinancingSources_IncomeSources_Items as CompanyIncomeSourcesApi,
  MutationInput_LegalClientController_UpdateById_Input_FinancingSources_FinancingSources_Items as CompanyFinancingSourcesApi,
  SaveLegalFinancingSourcesDto_Input,
} from 'api/generated/graphql';
import { LegalClientFinancingSourcesParam } from 'api/types/params';
import { lodash } from 'helpers';

export const parseLegalClientFinancingSourcesParam = (
  data: LegalClientFinancingSourcesParam
): SaveLegalFinancingSourcesDto_Input => ({
  incomeSources: lodash
    .compact(data.incomeSources)
    .map((v) => CompanyIncomeSourcesApi[v]),
  otherIncomeSource: data.otherIncomeSource || undefined,
  financingSources: lodash
    .compact(data.financingSources)
    .map((v) => CompanyFinancingSourcesApi[v]),
  otherFinancingSource: data.otherFinancingSource || undefined,
  lastYearAuditFinancialReportFileIds: data.lastYearAuditFinancialReportFileIds,
  recommendationLetterFileIds: data.recommendationLetterFiles || [],
});
