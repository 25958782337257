import { Box } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const FilterContainer = styled(Box)({
  height: '100%',
  padding: '5px 12px 5px 0',
  borderRight: `1px solid ${Colors.gray150}`,
  marginRight: 12,
  alignItems: 'center',
  display: 'inline-flex',
});
