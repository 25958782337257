// eslint-disable-next-line no-restricted-imports
import { LegalClientsListItemFragment } from 'api/generated/graphql';
import { LegalClientsListItemData } from 'api/types/data';

import { parseLegalClientAccountStatus } from '../legal/parseEntity/parseLegalClientAccountStatus';
import { parseLegalClientOnboardingStatus } from '../legal/parseEntity/parseLegalClientOnboardingStatus';
import { parseOboardingData } from '../parseOboardingData';

export const parseLegalClientsListItemData = (
  clients: LegalClientsListItemFragment[]
): LegalClientsListItemData[] =>
  clients.map((client) => ({
    id: client.id,
    fullName: client.fullName ?? '',
    shortName: client.shortName ?? undefined,

    email: client.representative?.email ?? undefined,
    phone: client.representative?.phoneNumber ?? undefined,

    onboardingStatus: parseLegalClientOnboardingStatus(client.onboardingStatus),
    accountStatus: parseLegalClientAccountStatus(client.accountStatus),

    countryRegistration: client.legalAddress?.country.name,
    location: client.actualAddress?.country.name,

    onboarding: parseOboardingData(client.representative?.onboarding),
  }));
