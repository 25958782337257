import {
  BiographyEntity,
  MaritalStatusType,
  OccupationType,
} from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { BiographyFormType } from '../../forms/biographyForm';

export const MaritalStatusTranslationPath: Record<
  MaritalStatusType,
  TranslationPath
> = {
  [MaritalStatusType.married]: 'client.client.biography.married',
  [MaritalStatusType.single]: 'client.client.biography.single',
};

export const OccupationTranslationPath: Record<
  OccupationType,
  TranslationPath
> = {
  [OccupationType.businessOwner]: 'client.client.biography.businessOwner',
  [OccupationType.employee]: 'client.client.biography.employee',
  [OccupationType.entrepreneur]: 'client.client.biography.entrepreneur',
  [OccupationType.unemployed]: 'client.client.biography.notWorking',
  [OccupationType.retired]: 'client.client.biography.retired',
  [OccupationType.student]: 'client.client.biography.student',
};

export const parseBiographyInitialValue = (
  t: TFunc,
  biography: BiographyEntity | undefined
) => {
  const values: BiographyFormType['biography'] = {
    ...biography,
    maritalStatus: biography?.maritalStatus
      ? {
          value: biography.maritalStatus,
          label: t(MaritalStatusTranslationPath[biography.maritalStatus]),
        }
      : null,

    occupation: biography?.occupation
      ? {
          value: biography.occupation,
          label: t(OccupationTranslationPath[biography.occupation]),
        }
      : null,
  };

  return values;
};
