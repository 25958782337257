import { Stack, Typography } from 'libs/ui';

interface Props {
  subtitle: string;
}

export const Header = ({ subtitle }: Props) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="subtitle">{subtitle}</Typography>
  </Stack>
);
