export enum RiskLevelType {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum RiskLevelFilterType {
  Unspecified = 'Unspecified',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}
