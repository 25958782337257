import { useUpdateLegalClient as useUpdateLegalClientApi } from 'api/requests/legal';
import { LegalClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { LegalClientFormType } from '../forms/legalClientForm';
import { getLegalClientSubmitData } from '../helpers/getLegalClientSubmitData';

export const useUpdateLegalClient = () => {
  const { updateLegalClient, loading, error } = useUpdateLegalClientApi();

  const notify = useNotify();
  const { t } = useTranslation();

  const handleUpdateClient = async (
    legalClient: LegalClientEntity,
    values: LegalClientFormType
  ) => {
    if (legalClient.versionNumber !== undefined) {
      const data = getLegalClientSubmitData(legalClient.versionNumber, values);
      const updatedLegalClient = await updateLegalClient(legalClient.id, data);

      if (updatedLegalClient) {
        notify.info(t('common.successSave'));
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClient: handleUpdateClient,
  };
};
