import { useCallback } from 'react';

import { useFileDownload } from 'api/requests';

export const useGetFileLinkDownload = () => {
  const { getFileLink, loading } = useFileDownload();

  const handleGetFileLink = useCallback(
    async (fileId: string) => {
      const link = await getFileLink(fileId);

      return link;
    },
    [getFileLink]
  );

  return {
    getFileLink: handleGetFileLink,
    loading,
  };
};
