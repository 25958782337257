// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetList_AllOf_1_List_Items_Representative_IdentityDocument_Type as IdentityDocumentTypeApi,
  IdentityDocumentFragment,
} from 'api/generated/graphql';
import { IdentityDocumentEntity, IdentityDocumentType } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileEntity } from '../client/parseEntity/parseFileEntity';
import { parseCountryEntity } from '../parseCountryEntity';

const docType: Record<IdentityDocumentTypeApi, IdentityDocumentType> = {
  [IdentityDocumentTypeApi.IdCard]: IdentityDocumentType.IdCard,
  [IdentityDocumentTypeApi.Passport]: IdentityDocumentType.Passport,
};
export const parseIdentityDocumentEntity = (
  data: IdentityDocumentFragment
): IdentityDocumentEntity => ({
  documentType: data.type ? docType[data.type] : undefined,
  authority: data.issuingAuthority ?? undefined,
  dateOfIssue: data.issueDate ? new Date(data.issueDate) : undefined,
  expirationDate: data.expirationDate
    ? new Date(data.expirationDate)
    : undefined,
  number: data.number ?? undefined,
  сountryOfIssue: data.issuingCountry
    ? parseCountryEntity(data.issuingCountry)
    : undefined,
  files: data.privateFiles
    ? lodash.compact(data.privateFiles).map(parseFileEntity)
    : undefined,
});
