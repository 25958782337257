import { ChangeEvent } from 'react';

export const parseNumber = (v?: string): number => {
  if (v === undefined || v === '') return 0;
  return Number(v.replace(',', '.'));
};

export const formatNumber = (v?: number): string => {
  if (v === undefined) return '';
  return `${v}`.replace('.', ',').substring(0, 13);
};

export const isFloat = (value: string | undefined = '') => {
  value = value.replace(/ /g, '');
  const num = Number(value.replace(',', '.'));
  if (Number.isNaN(num)) return false;
  if (value.includes(',') && value.split(',')[1].length > 2) return false;

  return true;
};

/**
 * Adjusts the cursor position inside an input field based on the value changes.
 * @param event - The event object triggered by the input change.
 * @param formattedValue - The updated formatted value in the input field.
 * @param originalValue - The original value before formatting in the input field.
 */
export const adjustInputCursor = (
  event: ChangeEvent<HTMLInputElement>,
  formattedValue: string,
  originalValue: string
) => {
  const caret =
    formattedValue.length -
    (originalValue.length ?? 0) +
    (event.target.selectionStart ?? 0);

  window.requestAnimationFrame(() => {
    event.target.selectionStart = caret;
    event.target.selectionEnd = caret;
  });
};
