import { Hint } from 'components';
import { formatToTime } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import { Colors } from 'libs/ui/theme';
import { VersionType } from 'modules/client/common/types';

import {
  ContentWrapper,
  HintWrapperStyled,
  WrapperStyled,
} from './Version.styles';

interface Props<V extends VersionType> {
  version: V;
  isCurrentVersion: boolean;
  onClickVersion: (version: V) => void;
  isSelectedVersion: (version: V) => boolean;
}

export const Version = <V extends VersionType>({
  version,
  isCurrentVersion,
  isSelectedVersion,
  onClickVersion,
}: Props<V>) => {
  const { t } = useTranslation();
  const time = formatToTime(version.createdAt);
  const selected = isSelectedVersion(version);

  return (
    <WrapperStyled direction="row" justifyContent="space-between">
      <ContentWrapper
        clickable={!selected}
        flex={1}
        spacing={4}
        onClick={() => onClickVersion(version)}
      >
        <Typography
          color={selected ? Colors.blue150 : Colors.black}
          variant="captionBold"
        >
          {time}{' '}
          {isCurrentVersion && (
            <>({t('client.client.versions.currentVersionLabel')})</>
          )}
        </Typography>
        <Typography variant="captionMedium">
          {t(`client.client.versions.clientType.${version.updatedEntity.type}`)}
          {version.createdBy?.name && ` / ${version.createdBy?.name}`}
        </Typography>
      </ContentWrapper>
      <HintWrapperStyled>
        <Hint
          title={
            <Stack>
              <Typography variant="bodyBold">
                {t('client.client.versions.changes.reason.label')}
              </Typography>
              <Typography>{version.reason}</Typography>
            </Stack>
          }
        />
      </HintWrapperStyled>
    </WrapperStyled>
  );
};
