// eslint-disable-next-line no-restricted-imports
import { ClientRepresentativeSnapshotFragment } from 'api/generated/graphql';
import { RepresentativeClientSnapshotEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { parseBaseRepresentativeClientEntity } from '../parseBaseRepresentativeClientEntity';

export const parseRepresentativeClientSnapshotEntity = (
  data: Maybe<ClientRepresentativeSnapshotFragment>
): RepresentativeClientSnapshotEntity | undefined =>
  data
    ? {
        createdAt: new Date(data.createdAt),
        id: data.id,
        value: parseBaseRepresentativeClientEntity(data.value),
      }
    : undefined;
