import { useCallback } from 'react';

export interface LockAndUnlockReadOnlyProps {
  lockClientIfPossible?: () => Promise<boolean>;
  unlockClient?: () => void;
}

export const useLockAndUnlockReadOnly = (
  setReadOnly: (v: boolean) => void,
  { lockClientIfPossible, unlockClient }: LockAndUnlockReadOnlyProps
) => {
  const onDisableReadOnly = useCallback(async () => {
    const isPossibleEdit = await lockClientIfPossible?.();
    if (isPossibleEdit === true) {
      setReadOnly(false);
    }
  }, [lockClientIfPossible, setReadOnly]);

  const onEnableReadOnly = useCallback(() => {
    unlockClient?.();
    setReadOnly(true);
  }, [setReadOnly, unlockClient]);

  return { onDisableReadOnly, onEnableReadOnly };
};
