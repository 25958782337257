import { useTranslation } from 'libs/i18n';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import { useFormContext } from 'modules/client/common/forms/additionalDocumentsForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { readOnly } = useFormContext();
  const { t } = useTranslation();
  return (
    <DocumentsTableFeature
      name="additionalDocumentsFiles"
      readOnly={readOnly}
      title={t('client.client.additionalDocuments.title')}
    />
  );
};
