import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Mail = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99984 4.16732L9.4766 9.2048C10.0785 9.67291 10.9212 9.67291 11.5231 9.2048L17.9998 4.16732M4.83317 16.6673H16.1665C17.0999 16.6673 17.5666 16.6673 17.9232 16.4857C18.2368 16.3259 18.4917 16.0709 18.6515 15.7573C18.8332 15.4008 18.8332 14.9341 18.8332 14.0007V6.00065C18.8332 5.06723 18.8332 4.60052 18.6515 4.244C18.4917 3.9304 18.2368 3.67543 17.9232 3.51564C17.5666 3.33398 17.0999 3.33398 16.1665 3.33398H4.83317C3.89975 3.33398 3.43304 3.33398 3.07652 3.51564C2.76292 3.67543 2.50795 3.9304 2.34816 4.244C2.1665 4.60052 2.1665 5.06723 2.1665 6.00065V14.0007C2.1665 14.9341 2.1665 15.4008 2.34816 15.7573C2.50795 16.0709 2.76292 16.3259 3.07652 16.4857C3.43304 16.6673 3.89975 16.6673 4.83317 16.6673Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);
