import { AmlPolicyReviewFrequency } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { getTodayWithResetHours } from 'modules/client/common/helpers';
import {
  Field,
  useFormContext,
  maxLengthTextField,
  getAmlPolicyReviewFrequencyOptions,
  getMonetaryServiceTransactionCountForLastSixMonthsOptions,
  maxLengthName,
  maxLengthOfficerPosition,
  maxLengthAuthorityName,
} from 'modules/client/legal/forms/companyFinancialMonitoringForm';
import { YesOrNoType } from 'types/yesOrNo';

export interface FormContentProps {
  hasBranches: boolean;
}

export const FormContent = ({ hasBranches }: FormContentProps) => {
  const { t } = useTranslation();
  const { values } = useFormContext();

  return (
    <>
      <Field.Autocomplete
        label={t(
          'client.legal.companyFinancialMonitoring.isSubjectToRegulation.label'
        )}
        name="financialMonitoring.isSubjectToRegulation"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.selectPlaceholder')}
        variant="select"
      />
      {values.financialMonitoring.isSubjectToRegulation?.value ===
        YesOrNoType.Yes && (
        <>
          <Field.Text
            label={t(
              'client.legal.companyFinancialMonitoring.authorityName.label'
            )}
            maxLength={maxLengthAuthorityName}
            name="financialMonitoring.authorityName"
            placeholder={t(
              'client.legal.companyFinancialMonitoring.authorityName.placeholder'
            )}
          />
          <Field.Text
            label={t(
              'client.legal.companyFinancialMonitoring.authorityWebsite.label'
            )}
            maxLength={maxLengthTextField}
            name="financialMonitoring.authorityWebsite"
            placeholder={t(
              'client.legal.companyFinancialMonitoring.authorityWebsite.placeholder'
            )}
          />

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.hasAmlCftComplianceControl.label'
            )}
            name="financialMonitoring.hasAmlCftComplianceControl"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.hasTransactionMonitoringAndSuspiciousActivityDetection.label'
            )}
            name="financialMonitoring.hasTransactionMonitoringAndSuspiciousActivityDetection"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.amlPolicyReviewFrequency.label'
            )}
            name="financialMonitoring.amlPolicyReviewFrequency"
            optionLabelKey="label"
            options={getAmlPolicyReviewFrequencyOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          {values.financialMonitoring.amlPolicyReviewFrequency?.value ===
            AmlPolicyReviewFrequency.Other && (
            <Field.Text
              label={t(
                'client.legal.companyFinancialMonitoring.otherAmlPolicyReviewFrequency.label'
              )}
              maxLength={maxLengthTextField}
              name="financialMonitoring.otherAmlPolicyReviewFrequency"
              placeholder={t(
                'client.legal.companyFinancialMonitoring.otherAmlPolicyReviewFrequency.placeholder'
              )}
            />
          )}

          {hasBranches && (
            <Field.Autocomplete
              label={t(
                'client.legal.companyFinancialMonitoring.amlCftPoliciesApplyToForeignEntities.label'
              )}
              name="financialMonitoring.amlCftPoliciesApplyToForeignEntities"
              optionLabelKey="label"
              options={getYesOrNoOptions(t)}
              optionValueKey="value"
              placeholder={t('common.selectPlaceholder')}
              variant="select"
            />
          )}

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.hasAmlCftDocumentEffectivenessAssessmentProcedures.label'
            )}
            name="financialMonitoring.hasAmlCftDocumentEffectivenessAssessmentProcedures"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          {values.financialMonitoring
            .hasAmlCftDocumentEffectivenessAssessmentProcedures?.value ===
            YesOrNoType.Yes && (
            <Field.DatePicker
              label={t(
                'client.legal.companyFinancialMonitoring.dateOfLastCheck.label'
              )}
              maxDate={getTodayWithResetHours()}
              name="financialMonitoring.dateOfLastCheck"
              placeholder={t(
                'client.legal.companyFinancialMonitoring.dateOfLastCheck.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.hasAmlCftInternalControlOfficer.label'
            )}
            name="financialMonitoring.hasAmlCftInternalControlOfficer"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          {values.financialMonitoring.hasAmlCftInternalControlOfficer?.value ===
            YesOrNoType.Yes && (
            <>
              <Field.Text
                label={t(
                  'client.legal.companyFinancialMonitoring.officerFirstName.label'
                )}
                maxLength={maxLengthName}
                name="financialMonitoring.officerFirstName"
                placeholder={t(
                  'client.legal.companyFinancialMonitoring.officerFirstName.placeholder'
                )}
                trim="start"
              />
              <Field.Text
                label={t(
                  'client.legal.companyFinancialMonitoring.officerLastName.label'
                )}
                maxLength={maxLengthName}
                name="financialMonitoring.officerLastName"
                placeholder={t(
                  'client.legal.companyFinancialMonitoring.officerLastName.placeholder'
                )}
                trim="start"
              />
              <Field.Text
                label={t(
                  'client.legal.companyFinancialMonitoring.officerMiddleName.label'
                )}
                maxLength={maxLengthName}
                name="financialMonitoring.officerMiddleName"
                placeholder={t(
                  'client.legal.companyFinancialMonitoring.officerMiddleName.placeholder'
                )}
                trim="start"
              />
              <Field.Text
                label={t(
                  'client.legal.companyFinancialMonitoring.officerPosition.label'
                )}
                maxLength={maxLengthOfficerPosition}
                name="financialMonitoring.officerPosition"
                placeholder={t(
                  'client.legal.companyFinancialMonitoring.officerPosition.placeholder'
                )}
                trim="start"
              />
              <Field.Phone
                label={t(
                  'client.legal.companyFinancialMonitoring.officerPhoneNumber.label'
                )}
                maxLength={22}
                name="financialMonitoring.officerPhoneNumber"
                placeholder={t(
                  'client.legal.companyFinancialMonitoring.officerPhoneNumber.placeholder'
                )}
                type="phone"
              />
              <Field.Text
                autoComplete="on"
                label={t(
                  'client.legal.companyFinancialMonitoring.officerEmail.label'
                )}
                name="financialMonitoring.officerEmail"
                placeholder={t(
                  'client.legal.companyFinancialMonitoring.officerEmail.placeholder'
                )}
                type="email"
              />
            </>
          )}
          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.canProvideAmlPolicyDocuments.label'
            )}
            name="financialMonitoring.canProvideAmlPolicyDocuments"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          {values.financialMonitoring.canProvideAmlPolicyDocuments?.value ===
            YesOrNoType.No && (
            <Field.Text
              label={t(
                'client.legal.companyFinancialMonitoring.documentProvisionRefusalReason.label'
              )}
              maxLength={maxLengthTextField}
              name="financialMonitoring.documentProvisionRefusalReason"
              placeholder={t(
                'client.legal.companyFinancialMonitoring.documentProvisionRefusalReason.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.isInternalControlInfoConfidential.label'
            )}
            name="financialMonitoring.isInternalControlInfoConfidential"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.hadAmlCftInvestigationsOrPenalties.label'
            )}
            name="financialMonitoring.hadAmlCftInvestigationsOrPenalties"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          {values.financialMonitoring.hadAmlCftInvestigationsOrPenalties
            ?.value === YesOrNoType.Yes && (
            <Field.Text
              label={t(
                'client.legal.companyFinancialMonitoring.investigationOrPenaltyReasons.label'
              )}
              maxLength={maxLengthTextField}
              name="financialMonitoring.investigationOrPenaltyReasons"
              placeholder={t(
                'client.legal.companyFinancialMonitoring.investigationOrPenaltyReasons.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.hasMandatoryControlTransactions.label'
            )}
            name="financialMonitoring.hasMandatoryControlTransactions"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          {values.financialMonitoring.hasMandatoryControlTransactions?.value ===
            YesOrNoType.Yes && (
            <Field.Text
              label={t(
                'client.legal.companyFinancialMonitoring.mandatoryControlTransactionIdentificationProcedure.label'
              )}
              maxLength={maxLengthTextField}
              name="financialMonitoring.mandatoryControlTransactionIdentificationProcedure"
              placeholder={t(
                'client.legal.companyFinancialMonitoring.mandatoryControlTransactionIdentificationProcedure.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.monetaryServiceTransactionCountForLastSixMonths.label'
            )}
            name="financialMonitoring.monetaryServiceTransactionCountForLastSixMonths"
            optionLabelKey="label"
            options={getMonetaryServiceTransactionCountForLastSixMonthsOptions(
              t
            )}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.isAffiliatedWithNonProfitOrganizations.label'
            )}
            name="financialMonitoring.isAffiliatedWithNonProfitOrganizations"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.receivesFundingFromStateBudget.label'
            )}
            name="financialMonitoring.receivesFundingFromStateBudget"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.receivesFundingFromForeignNonProfitNGOs.label'
            )}
            name="financialMonitoring.receivesFundingFromForeignNonProfitNGOs"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.isCompliantWithFATFRecommendations.label'
            )}
            name="financialMonitoring.isCompliantWithFATFRecommendations"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'client.legal.companyFinancialMonitoring.hasBeenRefusedToOpenAccountByBanks.label'
            )}
            name="financialMonitoring.hasBeenRefusedToOpenAccountByBanks"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />
        </>
      )}
    </>
  );
};
