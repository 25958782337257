import { CountryEntity } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/legal/forms/companyBankAccountsForm';

interface Props {
  index: number;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({ index, countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('client.legal.companyBankAccounts.country.label')}
        loading={countriesLoading}
        name={`bankAccounts.${index}.country`}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('client.legal.companyBankAccounts.country.placeholder')}
      />

      <Field.Text
        label={t('client.legal.companyBankAccounts.swiftCode.label')}
        name={`bankAccounts.${index}.swiftCode`}
        placeholder={t(
          'client.legal.companyBankAccounts.swiftCode.placeholder'
        )}
      />

      <Field.Autocomplete
        label={t(
          'client.legal.companyBankAccounts.onTerritoryWithNoAML_CFTLabel'
        )}
        name={`bankAccounts.${index}.onTerritoryWithNoAML_CFT`}
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.selectPlaceholder')}
        variant="select"
      />

      <Field.Autocomplete
        label={t(
          'client.legal.companyBankAccounts.hasPermanentManagementInCountryLabel'
        )}
        name={`bankAccounts.${index}.hasPermanentManagementInCountry`}
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.selectPlaceholder')}
        variant="select"
      />
    </>
  );
};
