import { FC } from 'react';

import { CountryEntity, KycEntity } from 'api/types/entity';
import { parseFullName } from 'helpers';
import { useFormErrorFocus } from 'hooks';
import { ClientType } from 'modules/client/client/types';
import {
  ClientTypeToggleButton,
  FormContentWrapper,
} from 'modules/client/common/components';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { useFormContext } from '../../../forms/representativeForm';
import { RepresentativeSections } from '../../RepresentativeSections';

export interface FormContentProps extends LockAndUnlockReadOnlyProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  submitLoading?: boolean;
  onChangeClientType: (v: ClientType) => void;
  onShowVersionHistory?: () => void;
}

export const FormContent: FC<FormContentProps> = ({
  countries,
  countriesLoading,
  kycData,
  submitLoading,
  lockClientIfPossible,
  onChangeClientType,
  unlockClient,
  onShowVersionHistory,
}) => {
  const { resetForm, values, dirty, setReadOnly } = useFormContext();

  const fullName = values ? parseFullName(values.personalData) : '';

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  useFormErrorFocus();

  return (
    <FormContentWrapper
      shownTabBar
      content={
        <RepresentativeSections
          countries={countries}
          countriesLoading={countriesLoading}
          kycData={kycData}
        />
      }
      dirty={dirty}
      fullName={fullName}
      resetForm={resetForm}
      submitLoading={submitLoading}
      TabBarComponent={ClientTypeToggleButton}
      tabBarValue={ClientType.representative}
      onChangeTabBarValue={onChangeClientType}
      onEdit={onDisableReadOnly}
      onEnableReadOnly={onEnableReadOnly}
      onShowVersionHistory={onShowVersionHistory}
    />
  );
};
