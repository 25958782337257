import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  priceAndCurrency,
  number,
} from 'libs/form';
import { PriceFieldValue } from 'libs/form/fields/PriceField';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType } from 'types/yesOrNo';

export interface ControlOverFormType {
  controlOver: {
    hasControlOverLegalClient: Maybe<YesOrNoSelectType>;
    directCapitalParticipationPercentage: number;
    indirectCapitalParticipationPercentage: number;
    financialConditionAmount: PriceFieldValue;
  };
}

export const controlOverSchema: ObjectSchema<ControlOverFormType> = object({
  controlOver: object({
    hasControlOverLegalClient: yesOrNotSelectValidation
      .default(null)
      .required(),

    directCapitalParticipationPercentage: number().min(0).max(100).required(),
    indirectCapitalParticipationPercentage: number().min(0).max(100).required(),

    financialConditionAmount: priceAndCurrency().required(),
  }),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ControlOverFormType>();

export type FormErrors = FormErrorsBase<ControlOverFormType>;
