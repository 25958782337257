// eslint-disable-next-line no-restricted-imports
import {
  SaveLegalClientAccountStatusDto_Input,
  MutationInput_LegalClientController_SaveAccountStatus_Input_Value as ClientAccountStatusTypeApi,
} from 'api/generated/graphql';
import { ClientAccountStatusType } from 'api/types/entity';
import { SaveAccountStatusParam } from 'api/types/params';

const statusMap: Record<ClientAccountStatusType, ClientAccountStatusTypeApi> = {
  [ClientAccountStatusType.Opened]: ClientAccountStatusTypeApi.Opened,
  [ClientAccountStatusType.Frozen]: ClientAccountStatusTypeApi.Frozen,
  [ClientAccountStatusType.Closed]: ClientAccountStatusTypeApi.Closed,
  [ClientAccountStatusType.ClosingInProgress]:
    ClientAccountStatusTypeApi.ClosingInProgress,
  [ClientAccountStatusType.OpenedWithRestrictions]:
    ClientAccountStatusTypeApi.OpenedWithRestrictions,
};

export const parseSaveLegalClientAccountStatusParam = (
  param: SaveAccountStatusParam
): SaveLegalClientAccountStatusDto_Input => {
  const value = statusMap[param.value];

  return {
    value,
    versionNumber: param.versionNumber,
  };
};
