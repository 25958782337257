import { Dispatch, SetStateAction } from 'react';

import { RiskLevelFilterType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';

import { CheckboxFilter } from '../CheckboxFilter';

interface Props {
  riskLevels: RiskLevelFilterType[];
  setRiskLevels: Dispatch<SetStateAction<RiskLevelFilterType[]>>;
}

export const RiskLevelFilter = ({ riskLevels, setRiskLevels }: Props) => {
  const { t } = useTranslation();

  const items = Object.values(RiskLevelFilterType).map((v) => ({
    label: t(`client.riskLevel.riskLevelType.${v}`),
    value: v,
  }));

  return (
    <CheckboxFilter
      items={items}
      selectedItems={riskLevels}
      setItems={setRiskLevels}
      title={
        t('client.filters.riskLevel') +
        (riskLevels.length ? ` (${riskLevels.length})` : '')
      }
    />
  );
};
