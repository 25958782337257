import {
  LegalClientEntity,
  LegalClientFirstHeadEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { parseFullName } from 'helpers';
import { usePageTitle } from 'libs/navigation';

export const useFirstHeadPageTitle = (
  legalClient: LegalClientEntity | null,
  legalClientLegalHead: LegalClientLegalHeadEntity | null,
  legalClientFirstHead: LegalClientFirstHeadEntity | null
) => {
  let fullName = legalClientFirstHead
    ? parseFullName(legalClientFirstHead)
    : '';

  fullName +=
    fullName && legalClientLegalHead
      ? ` - ${legalClientLegalHead.fullName}`
      : '';

  fullName += fullName && legalClient ? ` - ${legalClient.fullName}` : '';

  usePageTitle(fullName);
};
