import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientFirstHeadMutation } from 'api/generated/graphql';
import {
  parseLegalClientFirstHeadEntity,
  parseUpdateLegalClientFirstHeadParams,
  useError,
} from 'api/helpers';
import { UpdateLegalClientFirstHeadParams } from 'api/types/params';

export const useUpdateLegalClientFirstHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientFirstHeadMutation();

  const error = useError(apolloError);

  return {
    legalClientFirstHead: data?.updateLegalClientFirstHead
      ? parseLegalClientFirstHeadEntity(data.updateLegalClientFirstHead)
      : undefined,
    loading,
    error,
    updateLegalClientFirstHead: useCallback(
      async (id: string, params: UpdateLegalClientFirstHeadParams) => {
        const input = parseUpdateLegalClientFirstHeadParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateLegalClientFirstHead
          ? parseLegalClientFirstHeadEntity(
              res.data?.updateLegalClientFirstHead
            )
          : undefined;
      },
      [handle]
    ),
  };
};
