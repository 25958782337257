import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSnapshotQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseRepresentativeClientSnapshotEntity } from 'api/helpers/client/parseEntity/parseRepresentativeClientSnapshotEntity';

import { RequestOptions } from '../types';

export const useClientRepresentativeSnapshot = (
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useClientRepresentativeSnapshotQuery({
    variables: { versionId },
    ...options,
  });

  const error = useError(apolloError);

  const snapshot = useMemo(
    () =>
      data?.clientRepresentativeSnapshot
        ? parseRepresentativeClientSnapshotEntity(
            data.clientRepresentativeSnapshot
          )
        : null,
    [data?.clientRepresentativeSnapshot]
  );

  return {
    snapshot,
    loading,
    error,
    refetch,
  };
};
