import { useCallback } from 'react';

import { useFileUpload } from 'api/requests/file';
import { useField } from 'libs/form/fields/useField';
import { useFormContext } from 'libs/form/useFormContext';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import { LocalFileEntity } from '../../../types';

interface Props {
  maxDocumentCount?: number;
  name: string;
}

type FileType = LocalFileEntity & { loading?: boolean };

export const useDocumentField = ({ maxDocumentCount, name }: Props) => {
  const { field, helpers } = useField<FileType[]>(name);

  const { setSubmitDisabled } = useFormContext();

  const { upload } = useFileUpload();

  const handleFile = useCallback(
    async (acceptedFiles: File[]) => {
      setSubmitDisabled(true);

      if (maxDocumentCount) {
        const addCount = maxDocumentCount - (field.value?.length ?? 0);
        acceptedFiles = acceptedFiles.slice(0, addCount);
      }

      const newValues = [...(field.value ?? [])];

      await Promise.all(
        acceptedFiles.map(async (uploadFile) => {
          const id = uuidv4();
          newValues.push({
            id,
            name: uploadFile.name,
            createdAt: new Date(),
            size: uploadFile.size,
            loading: true,
            uploadedByAdmin: true,
          });
          helpers.setValue(newValues);

          const serverId = await upload(uploadFile).catch();

          const index = newValues.findIndex((f) => f.id === id);
          newValues[index].id = serverId;
          newValues[index].loading = false;

          helpers.setValue([...newValues]);
        })
      );

      setSubmitDisabled(false);
    },
    [field.value, helpers, maxDocumentCount, setSubmitDisabled, upload]
  );

  const { open, getInputProps } = useDropzone({
    noClick: true,
    multiple: true,
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: handleFile,
  });

  const onClickDelete = useCallback(
    (id: string) => {
      const newValues = field.value.filter((f) => f.id !== id);
      helpers.setValue([...newValues]);
    },
    [field.value, helpers]
  );

  return { open, getInputProps, onClickDelete };
};
