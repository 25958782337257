import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCurrentUserLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseUserEntity } from 'api/helpers/parseEntity';

export const useCurrentUserLazy = () => {
  const [getCurrentUser, { data, loading, error: apolloError }] =
    useCurrentUserLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const error = useError(apolloError);

  const user = useMemo(
    () =>
      data?.AuthController_getCurrentUser
        ? parseUserEntity(data.AuthController_getCurrentUser)
        : null,
    [data?.AuthController_getCurrentUser]
  );

  return {
    getCurrentUser,
    user,
    loading,
    error,
  };
};
