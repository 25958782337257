import {
  useLegalClient,
  useLegalClientSnapshot,
  useLegalClientVersions,
} from 'api/requests';
import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getLegalClientInitialValue } from '../helpers/getLegalClientInitialValue/getLegalClientInitialValue';

type ClientVersionContainerParams = {
  legalClientId: string;
  versionId: string;
};

export const useLegalClientVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ClientVersionContainerParams>();

  const { legalClient, loading: clientLoading } = useLegalClient(
    params.legalClientId!
  );

  const {
    snapshot,
    loading: snapshotLoading,
    error,
  } = useLegalClientSnapshot(params.versionId!, { fetchPolicy: 'no-cache' });

  const { loading: versionsLoading, versions } = useLegalClientVersions(
    params.legalClientId!
  );

  const hasRepresentative = !!snapshot?.value?.representativeId;

  const initialValues = snapshot?.value
    ? getLegalClientInitialValue(t, snapshot.value)
    : undefined;

  const fullName = legalClient?.fullName!;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: legalClient?.fullName,
    [RoutesId.legalClientVersion]: t('client.client.versions.header', {
      name: fullName,
    }),
  };

  const loading =
    (!legalClient && clientLoading) || (!versions && versionsLoading);

  const onChangeClientType = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientRepresentativeVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        legalClientRepresentativeId: legalClient?.representativeId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.fullPath,
      {
        legalClientId: params.legalClientId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: LegalClientVersionEntity) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  const onClickHead = (headId: string) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientHeadVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
        headId,
      },
      { replace: true }
    );
  };
  const onClickLegalHead = (headId: string) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientLegalHeadVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
        headId,
      },
      { replace: true }
    );
  };
  const onClickBeneficiary = (beneficiaryId: string) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientBeneficiaryVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
        beneficiaryId,
      },
      { replace: true }
    );
  };
  const onClickBranch = (branchId: string) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientBranchVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
        branchId,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    hasRepresentative,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    legalClient,
    params,
    onClickHead,
    onClickBeneficiary,
    onClickBranch,
    onClickLegalHead,
    onChangeVersion,
    onCloseVersionHistory,
    onChangeClientType,
  };
};
