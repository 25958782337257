import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/client/common/components/AddAndRemoveButtons';
import { useFormContext } from 'modules/client/legal/forms/companyActivitiesForm';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setValues, readOnly } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    values.activityTypes.push({ name: '' });
    setValues(values, false);
  };

  const onRemove = (index: number) => {
    values.activityTypes.splice(index, 1);
    setValues(values, false);
  };

  if (readOnly) {
    return null;
  }

  return (
    <AddAndRemoveButtons
      addLabel={t('client.legal.companyBankAccounts.addAccount')}
      counts={values.activityTypes.length}
      index={index}
      maxCount={Infinity}
      removeLabel={t('client.legal.companyBankAccounts.removeAccount')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
