import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { useLegalClientHead } from '../hooks';
import { LegalClientHeadView } from '../views/LegalClientHeadView';

export const LegalClientHeadContainer = () => {
  const {
    loading,
    legalClientHead,
    legalClient,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
  } = useLegalClientHead();

  useScrollToTop([]);

  if (error || (!legalClientHead && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientHead) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientHeadView
      bodies={legalClient?.bodies ?? []}
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      kycData={legalClientHead.kyc}
      lockClientIfPossible={lockClientIfPossible}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
