import { yesOrNotSelectValidation } from 'helpers/yesOrNo';
import { ObjectSchema, object, TypedForm } from 'libs/form';
import { SelectType } from 'types/selectType';
import { YesOrNoType } from 'types/yesOrNo';
import { Maybe } from 'yup';

export interface StatusesFormType {
  statuses: {
    accreditedInvestorStatus?: Maybe<SelectType<YesOrNoType>>;
  };
}

export const statusesSchema: ObjectSchema<StatusesFormType> = object({
  statuses: object({
    accreditedInvestorStatus: yesOrNotSelectValidation.nullable().default(null),
  }).default({}),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<StatusesFormType>();
