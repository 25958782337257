import { useCallback, useEffect, useState } from 'react';

import { secondsToTime } from 'helpers';

const MINUTE = 60;

export const useTimer = (seconds = MINUTE) => {
  const [timer, setTimer] = useState(seconds);
  const [reload, setReload] = useState(false);

  const reloadTimer = useCallback(
    (newSeconds = MINUTE) => [setTimer(newSeconds), setReload((prev) => !prev)],
    []
  );

  useEffect(() => {
    const interval = setInterval(
      () =>
        setTimer((prev) => {
          if (prev - 1 <= 0) clearInterval(interval);
          return prev - 1;
        }),
      1000
    );
    return () => clearInterval(interval);
  }, [reload]);

  const { minutesString, secondsString } = secondsToTime(timer);

  return {
    timer: `${minutesString}:${secondsString}`,
    reloadTimer,
    isTimerVisible: timer > 0,
  };
};
