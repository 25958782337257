import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const ArrowUp = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1752_4285)">
        <path
          d="M7.41 15.4102L12 10.8302L16.59 15.4102L18 14.0002L12 8.00016L6 14.0002L7.41 15.4102Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1752_4285">
          <rect
            fill="white"
            height="24"
            transform="matrix(-1 0 0 -1 24 24)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
