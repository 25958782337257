// eslint-disable-next-line no-restricted-imports
import {
  Query_UserController_GetList_AllOf_1_List_Items_Role_Permissions_Items_Name as PermissionsApi,
  RoleFragment,
  Query_UserController_GetList_AllOf_1_List_Items_Role_Name as RoleTypeApi,
} from 'api/generated/graphql';
import { PermissionType, RoleType, UserRoleEntity } from 'api/types/entity';
import { lodash } from 'helpers';

const permissions: Record<PermissionsApi, PermissionType> = {
  [PermissionsApi.UserCreateOne]: PermissionType.AdminCreate,
  [PermissionsApi.UserGetList]: PermissionType.AdminList,
  [PermissionsApi.UserGetOne]: PermissionType.AdminRead,
  [PermissionsApi.UserUpdateOne]: PermissionType.AdminEdit,
  [PermissionsApi.AuthResetPassword]: PermissionType.AdminResetPassword,
  [PermissionsApi.AuthSendAccountActivationEmail]:
    PermissionType.AdminResendInvite,
  [PermissionsApi.UserActivateOne]: PermissionType.AdminActivate,
  [PermissionsApi.UserDeactivateOne]: PermissionType.AdminDeactivate,
};

const roles: Record<RoleTypeApi, RoleType> = {
  [RoleTypeApi.Admin]: RoleType.Admin,
  [RoleTypeApi.Superadmin]: RoleType.SuperAdmin,
};

export const parseUserRoleEntity = (role: RoleFragment): UserRoleEntity => ({
  id: role!.id,
  name: roles[role.name],
  permissions: lodash
    .compact(role.permissions!)
    .map(({ name }) => permissions[name]),
});
