import { Box } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const Body = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? Colors.white : Colors.black,
  boxShadow: '0px 24px 38px 0px rgba(45, 54, 72, 0.02)',
  borderRadius: 24,
  width: '100%',
  padding: '52px 32px',
}));
