// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetById_Category as CategoryApi,
  Query_LegalClientController_GetList_AllOf_1_List_Items_Category as CategoryApi2,
} from 'api/generated/graphql';
import { LegalClientCategoryType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type CategoryApiType = CategoryApi | CategoryApi2;

const category: Record<CategoryApiType, LegalClientCategoryType> = {
  [CategoryApi.BrokerDealers]: LegalClientCategoryType.BrokerDealers,
  [CategoryApi.InsuranceOrganizations]:
    LegalClientCategoryType.InsuranceOrganizations,
  [CategoryApi.InvestmentFund]: LegalClientCategoryType.InvestmentFund,
  [CategoryApi.InvestmentFunds]: LegalClientCategoryType.InvestmentFunds,
  [CategoryApi.LegalEntityNonResident]:
    LegalClientCategoryType.LegalEntityNonResident,
  [CategoryApi.LegalEntityResident]:
    LegalClientCategoryType.LegalEntityResident,
  [CategoryApi.NominalHoldersResident]:
    LegalClientCategoryType.NominalHoldersResident,
  [CategoryApi.OtherFinancialMarketLicensees]:
    LegalClientCategoryType.OtherFinancialMarketLicensees,
  [CategoryApi.OtherLegalEntitiesNonResident]:
    LegalClientCategoryType.OtherLegalEntitiesNonResident,
  [CategoryApi.OtherLegalEntitiesResident]:
    LegalClientCategoryType.OtherLegalEntitiesResident,
  [CategoryApi.PensionFundOwnAssets]:
    LegalClientCategoryType.PensionFundOwnAssets,
  [CategoryApi.PensionFundPensionAssets]:
    LegalClientCategoryType.PensionFundPensionAssets,
  [CategoryApi.SecondTierBank]: LegalClientCategoryType.SecondTierBank,
};

export const parseCategoryType = (
  data: Maybe<CategoryApiType>
): LegalClientCategoryType | undefined => (data ? category[data] : undefined);
