import {
  LegalClientBeneficiaryEntity,
  LegalClientEntity,
} from 'api/types/entity';
import { useLockLegalClient } from 'modules/client/legal/hooks/useLockLegalClient';

export const useLockLegalClientBeneficiary = (
  legalClient: LegalClientEntity | null,
  beneficiary: LegalClientBeneficiaryEntity | null
) => {
  const { lockClientIfPossible, unlockClient } = useLockLegalClient(
    legalClient
      ? {
          ...legalClient,
          versionNumber: beneficiary?.versionNumber,
        }
      : null
  );

  return { lockClientIfPossible, unlockClient };
};
