import { useEffect } from 'react';

import {
  useRepresentativeClient,
  useClientRepresentativeSnapshot,
  useClient,
} from 'api/requests';
import { ClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getBaseRepresentativeInitialValue } from '../helpers';

import { useRepresentativeClientVersions } from './useRepresentativeClientVersions';

type ContainerParams = {
  clientId: string;
  representativeId: string;
  versionId: string;
};

export const useRepresentativeClientVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ContainerParams>();

  const { loading: loadingRepresentativeClient, representativeClient } =
    useRepresentativeClient(params.representativeId!);

  const { client } = useClient(params.clientId!);

  const { loading: versionsLoading, versions } =
    useRepresentativeClientVersions(params.clientId!, params.versionId);

  const {
    snapshot,
    loading: snapshotLoading,
    error,
  } = useClientRepresentativeSnapshot(params.versionId!, {
    fetchPolicy: 'no-cache',
  });

  const initialValues = snapshot?.value
    ? getBaseRepresentativeInitialValue(snapshot.value, t)
    : undefined;

  const fullName = representativeClient
    ? parseFullName(representativeClient)
    : '';

  const clientFullName = client ? parseFullName(client) : '';

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.personClient]: client ? parseFullName(client) : '',
    [RoutesId.personClientVersion]: t('client.client.versions.header', {
      name: clientFullName,
    }),
    [RoutesId.representativeClientVersion]: t('client.client.versions.header', {
      name: fullName,
    }),
  };

  const loading =
    (!representativeClient && loadingRepresentativeClient) ||
    (!versions && versionsLoading);

  const onChangeClientType = () => {
    navigate(
      ROUTES.clients.personClients.personClient.personClientVersion.fullPath,
      {
        clientId: params.clientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.personClients.personClient.representativeClient.fullPath,
      {
        clientId: params.clientId!,
        representativeId: params.representativeId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: ClientVersionEntity) => {
    navigate(
      ROUTES.clients.personClients.personClient.personClientVersion
        .representativeClientVersion.fullPath,
      {
        clientId: params.clientId!,
        representativeId: params.representativeId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.clients.personClients.personClient.personClientVersion.fullPath,
      {
        clientId: params.clientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  useEffect(() => {
    if (versions?.length && !params.versionId) {
      navigate(
        ROUTES.clients.personClients.personClient.personClientVersion
          .representativeClientVersion.fullPath,
        {
          clientId: params.clientId!,
          representativeId: params.representativeId!,
          versionId: versions[0].id,
        },
        { replace: true }
      );
    }
  }, [
    navigate,
    params.clientId,
    params.representativeId,
    params.versionId,
    versions,
  ]);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading,
    error,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    representativeClient,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
    onChangeClientType,
  };
};
