import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  useFormContext,
  Field,
} from 'modules/client/legal/forms/companyLicenseForm';
import { YesOrNoType } from 'types/yesOrNo';

import { Fields } from './Fields';

export interface FormContentProps {
  hideDocuments?: boolean;
}

export const FormContent = ({ hideDocuments }: FormContentProps) => {
  const { t } = useTranslation();
  const { values, readOnly } = useFormContext();
  return (
    <>
      <Field.Autocomplete
        label={t('client.legal.companyLicense.companyActivityIsLicensed.label')}
        name="companyLicense.companyActivityIsLicensed"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyLicense.companyActivityIsLicensed.placeholder'
        )}
        variant="select"
      />
      {values.companyLicense.companyActivityIsLicensed &&
        values.companyLicense.companyActivityIsLicensed.value ===
          YesOrNoType.Yes && (
          <>
            <Fields />

            {!hideDocuments && (
              <DocumentsTableFeature
                name="companyLicense.files"
                readOnly={readOnly}
              />
            )}
          </>
        )}
    </>
  );
};
