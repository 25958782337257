import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { parseFullName } from 'helpers';
import { usePageTitle } from 'libs/navigation';

export const useLegalPageTitle = (
  legalClient: LegalClientEntity | null,
  legalClientRepresentative: LegalClientRepresentativeEntity | null
) => {
  let fullName = legalClientRepresentative
    ? parseFullName(legalClientRepresentative)
    : '';

  fullName += fullName && legalClient ? ` - ${legalClient.fullName}` : '';

  usePageTitle(fullName);
};
