import { useCallback } from 'react';

import { convertDateByTimezone } from 'helpers';
import { useUser } from 'libs/providers';

export const useConvertDateToUserTimezone = () => {
  const { user } = useUser();

  if (!user) {
    throw new Error('not user');
  }

  return {
    convertDateToUserTimezone: useCallback(
      (date: Date) =>
        convertDateByTimezone(
          date,
          user.settings.timezone ?? {
            name: '',
            gmtOffset: new Date().getTimezoneOffset() * -60,
          }
        ),
      [user.settings.timezone]
    ),
  };
};
