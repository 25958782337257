// eslint-disable-next-line no-restricted-imports
import { LegalClientLegalHeadForLegalClientFragment } from 'api/generated/graphql';
import { LegalClientLegalHeadItemEntity } from 'api/types/entity/legal/legalClientLegalHeadItemEntity';

export const parseLegalClientLegalHeadItemEntity = (
  data: LegalClientLegalHeadForLegalClientFragment
): LegalClientLegalHeadItemEntity => ({
  id: data.id,
  name: data.fullName ?? '',
  createdAt: new Date(data.createdAt),
  registrationCity: data.legalAddress?.country.name,
  location: data.actualAddress?.country.name,
});
