import { PropsWithChildren } from 'react';

import { NotificationHeader } from './NotificationHeader';
import { ContentStyled } from './styles';

export interface NotificationWrapperProps {
  isRead: boolean;
  date: Date;
  title: string;
  loadingToggleRead?: boolean;
  onToggleRead: () => void;
}

export const NotificationWrapper = ({
  isRead,
  title,
  date,
  loadingToggleRead,
  children,
  onToggleRead,
}: NotificationWrapperProps & PropsWithChildren) => (
  <ContentStyled>
    <NotificationHeader
      date={date}
      isRead={isRead}
      loadingToggleRead={loadingToggleRead}
      title={title}
      onToggleRead={onToggleRead}
    />
    {children}
  </ContentStyled>
);
