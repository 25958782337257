/**
 * Represents the state of a dropzone component.
 */
export type DropzoneState = {
  /** Indicates whether the dropzone is currently focused. */
  isFocused: boolean;
  /** Indicates whether the dropped files are accepted. */
  isDragAccept: boolean;
  /** Indicates whether the dropped files are rejected. */
  isDragReject: boolean;
};

/**
 * Props for the UploadFiles component.
 */
export interface FilesUploadProps<T extends FileUpload> {
  /** The title of the file upload component. */
  title: string;

  /** An optional CSS class name for styling. */
  className?: string;

  /** An array of files. */
  files?: T[];

  /** Determines whether the file list is expanded by default. */
  defaultExpandFileList?: boolean;

  /** Indicates if an error occurred. */
  isError?: boolean;

  /** The maximum number of uploaded files allowed. */
  maxFiles?: number;

  /** An array of allowed file types. */
  fileTypes?: FileType[];

  /** Callback function triggered when new files are added. */
  onAddFiles?: (file: File[]) => void;

  /** Callback function triggered when a file is removed. */
  onRemoveFile?: (file: T, index: number) => void;
}

export interface FileInfo {
  name: string;
  size: number;
}

/**
 * Represents a file uploaded through the FilesUpload component.
 */
export interface FileUpload {
  /** Unique identifier for the file upload. */
  id: string;

  /** The uploaded file. */
  file?: File;

  fileInfo: FileInfo;

  /** Indicates if the file is in a loading state. */
  loading?: boolean;
}

/**
 * Enum representing different file types.
 */
export enum FileType {
  /** PDF file type */
  pdf,
  // Add more file types as needed...
}
