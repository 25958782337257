import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const ArrowDown = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_3155)">
        <path
          d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3_3155">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
