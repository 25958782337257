import { FC } from 'react';

import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
  ClientVersionEntity,
} from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { LayoutWithNavigationBreadcrumbs } from 'components/LayoutWithNavigationBreadcrumbs';
import { Form } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { ClientTypeToggleButton } from 'modules/client/common/components';
import { renderClientStatus } from 'modules/client/common/helpers';
import { VersionView } from 'modules/client/common/views';

import { ClientSections, ClientSectionsProps } from '../components';
import { ClientFormType } from '../forms/clientForm';
import { ClientType } from '../types';

export interface ClientVersionViewProps extends ClientSectionsProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  versions: ClientVersionEntity[];
  currentOnboardingStatus?: ClientOnboardingStatusType;
  currentAccountStatus?: ClientAccountStatusType | null;
  initialValues?: ClientFormType;
  fullName: string;
  versionId: string;
  snapshotLoading?: boolean;
  hasRepresentative: boolean;
  onChangeVersion: (version: ClientVersionEntity) => void;
  onChangeClientType: () => void;
  onCloseVersionHistory: () => void;
}

export const ClientVersionView: FC<ClientVersionViewProps> = ({
  breadcrumbsTitles,
  versions,
  currentAccountStatus,
  currentOnboardingStatus,
  onboardingData,
  fullName,
  initialValues,
  snapshotLoading,
  versionId,
  hasRepresentative,
  onCloseVersionHistory,
  onChangeClientType,
  onChangeVersion,
  ...sectionsProps
}) => {
  const { t } = useTranslation();
  const status = currentAccountStatus || currentOnboardingStatus;
  return (
    <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
      <VersionView
        content={
          initialValues && (
            <Form
              initialReadOnly
              initialValues={initialValues}
              onSubmit={() => {}}
            >
              <ClientSections hideActivitySection {...sectionsProps} />
            </Form>
          )
        }
        loading={snapshotLoading || !initialValues}
        name={fullName}
        topContent={
          <>
            {hasRepresentative && (
              <ClientTypeToggleButton
                value={ClientType.client}
                onChange={onChangeClientType}
              />
            )}
            {status &&
              renderClientStatus(
                {
                  onboarding: onboardingData,
                  status,
                },
                t,
                { size: 'smedium', uppercase: true }
              )}
          </>
        }
        versionId={versionId}
        versions={versions}
        onBack={onCloseVersionHistory}
        onChangeVersion={onChangeVersion}
        onCloseVersionHistory={onCloseVersionHistory}
      />
    </LayoutWithNavigationBreadcrumbs>
  );
};
