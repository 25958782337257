import { OccupationType } from 'api/types/entity';
import { subYears } from 'date-fns';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  BaseDebtsFormType,
  getBaseDebtsSchema,
} from 'modules/client/common/forms/baseDebtsForm';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { ActivityFormType, activitySchema } from './activityForm';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from './additionalDocumentsForm';
import { BankAccountsFormType, bankAccountsSchema } from './bankAccountsForm';
import { BiographyFormType, biographySchema } from './biographyForm';
import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import { ContactsFormType, getContactsSchema } from './contactsForm';
import { DocumentsFormType, documentsSchema } from './documentsForm';
import {
  IdentityDocumentFormType,
  identityDocumentSchema,
} from './identityDocumentForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import { PlaceOfBirthFormType, placeOfBirthSchema } from './placeOfBirthForm';
import {
  PowerOfAttorneyFormType,
  getPowerOfAttorneySchema,
} from './powerOfAttorneyForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import {
  ResidentialAddressFormType,
  residentialAddressSchema,
} from './residentialAddressForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';

export interface RepresentativeFormType
  extends ActivityFormType,
    ContactsFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    PlaceOfBirthFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    BiographyFormType,
    BankAccountsFormType,
    PepConnectionFormType,
    BaseDebtsFormType,
    DocumentsFormType,
    PowerOfAttorneyFormType,
    IdentityDocumentFormType,
    AdditionalDocumentsFormType,
    ReasonChangeFormType {}

export const dateOfBirthMaxDate = subYears(new Date(), 18);
export const dateOfBirthMinDate = subYears(new Date(), 100);

export const getSchema = (
  t: TFunc,
  initialValues?: RepresentativeFormType
): ObjectSchema<RepresentativeFormType> =>
  object()
    .concat(getContactsSchema(t))
    .concat(getPersonalDataSchema(t))
    .concat(citizenshipsSchema)
    .concat(taxResidencesSchema)
    .concat(registrationAddressSchema)
    .concat(residentialAddressSchema)
    .concat(placeOfBirthSchema)
    .concat(biographySchema)
    .concat(bankAccountsSchema)
    .concat(activitySchema)
    .concat(identityDocumentSchema)
    .concat(getPepConnectionSchema(t))
    .concat(getPowerOfAttorneySchema(t))
    .concat(documentsSchema)
    .concat(reasonChangeSchema)
    .concat(getAdditionalDocumentsSchema())
    .concat(
      getBaseDebtsSchema(
        initialValues?.biography?.occupation?.value ===
          OccupationType.entrepreneur
      )
    );

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RepresentativeFormType>();

export type FormErrors = FormErrorsBase<RepresentativeFormType>;
