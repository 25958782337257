import { FC, PropsWithChildren } from 'react';

import { cx } from 'libs/ui/theme';

import { Header, HeaderProps } from './Header';
import { LayoutStyled, LayoutStyledProps } from './Layout.styles';
import { WrapperStyled } from './Wrapper.styles';
import { LayoutClasses } from './types';

export interface LayoutProps extends LayoutStyledProps, HeaderProps {
  className?: string;
}

export const Layout: FC<LayoutProps & PropsWithChildren> = ({
  children,
  variant,
  className,
  hideNavigationMenu,
  hideNotifications,
}) => (
  <LayoutStyled className={cx(LayoutClasses.main, className)} variant={variant}>
    <Header
      className={LayoutClasses.header}
      hideNavigationMenu={hideNavigationMenu}
      hideNotifications={hideNotifications}
    />
    <WrapperStyled className={LayoutClasses.content}>{children}</WrapperStyled>
    <div className={LayoutClasses.footer} />
  </LayoutStyled>
);
