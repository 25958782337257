import { useCallback, useState } from 'react';

import { FileRejection, useDropzone } from 'react-dropzone';

import { FileType, FileUpload, FilesUploadProps } from './types';

interface Accept {
  [key: string]: string[];
}

const FILE_TYPES: Record<FileType, Accept> = {
  [FileType.pdf]: { 'application/pdf': ['.pdf'] },
};

export const useFilesUpload = <T extends FileUpload>({
  maxFiles,
  files,
  defaultExpandFileList = true,
  fileTypes,
  onAddFiles,
}: FilesUploadProps<T>) => {
  const [showFiles, setShowFiles] = useState(defaultExpandFileList);

  const addDisabled = Boolean(maxFiles && files && files.length >= maxFiles);

  const handleFile = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        //
      } else if (!addDisabled) {
        if (maxFiles) {
          const addCount = maxFiles - (files?.length ?? 0);
          onAddFiles?.(acceptedFiles.slice(0, addCount));
        } else {
          onAddFiles?.(acceptedFiles);
        }
      }
    },
    [addDisabled, files?.length, maxFiles, onAddFiles]
  );

  const accept = fileTypes
    ? fileTypes.reduce((a, v) => ({ ...a, ...FILE_TYPES[v] }), {})
    : undefined;

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    multiple: true,
    accept,
    onDrop: handleFile,
  });

  return {
    showFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    addDisabled,
    open,
    setShowFiles,
  };
};
