// eslint-disable-next-line no-restricted-imports
import { Palette } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { TypographyStyle } from '@mui/material/styles/createTypography';

/**
 * Represents different typography variants.
 */
export type TypographyVariant =
  | 'header' // h1
  | 'title' // h2
  | 'subtitle' // h3
  | 'headline'
  | 'body'
  | 'bodyMedium'
  | 'bodySemiBold'
  | 'bodyBold'
  | 'caption'
  | 'captionMedium'
  | 'captionBold'
  | 'captionSemiBold';

/** Fonts */
export enum FONTS {
  main = 'Nunito Sans',
  header = 'Mulish',
}
/**
 * Defines typography styles based on the provided palette.
 */
export const typography: (
  palette: Palette
) => Record<TypographyVariant, TypographyStyle> = () => ({
  fontFamily: `${FONTS.main}, sans-serif`,
  header: {
    fontSize: 84,
    fontFamily: FONTS.header,
    lineHeight: '100%',
  },
  title: {
    fontSize: 60,
    fontFamily: FONTS.header,
    lineHeight: '100%',
    fontWeight: 700,
  },
  headline: {
    fontSize: 34,
    fontFamily: FONTS.header,
    lineHeight: '100%',
  },
  subtitle: {
    fontSize: 24,
    lineHeight: '160%',
    fontWeight: 700,
  },
  body: {
    fontSize: 16,
    lineHeight: '160%',
  },
  body1: {
    fontSize: 16,
    lineHeight: '160%',
  },
  bodyMedium: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '160%',
  },
  bodySemiBold: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '160%',
  },
  bodyBold: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '160%',
  },
  caption: {
    fontSize: 14,
    lineHeight: '160%',
  },
  captionMedium: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '160%',
  },
  captionSemiBold: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '160%',
  },
  captionBold: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '160%',
  },

  button: {},
  overline: {},
  subtitle1: {},
  subtitle2: {},
});
