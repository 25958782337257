import { ObjectSchema, array, object } from 'libs/form';
import {
  CompanyTaxResidencyFormTaxValue,
  companyTaxResidencyObject,
} from 'modules/client/common/forms/companyTaxResidencyForm';

export {
  Field,
  Form,
  type CompanyTaxResidencyFormTaxValue,
  Submit,
  useFormContext,
} from 'modules/client/common/forms/companyTaxResidencyForm';

export interface CompanyTaxResidencyFormType {
  companyTaxResidences?: CompanyTaxResidencyFormTaxValue[];
}

export const companyTaxResidencySchema: ObjectSchema<CompanyTaxResidencyFormType> =
  object({
    companyTaxResidences: array()
      .of(companyTaxResidencyObject.required())
      .optional()
      .default(undefined),
  });
