import { PropsWithChildren } from 'react';

import { Stack, Typography } from 'libs/ui';

interface Props extends PropsWithChildren {
  title: string;
}

export const FilterContent = ({ title, children }: Props) => (
  <Stack spacing={12}>
    <Typography variant="bodyBold">{title}</Typography>
    {children}
  </Stack>
);
