import { TypedForm, ObjectSchema, string, object, array } from 'libs/form';

export interface CompanyStructureFormType {
  companyStructure: {
    id: string;
    type: string;
    name: string;
  }[];
}

export const companyStructureSchema: ObjectSchema<CompanyStructureFormType> =
  object({
    companyStructure: array()
      .of(
        object({
          id: string().required(),
          type: string().max(2000).required(),
          name: string().max(2000).required(),
        })
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyStructureFormType>();
