// eslint-disable-next-line no-restricted-imports
import { StateRegistrationFragment } from 'api/generated/graphql';
import { parseFileDocumentEntity } from 'api/helpers/client/parseEntity/parseFileEntity';
import { LegalClientStateRegistrationEntity } from 'api/types/entity/legal/legalClientStateRegistrationEntity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

interface ParseLegalClientStateRegistration {
  stateRegistration?: Maybe<StateRegistrationFragment>;
}

export const parseLegalClientStateRegistration = (
  data: ParseLegalClientStateRegistration
): LegalClientStateRegistrationEntity | undefined => {
  if (!data.stateRegistration) {
    return undefined;
  }

  return {
    initialStateRegistrationDate: new Date(
      data.stateRegistration.initialStateRegistrationDate
    ),
    registrationAuthorityName: data.stateRegistration.registrationAuthorityName,
    registrationNumber: data.stateRegistration.registrationNumber,
    registrationDocumentName: data.stateRegistration.registrationDocumentName,
    stateRegistrationConfirmationDocument: {
      dateOfIssue: new Date(
        data.stateRegistration.stateRegistrationConfirmationDocument.dateOfIssue
      ),
      files: lodash
        .compact(
          data.stateRegistration.stateRegistrationConfirmationDocument.documents
        )
        .map(parseFileDocumentEntity),
    },
  };
};
