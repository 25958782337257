import { DebtsParam } from 'api/types/params';
import { YesOrNoType } from 'types/yesOrNo';

import { DebtsFormType, DebtsOptionalPrice } from '../../forms/debtsForm';

const getPrice = (v?: DebtsOptionalPrice) =>
  v && v.currency && v.price !== undefined && v.price !== null
    ? { currency: v.currency!, price: v.price! }
    : null;

export const getDebtsParam = (
  { debts }: DebtsFormType,
  isEntrepreneur: boolean
): DebtsParam => ({
  fees: getPrice(debts.fees),
  finesForViolation: getPrice(debts.finesForViolation),
  penalties: getPrice(debts.penalties),
  taxes: getPrice(debts.taxes),

  hasBankruptcyProceedings: isEntrepreneur
    ? debts.bankruptcyProceedings?.value === YesOrNoType.Yes
    : undefined,

  hasDelaysOrNonFulfillmentOfObligations: isEntrepreneur
    ? debts.delaysOrNonFulfillmentOfObligations?.value === YesOrNoType.Yes
    : undefined,
  hasMarginTrades: debts.marginTrades?.value === YesOrNoType.Yes,
});
