// eslint-disable-next-line no-restricted-imports
import { LegalInvoiceFragment } from 'api/generated/graphql';
import { parseFileEntity } from 'api/helpers/client/parseEntity/parseFileEntity';
import { LegalClientEntityInvoice } from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

export const parseLegalClientInvoice = (
  invoice: Maybe<LegalInvoiceFragment>
): LegalClientEntityInvoice | undefined => {
  if (!invoice) {
    return undefined;
  }

  return {
    file: invoice.privateFile
      ? parseFileEntity(invoice.privateFile)
      : undefined,
    paymentConfirmationFiles: lodash
      .compact(invoice.paymentConfirmationFiles)
      .map(parseFileEntity),
  };
};
