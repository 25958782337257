export enum LegalClientCategoryType {
  PensionFundOwnAssets = 'PensionFundOwnAssets', // Накопительный пенсионный фонд (собственные активы)
  InvestmentFund = 'InvestmentFund', // Инвестиционный фонд
  LegalEntityResident = 'LegalEntityResident', // Юридичекское лицо (резидент Республики Казахстан)
  LegalEntityNonResident = 'LegalEntityNonResident', // Юридическое лицо (нерезидент Республики Казахстан)
  SecondTierBank = 'SecondTierBank', // Банк второго уровня (собственников)
  BrokerDealers = 'BrokerDealers', // Брокеры-дилеры Республики Казахстан (собственников - небанков)
  InvestmentFunds = 'InvestmentFunds', // Инвестиционные фонды Республики Казахстан
  PensionFundPensionAssets = 'PensionFundPensionAssets', // Накопительный пенсионный фонд (пенсионные активы)
  NominalHoldersResident = 'NominalHoldersResident', // Номинальные держатели - резиденты Республики Казахстан
  OtherFinancialMarketLicensees = 'OtherFinancialMarketLicensees', // Прочие лицензиаты финансового рынка Республики Казахстан (собственников)
  OtherLegalEntitiesNonResident = 'OtherLegalEntitiesNonResident', // Прочие юридические лица - нерезиденты Республики Казахстан
  OtherLegalEntitiesResident = 'OtherLegalEntitiesResident', // Прочие юридические лица - резиденты Республики Казахстан
  InsuranceOrganizations = 'InsuranceOrganizations', // Страховые (перестраховочные) организаций Республики Казахстан (собственников)
}
