import React, { MouseEvent, useState } from 'react';

import { Menu, MenuItem, MenuOption, Pressed } from 'libs/ui';

import { IconStyled } from './styles';

interface Props<T extends string | number> {
  options: MenuOption<T>[];
  variant?: 'horizontal' | 'vertical';
  onClickOption: (option: MenuItem<T>) => void;
}

export const MoreMenu = <T extends string | number>({
  options,
  variant = 'vertical',
  onClickOption,
}: Props<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (option: MenuItem<T>) => {
    onClickOption(option);
    handleClose();
  };

  return (
    <>
      <Pressed onClick={handleClick}>
        <IconStyled variant={variant} />
      </Pressed>

      <Menu
        anchorEl={anchorEl}
        open={open}
        options={options}
        onClickOption={handleClickOption}
        onClose={handleClose}
      />
    </>
  );
};
