import {
  ClientCategoryType,
  ClientEconomicSectorType,
  ManagementType,
  TariffType,
} from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers/yesOrNo';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/client/forms/generalForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        fullWidth
        label={t('client.client.generalSection.category.label')}
        name="general.category"
        optionLabelKey="label"
        options={Object.values(ClientCategoryType).map((v) => ({
          label: t(`client.client.generalSection.category.types.${v}`),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('client.client.generalSection.category.placeholder')}
        variant="select"
      />
      <Field.Autocomplete
        fullWidth
        label={t('client.client.generalSection.managementType.label')}
        name="general.managementType"
        optionLabelKey="label"
        options={Object.values(ManagementType).map((v) => ({
          label: t(`client.client.generalSection.managementType.types.${v}`),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.client.generalSection.managementType.placeholder'
        )}
        variant="select"
      />
      <Field.Autocomplete
        fullWidth
        label={t('client.client.generalSection.economicSector.label')}
        name="general.economicSector"
        optionLabelKey="label"
        options={Object.values(ClientEconomicSectorType).map((v) => ({
          label: t(`client.client.generalSection.economicSector.types.${v}`),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.client.generalSection.economicSector.placeholder'
        )}
        variant="select"
      />
      <Field.Autocomplete
        fullWidth
        label={t(
          'client.client.generalSection.isFinancialMonitoringSubject.label'
        )}
        name="general.isFinancialMonitoringSubject"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.client.generalSection.isFinancialMonitoringSubject.placeholder'
        )}
        variant="select"
      />
      <Field.Autocomplete
        fullWidth
        label={t('client.client.generalSection.isKazakhstanResident.label')}
        name="general.isKazakhstanResident"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.client.generalSection.isKazakhstanResident.placeholder'
        )}
        variant="select"
      />
      <Field.Text
        fullWidth
        label={t('client.client.generalSection.taxBenefits.label')}
        name="general.taxBenefits"
        placeholder={t('client.client.generalSection.taxBenefits.placeholder')}
      />

      <Field.Autocomplete
        fullWidth
        hideClearIcon
        label={t('client.client.generalSection.tariff.label')}
        name="general.tariff"
        optionLabelKey="label"
        options={Object.values(TariffType).map((v) => ({
          label: t(`client.client.generalSection.tariff.types.${v}`),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('client.client.generalSection.tariff.placeholder')}
        variant="select"
      />
    </>
  );
};
