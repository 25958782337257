import { LegalClientConstitutionalDocumentParam } from 'api/types/params';
import { CompanyConstituentDocsFormType } from 'modules/client/legal/forms/companyConstituentDocs';

export const parseConstitutionalDocumentParam = (
  constitutionalDocument:
    | CompanyConstituentDocsFormType['constituentDocs']
    | undefined
): LegalClientConstitutionalDocumentParam | undefined => {
  if (!constitutionalDocument) {
    return undefined;
  }

  return {
    dateOfLastChange: constitutionalDocument.dateOfLastChange,
    fileIds: constitutionalDocument.files.map(({ id }) => id!),
  };
};
