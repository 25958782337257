import { lodash } from 'helpers';

import { BankAccountsFormType } from '../../forms/bankAccountsForm';

export const getBankAccountsParam = (values: BankAccountsFormType) =>
  lodash.compact(
    values.bankAccounts.map((v) => ({
      swiftCode: v.swiftCode!,
      country: v.country?.id!,
    }))
  );
