export enum HeadType {
  legal,
  person,
}
export interface HeadItem {
  id: string;
  name: string;
  type: HeadType;
  city: string;
}
