import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from 'modules/client/common/forms/additionalDocumentsForm';
import {
  DocumentsFormType,
  documentsSchema,
} from 'modules/client/common/forms/documentsForm';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { AccountCodesFormType, accountCodesSchema } from './accountCodesForm';
import { BaseActivityFormType, baseActivitySchema } from './baseActivityForm';
import {
  CompanyActivitiesFormType,
  companyActivitiesSchema,
} from './companyActivitiesForm';
import {
  CompanyBankAccountsFormType,
  companyBankAccountsSchema,
} from './companyBankAccountsForm';
import {
  CompanyBusinessRelationshipFormType,
  getCompanyBusinessRelationshipSchema,
} from './companyBusinessRelationshipForm';
import {
  CompanyConfirmStructureFormType,
  companyConfirmStructureSchema,
} from './companyConfirmStructure';
import {
  CompanyConstituentDocsFormType,
  getCompanyConstituentDocsSchema,
} from './companyConstituentDocs';
import {
  CompanyContactsFormType,
  getCompanyContactsSchema,
} from './companyContactsForm';
import { DebtsFormType, getDebtsSchema } from './companyDebtsForm';
import {
  CompanyFinancialMonitoringFormType,
  getCompanyFinancialMonitoringSchema,
} from './companyFinancialMonitoringForm';
import {
  CompanyFinancingSourcesFormType,
  getCompanyFinancingSourcesSchema,
} from './companyFinancingSourcesForm';
import {
  CompanyLicenseFormType,
  getCompanyLicenseSchema,
} from './companyLicenseForm';
import { CompanyNameFormType, companyNameSchema } from './companyNameForm';
import {
  CompanyOrderSubmissionContactsFormType,
  getCompanyOrderSubmissionContactsSchema,
} from './companyOrderSubmissionContactsForm';
import {
  CompanyOrganizationalAndLegalFormFormType,
  companyOrganizationalAndLegalFormSchema,
} from './companyOrganizationalAndLegalFormForm';
import {
  CompanyRegistrationAddressFormType,
  companyRegistrationAddressSchema,
} from './companyRegistrationAddressForm';
import {
  CompanyRegistrationPlaceFormType,
  companyRegistrationPlaceSchema,
} from './companyRegistrationPlaceForm';
import {
  CompanyResidentialAddressFormType,
  companyResidentialAddressSchema,
} from './companyResidentialAddressForm';
import {
  CompanyStateRegistrationFormType,
  getCompanyStateRegistrationSchema,
} from './companyStateRegistrationForm';
import {
  CompanyStructureFormType,
  companyStructureSchema,
} from './companyStructureForm';
import {
  CompanyTaxResidencyFormType,
  companyTaxResidencySchema,
} from './companyTaxResidencyForm';
import {
  WithdrawalAccountsFormType,
  withdrawalAccountsSchema,
} from './companyWithdrawalAccountsForm';
import { GeneralFormType, generalSchema } from './generalForm';
import {
  OpenAccountOrderFormType,
  openAccountOrderSchema,
} from './openAccountOrderForm';
import { RiskLevelFormType, riskLevelSchema } from './riskLevelForm';
import {
  ServiceContractFormType,
  serviceContractSchema,
} from './serviceContractForm';
import { StatusesFormType, statusesSchema } from './statusesForm';

export interface LegalClientFormType
  extends StatusesFormType,
    GeneralFormType,
    RiskLevelFormType,
    AccountCodesFormType,
    ServiceContractFormType,
    BaseActivityFormType,
    CompanyTaxResidencyFormType,
    CompanyStructureFormType,
    CompanyStateRegistrationFormType,
    CompanyRegistrationPlaceFormType,
    CompanyNameFormType,
    CompanyLicenseFormType,
    CompanyFinancingSourcesFormType,
    CompanyFinancialMonitoringFormType,
    CompanyContactsFormType,
    CompanyConstituentDocsFormType,
    CompanyConfirmStructureFormType,
    CompanyBusinessRelationshipFormType,
    CompanyActivitiesFormType,
    CompanyBankAccountsFormType,
    CompanyRegistrationAddressFormType,
    DebtsFormType,
    WithdrawalAccountsFormType,
    CompanyOrganizationalAndLegalFormFormType,
    CompanyOrderSubmissionContactsFormType,
    OpenAccountOrderFormType,
    CompanyResidentialAddressFormType,
    AdditionalDocumentsFormType,
    DocumentsFormType,
    ReasonChangeFormType {}

export const getSchema = (
  t: TFunc,
  hasBranches?: boolean
): ObjectSchema<LegalClientFormType> =>
  object()
    .concat(statusesSchema)
    .concat(generalSchema)
    .concat(accountCodesSchema)
    .concat(serviceContractSchema)
    .concat(baseActivitySchema)
    .concat(riskLevelSchema)
    .concat(companyTaxResidencySchema)
    .concat(companyRegistrationAddressSchema)
    .concat(companyResidentialAddressSchema)
    .concat(companyBankAccountsSchema)
    .concat(companyActivitiesSchema)
    .concat(companyConfirmStructureSchema)
    .concat(companyNameSchema)
    .concat(companyRegistrationPlaceSchema)
    .concat(companyStructureSchema)
    .concat(companyOrganizationalAndLegalFormSchema)
    .concat(withdrawalAccountsSchema)
    .concat(openAccountOrderSchema)
    .concat(getCompanyLicenseSchema(t))
    .concat(getCompanyStateRegistrationSchema(t))
    .concat(getCompanyFinancingSourcesSchema(t))
    .concat(getCompanyFinancialMonitoringSchema(t, hasBranches))
    .concat(getCompanyContactsSchema(t))
    .concat(getCompanyConstituentDocsSchema(t))
    .concat(getCompanyOrderSubmissionContactsSchema(t))
    .concat(getDebtsSchema(true))
    .concat(getCompanyBusinessRelationshipSchema(t))
    .concat(getAdditionalDocumentsSchema())
    .concat(documentsSchema)
    .concat(reasonChangeSchema);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientFormType>();

export type FormErrors = FormErrorsBase<LegalClientFormType>;
