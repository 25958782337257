import { SvgIcon } from 'libs/ui/Icon';
import { SvgProps } from 'libs/ui/Icon/icons/types';
import { Colors } from 'libs/ui/theme';

export const UnreadIcon = ({
  className,
  size = { width: 12, height: 2 },
  color = Colors.gray250,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="2"
      viewBox="0 0 12 2"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 1H11.5" stroke={color} strokeWidth="2" />
    </svg>
  </SvgIcon>
);
