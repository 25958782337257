import { useTranslation } from 'libs/i18n';
import { Table } from 'libs/ui';

import { useColumns } from './columns';
import { HeadItem } from './types';

interface Props {
  items: HeadItem[];
  onCellClick?: (row: HeadItem) => void;
}

export const HeadsTable = ({ items, onCellClick }: Props) => {
  const columns = useColumns();

  const { t } = useTranslation();

  return (
    <Table
      hideFooter
      columns={columns}
      noRowsLabel={t('client.legal.companyHeads.noRowsLabel')}
      rows={items}
      onCellClick={onCellClick}
    />
  );
};
