import {
  LegalClientEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { DialogStateProps } from 'libs/ui';
import { AddDialogForm } from 'modules/client/common/components';

import { LegalClientAddLegalHeadSections } from '../components';
import { useLegalClientAddLegalHead } from '../hooks';

interface Props extends DialogStateProps {
  legalClient: LegalClientEntity;
  onSuccess: (legalClientLegalHead: LegalClientLegalHeadEntity) => void;
}

export const LegalClientAddLegalHeadDialogFeature = ({
  legalClient,
  isOpen,
  onClose,
  onSuccess,
}: Props) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    validationSchema,
    submitLoading,
    onSubmit,
  } = useLegalClientAddLegalHead(legalClient, onSuccess);

  const { t } = useTranslation();

  return (
    <AddDialogForm
      initialValues={initialValues}
      isOpen={isOpen}
      submitLoading={submitLoading}
      title={t('client.legal.legalClientHead.addDialog.title')}
      validationSchema={validationSchema}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <LegalClientAddLegalHeadSections
        bodies={legalClient.bodies ?? []}
        countries={countries ?? []}
        countriesLoading={countriesLoading}
      />
    </AddDialogForm>
  );
};
