import { useTranslation } from 'libs/i18n';
import { ToggleButton, ToggleButtonProps } from 'libs/ui';

import { ClientType } from '../../types';

interface Props
  extends Pick<ToggleButtonProps<ClientType>, 'value' | 'onChange'> {}

export const ClientTypeToggleButton = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <ToggleButton
      buttons={[
        {
          label: t('client.client.toggleButtons.client'),
          value: ClientType.client,
        },
        {
          label: t('client.client.toggleButtons.representative'),
          value: ClientType.representative,
        },
      ]}
      value={value}
      onChange={onChange}
    />
  );
};
