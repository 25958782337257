// eslint-disable-next-line no-restricted-imports
import { BaseDebtsFragment } from 'api/generated/graphql';
import { BaseDebtsEntity } from 'api/types/entity/baseDebtsEntity';

import { parseAmount } from './parseAmount';

const withoutNull = <T>(v: T) => (v === null ? undefined : v);

export const parseBaseDebtsEntity = (
  debts: Omit<BaseDebtsFragment, '__typename'> | undefined
): BaseDebtsEntity | undefined =>
  debts
    ? {
        hasBankruptcyProceedings: withoutNull(debts.hasBankruptcyProceedings),

        hasDelaysOrNonFulfillmentOfObligations: withoutNull(
          debts.hasDelaysOrNonFulfillmentOfObligations
        ),

        fees: debts.fees ? parseAmount(debts.fees) : undefined,

        penalties: debts.penalties ? parseAmount(debts.penalties) : undefined,

        finesForViolation: debts.finesForViolation
          ? parseAmount(debts.finesForViolation)
          : undefined,

        taxes: debts.taxes ? parseAmount(debts.taxes) : undefined,
      }
    : undefined;
