import { subYears } from 'date-fns';
import { ObjectSchema, string, object, TypedForm, email } from 'libs/form';
import { TFunc } from 'libs/i18n';

export const registrationDateMax = subYears(new Date(), 100);

export interface ContactsFormType {
  contacts: {
    phone?: string;
    email?: string;
  };
}

export const getContactsSchema = (t: TFunc): ObjectSchema<ContactsFormType> =>
  object({
    contacts: object({
      phone: string().max(200),
      email: email(t),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ContactsFormType>();
