// eslint-disable-next-line no-restricted-imports
import {
  ContactDto,
  Query_ClientController_GetList_AllOf_1_List_Items_OrderSubmissionContacts_Items_Type as OrderSubmissionContactsApi,
} from 'api/generated/graphql';
import { OrderSubmissionContactType } from 'api/types/entity';
import { lodash } from 'helpers';

export const parseOrderSubmissionContactEntity = ({
  type,
  value,
}: {
  value: string;
  type: OrderSubmissionContactsApi;
}): {
  value: string;
  type: OrderSubmissionContactType;
} => ({
  value,
  type: OrderSubmissionContactType[type],
});

export const parseOrderSubmissionContactsEntity = (
  orderSubmissionContacts?: (ContactDto | null)[] | null
) =>
  orderSubmissionContacts
    ? lodash
        .compact(orderSubmissionContacts)
        .map(parseOrderSubmissionContactEntity)
    : undefined;
