// eslint-disable-next-line no-restricted-imports
import { ClientFragment } from 'api/generated/graphql';
import { ClientEntity, TariffType } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseCountryEntity } from '../parseCountryEntity';
import { parseDebtsEntity } from '../parseEntity/parseDebtsEntity';
import { parseIdentityDocumentEntity } from '../parseEntity/parseIdentityDocumentEntity';
import { parseKycEntity } from '../parseEntity/parseKycEntity';
import { parseManagementType } from '../parseEntity/parseManagementType';
import { parsePepConnectionEntity } from '../parseEntity/parsePepConnectionEntity';
import { parseServiceContractEntity } from '../parseEntity/parseServiceContractEntity';
import { parseManagerData } from '../parseManagerData';

import { parseClientOnboardingData } from './parseClientOnboardingData';
import { parseBankAccounts } from './parseEntity/parseBankAccounts';
import { parseBiographyEntity } from './parseEntity/parseBiographyEntity';
import { parseCategoryType } from './parseEntity/parseCategoryType';
import { parseClientAccountStatus } from './parseEntity/parseClientAccountStatus';
import { parseClientOnboardingStatus } from './parseEntity/parseClientOnboardingStatus';
import { parseClientUserEntity } from './parseEntity/parseClientUser';
import { parseEconomicSectorType } from './parseEntity/parseEconomicSectorType';
import { parseFileDocumentEntity } from './parseEntity/parseFileEntity';
import { parseFinancingSourcesEntity } from './parseEntity/parseFinancingSourcesEntity';
import { parseInformationUpdateFrequency } from './parseEntity/parseInformationUpdateFrequency';
import { parseOpenAccountOrderEntity } from './parseEntity/parseOpenAccountOrderEntity';
import { parseOrderSubmissionContactsEntity } from './parseEntity/parseOrderSubmissionContactsEntity';
import { parseRiskLevel } from './parseEntity/parseRiskLevel';
import { parseTaxResidenceEntity } from './parseEntity/parseTaxResidenceEntity';
import { parseWithdrawalAccountsEntity } from './parseEntity/parseWithdrawalAccountsEntity';

export const parseClientEntity = (client: ClientFragment): ClientEntity => ({
  id: client.id,
  versionNumber: client.version?.number,
  versionId: client.version?.id,
  onboardingStatus: parseClientOnboardingStatus(client.onboardingStatus),
  accountStatus: parseClientAccountStatus(client.accountStatus),

  firstName: client.firstName,
  lastName: client.lastName,
  middleName: client.middleName ?? undefined,
  email: client.email,
  phone: client.phoneNumber ?? undefined,
  dateOfBirth: new Date(client.dateOfBirth),
  representativeId: client.representativeId ?? undefined,
  citizenships: client.citizenships
    ? lodash.compact(client.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: client.taxResidences
    ? lodash.compact(client.taxResidences).map(parseTaxResidenceEntity)
    : undefined,

  placeOfBirth: client.placeOfBirth,
  registrationAddress: client.registrationAddress ?? undefined,
  residentialAddress: client.residentialAddress ?? undefined,

  biography: client.biography
    ? parseBiographyEntity(client.biography)
    : undefined,

  bankAccounts: parseBankAccounts(client.bankAccounts),
  tariff: client.tariff ? TariffType[client.tariff] : undefined,
  financingSources: client.financingSources
    ? parseFinancingSourcesEntity(client.financingSources)
    : undefined,

  pepConnection: parsePepConnectionEntity(client),

  debts: parseDebtsEntity(client.debts ?? undefined),

  withdrawalAccounts: parseWithdrawalAccountsEntity(client.withdrawalAccounts),

  orderSubmissionContacts: parseOrderSubmissionContactsEntity(
    client.orderSubmissionContacts
  ),

  code: client.code ?? undefined,
  centralDepositoryAccountNumber:
    client.centralDepositoryAccountNumber ?? undefined,

  centralDepositoryAccountNumberOpeningDate:
    client.centralDepositoryAccountNumberOpeningDate
      ? new Date(client.centralDepositoryAccountNumberOpeningDate)
      : undefined,

  internalAccountNumber: client.internalAccount?.number ?? undefined,
  internalAccountNumberOpeningDate: client.internalAccount?.openedAt
    ? new Date(client.internalAccount.openedAt)
    : undefined,

  manager: client.manager ? parseManagerData(client.manager) : undefined,

  riskLevel: client.riskLevel ? parseRiskLevel(client.riskLevel) : undefined,
  accreditedInvestorStatus: client.accreditedInvestorStatus ?? undefined,
  informationUpdateFrequency: client.informationUpdateFrequency
    ? parseInformationUpdateFrequency(client.informationUpdateFrequency)
    : undefined,

  user: parseClientUserEntity(client.user),

  additionalDocumentFiles: lodash
    .compact(client.additionalDocuments)
    .map(parseFileDocumentEntity),

  additionalInformationFiles: lodash
    .compact(client.additionalInformationDocuments)
    .map(parseFileDocumentEntity),

  signaturesCardFiles: lodash
    .compact(client.signaturesCardDocuments)
    .map(parseFileDocumentEntity),

  openAccountOrder: client.openAccountOrder
    ? parseOpenAccountOrderEntity(client.openAccountOrder)
    : undefined,

  kyc: parseKycEntity(client.kyc),

  category: parseCategoryType(client.category),
  economicSector: parseEconomicSectorType(client.economicSector),
  managementType: parseManagementType(client.managementType),

  isFinancialMonitoringSubject:
    client.isFinancialMonitoringSubject ?? undefined,
  isKazakhstanResident: client.isKazakhstanResident ?? undefined,
  taxBenefits: client.taxBenefits ?? undefined,

  serviceContract: parseServiceContractEntity(client.serviceContract),

  riskLevelReviewDate: client.riskLevelReviewDate
    ? new Date(client.riskLevelReviewDate)
    : undefined,
  lastInformationUpdateDate: client.lastInformationUpdateDate
    ? new Date(client.lastInformationUpdateDate)
    : undefined,
  lastOperationMonitoringResults:
    client.lastOperationMonitoringResults ?? undefined,
  usedServices: client.usedServices ?? undefined,

  selfieFiles: client.selfieDocuments
    ? lodash.compact(client.selfieDocuments).map(parseFileDocumentEntity)
    : undefined,
  proofOfResidenceFiles: client.proofOfResidenceDocuments
    ? lodash
        .compact(client.proofOfResidenceDocuments)
        .map(parseFileDocumentEntity)
    : undefined,

  identityDocument: client.identityDocument
    ? parseIdentityDocumentEntity(client.identityDocument)
    : undefined,

  onboarding: parseClientOnboardingData(client),
});
