import { useMemo } from 'react';

import { useCreateAdmin as useCreateAdminApi } from 'api/requests';
import { ErrorCode } from 'api/types/error';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { CreateAdminFormType, FormErrors } from '../forms/createAdminForm';

export const useCreateAdmin = () => {
  const { createAdmin, loading, error } = useCreateAdminApi();

  const notify = useNotify();
  const { t } = useTranslation();

  const createFormError = useMemo((): FormErrors | undefined => {
    if (error?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return {
        email: error.localizedDescription,
      };
    }

    return undefined;
  }, [error]);

  const onSubmitCreate: FormSubmit<CreateAdminFormType> = async (
    values: CreateAdminFormType
  ) => {
    const res = !!(await createAdmin({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      position: values.position,
      roleName: values.role.value,
    }));

    if (res) {
      notify.info(t('admin.createAdminDialog.userAdded'));
    }

    return res;
  };

  return {
    onSubmitCreate,
    createLoading: loading,
    createFormError,
  };
};
