import { ManagementType, TariffType } from 'api/types/entity';
import { yesOrNotSelectValidation } from 'helpers';
import { ObjectSchema, string, object, mixed } from 'libs/form';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType } from 'types/yesOrNo';

export interface BaseGeneralFormType {
  managementType?: Maybe<SelectType<ManagementType>>;
  taxBenefits?: string;
  isFinancialMonitoringSubject?: Maybe<YesOrNoSelectType>;
  isKazakhstanResident?: Maybe<YesOrNoSelectType>;
  tariff?: Maybe<SelectType<TariffType>>;
}

export const baseGeneralSchema: ObjectSchema<BaseGeneralFormType> = object({
  taxBenefits: string().max(200),

  managementType: object({
    value: mixed<ManagementType>()
      .oneOf(Object.values(ManagementType))
      .required(),
    label: string().required(),
  })
    .nullable()
    .default(null),

  tariff: object({
    value: mixed<TariffType>().oneOf(Object.values(TariffType)).required(),
    label: string().required(),
  })
    .nullable()
    .default(null),

  isFinancialMonitoringSubject: yesOrNotSelectValidation
    .nullable()
    .default(null),
  isKazakhstanResident: yesOrNotSelectValidation.nullable().default(null),
});
