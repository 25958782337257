import { ReactNode } from 'react';

import { Body, ContentHeader } from 'components';
import { useTranslation } from 'libs/i18n';
import { CircularProgress, Input, Stack, Typography } from 'libs/ui';

import { VersionList } from '../../components';
import { VersionType } from '../../types';

import {
  ChangesContentStyled,
  CloseButtonStyled,
  ContentStyled,
  LoadingContentStyled,
} from './styles';

export interface VersionViewProps<V extends VersionType> {
  content: ReactNode;
  topContent?: ReactNode;
  versionId: string;
  name: string;
  versions: V[];
  loading?: boolean;
  error?: string;
  canBack?: boolean;
  onBack?: () => void;
  onCloseVersionHistory?: () => void;
  onChangeVersion: (version: V) => void;
}

export const VersionView = <V extends VersionType>({
  content,
  topContent,
  versions,
  name,
  versionId,
  loading,
  error,
  canBack,
  onBack,
  onChangeVersion,
  onCloseVersionHistory,
}: VersionViewProps<V>) => {
  const { t } = useTranslation();

  const headerRightContent = (
    <CloseButtonStyled onClick={onCloseVersionHistory}>
      <Typography variant="bodyBold">
        {t('client.client.versions.closeVersionHistoryLabel')}
      </Typography>
    </CloseButtonStyled>
  );

  const currentVersion = versions.find(({ id }) => id === versionId);

  return (
    <Stack spacing={32}>
      <ContentHeader
        canBack={canBack}
        header={t('client.client.versions.header', { name })}
        rightContent={headerRightContent}
        onBack={onBack}
      />
      <Stack alignItems="center" direction="row" spacing={32}>
        {topContent}
      </Stack>

      <ContentStyled direction="row">
        <Body className="clients-sidebar">
          <VersionList
            versionId={versionId}
            versions={versions}
            onClickVersion={onChangeVersion}
          />
        </Body>
        <Body className="clients-content">
          {loading ? (
            <LoadingContentStyled alignItems="center" justifyContent="center">
              <CircularProgress color="success" size={40} />
            </LoadingContentStyled>
          ) : error ? (
            <Typography>{error}</Typography>
          ) : (
            <>
              <ChangesContentStyled spacing={24}>
                <Typography variant="subtitle">
                  {t('client.client.versions.changes.title')}
                </Typography>
                <Input
                  readOnly
                  label={t('client.client.versions.changes.changed.label')}
                  value={t('client.client.versions.changes.changed.value', {
                    name: currentVersion?.updatedEntity.fullName,
                  })}
                />
                <Input
                  readOnly
                  label={t('client.client.versions.changes.reason.label')}
                  value={currentVersion?.reason}
                />
              </ChangesContentStyled>
              {content}
            </>
          )}
        </Body>
      </ContentStyled>
    </Stack>
  );
};
