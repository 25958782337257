// eslint-disable-next-line no-restricted-imports
import {
  LegalClientsQueryVariables,
  QueryInput_LegalClientController_GetList_RiskLevels_Items as RiskLevelsApi,
  QueryInput_LegalClientController_GetList_OnboardingStatuses_Items as LegalClientOnboardingStatusTypeApi,
} from 'api/generated/graphql';
import { LegalClientsFilters } from 'api/types/data';
import {
  ClientOnboardingStatusType,
  RiskLevelFilterType,
} from 'api/types/entity';

type Result = Pick<
  LegalClientsQueryVariables,
  | 'search'
  | 'riskLevels'
  | 'actualCountries'
  | 'legalCountries'
  | 'onboardingStatuses'
>;

const riskLevels: Record<RiskLevelFilterType, RiskLevelsApi> = {
  [RiskLevelFilterType.Unspecified]: RiskLevelsApi.NotSpecified,
  [RiskLevelFilterType.Low]: RiskLevelsApi.Low,
  [RiskLevelFilterType.Medium]: RiskLevelsApi.Medium,
  [RiskLevelFilterType.High]: RiskLevelsApi.High,
};

const statusMap: Record<
  ClientOnboardingStatusType,
  LegalClientOnboardingStatusTypeApi
> = {
  [ClientOnboardingStatusType.FillingOut]:
    LegalClientOnboardingStatusTypeApi.FillingOut,
  [ClientOnboardingStatusType.AccountOpened]:
    LegalClientOnboardingStatusTypeApi.AccountOpened,
  [ClientOnboardingStatusType.ComplianceCheck]:
    LegalClientOnboardingStatusTypeApi.ComplianceCheck,
  [ClientOnboardingStatusType.AccountOpeningDenied]:
    LegalClientOnboardingStatusTypeApi.AccountOpeningDenied,
  [ClientOnboardingStatusType.AmlCftCheck]:
    LegalClientOnboardingStatusTypeApi.AmlCftCheck,
  [ClientOnboardingStatusType.AccountOpening]:
    LegalClientOnboardingStatusTypeApi.AccountOpening,
  [ClientOnboardingStatusType.AwaitingAccountOpening]:
    LegalClientOnboardingStatusTypeApi.AwaitingAccountOpening,
  [ClientOnboardingStatusType.AwaitingAmlCftCheck]:
    LegalClientOnboardingStatusTypeApi.AwaitingAmlCftCheck,
  [ClientOnboardingStatusType.AwaitingComplianceCheck]:
    LegalClientOnboardingStatusTypeApi.AwaitingComplianceCheck,
  [ClientOnboardingStatusType.AwaitingValidation]:
    LegalClientOnboardingStatusTypeApi.AwaitingValidation,
  [ClientOnboardingStatusType.BoardReview]:
    LegalClientOnboardingStatusTypeApi.BoardReview,
  [ClientOnboardingStatusType.ValidationCheck]:
    LegalClientOnboardingStatusTypeApi.ValidationCheck,
  [ClientOnboardingStatusType.PendingBoardReview]:
    LegalClientOnboardingStatusTypeApi.PendingBoardReview,
  [ClientOnboardingStatusType.SigningDocuments]:
    LegalClientOnboardingStatusTypeApi.SigningDocuments,
  [ClientOnboardingStatusType.AwaitingPaymentConfirmation]:
    LegalClientOnboardingStatusTypeApi.AwaitingPaymentConfirmation,
};

export const parseLegalClientFiltersParam = (
  filter?: LegalClientsFilters
): Result => ({
  search: filter?.search || undefined,
  legalCountries: filter?.registrationCountries,
  actualCountries: filter?.locationCountries,
  riskLevels: filter?.riskLevels?.length
    ? filter.riskLevels.map((r) => riskLevels[r])
    : undefined,
  onboardingStatuses: filter?.statuses?.length
    ? filter?.statuses?.map((s) => statusMap[s])
    : undefined,
});
