import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/client/common/components/AddAndRemoveButtons';
import {
  initialOrderSubmissionContactType,
  useFormContext,
} from 'modules/client/common/forms/orderSubmissionContactsForm';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setValues, readOnly } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    values.orderSubmissionContacts.push({
      type: initialOrderSubmissionContactType,
      value: '',
    });
    setValues(values, false);
  };

  const onRemove = (index: number) => {
    values.orderSubmissionContacts.splice(index, 1);
    setValues(values, false);
  };

  if (readOnly) {
    return null;
  }

  return (
    <AddAndRemoveButtons
      addLabel={t('client.client.orderSubmissionContacts.addContact')}
      counts={values.orderSubmissionContacts.length}
      index={index}
      removeLabel={t('client.client.orderSubmissionContacts.removeContact')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
