import {
  BrokerRelationshipPurposeType,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { FinancingSourcesParam } from 'api/types/params';

import { FinancingSourcesFormType } from '../../forms/financingSourcesForm';

export const getFinancingSourcesParam = ({
  financingSources,
}: FinancingSourcesFormType): FinancingSourcesParam => {
  const plannedOperationTypes = financingSources.plannedOperationTypes.map(
    (v) => v.value
  );

  const incomeSources = financingSources.incomeSources.map((v) => v.value);

  const brokerRelationshipPurpose =
    financingSources.brokerRelationshipPurpose.map((v) => v.value);

  const data: FinancingSourcesParam = {
    incomeAmount: financingSources.incomeAmount!,
    incomeSourceFileIds: financingSources.incomeSourceFile.map((f) => f.id!),
    plannedAssetAmount: financingSources.plannedAssetAmount!,
    incomeSources,
    brokerRelationshipPurpose,
    plannedOperationTypes,
    otherPlannedOperationType: plannedOperationTypes.includes(
      PlannedOperationType.Other
    )
      ? financingSources.otherPlannedOperationType
      : undefined,

    otherBrokerRelationshipPurpose: brokerRelationshipPurpose.includes(
      BrokerRelationshipPurposeType.Other
    )
      ? financingSources.otherBrokerRelationshipPurpose
      : undefined,

    otherIncomeSource: incomeSources.includes(IncomeSourcesType.Other)
      ? financingSources.otherIncomeSource
      : undefined,
  };
  return data;
};
