import { useAdminResetPassword } from 'api/requests';
import { PermissionType } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

export const useResetPassword = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { resetPassword: resetPasswordApi, error } = useAdminResetPassword();
  const { checkPermission } = usePermissions();

  const resetPassword = async (id: string) => {
    const permission = checkPermission(PermissionType.AdminResetPassword, {
      showNotify: true,
    });

    if (permission && (await resetPasswordApi(id))) {
      notify.info(t('admin.admin.resetPasswordSuccess'));
    }
  };

  useServerErrorNotify(error);

  return { resetPassword, error };
};
