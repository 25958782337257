// eslint-disable-next-line no-restricted-imports
import { MutationInput_ClientController_UpdateById_Input_ManagementType as ManagementTypeApi1 } from 'api/generated/graphql';
import { ManagementType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type ManagementTypeApi = ManagementTypeApi1;

const managementType: Record<ManagementType, ManagementTypeApi> = {
  [ManagementTypeApi1.Underwriting]: ManagementTypeApi1.Underwriting,
  [ManagementType.BondholderRepresentative]:
    ManagementTypeApi1.BondholderRepresentative,
  [ManagementType.BrokerageOnFuturesMarket]:
    ManagementTypeApi1.BrokerageOnFuturesMarket,
  [ManagementType.BrokerageWithNominalHolding]:
    ManagementTypeApi1.BrokerageWithNominalHolding,
  [ManagementType.BrokerageWithoutNominalHolding]:
    ManagementTypeApi1.BrokerageWithoutNominalHolding,
  [ManagementType.FinancialConsulting]: ManagementTypeApi1.FinancialConsulting,
  [ManagementType.MarketMaker]: ManagementTypeApi1.MarketMaker,
  [ManagementType.NominalHolding]: ManagementTypeApi1.NominalHolding,
  [ManagementType.None]: ManagementTypeApi1.None,
  [ManagementType.OtherServices]: ManagementTypeApi1.OtherServices,
  [ManagementType.TrustManagement]: ManagementTypeApi1.TrustManagement,
};

export const parseManagementTypeParam = (
  data: Maybe<ManagementType>
): ManagementTypeApi | null => (data ? managementType[data] : null);
