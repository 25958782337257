/**
 * Regular expression to match emojis.
 */
const emojiRegexp = new RegExp(
  '[\\u{1f300}-\\u{1f5ff}\\u{1f900}-\\u{1f9ff}\\u{1f600}-\\u{1f64f}\\u{1f680}-\\u{1f6ff}\\u{2600}-\\u{26ff}\\u{2700}-\\u{27bf}\\u{1f1e6}-\\u{1f1ff}\\u{1f191}-\\u{1f251}\\u{1f004}\\u{1f0cf}\\u{1f170}-\\u{1f171}\\u{1f17e}-\\u{1f17f}\\u{1f18e}\\u{3030}\\u{2b50}\\u{2b55}\\u{2934}-\\u{2935}\\u{2b05}-\\u{2b07}\\u{2b1b}-\\u{2b1c}\\u{3297}\\u{3299}\\u{303d}\\u{00a9}\\u{00ae}\\u{2122}\\u{23f3}\\u{24c2}\\u{23e9}-\\u{23ef}\\u{25b6}\\u{23f8}-\\u{23fa}]',
  'gu'
);

/**
 * Regular expression to match strings containing only letters, spaces, and dashes.
 */
const lettersWithSpaceAndDashReg = /^[\p{Lu}\p{Ll}\s-]*$/u;

/**
 * Regular expression to match special characters.
 */
const specialCharsRegexp = new RegExp('[&=\\+<>\\(\\)$%#\\*\\^@]', '');

/**
 * Checks if the input string doesn't contain special characters.
 */
export const doesntContainSpecialChars = (val?: string) =>
  !val || !specialCharsRegexp.exec(val);

/**
 * Checks if the input string doesn't contain emojis.
 */
export const doesntContainEmojis = (val?: string) =>
  !emojiRegexp.test(val || '');

/**
 * Replaces emojis in the input string with an empty string.
 */
export const replaceContainEmojis = (val?: string) =>
  val?.replace(emojiRegexp, '');

/**
 * Checks if the input string contains only letters, spaces, and dashes.
 */
export const onlyContainsLettersWithDashAndSpace = (val?: string) =>
  !val || !!lettersWithSpaceAndDashReg.exec(val);

/**
 * Checks if the input string is a valid combination of letters, spaces, and dashes, without special characters or emojis.
 *
 */
export const isValidStringAndNumber = (val?: string) =>
  doesntContainEmojis(val) && doesntContainSpecialChars(val);

/**
 * Checks if the input string is a valid name, which includes only letters, spaces, and dashes, without special characters or emojis.
 */
export const isValidName = (val?: string) =>
  isValidStringAndNumber(val) && onlyContainsLettersWithDashAndSpace(val);
