import { groupByDays, lodash } from 'helpers';
import { useConvertDateToUserTimezone } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Accordion, Stack, Typography } from 'libs/ui';

import { VersionType } from '../../types';

import { Version } from './Version/Version';

interface Props<V extends VersionType> {
  versionId: string;
  versions: V[];
  onClickVersion: (version: V) => void;
}

export const VersionList = <V extends VersionType>({
  versions,
  versionId,
  onClickVersion,
}: Props<V>) => {
  const { t, language } = useTranslation();

  const isSelectedVersion = (version: V) => version.id === versionId;

  const { convertDateToUserTimezone } = useConvertDateToUserTimezone();

  const versionsByDays = groupByDays(
    lodash
      .sortBy(versions, ({ createdAt }) => createdAt)
      .reverse()
      .map((v) => ({
        ...v,
        createdAt: convertDateToUserTimezone(v.createdAt),
      })),
    language
  );

  return (
    <Stack spacing={24}>
      <Typography variant="subtitle">
        {t('client.client.versions.title')}
      </Typography>
      <Stack>
        {Object.entries(versionsByDays).map(([day, versions], dayIndex) => {
          const dayHasSelectedVersion = versions.some(isSelectedVersion);
          return (
            <Accordion
              key={day + dayHasSelectedVersion}
              content={
                <Stack spacing={12}>
                  {versions.map((version, index) => (
                    <Version
                      key={version.id}
                      isCurrentVersion={index === 0 && dayIndex === 0}
                      isSelectedVersion={isSelectedVersion}
                      version={version}
                      onClickVersion={onClickVersion}
                    />
                  ))}
                </Stack>
              }
              defaultExpanded={dayHasSelectedVersion}
              label={<Typography variant="bodyBold">{day}</Typography>}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
