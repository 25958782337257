import { CountryEntity } from 'api/types/entity';
import { TypedForm, ObjectSchema, string, object, array } from 'libs/form';
import { Maybe } from 'types/maybe';

export interface CompanyTaxResidencyFormTaxValue {
  taxNumber: string;
  country: Maybe<CountryEntity>;
}

export interface CompanyTaxResidencyFormType {
  companyTaxResidences: CompanyTaxResidencyFormTaxValue[];
}

export const companyTaxResidencyObject = object({
  taxNumber: string().required().max(50),
  country: object({
    id: string().required(),
    name: string().required(),
  }).required(),
});

export const companyTaxResidencySchema: ObjectSchema<CompanyTaxResidencyFormType> =
  object({
    companyTaxResidences: array()
      .of(companyTaxResidencyObject.required())
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyTaxResidencyFormType>();
