import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from 'modules/client/common/forms/additionalDocumentsForm';
import {
  DocumentsFormType,
  documentsSchema,
} from 'modules/client/common/forms/documentsForm';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { ActivityFormType, activitySchema } from './activityForm';
import { BiographyFormType, biographySchema } from './biographyForm';
import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import { CompanyNameFormType, companyNameSchema } from './companyNameForm';
import {
  CompanyRegistrationAddressFormType,
  companyRegistrationAddressSchema,
} from './companyRegistrationAddressForm';
import {
  CompanyResidentialAddressFormType,
  companyResidentialAddressSchema,
} from './companyResidentialAddressForm';
import {
  CompanyStateRegistrationFormType,
  getCompanyStateRegistrationSchema,
} from './companyStateRegistrationForm';
import {
  CompanyTaxResidencyFormType,
  companyTaxResidencySchema,
} from './companyTaxResidencyForm';
import { ContactsFormType, getContactsSchema } from './contactsForm';
import {
  IdentityDocumentFormType,
  identityDocumentSchema,
} from './identityDocumentForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import { PlaceOfBirthFormType, placeOfBirthSchema } from './placeOfBirthForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import {
  ResidentialAddressFormType,
  residentialAddressSchema,
} from './residentialAddressForm';
import {
  RoleInCompanyFormType,
  getRoleInCompanySchema,
} from './roleInCompanyForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';

export interface LegalRepresentativeFormType
  extends ActivityFormType,
    PersonalDataFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    CitizenshipFormType,
    PlaceOfBirthFormType,
    BiographyFormType,
    TaxResidencyFormType,
    PepConnectionFormType,
    ContactsFormType,
    RoleInCompanyFormType,
    CompanyNameFormType,
    CompanyRegistrationAddressFormType,
    CompanyResidentialAddressFormType,
    CompanyTaxResidencyFormType,
    CompanyStateRegistrationFormType,
    IdentityDocumentFormType,
    AdditionalDocumentsFormType,
    DocumentsFormType,
    ReasonChangeFormType {}

export const getSchema = (
  t: TFunc
): ObjectSchema<LegalRepresentativeFormType> =>
  object()
    .concat(activitySchema)
    .concat(registrationAddressSchema)
    .concat(residentialAddressSchema)
    .concat(placeOfBirthSchema)
    .concat(citizenshipsSchema)
    .concat(taxResidencesSchema)
    .concat(biographySchema)
    .concat(companyNameSchema)
    .concat(companyRegistrationAddressSchema)
    .concat(companyResidentialAddressSchema)
    .concat(companyTaxResidencySchema)
    .concat(identityDocumentSchema)
    .concat(getRoleInCompanySchema(t))
    .concat(getContactsSchema(t))
    .concat(getPepConnectionSchema(t))
    .concat(getPersonalDataSchema(t))
    .concat(getCompanyStateRegistrationSchema(t))
    .concat(getAdditionalDocumentsSchema())
    .concat(reasonChangeSchema)
    .concat(documentsSchema);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalRepresentativeFormType>();

export type FormErrors = FormErrorsBase<LegalRepresentativeFormType>;
