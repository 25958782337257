import { useTranslation } from 'libs/i18n';
import { useFormContext } from 'modules/client/client/forms/withdrawalAccountsForm';
import { AddAndRemoveButtons } from 'modules/client/common/components/AddAndRemoveButtons';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setValues, readOnly } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    values.withdrawalAccounts.push({
      bankCountry: null,
      swiftCode: '',
      bankAddress: '',
      bankName: '',
      checkingAccount: '',
      correspondentAccount: '',
      currency: null,
      ownerConfirmationFiles: [],
    });
    setValues(values, false);
  };

  const onRemove = (index: number) => {
    values.withdrawalAccounts.splice(index, 1);
    setValues(values, false);
  };

  if (readOnly) return null;

  return (
    <AddAndRemoveButtons
      addLabel={t('client.client.withdrawalAccounts.addButton')}
      counts={values.withdrawalAccounts.length}
      index={index}
      removeLabel={t('client.client.withdrawalAccounts.removeButton')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
