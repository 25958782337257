import { MaritalStatusType, OccupationType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import {
  Field,
  useFormContext,
} from 'modules/client/common/forms/biographyForm';
import {
  MaritalStatusTranslationPath,
  OccupationTranslationPath,
} from 'modules/client/common/helpers';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isCompany =
    values.biography?.occupation?.value === OccupationType.businessOwner ||
    values.biography?.occupation?.value === OccupationType.employee;

  const isEntrepreneur =
    values.biography?.occupation?.value === OccupationType.entrepreneur;

  return (
    <>
      <Field.Autocomplete
        label={t('client.client.biography.maritalStatus.label')}
        name="biography.maritalStatus"
        optionLabelKey="label"
        options={Object.values(MaritalStatusType).map((v) => ({
          label: t(MaritalStatusTranslationPath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('client.client.biography.maritalStatus.placeholder')}
        variant="select"
      />

      <Field.Autocomplete
        label={t('client.client.biography.occupation.label')}
        name="biography.occupation"
        optionLabelKey="label"
        options={Object.values(OccupationType).map((v) => ({
          label: t(OccupationTranslationPath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('client.client.biography.occupation.placeholder')}
        variant="select"
      />

      {isCompany && (
        <>
          <Field.Text
            label={t('client.client.biography.companyName.label')}
            name="biography.companyName"
            placeholder={t('client.client.biography.companyName.placeholder')}
          />
          <Field.Text
            label={t('client.client.biography.workAddress.label')}
            name="biography.workAddress"
            placeholder={t('client.client.biography.workAddress.placeholder')}
          />
          <Field.Text
            label={t('client.client.biography.position.label')}
            name="biography.position"
            placeholder={t('client.client.biography.position.placeholder')}
          />
        </>
      )}

      {isEntrepreneur && (
        <>
          <Field.Text
            label={t('client.client.biography.entrepreneurName.label')}
            name="biography.entrepreneurName"
            placeholder={t(
              'client.client.biography.entrepreneurName.placeholder'
            )}
          />
          <Field.Text
            label={t('client.client.biography.registrationNumber.label')}
            name="biography.registrationNumber"
            placeholder={t(
              'client.client.biography.registrationNumber.placeholder'
            )}
          />
          <Field.DatePicker
            label={t('client.client.biography.registrationDate.label')}
            name="biography.registrationDate"
            placeholder={t(
              'client.client.biography.registrationDate.placeholder'
            )}
          />
          <Field.Text
            label={t('client.client.biography.registrationPlace.label')}
            name="biography.registrationPlace"
            placeholder={t(
              'client.client.biography.registrationPlace.placeholder'
            )}
          />
          <Field.Text
            label={t('client.client.biography.typeOfServices.label')}
            name="biography.typeOfServices"
            placeholder={t(
              'client.client.biography.typeOfServices.placeholder'
            )}
          />
        </>
      )}
    </>
  );
};
