import { NotificationChangeOnboardingStatusEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Pressed, Stack, Typography } from 'libs/ui';

import { ContentStyled } from './styles';

export interface NotificationChangeOnboardingStatusProps {
  notification: NotificationChangeOnboardingStatusEntity;
  onClickClientName?: () => void;
}

export const NotificationChangeOnboardingStatus = ({
  notification,
  onClickClientName,
}: NotificationChangeOnboardingStatusProps) => {
  const { t } = useTranslation();

  return (
    <ContentStyled>
      <Stack>
        <Typography>
          <Typography variant="bodyBold">
            {t(
              'components.dialogNotifications.templates.changeOnboardingStatus.clientLabel'
            )}{' '}
          </Typography>
          <Pressed onClick={onClickClientName}>
            <Typography className="notificationChangeOnboardingStatus-clientName">
              {notification.payload.clientFullName}
            </Typography>
          </Pressed>
        </Typography>
        <Typography>
          <Typography variant="bodyBold">
            {t(
              'components.dialogNotifications.templates.changeOnboardingStatus.statusLabel'
            )}{' '}
          </Typography>
          <Typography className="notificationChangeOnboardingStatus-prevStatus">
            {t(`client.status.${notification.payload.prevOnboardingStatus}`)}
          </Typography>
          <Typography> → </Typography>
          <Typography>
            {t(`client.status.${notification.payload.currentOnboardingStatus}`)}
          </Typography>
        </Typography>
      </Stack>
    </ContentStyled>
  );
};
