import { ErrorLayout, LoadingLayout } from 'components';

import { useClient, useClientStatus } from '../hooks';
import { ClientView } from '../views/ClientView';

export const ClientContainer = () => {
  const {
    client,
    loading,
    countries,
    countriesLoading,
    error,
    hasRepresentative,
    initialValues,
    submitLoading,
    formError,
    managers,
    managersLoading,
    breadcrumbsTitles,
    unlockClient,
    lockClientIfPossible,
    onSubmit,
    onChangeClientType,
    onShowVersionHistory,
  } = useClient();

  const {
    loading: loadingStatuses,
    statuses: statusesForChange,
    submitLoading: submitLoadingStatus,
    visibleOnboardingStatuses,
    fetchAvailableStatuses: fetchStatusesForChange,
    onChangeOnboardingStatus,
    onChangeAccountStatus,
  } = useClientStatus(client);

  if (error || (!client && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !client || !initialValues) {
    return <LoadingLayout />;
  }

  return (
    <ClientView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      currentAccountStatus={client.accountStatus}
      currentOnboardingStatus={client.onboardingStatus}
      fetchStatusesForChange={fetchStatusesForChange}
      hasRepresentative={hasRepresentative}
      initialErrors={formError}
      initialValues={initialValues}
      kycData={client.kyc}
      loadingStatuses={loadingStatuses}
      lockClientIfPossible={lockClientIfPossible}
      managers={managers}
      managersLoading={managersLoading}
      onboardingData={client.onboarding}
      statusesForChange={statusesForChange}
      submitLoading={submitLoading}
      submitLoadingStatus={submitLoadingStatus}
      unlockClient={unlockClient}
      visibleOnboardingStatuses={visibleOnboardingStatuses}
      onChangeAccountStatus={onChangeAccountStatus}
      onChangeClientType={onChangeClientType}
      onChangeOnboardingStatus={onChangeOnboardingStatus}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
