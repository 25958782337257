import { OrganizationalAndLegalFormEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';

import { CompanyOrganizationalAndLegalFormFormType } from '../../forms/companyOrganizationalAndLegalFormForm';

export const parseOrganizationalAndLegalFormValue = (
  t: TFunc,
  data: OrganizationalAndLegalFormEntity | undefined
) => {
  const values:
    | CompanyOrganizationalAndLegalFormFormType['organizationalAndLegalForm']
    | undefined = data
    ? {
        organizationalAndLegalForm: data.organizationalAndLegalForm,

        organizationType: {
          label: t(
            `client.legal.organizationalAndLegalForm.organizationType.${data.organizationType}`
          ),
          value: data.organizationType,
        },

        isFinancialInstitution: yesOrNotOption(t, data.isFinancialInstitution),

        isUnincorporatedStructure: yesOrNotOption(
          t,
          data.isUnincorporatedStructure
        ),
      }
    : ({} as CompanyOrganizationalAndLegalFormFormType['organizationalAndLegalForm']);

  return values;
};
