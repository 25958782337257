import { FC, useMemo } from 'react';

import {
  ManagerData,
  ClientOnboardingData,
  OnboardingDataStatus,
} from 'api/types/data';
import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
  CountryEntity,
  KycEntity,
} from 'api/types/entity';
import { parseFullName } from 'helpers';
import { useFormErrorFocus } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { ClientType } from 'modules/client/client/types';
import {
  FormContentWrapper,
  ClientTypeToggleButton,
} from 'modules/client/common/components';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { ActionProps } from '../../../../common/components/FormContentWrapper/types';
import { renderClientStatus } from '../../../../common/helpers';
import { useFormContext } from '../../../forms/clientForm';
import { ClientSections } from '../../ClientSections';

export interface FormContentProps extends LockAndUnlockReadOnlyProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  hasRepresentative: boolean;
  submitLoading?: boolean;
  kycData?: KycEntity;
  onboardingData?: ClientOnboardingData;
  managers: ManagerData[];
  managersLoading?: boolean;
  currentOnboardingStatus: ClientOnboardingStatusType;
  currentAccountStatus: ClientAccountStatusType | null;
  visibleOnboardingStatuses?: OnboardingDataStatus[];
  onChangeClientType: (v: ClientType) => void;
  onChangeOnboardingStatus?: () => void;
  onChangeAccountStatus?: () => void;
  onShowVersionHistory?: () => void;
}

export const FormContent: FC<FormContentProps> = ({
  countries,
  countriesLoading,
  hasRepresentative,
  submitLoading,
  kycData,
  onboardingData,
  managers,
  managersLoading,
  onChangeClientType,
  onChangeOnboardingStatus,
  onChangeAccountStatus,
  lockClientIfPossible,
  unlockClient,
  currentOnboardingStatus,
  visibleOnboardingStatuses,
  currentAccountStatus,
  onShowVersionHistory,
}) => {
  const { resetForm, values, dirty, setReadOnly } = useFormContext();
  const { t } = useTranslation();

  const fullName = values ? parseFullName(values.personalData) : '';

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  useFormErrorFocus();

  const topContent = useMemo(
    () =>
      renderClientStatus(
        {
          onboarding: onboardingData,
          status: currentAccountStatus || currentOnboardingStatus,
        },
        t,
        { size: 'smedium', uppercase: true }
      ),
    [currentAccountStatus, currentOnboardingStatus, onboardingData, t]
  );

  const actionMenuProps: ActionProps | undefined = useMemo(() => {
    if (currentOnboardingStatus === ClientOnboardingStatusType.AccountOpened) {
      if (
        currentAccountStatus === ClientAccountStatusType.Closed ||
        !onChangeAccountStatus
      ) {
        return undefined;
      }

      return {
        showChangeAccountStatusAction: true,
        onChangeAccountStatus,
      };
    }

    if (!onChangeOnboardingStatus) {
      return undefined;
    }

    return {
      showChangeOnboardingStatusAction: true,
      onChangeOnboardingStatus,
    };
  }, [
    currentAccountStatus,
    currentOnboardingStatus,
    onChangeAccountStatus,
    onChangeOnboardingStatus,
  ]);

  return (
    <FormContentWrapper
      actionMenuProps={actionMenuProps}
      content={
        <ClientSections
          countries={countries}
          countriesLoading={countriesLoading}
          kycData={kycData}
          managers={managers}
          managersLoading={managersLoading}
          onboardingData={onboardingData}
          visibleOnboardingStatuses={visibleOnboardingStatuses}
        />
      }
      dirty={dirty}
      fullName={fullName}
      resetForm={resetForm}
      shownTabBar={hasRepresentative}
      submitLoading={submitLoading}
      TabBarComponent={ClientTypeToggleButton}
      tabBarValue={ClientType.client}
      topContent={topContent}
      onChangeTabBarValue={onChangeClientType}
      onEdit={onDisableReadOnly}
      onEnableReadOnly={onEnableReadOnly}
      onShowVersionHistory={onShowVersionHistory}
    />
  );
};
