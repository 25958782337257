import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientBranchMutation } from 'api/generated/graphql';
import {
  parseLegalClientBranchEntity,
  parseUpdateLegalClientBranchParams,
  useError,
} from 'api/helpers';
import { UpdateLegalClientBranchParams } from 'api/types/params';

export const useUpdateLegalClientBranch = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientBranchMutation();

  const error = useError(apolloError);

  return {
    legalClientBranch: data?.updateLegalClientBranch
      ? parseLegalClientBranchEntity(data.updateLegalClientBranch)
      : undefined,
    loading,
    error,
    updateLegalClientBranch: useCallback(
      async (id: string, params: UpdateLegalClientBranchParams) => {
        const input = parseUpdateLegalClientBranchParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateLegalClientBranch
          ? parseLegalClientBranchEntity(res.data?.updateLegalClientBranch)
          : undefined;
      },
      [handle]
    ),
  };
};
