// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_ClientController_GetList_SortOrder,
  useClientsQuery,
} from 'api/generated/graphql';
import {
  parseClientFiltersParam,
  parseClientsListItemData,
  useError,
} from 'api/helpers';
import {
  ClientsFilters,
  ClientsListItemData,
  ListDataQueryFunction,
} from 'api/types/data';
import { ListData } from 'api/types/data/listData';
import { SortDirection } from 'api/types/params/listParams';
import { lodash } from 'helpers';

type ClientsSortField = 'lastName' | 'onboardingStatus';

const sortMap: Record<
  NonNullable<SortDirection>,
  QueryInput_ClientController_GetList_SortOrder
> = {
  desc: QueryInput_ClientController_GetList_SortOrder.Desc,
  asc: QueryInput_ClientController_GetList_SortOrder.Asc,
};

export const useClients: ListDataQueryFunction<
  ClientsListItemData,
  ClientsSortField,
  ClientsFilters
> = (pagination, sort?, filter?) => {
  const {
    data,
    loading,
    error: apolloError,
  } = useClientsQuery({
    variables: {
      limit: pagination.limit,
      page: pagination.page + 1,
      sortBy: sort?.field ? sort?.field : undefined,
      sortOrder: sort?.sort ? sortMap[sort?.sort] : sortMap.desc,
      ...parseClientFiltersParam(filter),
    },
  });

  const error = useError(apolloError);

  const listData: ListData<ClientsListItemData> | undefined = data?.clients
    ? {
        total: data?.clients?.total,
        list: parseClientsListItemData(lodash.compact(data?.clients.list)),
      }
    : undefined;

  return {
    listData,
    loading,
    error,
  };
};
