import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateClientRepresentativeMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseRepresentativeClientEntity } from 'api/helpers/client/parseRepresentativeClientEntity';
import { parseUpdateRepresentativeClientParams } from 'api/helpers/client/parseUpdateRepresentativeClientParams';
import { UpdateRepresentativeClientParams } from 'api/types/params';

export const useUpdateRepresentativeClient = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateClientRepresentativeMutation();

  const error = useError(apolloError);

  return {
    representativeClient: data?.updateClient
      ? parseRepresentativeClientEntity(data.updateClient)
      : undefined,
    loading,
    error,
    updateClient: useCallback(
      async (id: string, params: UpdateRepresentativeClientParams) => {
        const input = parseUpdateRepresentativeClientParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateClient
          ? parseRepresentativeClientEntity(res.data?.updateClient)
          : undefined;
      },
      [handle]
    ),
  };
};
