import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';

export const WrapperStyled = styled(Stack)(() => ({
  paddingLeft: '32px',
  paddingRight: '4px',
}));

export const HintWrapperStyled = styled('div')(() => ({
  paddingTop: '3px',
}));

export const ContentWrapper = styled(Stack)<{ clickable?: boolean }>(
  ({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'default',
    pointerEvents: clickable ? 'auto' : 'none',
  })
);
