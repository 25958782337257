// eslint-disable-next-line no-restricted-imports
import { LegalClientLegalHeadFirstHeadForLegalClientLegalHeadFragment } from 'api/generated/graphql';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';

export const parseLegalClientFirstHeadItemEntity = (
  data: LegalClientLegalHeadFirstHeadForLegalClientLegalHeadFragment
): LegalClientInnerItemEntity => ({
  id: data.id,
  name: `${data.lastName} ${data.firstName} ${data.middleName ?? ''}`,
  registrationCity: data.registrationAddress?.country.name,
  location: data.residentialAddress?.country.name,
  email: data.email,
  createdAt: new Date(data.createdAt),
});
