import { useCallback, useMemo, useState } from 'react';

import { useTimezones, useUserUpdateTimezone } from 'api/requests';
import { TimezoneEntity } from 'api/types/entity';
import { secondsToTime } from 'helpers';
import { useUser } from 'libs/providers';

const getLabel = (v: TimezoneEntity) => {
  const { hoursString, minutesString } = secondsToTime(Math.abs(v.gmtOffset));
  const sign = v.gmtOffset >= 0 ? '+' : '-';

  return `(GMT ${sign}${hoursString}:${minutesString}) ${v.name}`;
};

export const useUserTimezoneSettings = (onClose: () => void) => {
  const { user, reUser } = useUser();
  const { timezones, loading } = useTimezones();
  const { loading: updateLoading, updateTimezone } = useUserUpdateTimezone();

  const settingTimezone = user?.settings.timezone;

  const userTimezone = useMemo(
    () =>
      settingTimezone
        ? {
            name: settingTimezone.name,
            gmtOffset: settingTimezone.gmtOffset,
            label: getLabel(settingTimezone),
          }
        : null,
    [settingTimezone]
  );

  const [timezone, setTimezone] = useState(userTimezone);
  const [auto, setAuto] = useState(!settingTimezone);

  const timezonesOptions = useMemo(
    () =>
      timezones?.map((v) => ({
        name: v.name,
        gmtOffset: v.gmtOffset,
        label: getLabel(v),
      })),
    [timezones]
  );

  const resetTimezone = useCallback(() => {
    setTimezone(userTimezone);
    setAuto(!settingTimezone);
  }, [settingTimezone, userTimezone]);

  const onUpdateTimezone = async () => {
    await updateTimezone(!auto ? timezone || undefined : undefined);
    await reUser();
    onClose();
  };

  return {
    timezones,
    timezonesOptions,
    timezone,
    loading,
    auto,
    updateLoading,
    setAuto,
    setTimezone,
    resetTimezone,
    onUpdateTimezone,
  };
};
