import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useTimezonesQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { TimezoneEntity } from 'api/types/entity';
import { lodash } from 'helpers';

export const useTimezones = () => {
  const { data, loading, error: apolloError } = useTimezonesQuery();

  const error = useError(apolloError);

  const timezones: TimezoneEntity[] | undefined = useMemo(
    () =>
      data?.timezones
        ? lodash
            .compact(data.timezones)
            .map((v) => ({ name: v.name, gmtOffset: v.gmtOffset }))
        : undefined,
    [data]
  );

  return {
    timezones,
    loading,
    error,
  };
};
