import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/client/common/helpers';

import { Field, useFormContext } from '../../../../forms/powerOfAttorneyForm';

export const FormContent = () => {
  const { t } = useTranslation();
  const { readOnly } = useFormContext();

  return (
    <Stack spacing={24}>
      <Field.Text
        label={t('client.client.powerOfAttorney.documentNumber.label')}
        name="powerOfAttorney.documentNumber"
        placeholder={t(
          'client.client.powerOfAttorney.documentNumber.placeholder'
        )}
      />
      <Field.DatePicker
        label={t('client.client.powerOfAttorney.registrationDate.label')}
        maxDate={getToday()}
        name="powerOfAttorney.dateOfIssue"
        placeholder={t(
          'client.client.powerOfAttorney.registrationDate.placeholder'
        )}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('client.client.powerOfAttorney.expirationDate.label')}
        minDate={getTodayWithResetHours()}
        name="powerOfAttorney.dateOfExpiration"
        placeholder={t(
          'client.client.powerOfAttorney.expirationDate.placeholder'
        )}
      />

      <Field.Text
        label={t('client.client.powerOfAttorney.notaryFullName.label')}
        name="powerOfAttorney.notaryFullName"
        placeholder={t(
          'client.client.powerOfAttorney.notaryFullName.placeholder'
        )}
      />

      <Field.Text
        label={t('client.client.powerOfAttorney.notaryLicenseInfo.label')}
        name="powerOfAttorney.notaryLicenseInfo"
        placeholder={t(
          'client.client.powerOfAttorney.notaryLicenseInfo.placeholder'
        )}
      />

      <DocumentsTableFeature name="powerOfAttorney.files" readOnly={readOnly} />
    </Stack>
  );
};
