import { LegalClientEntity } from 'api/types/entity';
import { formatToISODate, yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';
import { parseAccountCodesInitialValue } from 'modules/client/client/helpers/getClientInitialValue/parseAccountCodesInitialValue';
import {
  parseAddressValue,
  parseCompanyStateRegistrationValue,
  parseDebtsInitialValue,
  parseRiskLevelInitialValue,
  parseServiceContractInitialValue,
  parseStatusesInitialValue,
  parseWithdrawalAccountsInitialValue,
} from 'modules/client/common/helpers';

import { CompanyConstituentDocsFormType } from '../../forms/companyConstituentDocs';
import { LegalClientFormType } from '../../forms/legalClientForm';

import { parseCompanyBusinessRrelationshipValue } from './parseCompanyBusinessRrelationshipValue';
import { parseCompanyContactsValue } from './parseCompanyContactsValue';
import { parseCompanyLicense } from './parseCompanyLicense';
import { parseFinancialMonitoringValue } from './parseFinancialMonitoringValue';
import { parseFinancingSourcesValue } from './parseFinancingSourcesValue';
import { parseGeneralValue } from './parseGeneralValue';
import { parseLegalClientDocumentsFiles } from './parseLegalClientDocumentsFiles';
import { parseOrderSubmissionContactsInitialValue } from './parseOrderSubmissionContactsInitialValue';
import { parseOrganizationalAndLegalFormValue } from './parseOrganizationalAndLegalFormValue';

export const getLegalClientInitialValue = (
  t: TFunc,
  client: LegalClientEntity
): LegalClientFormType => ({
  reasonChange: { reason: '' },
  companyTaxResidences: client.taxResidences?.map((v) => ({
    country: v.country || null,
    taxNumber: v.taxId,
  })) || [{ country: null, taxNumber: '' }],

  companyRegistrationAddress: parseAddressValue(client.legalAddress),
  companyResidentialAddress: parseAddressValue(client.actualAddress),

  bankAccounts: client.bankAccounts?.map((v) => ({
    country: v.country || null,
    swiftCode: v.swiftCode,
    hasPermanentManagementInCountry:
      yesOrNotOption(t, v.hasPermanentManagementInCountry) ?? null,
    onTerritoryWithNoAML_CFT:
      yesOrNotOption(t, v.onTerritoryWithNoAML_CFT) ?? null,
  })) || [
    {
      country: null,
      swiftCode: '',
      hasPermanentManagementInCountry: null,
      onTerritoryWithNoAML_CFT: null,
    },
  ],

  financingSources: parseFinancingSourcesValue(t, client.financingSource),

  debts: parseDebtsInitialValue(client.debts, t),

  withdrawalAccounts: parseWithdrawalAccountsInitialValue(
    client.withdrawalAccounts
  ),

  orderSubmissionContacts: parseOrderSubmissionContactsInitialValue(
    t,
    client.orderSubmissionContacts
  ),

  activityTypes: client.activityTypes ?? [{ name: '' }],

  businessRelationship: parseCompanyBusinessRrelationshipValue(
    t,
    client.businessRelationship
  ),

  companyContacts: parseCompanyContactsValue(t, client.contacts),

  companyLicense: parseCompanyLicense(t, client),

  companyName: {
    fullName: client.fullName ?? '',
    shortName: client.shortName,
    fullNameEng: client.fullNameEng,
    shortNameEng: client.shortNameEng,
  },

  companyStructure: client.bodies ?? [],

  confirmStructure: { files: client.ownershipStructureFiles },

  constituentDocs:
    client.constitutionalDocument as CompanyConstituentDocsFormType['constituentDocs'],

  financialMonitoring: parseFinancialMonitoringValue(
    t,
    client.financialMonitoring
  ),

  organizationalAndLegalForm: parseOrganizationalAndLegalFormValue(
    t,
    client.organizationalAndLegalForm
  ),

  registrationPlace: {
    country: client.registrationPlace?.country ?? null,
    locality: client.registrationPlace?.locality ?? '',
    specialRegistrationArea:
      client.registrationPlace?.specialRegistrationArea ?? undefined,
  },

  companyStateRegistration: parseCompanyStateRegistrationValue(
    client.stateRegistration
  ),

  statuses: parseStatusesInitialValue(client, t),

  accountCodes: parseAccountCodesInitialValue(client),

  activity: {
    id: client.id,
    createdAt: formatToISODate(client.createdAt),
  },

  openAccountOrder: {
    date: client.openAccountOrder?.date,
    number: client.openAccountOrder?.number,
    orderReceivedDate: client.openAccountOrder?.orderReceivedDate,
  },
  additionalDocumentsFiles: client.additionalInformationFiles,
  documentsFiles: parseLegalClientDocumentsFiles(client),

  general: parseGeneralValue(client, t),

  serviceContract: parseServiceContractInitialValue(t, client.serviceContract),

  riskLevel: parseRiskLevelInitialValue(client, t),
});
