// eslint-disable-next-line no-restricted-imports
import { AmountWithValueAndCurrency } from 'api/generated/graphql';
import { parseAmount } from 'api/helpers/parseEntity/parseAmount';
import { ControlOverEntity } from 'api/types/entity';

interface ParseControlOverEntityarams {
  financialConditionAmount?: AmountWithValueAndCurrency | null;
  directCapitalParticipationPercentage?: number | null;
  hasControlOverLegalClient?: boolean | null;
  indirectCapitalParticipationPercentage?: number | null;
}

export const parseControlOverEntity = (
  data: ParseControlOverEntityarams
): ControlOverEntity | undefined => {
  if (!data.financialConditionAmount) return undefined;

  return {
    directCapitalParticipationPercentage:
      data.directCapitalParticipationPercentage!,
    financialConditionAmount: parseAmount(data.financialConditionAmount!),
    hasControlOverLegalClient: data.hasControlOverLegalClient!,
    indirectCapitalParticipationPercentage:
      data.indirectCapitalParticipationPercentage!,
  };
};
