export enum ManagementType {
  Underwriting = 'Underwriting', // Андеррайтинг
  TrustManagement = 'TrustManagement', // Доверительное управление
  BrokerageWithoutNominalHolding = 'BrokerageWithoutNominalHolding', // Брокерское без номинального держания
  BrokerageOnFuturesMarket = 'BrokerageOnFuturesMarket', // Брокерское на срочном рынке
  BrokerageWithNominalHolding = 'BrokerageWithNominalHolding', // Брокерское с номинальным держанием
  MarketMaker = 'MarketMaker',
  None = 'None',
  NominalHolding = 'NominalHolding', // Номинальное держание
  BondholderRepresentative = 'BondholderRepresentative', // Представитель держателей облигаций
  OtherServices = 'OtherServices', // Прочие услуги
  FinancialConsulting = 'FinancialConsulting', // Финансовое консультирование
}
