import { useMemo } from 'react';

import { useManagersForClients as useManagersForClientsApi } from 'api/requests';
import { parseFullName } from 'helpers';

export const useManagersForClients = () => {
  const { managers: managersApi, loading } = useManagersForClientsApi();

  const managers = useMemo(
    () =>
      managersApi.map((admin) => ({
        id: admin.id,
        name: parseFullName(admin),
      })),
    [managersApi]
  );

  return { managers, loading };
};
