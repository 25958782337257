import { useEffect, useState } from 'react';

import { ListDataQueryFunction } from 'api/types/data';

import { UseTableArgument, UseTableFilterParam, useTable } from './useTable';

/**
 * Hook for querying list data with pagination, sorting, and filtering.
 */
export const useQueryListData = <
  T,
  S extends string = string,
  F extends UseTableFilterParam = {},
  Result = any
>(
  useQuery: ListDataQueryFunction<T, S, F, Result>,
  { limit, ...restTableProps }: UseTableArgument<S, F> & { limit: number }
) => {
  const { sort, onSortChange, onPaginationChange, page, filter, setFilter } =
    useTable(restTableProps);

  const { listData, loading, ...restResult } = useQuery(
    { page, limit },
    sort,
    filter
  );

  const [rowCount, setRowCount] = useState(listData?.total);

  const list: T[] = listData?.list ?? [];

  // Reset row count when the filter changes
  useEffect(() => {
    setRowCount(0);
  }, [filter]);

  // Update row count when new data is fetched
  useEffect(() => {
    if (listData?.total !== undefined) {
      setRowCount(listData.total);
    }
  }, [listData?.total]);

  return {
    sort,
    onSortChange,
    page,
    onPaginationChange,
    setFilter,
    filter,
    loading,
    ...restResult,
    rowCount,
    list,
  };
};
