import {
  useLegalClient,
  useLegalClientBeneficiary as useLegalClientBeneficiaryApi,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId, ROUTES } from 'libs/navigation';

import { LegalClientBeneficiaryFormType } from '../forms/legalClientBeneficiaryForm';
import { getLegalClientBeneficiaryInitialValue } from '../helpers';

import { useBeneficiaryPageTitle } from './useBeneficiaryPageTitle';
import { useLockLegalClientBeneficiary } from './useLockLegalClientBeneficiary';
import { useUpdateLegalClientBeneficiary } from './useUpdateLegalClientBeneficiary';

type NavigateParams = {
  beneficiaryId: string;
  legalClientId: string;
};

export const useLegalClientBeneficiary = () => {
  const { t } = useTranslation();

  const { navigate, params } = useNavigate<NavigateParams>();

  const { loading, legalClientBeneficiary, error } =
    useLegalClientBeneficiaryApi(params.beneficiaryId!);

  const { loading: submitLoading, updateLegalClientBeneficiary } =
    useUpdateLegalClientBeneficiary();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientBeneficiary(
    legalClient,
    legalClientBeneficiary
  );

  useBeneficiaryPageTitle(legalClient, legalClientBeneficiary);

  const onSubmit: FormSubmit<LegalClientBeneficiaryFormType> = async (
    values,
    helpers
  ) => {
    if (await updateLegalClientBeneficiary(legalClientBeneficiary!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientBeneficiary?.versionNumber &&
    legalClientBeneficiary?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientBeneficiaryVersion.fullPath,
            {
              legalClientId: legalClient.id,
              beneficiaryId: params.beneficiaryId!,
              versionId: legalClientBeneficiary.versionId!,
            }
          );
        }
      : undefined;

  const initialValues = legalClientBeneficiary
    ? getLegalClientBeneficiaryInitialValue(legalClientBeneficiary, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientBeneficiary]: legalClientBeneficiary
      ? parseFullName(legalClientBeneficiary)
      : '',
    [RoutesId.legalClient]: legalClient?.fullName ?? '',
  };

  return {
    legalClientBeneficiary,
    loading: !legalClientBeneficiary && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    onSubmit,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
  };
};
