import { CountryEntity } from 'api/types/entity';
import { TypedForm } from 'libs/form';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Row } from 'modules/client/common/components/Row';
import { AddressFormType } from 'modules/client/common/forms/registrationAddressForm';

export const { Field, Form, useFormContext, Submit } = TypedForm<{
  registrationAddress: AddressFormType;
  residentialAddress: AddressFormType;
  companyRegistrationAddress: AddressFormType;
  companyResidentialAddress: AddressFormType;
}>();

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  variant:
    | 'registrationAddress'
    | 'residentialAddress'
    | 'companyRegistrationAddress'
    | 'companyResidentialAddress';
}

export const AddressFields = ({
  countriesLoading,
  countries,
  variant,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Box flex={1}>
          <Field.Autocomplete
            fullWidth
            label={t('client.client.addressFields.country.label')}
            loading={countriesLoading}
            name={`${variant}.country`}
            optionLabelKey="name"
            options={countries}
            optionValueKey="id"
            placeholder={t('client.client.addressFields.country.placeholder')}
          />
        </Box>
        <Box flex={1}>
          <Field.Text
            fullWidth
            label={t('client.client.addressFields.region.label')}
            name={`${variant}.region`}
            placeholder={t('client.client.addressFields.region.placeholder')}
          />
        </Box>
      </Row>
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.addressFields.locality.label')}
          name={`${variant}.locality`}
          placeholder={t('client.client.addressFields.locality.placeholder')}
        />
        <Field.Text
          fullWidth
          label={t('client.client.addressFields.district.label')}
          name={`${variant}.district`}
          placeholder={t('client.client.addressFields.district.placeholder')}
        />
      </Row>
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.addressFields.street.label')}
          name={`${variant}.street`}
          placeholder={t('client.client.addressFields.street.placeholder')}
        />

        <Field.Text
          fullWidth
          label={t('client.client.addressFields.house.label')}
          name={`${variant}.house`}
          placeholder={t('client.client.addressFields.house.placeholder')}
        />
      </Row>
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.addressFields.building.label')}
          name={`${variant}.building`}
          placeholder={t('client.client.addressFields.building.placeholder')}
        />

        <Field.Text
          fullWidth
          label={t('client.client.addressFields.apartment.label')}
          name={`${variant}.apartment`}
          placeholder={t('client.client.addressFields.apartment.placeholder')}
        />
      </Row>
      <Field.Text
        fullWidth
        label={t('client.client.addressFields.postalCode.label')}
        name={`${variant}.postalCode`}
        placeholder={t('client.client.addressFields.postalCode.placeholder')}
      />
    </>
  );
};
