// eslint-disable-next-line no-restricted-imports
import { useMemo, useRef } from 'react';

import { useLegalClientVersions } from 'api/requests';

export const useLegalClientFirstHeadVersions = (
  legalClientId: string,
  firstHeadId: string,
  versionId?: string
) => {
  const savedVersionId = useRef(versionId).current;
  const { loading, versions, error } = useLegalClientVersions(legalClientId);

  const filteredVersions = useMemo(() => {
    const currentVersion = versions
      ? Math.max(...versions.map((v) => v.number))
      : undefined;

    return versions?.filter((version) => {
      if (version.updatedEntity.id === firstHeadId) {
        return true;
      }

      return (
        version.number === currentVersion ||
        version.number === 1 ||
        version.id === savedVersionId
      );
    });
  }, [firstHeadId, savedVersionId, versions]);

  return { loading, versions: filteredVersions, error };
};
