import { TypedForm, ObjectSchema, object, array } from 'libs/form';
import { localFileScheme } from 'modules/client/common/helpers';

import { LocalFileEntity } from '../types';

export interface CompanyConfirmStructureFormType {
  confirmStructure: {
    files?: LocalFileEntity[];
  };
}

export const MAX_FILES = 10;

export const companyConfirmStructureSchema: ObjectSchema<CompanyConfirmStructureFormType> =
  object({
    confirmStructure: object({
      files: array().of(localFileScheme).required().min(2).max(MAX_FILES),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyConfirmStructureFormType>();
