import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientRepresentativeMutation } from 'api/generated/graphql';
import {
  parseLegalClientRepresentative,
  parseUpdateLegalClientRepresentativeParams,
  useError,
} from 'api/helpers';
import { UpdateLegalClientRepresentativeParams } from 'api/types/params';

export const useUpdateLegalClientRepresentative = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientRepresentativeMutation();

  const error = useError(apolloError);

  return {
    legalClientRepresentative: data?.updateLegalClientRepresentative
      ? parseLegalClientRepresentative(data.updateLegalClientRepresentative)
      : undefined,
    loading,
    error,
    updateLegalClientRepresentative: useCallback(
      async (id: string, params: UpdateLegalClientRepresentativeParams) => {
        const input = parseUpdateLegalClientRepresentativeParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateLegalClientRepresentative
          ? parseLegalClientRepresentative(
              res.data?.updateLegalClientRepresentative
            )
          : undefined;
      },
      [handle]
    ),
  };
};
