import { CreateLegalClientLegalHeadParams } from 'api/types/params';
import { yesOrNot } from 'helpers';
import {
  parseCompanyAddressParam,
  parseCompanyTaxResidencyParam,
  parseStateRegistrationParam,
} from 'modules/client/common/helpers';

import { LegalClientLegalHeadFormType } from '../../forms/legalClientLegalHeadForm';

export const getLegalClientLegalHeadSubmitData = (
  legalClientId: string,
  versionNumber: number,
  values: LegalClientLegalHeadFormType
): CreateLegalClientLegalHeadParams => {
  const addressParam = parseCompanyAddressParam(values);

  return {
    versionNumber,
    legalClientId,
    legalClientBodyId: values.headInfo.body?.value!,
    reason: values.reasonChange.reason,
    fullName: values.companyName.fullName,
    shortName: values.companyName.shortName,
    legalAddress: addressParam.legalAddress,
    actualAddress: addressParam.actualAddress,
    isFirstHead: yesOrNot(values.headInfo.firstHead?.value) ?? false,
    taxResidences: parseCompanyTaxResidencyParam(values),

    stateRegistration: parseStateRegistrationParam({
      companyStateRegistration: values.companyStateRegistration!,
    }),
    legalClientBodyMemberConfirmationFileIds: values.headInfo.legalFiles?.map(
      ({ id }) => id!
    ),
  };
};
