// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_UserController_GetList_SortOrder as SortOrderApi,
  useAdminsQuery,
} from 'api/generated/graphql';
import { parseAdminsListItemData, useError } from 'api/helpers';
import { AdminsListItemData, ListDataQueryFunction } from 'api/types/data';
import { ListData } from 'api/types/data/listData';
import { SortDirection } from 'api/types/params/listParams';
import { lodash } from 'helpers';

type SortField = 'lastName' | 'status';

const sortMap: Record<NonNullable<SortDirection>, SortOrderApi> = {
  desc: SortOrderApi.Desc,
  asc: SortOrderApi.Asc,
};

export type AdminsFilter = {
  search?: string;
};

export const useAdmins: ListDataQueryFunction<
  AdminsListItemData,
  SortField,
  AdminsFilter,
  Pick<ReturnType<typeof useAdminsQuery>, 'refetch'>
> = (pagination, sort?, filter?) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useAdminsQuery({
    variables: {
      limit: pagination.limit,
      page: pagination.page + 1,
      sortBy: sort?.field ? sort?.field : undefined,
      sortOrder: sort?.sort ? sortMap[sort?.sort] : sortMap.desc,
      search: filter?.search || undefined,
    },
  });

  const error = useError(apolloError);

  const listData: ListData<AdminsListItemData> | undefined = data?.admins
    ? {
        total: data?.admins?.total,
        list: lodash.compact(data?.admins.list).map(parseAdminsListItemData),
      }
    : undefined;

  return {
    listData,
    loading,
    error,
    refetch,
  };
};
