import {
  AmlPolicyReviewFrequency,
  LegalClientFinancialMonitoringEntity,
  LegalClientFinancialMonitoringInfoEntity,
  LegalClientFinancialMonitoringInfoOfficerEntity,
} from 'api/types/entity';
import { Maybe } from 'types/maybe';
import { YesOrNoType } from 'types/yesOrNo';

import { CompanyFinancialMonitoringFormType } from '../../forms/companyFinancialMonitoringForm';

export const getFinancialMonitoringParam = (
  values: CompanyFinancialMonitoringFormType
): LegalClientFinancialMonitoringEntity => {
  const isSubjectToRegulation =
    values.financialMonitoring.isSubjectToRegulation?.value === YesOrNoType.Yes;

  if (!isSubjectToRegulation) {
    return { isSubjectToRegulation };
  }

  const hasAmlCftInternalControlOfficer =
    values.financialMonitoring.hasAmlCftInternalControlOfficer?.value ===
    YesOrNoType.Yes;

  const officerInfo:
    | Maybe<LegalClientFinancialMonitoringInfoOfficerEntity>
    | undefined = hasAmlCftInternalControlOfficer
    ? {
        email: values.financialMonitoring.officerEmail!,
        firstName: values.financialMonitoring.officerFirstName!,
        lastName: values.financialMonitoring.officerLastName!,
        phoneNumber: values.financialMonitoring.officerPhoneNumber!,
        position: values.financialMonitoring.officerPosition!,
        middleName: values.financialMonitoring.officerMiddleName,
      }
    : undefined;

  const frequencyReviewPolice =
    values.financialMonitoring.amlPolicyReviewFrequency?.value;

  const otherAmlPolicyReviewFrequencyField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'otherAmlPolicyReviewFrequency'
  > =
    frequencyReviewPolice === AmlPolicyReviewFrequency.Other
      ? {
          otherAmlPolicyReviewFrequency:
            values.financialMonitoring.otherAmlPolicyReviewFrequency,
        }
      : {};

  const hasAmlCftDocumentEffectivenessAssessmentProcedures =
    values.financialMonitoring
      .hasAmlCftDocumentEffectivenessAssessmentProcedures?.value ===
    YesOrNoType.Yes;

  const documentProvisionRefusalReasonField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'documentProvisionRefusalReason'
  > =
    values.financialMonitoring.canProvideAmlPolicyDocuments?.value ===
    YesOrNoType.No
      ? {
          documentProvisionRefusalReason:
            values.financialMonitoring.documentProvisionRefusalReason,
        }
      : {};

  const hadAmlCftInvestigationsOrPenalties =
    values.financialMonitoring.hadAmlCftInvestigationsOrPenalties?.value ===
    YesOrNoType.Yes;
  const investigationOrPenaltyReasonsField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'investigationOrPenaltyReasons'
  > = hadAmlCftInvestigationsOrPenalties
    ? {
        investigationOrPenaltyReasons:
          values.financialMonitoring.investigationOrPenaltyReasons,
      }
    : {};

  const hasMandatoryControlTransactions =
    values.financialMonitoring.hasMandatoryControlTransactions?.value ===
    YesOrNoType.Yes;
  const mandatoryControlTransactionIdentificationProcedureField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'mandatoryControlTransactionIdentificationProcedure'
  > = hasMandatoryControlTransactions
    ? {
        mandatoryControlTransactionIdentificationProcedure:
          values.financialMonitoring
            .mandatoryControlTransactionIdentificationProcedure,
      }
    : {};

  return {
    isSubjectToRegulation,
    info: {
      authorityName: values.financialMonitoring.authorityName!,
      authorityWebsite: values.financialMonitoring.authorityWebsite,
      hasAmlCftComplianceControl:
        values.financialMonitoring.hasAmlCftComplianceControl?.value ===
        YesOrNoType.Yes,
      hasTransactionMonitoringAndSuspiciousActivityDetection:
        values.financialMonitoring
          .hasTransactionMonitoringAndSuspiciousActivityDetection?.value ===
        YesOrNoType.Yes,
      amlPolicyReviewFrequency:
        values.financialMonitoring.amlPolicyReviewFrequency?.value!,
      ...otherAmlPolicyReviewFrequencyField,
      amlCftPoliciesApplyToForeignEntities:
        values.financialMonitoring.amlCftPoliciesApplyToForeignEntities
          ?.value === YesOrNoType.Yes,
      hasAmlCftDocumentEffectivenessAssessmentProcedures,
      dateOfLastCheck: values.financialMonitoring.dateOfLastCheck,
      hasAmlCftInternalControlOfficer,
      officer: officerInfo,
      canProvideAmlPolicyDocuments:
        values.financialMonitoring.canProvideAmlPolicyDocuments?.value ===
        YesOrNoType.Yes,
      ...documentProvisionRefusalReasonField,
      isInternalControlInfoConfidential:
        values.financialMonitoring.isInternalControlInfoConfidential?.value ===
        YesOrNoType.Yes,
      hadAmlCftInvestigationsOrPenalties,
      ...investigationOrPenaltyReasonsField,
      hasMandatoryControlTransactions,
      ...mandatoryControlTransactionIdentificationProcedureField,
      monetaryServiceTransactionCountForLastSixMonths:
        values.financialMonitoring
          .monetaryServiceTransactionCountForLastSixMonths?.value!,
      isAffiliatedWithNonProfitOrganizations:
        values.financialMonitoring.isAffiliatedWithNonProfitOrganizations
          ?.value === YesOrNoType.Yes,
      receivesFundingFromStateBudget:
        values.financialMonitoring.receivesFundingFromStateBudget?.value ===
        YesOrNoType.Yes,
      receivesFundingFromForeignNonProfitNGOs:
        values.financialMonitoring.receivesFundingFromForeignNonProfitNGOs
          ?.value === YesOrNoType.Yes,
      isCompliantWithFATFRecommendations:
        values.financialMonitoring.isCompliantWithFATFRecommendations?.value ===
        YesOrNoType.Yes,
      hasBeenRefusedToOpenAccountByBanks:
        values.financialMonitoring.hasBeenRefusedToOpenAccountByBanks?.value ===
        YesOrNoType.Yes,
    },
  };
};
