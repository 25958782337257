import {
  LegalClientEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { useLockLegalClient } from 'modules/client/legal/hooks/useLockLegalClient';

export const useLockLegalClientLegalHead = (
  legalClient: LegalClientEntity | null,
  legalHead: LegalClientLegalHeadEntity | null
) => {
  const { lockClientIfPossible, unlockClient } = useLockLegalClient(
    legalClient
      ? {
          ...legalClient,
          versionNumber: legalHead?.versionNumber,
        }
      : null
  );

  return { lockClientIfPossible, unlockClient };
};
