import { useMemo } from 'react';

import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { renderDate, renderMore, renderName } from './renders';
import { DocumentColumnArgs, DocumentItemType } from './types';

export const getColumns = (
  t: TFunc,
  { onClickOption, onClickName, editable = false }: DocumentColumnArgs
): TableColumn<DocumentItemType>[] => [
  {
    field: 'number',
    title: '',
    width: 64,
    align: 'center',
  },
  {
    field: 'createdAt',
    title: t('client.client.documents.columns.date'),
    flex: 1,
    renderCell: (item) => renderDate(item),
  },
  {
    field: 'name',
    title: t('client.client.documents.columns.name'),
    flex: 1,
    renderCell: (item) => renderName(item, onClickName),
  },
  {
    field: 'more' as 'number',
    title: '',
    width: 56,
    align: 'center',
    renderCell: (item) => renderMore(t, item, editable, onClickOption),
  },
];

export const useColumns = (args: DocumentColumnArgs) => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t, args), [args, t]);
};
