import { LegalClientBodyEntity } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  Field,
  useFormContext,
  getLegalClientHeadTypeOptions,
} from 'modules/client/common/forms/legalClientHeadInfoForm';

interface Props {
  showDocuments?: boolean;
  bodies: LegalClientBodyEntity[];
}

export const LegalClientHeadInfoSection = ({
  showDocuments,
  bodies,
}: Props) => {
  const { t } = useTranslation();
  const { readOnly } = useFormContext();

  return (
    <Section title={t('client.legal.legalClientHead.headInfo.title')}>
      <Field.Autocomplete
        hideClearIcon
        label={t('client.legal.legalClientHead.headInfo.body.label')}
        name="headInfo.body"
        optionLabelKey="label"
        options={bodies.map((b) => ({
          value: b.id,
          label: b.name,
        }))}
        optionValueKey="value"
        placeholder={t(
          'client.legal.legalClientHead.headInfo.body.placeholder'
        )}
        variant="select"
      />
      <Row>
        <Box flex={1}>
          <Field.Autocomplete
            fullWidth
            readOnly
            label={t('client.legal.legalClientHead.headInfo.headType.label')}
            name="headInfo.headType"
            optionLabelKey="label"
            options={getLegalClientHeadTypeOptions(t)}
            optionValueKey="value"
            placeholder={t(
              'client.legal.legalClientHead.headInfo.headType.placeholder'
            )}
            variant="select"
          />
        </Box>
        <Box flex={1}>
          <Field.Autocomplete
            fullWidth
            label={t('client.legal.legalClientHead.headInfo.firstHead.label')}
            name="headInfo.firstHead"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t(
              'client.legal.legalClientHead.headInfo.firstHead.placeholder'
            )}
            variant="select"
          />
        </Box>
      </Row>

      {showDocuments && (
        <DocumentsTableFeature name="headInfo.legalFiles" readOnly={readOnly} />
      )}
    </Section>
  );
};
