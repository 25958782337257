import { Stack } from 'libs/ui';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps {}

export const DocumentsSection = ({ ...formProps }: Props) => (
  <Stack spacing={32}>
    <FormContent {...formProps} />
  </Stack>
);
