import { useCallback } from 'react';

import { openLink } from '../../helpers';

import { useGetFileLinkDownload } from './useGetFileLinkDownload';

export const useDownloadFile = () => {
  const { getFileLink, loading } = useGetFileLinkDownload();

  const handleDownloadFile = useCallback(
    async (fileId: string) => {
      const link = await getFileLink(fileId);

      if (!link) {
        return;
      }

      openLink(link);
    },
    [getFileLink]
  );

  return {
    downloadFile: handleDownloadFile,
    loading,
  };
};
