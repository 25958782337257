// eslint-disable-next-line no-restricted-imports
import { ClientUserFragment } from 'api/generated/graphql';
import { ClientUserEntity } from 'api/types/entity/client/clientUserEntity';

export const parseClientUserEntity = (
  user: ClientUserFragment
): ClientUserEntity => ({
  id: user.id,
  createdAt: user.createdAt,
  lastAuthSessionLog: user.lastAuthSessionLog
    ? {
        createdAt: user.lastAuthSessionLog.createdAt,
        deviceId: user.lastAuthSessionLog.deviceId,
        userAgent: user.lastAuthSessionLog.userAgent ?? undefined,
        ip: user.lastAuthSessionLog.ip,
        updatedAt: user.lastAuthSessionLog.updatedAt,
      }
    : undefined,
});
