import { ReactNode, useEffect, useMemo, useState } from 'react';

import { config } from 'config';
import { FormikContext, useFormik } from 'formik';
import { lodash } from 'helpers';
import { ObjectSchema } from 'yup';

import { FormContext } from './formContext';
import { prepareDataForValidation } from './helpers';
import { FormErrors } from './types';

export type FormSubmit<Values extends object> = (
  values: Values,
  helpers: FormHelpers
) => void | Promise<any>;

export interface FormHelpers {
  readOnly?: boolean;
  setReadOnly: (v: boolean) => void;
  submitDisabled?: boolean;
  setSubmitDisabled: (v: boolean) => void;
}

/**
 * Props for the Form component.
 */
export interface FormProps<Values extends object> {
  /** Initial form field values (optional). */
  initialValues?: Partial<Values>;
  /** Initial form errors (optional). */
  initialErrors?: FormErrors<Values>;
  /** Function to handle form submission. */
  onSubmit: FormSubmit<Values>;
  /** Whether to validate on field change (default is false). */
  validateOnChange?: boolean;
  /** Whether to validate on field blur (default is false). */
  validateOnBlur?: boolean;
  /** A Yup Schema or a function that returns a Yup schema (optional). */
  validationSchema?: ObjectSchema<Values>;
  /** Child components or elements (optional). */
  children?: ReactNode;

  initialReadOnly?: boolean;
  initialSubmitDisabled?: boolean;
}

/**
 * Form component that uses Formik for form management and validation.
 */
export const Form = <Values extends object>(props: FormProps<Values>) => {
  const {
    initialValues = {},
    onSubmit,
    validateOnChange = false,
    validateOnBlur = false,
    children,
    validationSchema,
    initialErrors,
    initialReadOnly,
    initialSubmitDisabled,
    ...rest
  } = props;

  const [readOnly, setReadOnly] = useState(initialReadOnly);
  const [submitDisabled, setSubmitDisabled] = useState(initialSubmitDisabled);

  const formState = useFormik({
    ...rest,
    enableReinitialize: true,
    validateOnChange,
    validateOnBlur,
    initialErrors,
    initialValues: (initialValues || {}) as Values,
    validationSchema,
    onSubmit: (values: Values) =>
      onSubmit(prepareDataForValidation(values) as Values, {
        setReadOnly,
        readOnly,
        submitDisabled,
        setSubmitDisabled,
      }),
  });

  const formValue = useMemo(
    () => ({ readOnly, setReadOnly, submitDisabled, setSubmitDisabled }),
    [readOnly, submitDisabled]
  );

  const value = useMemo(
    () => ({ ...formState, validationSchema }),
    [formState, validationSchema]
  );

  useEffect(() => {
    if (
      formState.isSubmitting &&
      !formState.isValidating &&
      !lodash.isEmpty(formState.errors) &&
      config.IS_DEVELOPMENT
    ) {
      // eslint-disable-next-line no-console
      console.info(formState.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors]);

  return (
    <FormContext.Provider value={formValue}>
      <FormikContext.Provider value={value}>{children}</FormikContext.Provider>
    </FormContext.Provider>
  );
};
