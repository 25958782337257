import { LegalClientStateRegistrationEntity } from 'api/types/entity/legal/legalClientStateRegistrationEntity';

import { CompanyStateRegistrationFormType } from '../../forms/companyStateRegistrationForm';

export const parseCompanyStateRegistrationValue = (
  stateRegistration: LegalClientStateRegistrationEntity | undefined
): CompanyStateRegistrationFormType['companyStateRegistration'] => {
  if (!stateRegistration) {
    return {} as CompanyStateRegistrationFormType['companyStateRegistration'];
  }

  return {
    date: stateRegistration.initialStateRegistrationDate,
    dateOfIssue:
      stateRegistration.stateRegistrationConfirmationDocument.dateOfIssue,
    nameAuthority: stateRegistration.registrationAuthorityName,
    number: stateRegistration.registrationNumber,
    files: stateRegistration.stateRegistrationConfirmationDocument.files,
    registrationDocumentName: stateRegistration.registrationDocumentName,
  };
};
