import {
  useLockLegalClient as useLockApi,
  useUnlockLegalClient,
} from 'api/requests';
import { LegalClientEntity } from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockLegalClient = (client: LegalClientEntity | null) => {
  const { lockClientIfPossible } = useLockClientIfPossible(client, useLockApi);

  const { unlock } = useUnlockLegalClient();
  const unlockClient = () => unlock(client?.id!);

  return { lockClientIfPossible, unlockClient };
};
