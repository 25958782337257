// eslint-disable-next-line no-restricted-imports
import { LinearProgress as ProgressBarBase } from '@mui/material';
import { Colors } from 'libs/ui/theme';

import { Typography } from '../Typography';
import { styled } from '../styled';

export const ProgressBarStyled = styled(ProgressBarBase)({
  width: '100%',
  minWidth: 300,
  borderRadius: 20,
  background: Colors.gray100,
  height: 8,

  '& .MuiLinearProgress-bar1Determinate': {
    background: Colors.blue150,
  },
});

export const ProgressStatusStyled = styled(Typography)({
  lineHeight: '16px',
});
