// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_AccountStatus as ClientAccountStatusTypeApi,
  Query_ClientController_GetList_AllOf_1_List_Items_AccountStatus as ClientAccountStatusTypeApi2,
  Query_ClientController_GetAvailableStatuses_Account_Items as ClientAccountStatusTypeApi3,
} from 'api/generated/graphql';
import { ClientAccountStatusType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type Status =
  | ClientAccountStatusTypeApi
  | ClientAccountStatusTypeApi2
  | ClientAccountStatusTypeApi3;

export const parseClientAccountStatus = (status: Maybe<Status>) =>
  status ? ClientAccountStatusType[status] : null;
