import { Pressed } from 'libs/ui/Pressed';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContainerStyled = styled(Pressed)(() => ({
  position: 'relative',
  display: 'inline-block',

  '.input': {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0,
    position: 'absolute',

    '.input-root': {
      height: 84,
    },
  },

  '.text': {
    height: 84,
    width: 84,
    borderRadius: 12,
    borderColor: Colors.gray100,
    borderWidth: 1,
    borderStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none',

    '&.filled': {
      backgroundColor: Colors.gray50,
      borderColor: Colors.gray150,
    },
    '&.focused': {
      borderColor: Colors.blue200,
    },
    '&.error': {
      borderColor: Colors.red100,
      backgroundColor: Colors.red50,
    },
  },
}));
