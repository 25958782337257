// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetList_AllOf_1_List_Items_ServiceContract_Type as ServiceContractTypeApi1,
  ServiceContractDto_Input,
} from 'api/generated/graphql';
import { ServiceContractType } from 'api/types/entity';
import { ServiceContractParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

type ServiceContractTypeApi = ServiceContractTypeApi1;

const serviceContract: Record<ServiceContractType, ServiceContractTypeApi> = {
  [ServiceContractType.AdditionalAgreementToCustodialContract]:
    ServiceContractTypeApi1.AdditionalAgreementToCustodialContract,
  [ServiceContractType.BrokerageServicesContract]:
    ServiceContractTypeApi1.BrokerageServicesContract,
  [ServiceContractType.BrokerageServicesContractWithNominalHolding]:
    ServiceContractTypeApi1.BrokerageServicesContractWithNominalHolding,
  [ServiceContractType.ConsultancyServicesContract]:
    ServiceContractTypeApi1.ConsultancyServicesContract,
  [ServiceContractType.CustodialContract]:
    ServiceContractTypeApi1.CustodialContract,
  [ServiceContractType.InvestmentPortfolioManagementContract]:
    ServiceContractTypeApi1.InvestmentPortfolioManagementContract,
  [ServiceContractType.MarketMakerServicesContract]:
    ServiceContractTypeApi1.MarketMakerServicesContract,
  [ServiceContractType.SupplementaryAgreement]:
    ServiceContractTypeApi1.SupplementaryAgreement,
  [ServiceContractType.UnderwritingServicesContract]:
    ServiceContractTypeApi1.UnderwritingServicesContract,
};

export const parseServiceContractParam = (
  data: ServiceContractParam | undefined | null
): ServiceContractDto_Input => ({
  type: data?.type ? serviceContract[data.type] : undefined,
  number: data?.number ?? undefined,
  conclusionDate: data?.conclusionDate
    ? formatToISODate(data.conclusionDate)
    : null,
  terminationDate: data?.terminationDate
    ? formatToISODate(data.terminationDate)
    : null,
});
