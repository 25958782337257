// eslint-disable-next-line no-restricted-imports
import { ClientSnapshotFragment } from 'api/generated/graphql';
import { ClientSnapshotEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { parseClientEntity } from '../parseClientEntity';

export const parseClientSnapshotEntity = (
  data: Maybe<ClientSnapshotFragment>
): ClientSnapshotEntity | undefined =>
  data
    ? {
        clientId: data.clientId,
        createdAt: new Date(data.createdAt),
        id: data.id,
        value: parseClientEntity(data.value),
      }
    : undefined;
