import { useEffect } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useBlocker } from 'react-router-dom';

export const usePrompt = (
  message: string,
  when: boolean,
  onConfirm?: () => void
) => {
  const blocker = useBlocker(when);

  useEffect(() => {
    // This timeout is needed for FireFox to work correctly
    setTimeout(() => {
      if (blocker.state === 'blocked') {
        // eslint-disable-next-line no-alert
        const proceed = window.confirm(message);
        if (proceed) {
          // This timeout is needed to avoid a weird "race" on POP navigations
          // between the `window.history` revert navigation and the result of
          // `window.confirm`
          // eslint-disable-next-line @typescript-eslint/no-implied-eval
          onConfirm?.();
          setTimeout(blocker.proceed, 0);
        } else {
          blocker.reset();
        }
      }
    }, 0);
  }, [blocker, message, onConfirm]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when]);
};
