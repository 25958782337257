import { FC } from 'react';

import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';

import {
  LegalClientBranchForm,
  LegalClientBranchFormProps,
} from '../components';

export interface LegalClientBranchViewProps extends LegalClientBranchFormProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
}

export const LegalClientBranchView: FC<LegalClientBranchViewProps> = ({
  breadcrumbsTitles,
  ...formProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <LegalClientBranchForm {...formProps} />
  </LayoutWithNavigationBreadcrumbs>
);
