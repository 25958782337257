import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useAdminResendInviteMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useAdminResendInvite = () => {
  const [resendInvite, { data, loading, error: apolloError }] =
    useAdminResendInviteMutation();

  const error = useError(apolloError);

  return {
    timerData: data?.resendInvite
      ? {
          resendTime: data?.resendInvite.resendTime,
        }
      : undefined,
    loading,
    error,
    resendInvite: useCallback(
      async (id: string) => {
        const res = await resendInvite({ variables: { id } });

        return res.data?.resendInvite
          ? {
              resendTime: res.data?.resendInvite.resendTime,
            }
          : undefined;
      },
      [resendInvite]
    ),
  };
};
