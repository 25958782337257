import { useCreateLegalClientLegalHead } from 'api/requests';
import { useCountries } from 'api/requests/country';
import {
  LegalClientEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import {
  getSchema,
  LegalClientLegalHeadFormType,
} from '../forms/legalClientLegalHeadForm';
import {
  getLegalClientLegalHeadInitialValue,
  getLegalClientLegalHeadSubmitData,
} from '../helpers';

export const useLegalClientAddLegalHead = (
  legalClient: LegalClientEntity,
  onSuccess: (legalClientHead: LegalClientLegalHeadEntity) => void
) => {
  const { countries, loading: countriesLoading } = useCountries();
  const { t } = useTranslation();
  const notify = useNotify();

  const { loading: submitLoading, createLegalClientLegalHead } =
    useCreateLegalClientLegalHead();

  const initialValues = getLegalClientLegalHeadInitialValue();

  const validationSchema = getSchema(t);

  const onSubmit: FormSubmit<LegalClientLegalHeadFormType> = async (values) => {
    const params = getLegalClientLegalHeadSubmitData(
      legalClient.id,
      legalClient.versionNumber!,
      values
    );

    const head = await createLegalClientLegalHead(params);
    if (head) {
      onSuccess(head);
      notify.info(t('client.legal.legalClientHead.addDialog.successAdd'));
    }
  };

  return {
    countries: countries ?? [],
    countriesLoading,
    initialValues,
    validationSchema,
    submitLoading,
    onSubmit,
  };
};
