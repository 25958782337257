// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSnapshotQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClientRepresentativeSnapshotEntity } from 'api/helpers/legalRepresentative/parseLegalClientRepresentativeSnapshotEntity';

import { RequestOptions } from '../types';

export const useLegalClientRepresentativeSnapshot = (
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientRepresentativeSnapshotQuery({
    variables: { versionId },
    ...options,
  });

  const error = useError(apolloError);

  const snapshot = data?.legalClientRepresentativeSnapshot
    ? parseLegalClientRepresentativeSnapshotEntity(
        data.legalClientRepresentativeSnapshot
      )
    : null;

  return {
    snapshot,
    loading,
    error,
    refetch,
  };
};
