import { ObjectSchema, object, TypedForm, email } from 'libs/form';
import { TFunc } from 'libs/i18n';
import { getBasePersonalDataSchema } from 'modules/client/common/forms/personalDataForm';

export interface PersonalDataFormType {
  personalData: {
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    dateOfBirth?: Date;
  };
}

export const getPersonalDataSchema = (
  t: TFunc
): ObjectSchema<PersonalDataFormType> =>
  object({
    personalData: object({
      email: email(t).required(),
    }).concat(getBasePersonalDataSchema(t)),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PersonalDataFormType>();
