import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContentStyled = styled(Stack)(() => ({
  gap: 12,
  '&:hover': {
    '.notificationItem-checkBox': {
      display: 'flex',
    },
    '.notificationItem-readMark': {
      display: 'none',
    },
  } as CSSObject,

  '.notificationItem-checkBox': {
    width: 24,
    height: 24,
    borderRadius: 4,
    border: `1px solid${Colors.gray150}`,
    boxShadow:
      '0px 1px 2px 0px rgba(56, 77, 108, 0.04), 0px 1px 11px 0px rgba(56, 77, 108, 0.12)',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'none',
  } as CSSObject,

  '.notificationItem-readMark': {
    width: 12,
    height: 12,
    borderRadius: 12,
    backgroundColor: Colors.blue150,
    marginTop: 4,
  } as CSSObject,
}));
