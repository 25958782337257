import { LegalClientOrderSubmissionContactEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import { parseOrderSubmissionContactInitialValue } from 'modules/client/common/helpers';

import {
  CompanyOrderSubmissionContactsFormType,
  initialOrderSubmissionContactType,
} from '../../forms/companyOrderSubmissionContactsForm';

export const parseOrderSubmissionContactsInitialValue = (
  t: TFunc,
  orderSubmissionContacts?: LegalClientOrderSubmissionContactEntity[]
): CompanyOrderSubmissionContactsFormType['orderSubmissionContacts'] =>
  orderSubmissionContacts
    ? orderSubmissionContacts.map((v) => {
        const { value, type } = parseOrderSubmissionContactInitialValue(t, v);

        return {
          ...v,
          value,
          type,
        };
      })
    : [
        {
          type: initialOrderSubmissionContactType,
          value: '',
          firstName: '',
          lastName: '',
          position: '',
        },
      ];
