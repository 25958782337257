import { PropsWithChildren, ReactNode } from 'react';

import { Stack, Typography } from 'libs/ui';

interface Props extends PropsWithChildren {
  title: string;
  headerRight?: ReactNode;
}

export const Section = ({ title, headerRight, children }: Props) => (
  <Stack spacing={32}>
    <Stack direction="row" justifyContent="space-between" spacing={32}>
      <Typography variant="headline">{title}</Typography>
      {headerRight}
    </Stack>
    {children}
  </Stack>
);
