// eslint-disable-next-line no-restricted-imports
import { RoleInCompanyFragment } from 'api/generated/graphql';
import { parseFileDocumentEntity } from 'api/helpers/client/parseEntity/parseFileEntity';
import {
  CivilLawSubjectType,
  RoleInCompanyEntity,
  RoleInCompanyType,
} from 'api/types/entity';
import { lodash } from 'helpers';

export const parseRoleInCompanyEntity = (
  roleInCompany: RoleInCompanyFragment
): RoleInCompanyEntity => ({
  authorityConfirmationDocument: {
    dateOfExpiration: new Date(
      roleInCompany.authorityConfirmationDocument.dateOfExpiration
    ),
    dateOfIssue: new Date(
      roleInCompany.authorityConfirmationDocument.dateOfIssue
    ),
    files: lodash
      .compact(roleInCompany.authorityConfirmationDocument.documents)
      .map(parseFileDocumentEntity),
    number: roleInCompany.authorityConfirmationDocument.number || undefined,
  },
  civilLawSubject: roleInCompany.civilLawSubject
    ? CivilLawSubjectType[roleInCompany.civilLawSubject]
    : undefined,
  role: RoleInCompanyType[roleInCompany.roleInCompany],
});
