import { CSSObject } from 'libs/ui/styled';
import { Colors, Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

const getColorPrimary = () => ({
  bgColor: Colors.blue150,
  hoverBgColor: Colors.blue200,
  focusBgColor: Colors.blue250,
  textColor: Colors.white,
  hoverTextColor: Colors.blue100,
  focusTextColor: Colors.white,

  disableBgColor: Colors.gray200,
  disableTextColor: Colors.gray250,
});

const getColorRed = () => ({
  bgColor: Colors.red100,
  hoverBgColor: Colors.red150,
  focusBgColor: Colors.red200,
  textColor: Colors.white,
  hoverTextColor: Colors.red50,
  focusTextColor: Colors.white,

  disableBgColor: Colors.gray200,
  disableTextColor: Colors.gray250,
});

export const getStandardMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => {
  const colors = colorVariant === 'primary' ? getColorPrimary() : getColorRed();
  return {
    ...theme.typography.bodyBold,
    backgroundColor: colors.bgColor,
    borderRadius: 40,
    height: 64,
    paddingLeft: 64,
    paddingRight: 64,
    color: colors.textColor,

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: colors.hoverBgColor,
      color: colors.hoverTextColor,
    } as CSSObject,

    '&:active': {
      boxShadow: 'none',
      backgroundColor: colors.focusBgColor,
      color: colors.focusTextColor,
    } as CSSObject,

    '&:disabled': {
      backgroundColor: colors.disableBgColor,
      color: colors.disableTextColor,
    } as CSSObject,
  };
};
