// eslint-disable-next-line no-restricted-imports
import { DebtsFragment } from 'api/generated/graphql';
import { DebtsEntity } from 'api/types/entity/debtsEntity';

import { parseBaseDebtsEntity } from './parseBaseDebtsEntity';

export const parseDebtsEntity = (
  debts: DebtsFragment | undefined
): DebtsEntity | undefined => {
  const base = parseBaseDebtsEntity(debts);

  return base && debts
    ? {
        ...base,
        hasMarginTrades: debts.hasMarginTrades,
      }
    : undefined;
};
