// eslint-disable-next-line no-restricted-imports
import { Query_LegalClientController_GetList_AllOf_1_List_Items_EconomicSector as EconomicSectorApi } from 'api/generated/graphql';
import { LegalClientEconomicSectorType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

const economicSector: Record<LegalClientEconomicSectorType, EconomicSectorApi> =
  {
    [LegalClientEconomicSectorType.CentralBanks]:
      EconomicSectorApi.CentralBanks,
    [LegalClientEconomicSectorType.CentralGovernment]:
      EconomicSectorApi.CentralGovernment,
    [LegalClientEconomicSectorType.GovNonFinancialOrgs]:
      EconomicSectorApi.GovNonFinancialOrgs,
    [LegalClientEconomicSectorType.Households]: EconomicSectorApi.Households,
    [LegalClientEconomicSectorType.NonGovFinancialOrgs]:
      EconomicSectorApi.NonGovFinancialOrgs,
    [LegalClientEconomicSectorType.NonGovNonFinancialOrgs]:
      EconomicSectorApi.NonGovNonFinancialOrgs,
    [LegalClientEconomicSectorType.NonProfitOrgs]:
      EconomicSectorApi.NonProfitOrgs,
    [LegalClientEconomicSectorType.OtherDepositOrganizations]:
      EconomicSectorApi.OtherDepositOrganizations,
    [LegalClientEconomicSectorType.OtherFinancialOrgs]:
      EconomicSectorApi.OtherFinancialOrgs,
    [LegalClientEconomicSectorType.RegionalLocalAuthorities]:
      EconomicSectorApi.RegionalLocalAuthorities,
  };

export const parseEconomicSectorTypeParam = (
  data: Maybe<LegalClientEconomicSectorType>
): EconomicSectorApi | null => (data ? economicSector[data] : null);
