import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSnapshotQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientSnapshotEntity } from '../../helpers/client/parseEntity/parseClientSnapshotEntity';
import { RequestOptions } from '../types';

export const useClientSnapshot = (
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useClientSnapshotQuery({ variables: { versionId }, ...options });

  const error = useError(apolloError);

  const snapshot = useMemo(
    () =>
      data?.clientSnapshot
        ? parseClientSnapshotEntity(data.clientSnapshot)
        : null,
    [data?.clientSnapshot]
  );

  return {
    snapshot,
    loading,
    error,
    refetch,
  };
};
