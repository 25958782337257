import { useActivateAdmin } from 'api/requests';
import { PermissionType } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

export const useActivate = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { activateAdmin, error } = useActivateAdmin();
  const { checkPermission } = usePermissions();

  const activate = async (id: string) => {
    const permission = checkPermission(PermissionType.AdminActivate, {
      showNotify: true,
    });

    if (permission && (await activateAdmin(id))) {
      notify.info(t('admin.admin.successActivated'));
    }
  };

  useServerErrorNotify(error);

  return { activate, error };
};
