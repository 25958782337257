import { PowerOfAttorneyEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { PowerOfAttorneyFormType } from '../../forms/powerOfAttorneyForm';

export const parsePowerOfAttorney = (
  data?: Maybe<PowerOfAttorneyEntity>
): PowerOfAttorneyFormType['powerOfAttorney'] =>
  data
    ? {
        dateOfExpiration: data.dateOfExpiration,
        files: data.files,
        dateOfIssue: data.dateOfIssue,
        documentNumber: data.documentNumber,
        notaryFullName: data.notary?.fullName,
        notaryLicenseInfo: data.notary?.licenseInfo,
      }
    : ({} as PowerOfAttorneyFormType['powerOfAttorney']);
