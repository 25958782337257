import { LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { useLegalClientHeadVersion } from '../hooks';
import { LegalClientHeadVersionView } from '../views';

export const LegalClientHeadVersionContainer = () => {
  const {
    initialValues,
    loading,
    isError,
    error,
    versions,
    breadcrumbsTitles,
    snapshot,
    fullName,
    snapshotLoading,
    versionId,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
  } = useLegalClientHeadVersion();

  const { t } = useTranslation();

  useScrollToTop([]);

  if (loading) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientHeadVersionView
      bodies={[]}
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      error={
        isError
          ? error?.localizedDescription ??
            t('client.legal.legalClientHead.version.notVersionMessage')
          : undefined
      }
      fullName={fullName}
      initialValues={initialValues}
      kycData={snapshot?.value.kyc}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
