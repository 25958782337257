import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Computer = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 13.3333V17.5M10.5 17.5H15.5M10.5 17.5H5.5M5.66667 13.3333H15.3333C16.2668 13.3333 16.7335 13.3333 17.09 13.1517C17.4036 12.9919 17.6586 12.7369 17.8183 12.4233C18 12.0668 18 11.6001 18 10.6667V5.16667C18 4.23325 18 3.76654 17.8183 3.41002C17.6586 3.09641 17.4036 2.84144 17.09 2.68166C16.7335 2.5 16.2668 2.5 15.3333 2.5H5.66667C4.73325 2.5 4.26654 2.5 3.91002 2.68166C3.59641 2.84144 3.34144 3.09641 3.18166 3.41002C3 3.76654 3 4.23325 3 5.16667V10.6667C3 11.6001 3 12.0668 3.18166 12.4233C3.34144 12.7369 3.59641 12.9919 3.91002 13.1517C4.26654 13.3333 4.73325 13.3333 5.66667 13.3333Z"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);
