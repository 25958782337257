import { Link, LinkProps } from '../Link';
import { styled } from '../styled';
import { Colors } from '../theme';

export const LinkStyled = styled(Link)<LinkProps>(({ disabled }) => ({
  textDecoration: 'none',

  '.link-label': {
    color: disabled ? Colors.gray150 : Colors.blue150,
  },
}));
