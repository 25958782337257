import { useLockClient as useLockApi, useUnlockClient } from 'api/requests';
import { RepresentativeClientEntity } from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockRepresentativeClient = (
  representative: RepresentativeClientEntity | null
) => {
  const { lockClientIfPossible } = useLockClientIfPossible(
    { ...representative, id: representative?.clientId },
    useLockApi
  );

  const { unlock } = useUnlockClient();
  const unlockClient = () => unlock(representative?.clientId!);

  return { lockClientIfPossible, unlockClient };
};
