/* eslint-disable no-restricted-imports */
import { UpdateAdminUserDto_Input } from 'api/generated/graphql';
import { UpdateAdminParams } from 'api/types/params';

import { parseRole } from './parseRole';

export const parseUpdateAdminParams = (
  params: UpdateAdminParams
): UpdateAdminUserDto_Input => ({
  firstName: params.firstName,
  lastName: params.lastName,
  middleName: params.middleName,
  position: params.position,
  roleName: params.roleName ? parseRole(params.roleName) : undefined,
});
