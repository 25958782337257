import { useAdminResendInvite } from 'api/requests';
import { PermissionType } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

export const useResendInvite = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const {
    resendInvite: resendInviteApi,
    error,
    timerData,
  } = useAdminResendInvite();
  const { checkPermission } = usePermissions();

  const resendInvite = async (id: string) => {
    const permission = checkPermission(PermissionType.AdminResendInvite, {
      showNotify: true,
    });

    if (permission && (await resendInviteApi(id))) {
      notify.info(t('admin.admin.resendInviteSuccess'));
    }
  };

  useServerErrorNotify(error);

  return { resendInvite, timerData, error };
};
