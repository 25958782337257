// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_PepConnectionType as ClientPepConnectionTypeApi,
  Query_LegalClientRepresentativeController_GetById_PepConnectionType as ClientPepConnectionTypeApi2,
  Query_ClientRepresentativeController_GetById_PepConnectionType as ClientPepConnectionTypeApi3,
  Query_LegalClientHeadController_GetById_PepConnectionType as ClientPepConnectionTypeApi4,
  Query_LegalClientLegalHeadFirstHeadController_GetById_PepConnectionType as ClientPepConnectionTypeApi5,
  Query_LegalClientBeneficiaryController_GetById_PepConnectionType as ClientPepConnectionTypeApi6,
  Query_ClientController_GetList_AllOf_1_List_Items_PepConnectionType as ClientPepConnectionTypeApi7,
  Query_ClientController_GetList_AllOf_1_List_Items_Representative_PepConnectionType as ClientPepConnectionTypeApi8,
  Query_LegalClientHeadController_GetSnapshot_Value_PepConnectionType as ClientPepConnectionTypeApi9,
  Query_LegalClientRepresentativeController_GetSnapshot_Value_PepConnectionType as ClientPepConnectionTypeApi10,
  Query_LegalClientLegalHeadFirstHeadController_GetSnapshot_Value_PepConnectionType as ClientPepConnectionTypeApi11,
  Query_LegalClientBeneficiaryController_GetSnapshot_Value_PepConnectionType as ClientPepConnectionTypeApi12,
  PepInfoDto,
} from 'api/generated/graphql';
import { PepConnectionEntity, RelationType } from 'api/types/entity';

export type PepConnectionTypeApi =
  | ClientPepConnectionTypeApi
  | ClientPepConnectionTypeApi2
  | ClientPepConnectionTypeApi3
  | ClientPepConnectionTypeApi4
  | ClientPepConnectionTypeApi5
  | ClientPepConnectionTypeApi6
  | ClientPepConnectionTypeApi7
  | ClientPepConnectionTypeApi8
  | ClientPepConnectionTypeApi9
  | ClientPepConnectionTypeApi10
  | ClientPepConnectionTypeApi11
  | ClientPepConnectionTypeApi12;

const relationType: Record<PepConnectionTypeApi, RelationType> = {
  [ClientPepConnectionTypeApi.None]: RelationType.not,
  [ClientPepConnectionTypeApi.FamilyMember]: RelationType.yesSpouses,
  [ClientPepConnectionTypeApi.Self]: RelationType.yesIAm,
};

export interface ParsePepConnectionEntityParams {
  pepConnectionType?: PepConnectionTypeApi | null;
  connectedPepInfo?: PepInfoDto | null;
}

export const parsePepConnectionEntity = (
  data?: ParsePepConnectionEntityParams
): PepConnectionEntity | undefined =>
  data?.pepConnectionType
    ? {
        relation: relationType[data.pepConnectionType],
        firstname: data.connectedPepInfo?.firstName,
        lastname: data.connectedPepInfo?.lastName,
        position: data.connectedPepInfo?.employerName,
        surname: data.connectedPepInfo?.middleName || undefined,
        workName: data.connectedPepInfo?.jobTitle,
      }
    : undefined;
