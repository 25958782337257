import {
  useLegalClient,
  useLegalClientHead as useLegalClientHeadApi,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId, ROUTES } from 'libs/navigation';

import { LegalClientHeadFormType } from '../forms/legalClientHeadForm';
import { getLegalClientHeadInitialValue } from '../helpers';

import { useHeadPageTitle } from './useHeadPageTitle';
import { useLockLegalClientHead } from './useLockLegalClientHead';
import { useUpdateLegalClientHead } from './useUpdateLegalClientHead';

type NavigateParams = {
  headId: string;
  legalClientId: string;
};

export const useLegalClientHead = () => {
  const { t } = useTranslation();

  const { navigate, params } = useNavigate<NavigateParams>();

  const { loading, legalClientHead, error } = useLegalClientHeadApi(
    params.headId!
  );

  const { updateLegalClientHead, loading: submitLoading } =
    useUpdateLegalClientHead();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientHead(
    legalClient,
    legalClientHead
  );

  useHeadPageTitle(legalClient, legalClientHead);

  const onSubmit: FormSubmit<LegalClientHeadFormType> = async (
    values: LegalClientHeadFormType,
    helpers
  ) => {
    if (await updateLegalClientHead(legalClientHead!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientHead?.versionNumber &&
    legalClientHead?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientHeadVersion.fullPath,
            {
              legalClientId: legalClient.id,
              headId: params.headId!,
              versionId: legalClientHead.versionId!,
            }
          );
        }
      : undefined;

  const initialValues = legalClientHead
    ? getLegalClientHeadInitialValue(legalClientHead, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientHead]: legalClientHead
      ? parseFullName(legalClientHead)
      : '',
    [RoutesId.legalClient]: legalClient?.fullName ?? '',
  };

  return {
    legalClientHead,
    loading: !legalClientHead && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    legalClient,
    onSubmit,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
  };
};
