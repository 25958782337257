import {
  CompanyFinancingSources,
  CompanyIncomeSources,
  IncomeSourcesType,
} from 'api/types/entity';
import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  array,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { localFileScheme } from 'modules/client/common/helpers';
import { SelectType } from 'types/selectType';

import { LocalFileEntity } from '../types';

export interface CompanyFinancingSourcesFormType {
  financingSources: {
    incomeSources: SelectType<CompanyIncomeSources>[];
    otherIncomeSource?: string;
    financingSources: SelectType<CompanyFinancingSources>[];
    otherFinancingSource?: string;
    auditedFinancialReportFiles: LocalFileEntity[];
    recommendationLetterFiles?: LocalFileEntity[];
  };
}

export const MAX_FILES = 10;
export const maxLengthOtherIncomeSource = 200;
export const maxLengthotherFinancingSource = 150;

const isOtherIncomeSource = (values: SelectType<IncomeSourcesType>[]) =>
  values?.find((v) => v?.value === IncomeSourcesType.Other) !== undefined;

const isotherFinancingSource = (
  values: SelectType<CompanyFinancingSources>[]
) =>
  values?.find((v) => v?.value === CompanyFinancingSources.Other) !== undefined;

export const getIncomeSourcesOptions = (t: TFunc) =>
  Object.values(CompanyIncomeSources).map((v) => ({
    label: t(`client.legal.companyFinancingSources.incomeSourcesOptions.${v}`),
    value: v,
  }));

export const getFinancingSourcesOptions = (t: TFunc) =>
  Object.values(CompanyFinancingSources).map((v) => ({
    label: t(
      `client.legal.companyFinancingSources.financingSourcesOptions.${v}`
    ),
    value: v,
  }));

export const getCompanyFinancingSourcesSchema = (
  t: TFunc
): ObjectSchema<CompanyFinancingSourcesFormType> =>
  object({
    financingSources: object({
      incomeSources: array()
        .of(
          object({
            value: mixed<CompanyIncomeSources>()
              .oneOf(Object.values(CompanyIncomeSources))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),
      otherIncomeSource: string().when('incomeSources', {
        is: isOtherIncomeSource,
        then: (schema) => schema.required().max(maxLengthOtherIncomeSource),
      }),
      financingSources: array()
        .of(
          object({
            value: mixed<CompanyFinancingSources>()
              .oneOf(Object.values(CompanyFinancingSources))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),
      otherFinancingSource: string().when('financingSources', {
        is: isotherFinancingSource,
        then: (schema) => schema.required().max(maxLengthotherFinancingSource),
      }),
      auditedFinancialReportFiles: array()
        .of(localFileScheme.required())
        .required()
        .min(1)
        .max(MAX_FILES),
      recommendationLetterFiles: array()
        .of(localFileScheme.required())
        .max(MAX_FILES),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyFinancingSourcesFormType>();
