import { FC } from 'react';

import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';

import {
  LegalClientBeneficiaryForm,
  LegalClientBeneficiaryFormProps,
} from '../components';

export interface LegalClientBeneficiaryViewProps
  extends LegalClientBeneficiaryFormProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
}

export const LegalClientBeneficiaryView: FC<
  LegalClientBeneficiaryViewProps
> = ({ breadcrumbsTitles, ...formProps }) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <LegalClientBeneficiaryForm {...formProps} />
  </LayoutWithNavigationBreadcrumbs>
);
