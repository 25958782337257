import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  date,
  array,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
  localFileScheme,
} from 'modules/client/common/helpers';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

import { LocalFileEntity } from '../types';

export interface CompanyBaseLicenseFormType {
  companyLicense: {
    companyActivityIsLicensed?: Maybe<YesOrNoSelectType>;
    licensedActivityType?: string;
    licenseNumber?: string;
    dateOfIssue?: Date;
    dateOfExpiry?: Date | null;
    authorityName?: string;
  };
}

export interface CompanyLicenseFormType {
  companyLicense: CompanyBaseLicenseFormType['companyLicense'] & {
    files?: LocalFileEntity[];
  };
}

export const MAX_FILES = 10;
export const maxLengthLicenseNumber = 200;
export const maxLengthActivityType = 2000;
export const maxLengthAuthorityName = 2000;

const companyActivityIsLicensed = (v: YesOrNoSelectType) =>
  v?.value === YesOrNoType.Yes;

const getBaseLicenseSchema = (
  t: TFunc
): ObjectSchema<CompanyBaseLicenseFormType['companyLicense']> =>
  object({
    companyActivityIsLicensed: yesOrNotSelectValidation
      .default(null)
      .required(),
    licensedActivityType: string()
      .max(maxLengthActivityType)
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      }),
    licenseNumber: string()
      .max(maxLengthLicenseNumber)
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      }),
    dateOfIssue: date()
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      })
      .max(getToday(), t('validation.maxDate')),
    dateOfExpiry: date()
      .min(getTodayWithResetHours(), t('validation.minDate'))
      .nullable()
      .default(null),
    authorityName: string()
      .max(2000)
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      }),
  });

export const getCompanyBaseLicenseSchema = (
  t: TFunc
): ObjectSchema<CompanyBaseLicenseFormType> =>
  object({
    companyLicense: getBaseLicenseSchema(t),
  });

export const getCompanyLicenseSchema = (
  t: TFunc
): ObjectSchema<CompanyLicenseFormType> =>
  object({
    companyLicense: object({
      files: array()
        .max(MAX_FILES)
        .when('companyActivityIsLicensed', {
          is: companyActivityIsLicensed,
          then: (schema) =>
            schema.of(localFileScheme.required()).required().min(1),
        }),
    }).concat(getBaseLicenseSchema(t)),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyLicenseFormType>();
