import { Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

interface Props {
  title: string;
}

export const Item = ({ title }: Props) => (
  <Stack
    alignItems="center"
    direction="row"
    flex={1}
    justifyContent="space-between"
  >
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={16}
    >
      <Typography variant="bodyBold">{title}</Typography>
    </Stack>
    <Icon.ArrowRight />
  </Stack>
);
