import {
  CompanyIncomeSources,
  CompanyFinancingSources,
} from 'api/types/entity';
import { LegalClientFinancingSourcesParam } from 'api/types/params';

import { CompanyFinancingSourcesFormType } from '../../forms/companyFinancingSourcesForm';

export const getFinancingSourcesParam = (
  values: CompanyFinancingSourcesFormType
): LegalClientFinancingSourcesParam => {
  const incomeSources = values.financingSources.incomeSources.map(
    (v) => v.value
  );
  const financingSources = values.financingSources.financingSources.map(
    (v) => v.value
  );

  const data: LegalClientFinancingSourcesParam = {
    incomeSources,
    financingSources,
    otherIncomeSource: incomeSources.includes(CompanyIncomeSources.Other)
      ? values.financingSources.otherIncomeSource
      : undefined,
    otherFinancingSource: financingSources.includes(
      CompanyFinancingSources.Other
    )
      ? values.financingSources.otherFinancingSource
      : undefined,
    lastYearAuditFinancialReportFileIds:
      values.financingSources.auditedFinancialReportFiles.map(({ id }) => id!),

    recommendationLetterFiles:
      values.financingSources.recommendationLetterFiles?.map(({ id }) => id!),
  };

  return data;
};
