// eslint-disable-next-line no-restricted-imports
import { LegalClientLegalHeadFragment } from 'api/generated/graphql';
import { LegalClientLegalHeadEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';
import { parseLegalClientFirstHeadItemEntity } from '../legal/parseEntity/innerItemEntity/parseLegalClientFirstHeadItemEntity';
import { parseLegalClientStateRegistration } from '../legal/parseEntity/parseLegalClientStateRegistration';
import { parseLegalClientTaxResidency } from '../legal/parseEntity/parseLegalClientTaxResidency';
import { parseOboardingData } from '../parseOboardingData';

export const parseLegalClientLegalHeadEntity = (
  data: LegalClientLegalHeadFragment
): LegalClientLegalHeadEntity => ({
  id: data.id,
  versionNumber: data.version?.number,
  versionId: data.version?.id,
  fullName: data.fullName ?? undefined,
  shortName: data.shortName ?? undefined,
  legalClientId: data.legalClientId ?? undefined,

  legalAddress: data.legalAddress,
  actualAddress: data.actualAddress,

  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseLegalClientTaxResidency)
    : undefined,

  stateRegistration: parseLegalClientStateRegistration(data),

  firstHeads: data.firstHeads
    ? lodash.compact(data.firstHeads).map(parseLegalClientFirstHeadItemEntity)
    : undefined,

  createdAt: new Date(data.createdAt),
  isFirstHead: data.isFirstHead ?? undefined,
  legalClientBody: data.legalClientBody ?? undefined,
  onboarding: parseOboardingData(data.onboarding),

  bodyMemberConfirmationFiles: lodash
    .compact(data.legalClientBodyMemberConfirmationDocuments)
    .map(parseFileDocumentEntity),
  additionalInformationFiles: lodash
    .compact(data.additionalInformationDocuments)
    .map(parseFileDocumentEntity),
});
