// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeQuery } from 'api/generated/graphql';
import { parseLegalClientRepresentative, useError } from 'api/helpers';

export const useLegalClientRepresentative = (id: string) => {
  const {
    data,
    loading,
    refetch,
    error: apolloError,
  } = useLegalClientRepresentativeQuery({ variables: { id } });

  const error = useError(apolloError);

  const legalClientRepresentative = data?.legalClientRepresentative
    ? parseLegalClientRepresentative(data.legalClientRepresentative)
    : null;

  return {
    legalClientRepresentative,
    loading,
    refetch,
    error,
  };
};
