// eslint-disable-next-line no-restricted-imports
import { DebtsFragment } from 'api/generated/graphql';
import { parseAmount } from 'api/helpers/client/parseEntity/parseAmount';
import { DebtsEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

const withoutNull = <T>(v: T) => (v === null ? undefined : v);

export const parseDebtsEntity = (
  debts?: Maybe<DebtsFragment>
): DebtsEntity | undefined =>
  debts
    ? {
        hasMarginTrades: debts.hasMarginTrades,

        hasBankruptcyProceedings: withoutNull(debts.hasBankruptcyProceedings),

        hasDelaysOrNonFulfillmentOfObligations: withoutNull(
          debts.hasDelaysOrNonFulfillmentOfObligations
        ),

        fees: debts.fees ? parseAmount(debts.fees) : undefined,

        penalties: debts.penalties ? parseAmount(debts.penalties) : undefined,

        finesForViolation: debts.finesForViolation
          ? parseAmount(debts.finesForViolation)
          : undefined,

        taxes: debts.taxes ? parseAmount(debts.taxes) : undefined,
      }
    : undefined;
