import { LegalClientEntity, LegalClientHeadEntity } from 'api/types/entity';
import { parseFullName } from 'helpers';
import { usePageTitle } from 'libs/navigation';

export const useHeadPageTitle = (
  legalClient: LegalClientEntity | null,
  legalClientHead: LegalClientHeadEntity | null
) => {
  let fullName = legalClientHead ? parseFullName(legalClientHead) : '';

  fullName += fullName && legalClient ? ` - ${legalClient.fullName}` : '';

  usePageTitle(fullName);
};
