// eslint-disable-next-line no-restricted-imports
import {
  BusinessRelationshipDto_Input,
  Query_LegalClientController_GetList_AllOf_1_List_Items_BusinessRelationship_BusinessRelationshipGoals_Items as BusinessRelationshipGoalsItems,
  Query_LegalClientController_GetList_AllOf_1_List_Items_BusinessRelationship_BusinessRelationshipNatures_Items as BusinessRelationshipNaturesItems,
  Query_LegalClientController_GetList_AllOf_1_List_Items_BusinessRelationship_PlannedAssetTypes_Items as PlannedAssetTypesItems,
  Query_LegalClientController_GetList_AllOf_1_List_Items_BusinessRelationship_PlannedOperationTypes_Items as PlannedOperationTypesItems,
} from 'api/generated/graphql';
import { parsePriceParam } from 'api/helpers/parseParam';
import { LegalClientBusinessRelationshipParam } from 'api/types/params';

export const parseLegalClientBusinessRelationsParam = (
  data: LegalClientBusinessRelationshipParam
): BusinessRelationshipDto_Input => ({
  businessRelationshipGoals: data.businessRelationshipGoals.map(
    (v) => BusinessRelationshipGoalsItems[v]
  ),
  businessRelationshipNatures: data.businessRelationshipNatures.map(
    (v) => BusinessRelationshipNaturesItems[v]
  ),
  plannedAssetSize: parsePriceParam(data.plannedAssetSize),
  plannedAssetTypes: data.plannedAssetTypes.map(
    (v) => PlannedAssetTypesItems[v]
  ),
  plannedOperationTypes: data.plannedOperationTypes.map(
    (v) => PlannedOperationTypesItems[v]
  ),
  otherBusinessRelationshipGoal: data.otherBusinessRelationshipGoal,
  otherBusinessRelationshipNature: data.otherBusinessRelationshipNature,
  otherPlannedOperationType: data.otherPlannedOperationType,
});
