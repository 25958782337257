// eslint-disable-next-line no-restricted-imports
import {
  SaveClientAccountStatusDto_Input,
  MutationInput_ClientController_SaveAccountStatus_Input_Value as ClientAccountStatusTypeApi,
} from 'api/generated/graphql';
import { ClientAccountStatusType } from 'api/types/entity';
import { SaveAccountStatusParam } from 'api/types/params';

// eslint-disable-next-line no-restricted-imports

const statusMap: Record<
  ClientAccountStatusType,
  ClientAccountStatusTypeApi | undefined
> = {
  [ClientAccountStatusType.Opened]: ClientAccountStatusTypeApi.Opened,
  [ClientAccountStatusType.Frozen]: ClientAccountStatusTypeApi.Frozen,
  [ClientAccountStatusType.Closed]: ClientAccountStatusTypeApi.Closed,
  [ClientAccountStatusType.ClosingInProgress]:
    ClientAccountStatusTypeApi.ClosingInProgress,
  [ClientAccountStatusType.OpenedWithRestrictions]: undefined,
};

export const parseSaveClientAccountStatusParam = (
  param: SaveAccountStatusParam
): SaveClientAccountStatusDto_Input => {
  const value = statusMap[param.value];

  if (!value) {
    throw new Error('Invalid account status');
  }

  return {
    value,
    versionNumber: param.versionNumber,
  };
};
