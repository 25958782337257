import { parseFullName } from 'helpers';
import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { AdminsListItem } from '../../types';

import { renderStatus } from './renders';

export const getColumns = (t: TFunc): TableColumn<AdminsListItem>[] => [
  {
    field: 'email' as 'firstName',
    title: t('admin.admins.columns.email'),
    flex: 1,
  },
  {
    field: 'lastName',
    title: t('admin.admins.columns.fullName'),
    sortable: true,
    flex: 1,
    renderValue: parseFullName,
  },
  {
    field: 'status',
    title: t('admin.admins.columns.status'),
    sortable: true,
    flex: 1,
    renderCell: (item) => renderStatus(item, t),
  },
];

export const useColumns = () => {
  const { t } = useTranslation();
  return getColumns(t);
};
