import { useUpdateLegalClientFirstHead as useUpdateLegalClientFirstHeadApi } from 'api/requests';
import { LegalClientFirstHeadEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { LegalClientFirstHeadFormType } from '../forms/legalClientFirstHeadForm';
import { getLegalClientFirstHeadSubmitData } from '../helpers';

export const useUpdateLegalClientFirstHead = () => {
  const { updateLegalClientFirstHead, loading, error } =
    useUpdateLegalClientFirstHeadApi();

  const notify = useNotify();
  const { t } = useTranslation();

  const handleUpdate = async (
    legalClientFirstHead: LegalClientFirstHeadEntity,
    values: LegalClientFirstHeadFormType
  ) => {
    if (legalClientFirstHead.versionNumber !== undefined) {
      const data = getLegalClientFirstHeadSubmitData(
        legalClientFirstHead.versionNumber,
        values
      );
      if (await updateLegalClientFirstHead(legalClientFirstHead.id, data)) {
        notify.info(t('common.successSave'));
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientFirstHead: handleUpdate,
  };
};
