// eslint-disable-next-line no-restricted-imports
import { LegalClientFragment } from 'api/generated/graphql';
import { LegalClientEntity, TariffType } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';
import { parseInformationUpdateFrequency } from '../client/parseEntity/parseInformationUpdateFrequency';
import { parseOpenAccountOrderEntity } from '../client/parseEntity/parseOpenAccountOrderEntity';
import { parseRiskLevel } from '../client/parseEntity/parseRiskLevel';
import { parseWithdrawalAccountsEntity } from '../client/parseEntity/parseWithdrawalAccountsEntity';
import { parseManagementType } from '../parseEntity/parseManagementType';
import { parseServiceContractEntity } from '../parseEntity/parseServiceContractEntity';
import { parseManagerData } from '../parseManagerData';
import { parseOboardingData } from '../parseOboardingData';

import { parseLegalClientBeneficiaryItemEntity } from './parseEntity/innerItemEntity/parseLegalClientBeneficiaryItemEntity';
import { parseLegalClientBranchItemEntity } from './parseEntity/innerItemEntity/parseLegalClientBranchItemEntity';
import { parseLegalClientHeadItemEntity } from './parseEntity/innerItemEntity/parseLegalClientHeadItemEntity';
import { parseLegalClientLegalHeadItemEntity } from './parseEntity/innerItemEntity/parseLegalClientLegalHeadItemEntity';
import { parseBusinessRelationshipEntity } from './parseEntity/parseBusinessRelationshipEntity';
import { parseCategoryType } from './parseEntity/parseCategoryType';
import { parseLegalClientConstitutionalDocument } from './parseEntity/parseConstitutionalDocument';
import { parseDebtsEntity } from './parseEntity/parseDebtsEntity';
import { parseEconomicSectorType } from './parseEntity/parseEconomicSectorType';
import { parseFinancialMonitoringEntity } from './parseEntity/parseFinancialMonitoringEntity';
import { parseLegalClientAccountStatus } from './parseEntity/parseLegalClientAccountStatus';
import { parseLegalClientActivityTypes } from './parseEntity/parseLegalClientActivityTypes';
import { parseLegalClientBankAccounts } from './parseEntity/parseLegalClientBankAccounts';
import { parseLegalClientFinancingSourcesEntity } from './parseEntity/parseLegalClientFinancingSourcesEntity';
import { parseLegalClientInvoice } from './parseEntity/parseLegalClientInvoice';
import { parseLegalClientLicenseInfo } from './parseEntity/parseLegalClientLicenseInfo';
import { parseLegalClientOnboardingStatus } from './parseEntity/parseLegalClientOnboardingStatus';
import { parseLegalClientOrderSubmissionContactsEntity } from './parseEntity/parseLegalClientOrderSubmissionContactsEntity';
import { parseLegalClientStateRegistration } from './parseEntity/parseLegalClientStateRegistration';
import { parseLegalClientTaxResidency } from './parseEntity/parseLegalClientTaxResidency';
import { parseOrganizationalAndLegalFormEntity } from './parseEntity/parseOrganizationalAndLegalFormEntity';
import { parseRegistrationPlaceEntity } from './parseEntity/parseRegistrationPlaceEntity';
import { parseLegalClientOboardingData } from './parseLegalClientOnboardingData';

export const parseLegalClient = (
  client: LegalClientFragment
): LegalClientEntity => ({
  id: client.id,
  versionNumber: client.version?.number,
  versionId: client.version?.id,
  createdAt: new Date(client.createdAt),
  representativeId: client.representative?.id,
  fullName: client.fullName ?? undefined,
  shortName: client.shortName ?? undefined,
  fullNameEng: client.fullNameEng ?? undefined,
  shortNameEng: client.shortNameEng ?? undefined,
  registrationPlace: client.registrationPlace
    ? parseRegistrationPlaceEntity(client.registrationPlace)
    : undefined,
  legalAddress: client.legalAddress,
  actualAddress: client.actualAddress,
  activityTypes: parseLegalClientActivityTypes(client.activityTypes),
  organizationalAndLegalForm: parseOrganizationalAndLegalFormEntity(
    client.organizationalAndLegalForm ?? undefined
  ),
  taxResidences: client.taxResidences
    ? lodash.compact(client.taxResidences).map(parseLegalClientTaxResidency)
    : undefined,
  bankAccounts: parseLegalClientBankAccounts(client.bankAccounts),
  contacts: client.contacts,
  stateRegistration: parseLegalClientStateRegistration(client),
  debts: parseDebtsEntity(client.debts),
  licenseInfo: parseLegalClientLicenseInfo(client.licenseInfo),
  isLicensed: client.isLicensed ?? undefined,
  ownershipStructureFiles: client.ownershipStructureDocuments
    ? lodash
        .compact(client.ownershipStructureDocuments)
        .map(parseFileDocumentEntity)
    : undefined,
  bodies: client.bodies ? lodash.compact(client.bodies) : undefined,
  constitutionalDocument: parseLegalClientConstitutionalDocument(
    client.constitutionalDocument
  ),
  beneficiaries: client.beneficiaries
    ? lodash
        .compact(client.beneficiaries)
        .map(parseLegalClientBeneficiaryItemEntity)
    : undefined,
  heads: client.heads
    ? lodash.compact(client.heads).map(parseLegalClientHeadItemEntity)
    : undefined,
  legalHeads: client.legalHeads
    ? lodash.compact(client.legalHeads).map(parseLegalClientLegalHeadItemEntity)
    : undefined,
  tariff: client.tariff ? TariffType[client.tariff] : undefined,
  financingSource: parseLegalClientFinancingSourcesEntity(
    client.financingSources
  ),
  financialMonitoring: parseFinancialMonitoringEntity(
    client.financialMonitoring
  ),

  businessRelationship: parseBusinessRelationshipEntity(
    client.businessRelationship
  ),
  withdrawalAccounts: parseWithdrawalAccountsEntity(client.withdrawalAccounts),
  orderSubmissionContacts: parseLegalClientOrderSubmissionContactsEntity(
    client.orderSubmissionContacts
  ),
  branches: client.branches
    ? lodash.compact(client.branches).map(parseLegalClientBranchItemEntity)
    : undefined,
  onboardingStatus: parseLegalClientOnboardingStatus(client.onboardingStatus),
  accountStatus: parseLegalClientAccountStatus(client.accountStatus),

  code: client.code ?? undefined,
  centralDepositoryAccountNumber:
    client.centralDepositoryAccountNumber ?? undefined,

  centralDepositoryAccountNumberOpeningDate:
    client.centralDepositoryAccountNumberOpeningDate
      ? new Date(client.centralDepositoryAccountNumberOpeningDate)
      : undefined,

  internalAccountNumber: client.internalAccount?.number ?? undefined,
  internalAccountNumberOpeningDate: client.internalAccount?.openedAt
    ? new Date(client.internalAccount.openedAt)
    : undefined,

  manager: client.manager ? parseManagerData(client.manager) : undefined,

  riskLevel: client.riskLevel ? parseRiskLevel(client.riskLevel) : undefined,

  accreditedInvestorStatus: client.accreditedInvestorStatus ?? undefined,

  informationUpdateFrequency: client.informationUpdateFrequency
    ? parseInformationUpdateFrequency(client.informationUpdateFrequency)
    : undefined,

  signaturesCardFiles: client.signaturesCardDocuments
    ? lodash
        .compact(client.signaturesCardDocuments)
        .map(parseFileDocumentEntity)
    : undefined,

  openAccountOrder: client.openAccountOrder
    ? parseOpenAccountOrderEntity(client.openAccountOrder)
    : undefined,

  additionalDocumentFiles: lodash
    .compact(client.additionalDocuments)
    .map(parseFileDocumentEntity),
  additionalInformationFiles: lodash
    .compact(client.additionalInformationDocuments)
    .map(parseFileDocumentEntity),

  unidentifiableBeneficiaryLetterFiles: lodash
    .compact(client.unidentifiableBeneficiaryLetterDocuments)
    .map(parseFileDocumentEntity),

  invoice: parseLegalClientInvoice(client.invoice),

  category: parseCategoryType(client.category),
  economicSector: parseEconomicSectorType(client.economicSector),
  managementType: parseManagementType(client.managementType),

  isFinancialMonitoringSubject:
    client.isFinancialMonitoringSubject ?? undefined,
  isKazakhstanResident: client.isKazakhstanResident ?? undefined,
  taxBenefits: client.taxBenefits ?? undefined,

  serviceContract: parseServiceContractEntity(client.serviceContract),

  riskLevelReviewDate: client.riskLevelReviewDate
    ? new Date(client.riskLevelReviewDate)
    : undefined,
  lastInformationUpdateDate: client.lastInformationUpdateDate
    ? new Date(client.lastInformationUpdateDate)
    : undefined,
  lastOperationMonitoringResults:
    client.lastOperationMonitoringResults ?? undefined,
  usedServices: client.usedServices ?? undefined,

  representativeOnboarding: parseOboardingData(
    client.representative?.onboarding
  ),
  onboarding: parseLegalClientOboardingData(client.onboarding),
});
