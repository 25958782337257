import { usePageTitleTranslation } from 'libs/navigation';

import { useAdmins } from '../hooks';
import { AdminsView } from '../views/AdminsView';

export const AdminsContainer = () => {
  const {
    admins,
    loading,
    sort,
    page,
    limit,
    rowCount,
    filter,
    createLoading,
    createFormError,
    onSubmitCreate,
    setFilter,
    onCellClick,
    onSortChange,
    onPaginationChange,
  } = useAdmins();

  usePageTitleTranslation('admin.admins.pageTitle');

  return (
    <AdminsView
      admins={admins}
      createFormError={createFormError}
      createLoading={createLoading}
      limit={limit}
      loading={!admins?.length && loading}
      page={page}
      rowCount={rowCount}
      search={filter.search ?? ''}
      sort={sort}
      onCellClick={onCellClick}
      onPaginationChange={onPaginationChange}
      onSearch={(v) => setFilter({ search: v })}
      onSortChange={onSortChange}
      onSubmitCreate={onSubmitCreate}
    />
  );
};
