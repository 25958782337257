import { TypedForm, ObjectSchema, object, date, array } from 'libs/form';
import { TFunc } from 'libs/i18n';
import { getToday, localFileScheme } from 'modules/client/common/helpers';

import { LocalFileEntity } from '../types';

export interface CompanyConstituentDocsFormType {
  constituentDocs: {
    dateOfLastChange: Date;
    files: LocalFileEntity[];
  };
}
export const MAX_FILES = 10;

export const getCompanyConstituentDocsSchema = (
  t: TFunc
): ObjectSchema<CompanyConstituentDocsFormType> =>
  object({
    constituentDocs: object({
      dateOfLastChange: date()
        .required()
        .max(getToday(), t('validation.maxDate')),
      files: array().of(localFileScheme).required().min(1).max(MAX_FILES),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyConstituentDocsFormType>();
