// eslint-disable-next-line no-restricted-imports
import {
  BiographyFragment,
  Query_ClientController_GetList_AllOf_1_List_Items_Representative_Biography_MaritalStatus as MaritalStatusApi,
  Query_ClientController_GetList_AllOf_1_List_Items_Representative_Biography_Occupation as OccupationApi,
} from 'api/generated/graphql';
import {
  BiographyEntity,
  MaritalStatusType,
  OccupationType,
} from 'api/types/entity';

const maritalStatus: Record<MaritalStatusApi, MaritalStatusType> = {
  [MaritalStatusApi.Married]: MaritalStatusType.married,
  [MaritalStatusApi.Single]: MaritalStatusType.single,
};
const occupation: Record<OccupationApi, OccupationType> = {
  [OccupationApi.BusinessOwner]: OccupationType.businessOwner,
  [OccupationApi.Employee]: OccupationType.employee,
  [OccupationApi.Entrepreneur]: OccupationType.entrepreneur,
  [OccupationApi.Retired]: OccupationType.retired,
  [OccupationApi.Student]: OccupationType.student,
  [OccupationApi.Unemployed]: OccupationType.unemployed,
};

export const parseBiographyEntity = (
  biography: BiographyFragment
): BiographyEntity => ({
  companyName: biography.companyName || undefined,
  position: biography.position || undefined,
  entrepreneurName: biography.entrepreneurName || undefined,
  registrationNumber: biography.registrationNumber || undefined,
  typeOfServices: biography.typeOfServices || undefined,
  workAddress: biography.workAddress || undefined,
  registrationPlace: biography.registrationPlace || undefined,
  maritalStatus: maritalStatus[biography.maritalStatus],
  occupation: occupation[biography.occupation],
  registrationDate: biography.registrationDate
    ? new Date(biography.registrationDate)
    : undefined,
});
