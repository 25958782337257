/* eslint-disable no-restricted-imports */
import { Admin_Const } from 'api/generated/graphql';
import { RoleType } from 'api/types/entity';

const roles: Record<RoleType, Admin_Const | undefined> = {
  [RoleType.Admin]: Admin_Const.Admin,
  [RoleType.SuperAdmin]: undefined,
};

export const parseRole = (role: RoleType): Admin_Const | undefined =>
  roles[role];
