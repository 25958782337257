// eslint-disable-next-line no-restricted-imports
import { OpenAccountOrderDto_Input } from 'api/generated/graphql';
import { OpenAccountOrderEntity } from 'api/types/entity';
import { formatToISODate } from 'helpers';

export const parseOpenAccountOrderParam = (
  openAccountOrder: OpenAccountOrderEntity
): OpenAccountOrderDto_Input => ({
  date: openAccountOrder.date ? formatToISODate(openAccountOrder.date) : null,
  orderReceivedDate: openAccountOrder.orderReceivedDate
    ? formatToISODate(openAccountOrder.orderReceivedDate)
    : null,
  number: openAccountOrder.number || null,
});
