import { AdminStatusType } from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import { Chip, ChipVariant } from 'libs/ui';
import { AdminsListItem } from 'modules/admin/admins/types';

export const renderStatus = (item: AdminsListItem, t: TFunc) => {
  let variant: ChipVariant = 'blue';

  const text = t(`admin.status.${item.status}`);

  switch (item.status) {
    case AdminStatusType.Active:
      variant = 'green';
      break;

    case AdminStatusType.Deactivated:
      variant = 'red';
      break;

    default:
      variant = 'green';
      break;
  }

  return <Chip size="small" text={text} variant={variant} />;
};
