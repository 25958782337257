import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// eslint-disable-next-line no-restricted-imports
import { ThemeProvider as ThemeProviderMui } from '@mui/material';

import { Theme, createTheme, PaletteMode } from './theme';

// Define a constant for the local storage key used to store the selected theme mode.
const THEME_MODE_KEY = 'themeMode';

/**
 * Defines the shape of the theme context object used for theming within the application.
 * @interface ThemeContextType
 */
interface ThemeContextType {
  /** The theme configuration */
  theme: Theme;
  /** The current theme mode ('light' or 'dark'). */
  mode: PaletteMode;
  /** Function to toggle between 'light' and 'dark' modes */
  toggleColorMode: () => void;
}

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

/**
 * ThemeProvider component for managing the application's theme.
 */
export function ThemeProvider({ children }: PropsWithChildren): JSX.Element {
  const localMode = useRef(localStorage.getItem(THEME_MODE_KEY)).current;
  const currentTheme = useRef<PaletteMode>(
    localMode === 'dark' ? 'dark' : 'light'
  ).current;

  const [mode, setMode] = useState<PaletteMode>(currentTheme);

  // Function to toggle between 'light' and 'dark' theme modes.
  const toggleColorMode = useCallback(() => {
    setMode((prevMode: PaletteMode) =>
      prevMode === 'light' ? 'dark' : 'light'
    );
  }, []);

  // Effect to persist the selected theme mode in local storage when it changes.
  useEffect(() => {
    localStorage.setItem(THEME_MODE_KEY, mode);
  }, [mode]);

  const theme = useMemo(() => createTheme(mode), [mode]);

  const value = useMemo(
    () => ({ toggleColorMode, mode, theme }),
    [mode, toggleColorMode, theme]
  );

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProviderMui theme={theme}>{children}</ThemeProviderMui>
    </ThemeContext.Provider>
  );
}
/**
 * Hook for accessing the theme context and functions.
 * @returns {ThemeContextType} The theme context and functions.
 */
export const useTheme = (): ThemeContextType => useContext(ThemeContext);
