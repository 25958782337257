import { ReactNode } from 'react';

import { SearchBarProps, SearchBar } from 'components/SearchBar/SearchBar';
import { useTranslation } from 'libs/i18n';
import { Drawer, Pressed, Typography } from 'libs/ui';
import { Colors } from 'libs/ui/theme';

import { FilterContainer } from './styles';

interface Props extends Omit<SearchBarProps, 'leftElement'> {
  filterCount?: number;
  isFilterOpen: boolean;
  filterContent: ReactNode;
  onFilterClose: () => void;
  onFilterOpen: () => void;
}

export const SearchBarWithFilter = ({
  filterCount,
  isFilterOpen,
  filterContent,
  onFilterOpen,
  onFilterClose,
  ...searchProps
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <SearchBar
        {...searchProps}
        leftElement={
          <FilterContainer>
            <Pressed onClick={onFilterOpen}>
              <Typography
                color={filterCount ? Colors.gray300 : Colors.gray250}
                variant="bodySemiBold"
              >
                {t('common.filters') + (filterCount ? ` (${filterCount})` : '')}
              </Typography>
            </Pressed>
          </FilterContainer>
        }
      />
      <Drawer isOpen={isFilterOpen} onClose={onFilterClose}>
        {filterContent}
      </Drawer>
    </>
  );
};
