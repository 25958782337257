import { CSSObject } from 'libs/ui/styled';
import { Colors, Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

import { getStandardMode } from './standard';

const getColorPrimary = () => ({
  textColor: Colors.blue150,
  borderColor: Colors.blue150,
  hoverBgColor: Colors.blue50,
  disableBorderColor: Colors.gray250,
  disableTextColor: Colors.gray250,
});

const getColorRed = () => ({
  textColor: Colors.red100,
  borderColor: Colors.red100,
  hoverBgColor: Colors.red50,
  disableBorderColor: Colors.gray250,
  disableTextColor: Colors.gray250,
});

export const getOutlinedMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => {
  const colors = colorVariant === 'primary' ? getColorPrimary() : getColorRed();

  return {
    ...getStandardMode(theme, colorVariant),
    background: 'none',
    color: colors.textColor,
    border: `1px solid ${colors.borderColor}`,

    '&:hover': {
      backgroundColor: colors.hoverBgColor,
    } as CSSObject,

    '&:active': {
      background: 'none',
    } as CSSObject,

    '&:disabled': {
      borderColor: colors.disableTextColor,
      color: colors.disableTextColor,
    } as CSSObject,
  };
};
