import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientHeadMutation } from 'api/generated/graphql';
import {
  parseLegalClientHeadEntity,
  parseUpdateLegalClientHeadParams,
  useError,
} from 'api/helpers';
import { UpdateLegalClientHeadParams } from 'api/types/params';

export const useUpdateLegalClientHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientHeadMutation();

  const error = useError(apolloError);

  return {
    legalClientHead: data?.updateLegalClientHead
      ? parseLegalClientHeadEntity(data.updateLegalClientHead)
      : undefined,
    loading,
    error,
    updateLegalClientHead: useCallback(
      async (id: string, params: UpdateLegalClientHeadParams) => {
        const input = parseUpdateLegalClientHeadParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateLegalClientHead
          ? parseLegalClientHeadEntity(res.data?.updateLegalClientHead)
          : undefined;
      },
      [handle]
    ),
  };
};
