import {
  useLegalClient,
  useLegalClientFirstHead,
  useLegalClientFirstHeadSnapshot,
  useLegalClientLegalHead,
} from 'api/requests';
import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getLegalClientFirstHeadInitialValue } from '../helpers';

import { useLegalClientFirstHeadVersions } from './useLegalClientFirstHeadVersions';

type ContainerParams = {
  firstHeadId: string;
  headId: string;
  legalClientId: string;
  versionId: string;
};

export const useLegalClientFirstHeadVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ContainerParams>();

  const { loading: loadingFirstHeadClient, legalClientFirstHead } =
    useLegalClientFirstHead(params.firstHeadId!);

  const { loading: loadingLegalHeadClient, legalClientLegalHead } =
    useLegalClientLegalHead(params.headId!);

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: versionsLoading, versions } =
    useLegalClientFirstHeadVersions(
      params.legalClientId!,
      params.firstHeadId!,
      params.versionId
    );

  const {
    snapshot,
    loading: snapshotLoading,
    error,
    isError,
  } = useLegalClientFirstHeadSnapshot(params.firstHeadId!, params.versionId!, {
    fetchPolicy: 'no-cache',
  });

  const initialValues = snapshot?.value
    ? getLegalClientFirstHeadInitialValue(snapshot.value, t)
    : undefined;

  const fullName = legalClientFirstHead
    ? parseFullName(legalClientFirstHead)
    : '';

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: legalClient?.fullName,
    [RoutesId.legalClientVersion]: t('client.client.versions.header', {
      name: legalClient?.fullName,
    }),
    [RoutesId.legalClientLegalHeadVersion]: t('client.client.versions.header', {
      name: legalClientLegalHead?.fullName,
    }),
    [RoutesId.legalClientFirstHeadVersion]: t('client.client.versions.header', {
      name: fullName,
    }),
  };

  const loading =
    (!legalClientFirstHead && loadingFirstHeadClient) ||
    (!legalClientLegalHead && loadingLegalHeadClient) ||
    (!versions && versionsLoading);

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientLegalHead
        .legalClientFirstHead.fullPath,
      {
        legalClientId: params.legalClientId!,
        headId: params.headId!,
        firstHeadId: params.firstHeadId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: LegalClientVersionEntity) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientLegalHeadVersion.legalClientFirstHeadVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        headId: params.headId!,
        versionId: version.id,
        firstHeadId: params.firstHeadId!,
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientLegalHeadVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        headId: params.headId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    isError,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
  };
};
