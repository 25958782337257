import { RoleType } from 'api/types/entity';
import { isValidName } from 'helpers';
import {
  ObjectSchema,
  string,
  object,
  FormErrors as FormErrorsBase,
  mixed,
  email,
  TypedForm,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';

export interface AdminFormType {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  position?: string;
  role: SelectType<RoleType>;
}

export const getSchema = (t: TFunc): ObjectSchema<AdminFormType> =>
  object({
    email: email(t).required(),
    firstName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    lastName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    middleName: string()
      .max(20)
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    position: string().max(200),
    role: object({
      value: mixed<RoleType>().oneOf(Object.values(RoleType)).required(),
      label: string().required(),
    }).required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<AdminFormType>();

export type FormErrors = FormErrorsBase<AdminFormType>;
