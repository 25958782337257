// eslint-disable-next-line no-restricted-imports
import { LegalClientBranchSnapshotFragment } from 'api/generated/graphql';
import { LegalClientBranchSnapshotEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { parseLegalClientBranchEntity } from './parseLegalClientBranchEntity';

export const parseLegalClientBranchSnapshotEntity = (
  data: Maybe<LegalClientBranchSnapshotFragment>
): LegalClientBranchSnapshotEntity | undefined =>
  data
    ? {
        createdAt: new Date(data.createdAt),
        id: data.id,
        value: parseLegalClientBranchEntity(data.value),
      }
    : undefined;
