import {
  ObjectSchema,
  string,
  object,
  array,
  TypedForm,
  number,
  date,
  boolean,
} from 'libs/form';

import { LocalFileEntity } from '../types';

export interface DocumentsFormType {
  documentsFiles?: LocalFileEntity[];
}

export const documentsSchema: ObjectSchema<DocumentsFormType> = object({
  documentsFiles: array().of(
    object({
      id: string(),
      name: string().required(),
      size: number().required(),
      createdAt: date().required(),
      uploadedByAdmin: boolean(),
    }).required()
  ),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<DocumentsFormType>();
