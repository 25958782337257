import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Field } from 'modules/client/common/forms/placeOfBirthForm';

import { Row } from '../../../Row';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Box flex={1}>
        <Field.Autocomplete
          label={t('client.client.placeOfBirth.country.label')}
          loading={countriesLoading}
          name="placeOfBirth.country"
          optionLabelKey="name"
          options={countries}
          optionValueKey="id"
          placeholder={t('client.client.placeOfBirth.country.placeholder')}
        />
      </Box>
      <Box flex={1}>
        <Field.Text
          fullWidth
          label={t('client.client.placeOfBirth.locality.label')}
          name="placeOfBirth.locality"
          placeholder={t('client.client.placeOfBirth.locality.placeholder')}
        />
      </Box>
    </Row>
  );
};
