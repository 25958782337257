import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Eye = ({ size = 24, color = Colors.black, onClick }: SvgProps) => (
  <SvgIcon size={size} onClick={onClick}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.18805 11.9997C7.18805 9.34765 9.34523 7.18957 12.0002 7.18957C14.6523 7.18957 16.8095 9.3476 16.8095 11.9997C16.8095 14.6524 14.6523 16.8096 12.0002 16.8096C9.34519 16.8096 7.18805 14.6524 7.18805 11.9997ZM1.33734 11.5408C1.17141 11.841 1.17141 12.1585 1.33734 12.4589C3.49167 16.3275 7.57711 18.7308 12.0002 18.7308C16.4204 18.7308 20.5058 16.3276 22.6602 12.4589C22.829 12.1585 22.829 11.841 22.6602 11.5408C20.5058 7.67224 16.4204 5.26929 12.0002 5.26929C7.57711 5.26929 3.49167 7.67224 1.33734 11.5408ZM12.0002 8.76597C10.215 8.76597 8.76441 10.2165 8.76441 11.9997C8.76441 13.783 10.2149 15.2335 12.0002 15.2335C13.7826 15.2335 15.2331 13.783 15.2331 11.9997C15.2331 10.2165 13.7826 8.76597 12.0002 8.76597Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  </SvgIcon>
);
