import { RelationType } from 'api/types/entity';
import { isValidName } from 'helpers';
import { ObjectSchema, string, object, mixed, TypedForm } from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export interface PepConnectionFormType {
  pepConnection: {
    relation: Maybe<SelectType<RelationType>>;

    firstname?: string;
    lastname?: string;
    surname?: string;

    workName?: string;
    position?: string;
  };
}

const isYes = (v: SelectType<RelationType>) =>
  v?.value === RelationType.yesSpouses;

export const getPepConnectionSchema = (
  t: TFunc
): ObjectSchema<PepConnectionFormType> =>
  object({
    pepConnection: object({
      relation: object({
        value: mixed<RelationType>()
          .oneOf(Object.values(RelationType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .required(),

      firstname: string().when('relation', {
        is: isYes,
        then: (schema) =>
          schema
            .max(20)
            .required()
            .test(
              'ischars',
              t('validation.noSpecialCharsAndNumber'),
              isValidName
            ),
      }),

      lastname: string().when('relation', {
        is: isYes,
        then: (schema) =>
          schema
            .max(20)
            .required()
            .test(
              'ischars',
              t('validation.noSpecialCharsAndNumber'),
              isValidName
            ),
      }),

      surname: string().when('relation', {
        is: isYes,
        then: (schema) =>
          schema
            .max(20)
            .test(
              'ischars',
              t('validation.noSpecialCharsAndNumber'),
              isValidName
            ),
      }),
      workName: string().when('relation', {
        is: isYes,
        then: (schema) => schema.max(2000).required(),
      }),

      position: string().when('relation', {
        is: isYes,
        then: (schema) => schema.max(2000).required(),
      }),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PepConnectionFormType>();
