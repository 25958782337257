import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
} from 'api/types/entity';

export const shouldShowResultCheckInput = (
  currentStatus: ClientOnboardingStatusType | ClientAccountStatusType | null
) => {
  switch (currentStatus) {
    case ClientOnboardingStatusType.ValidationCheck:
    case ClientOnboardingStatusType.ComplianceCheck:
    case ClientOnboardingStatusType.AmlCftCheck:
    case ClientOnboardingStatusType.AwaitingPaymentConfirmation:
    case ClientOnboardingStatusType.BoardReview:
      return true;

    default: {
      return false;
    }
  }
};
