// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_UserController_GetNotifications_SortOrder,
  useNotificationsQuery,
} from 'api/generated/graphql';
import { parseNotificationEntity, useError } from 'api/helpers';
import { ListDataQueryFunction } from 'api/types/data';
import { ListData } from 'api/types/data/listData';
import { NotificationEntity } from 'api/types/entity';
import { lodash } from 'helpers';

type SortField = 'createdAt';

export const useNotificationList: ListDataQueryFunction<
  NotificationEntity,
  SortField,
  {},
  Pick<ReturnType<typeof useNotificationsQuery>, 'refetch'> & {
    unreadCount?: number;
  }
> = (pagination) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useNotificationsQuery({
    variables: {
      limit: pagination.limit,
      page: pagination.page + 1,
      sortOrder: QueryInput_UserController_GetNotifications_SortOrder.Desc,
      sortBy: 'createdAt',
    },
  });

  const error = useError(apolloError);

  const listData: ListData<NotificationEntity> | undefined = data?.notifications
    ? {
        total: data?.notifications?.total,

        list: lodash
          .compact(data?.notifications.list)
          .map(parseNotificationEntity),
      }
    : undefined;

  return {
    listData,
    unreadCount: data?.notifications?.unreadCount,
    loading,
    error,
    refetch,
  };
};
