import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  Field,
  useFormContext,
} from 'modules/client/common/forms/taxResidencyForm';

interface Props {
  index: number;
}

export const Header = ({ index }: Props) => {
  const { readOnly } = useFormContext();
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="subtitle">
        {t(
          index === 0
            ? 'client.client.taxResidency.taxNumberTitle'
            : 'client.client.taxResidency.taxNumberTitleOther'
        )}
      </Typography>
      {!readOnly && (
        <Stack alignItems="center" direction="row" spacing={16}>
          <Typography variant="bodyMedium">
            {t('client.client.taxResidency.taxNumberIsMissing')}
          </Typography>

          <Field.Switch
            name={
              `taxResidences.${index}.taxNumberIsMissing` as 'taxResidences'
            }
          />
        </Stack>
      )}
    </Stack>
  );
};
