import {
  CompanyFinancingSources,
  CompanyIncomeSources,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  useFormContext,
  Field,
  getIncomeSourcesOptions,
  getFinancingSourcesOptions,
  maxLengthotherFinancingSource,
  maxLengthOtherIncomeSource,
} from 'modules/client/legal/forms/companyFinancingSourcesForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { values, readOnly } = useFormContext();
  const { t } = useTranslation();

  const isOtherIncomeSource = values.financingSources.incomeSources?.some(
    (source) => source.value === CompanyIncomeSources.Other
  );
  const isFinancingSourcesOther =
    values.financingSources.financingSources?.some(
      (source) => source.value === CompanyFinancingSources.Other
    );

  return (
    <>
      <Field.Autocomplete
        multiple
        label={t('client.legal.companyFinancingSources.incomeSources.label')}
        name="financingSources.incomeSources"
        optionLabelKey="label"
        options={getIncomeSourcesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyFinancingSources.incomeSources.placeholder'
        )}
        variant="select"
      />

      {isOtherIncomeSource && (
        <Field.Text
          label={t('client.legal.companyFinancingSources.other.label')}
          maxLength={maxLengthOtherIncomeSource}
          name="financingSources.otherIncomeSource"
          placeholder={t(
            'client.legal.companyFinancingSources.other.placeholder'
          )}
        />
      )}

      <Field.Autocomplete
        multiple
        label={t('client.legal.companyFinancingSources.financingSources.label')}
        name="financingSources.financingSources"
        optionLabelKey="label"
        options={getFinancingSourcesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyFinancingSources.financingSources.placeholder'
        )}
        variant="select"
      />

      {isFinancingSourcesOther && (
        <Field.Text
          label={t('client.legal.companyFinancingSources.other.label')}
          maxLength={maxLengthotherFinancingSource}
          name="financingSources.otherFinancingSource"
          placeholder={t(
            'client.legal.companyFinancingSources.other.placeholder'
          )}
        />
      )}

      <DocumentsTableFeature
        name="financingSources.auditedFinancialReportFiles"
        readOnly={readOnly}
        title={t(
          'client.legal.companyFinancingSources.auditedFinancialReportDocumentTitle'
        )}
      />

      <DocumentsTableFeature
        name="financingSources.recommendationLetterFiles"
        readOnly={readOnly}
        title={t(
          'client.legal.companyFinancingSources.recommendationLetterDocumentTitle'
        )}
      />
    </>
  );
};
