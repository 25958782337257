import { useMemo } from 'react';

import { useCreateLegalClientBeneficiary } from 'api/requests';
import { useCountries } from 'api/requests/country';
import {
  LegalClientEntity,
  LegalClientBeneficiaryEntity,
} from 'api/types/entity';
import { ErrorCode } from 'api/types/error';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import {
  FormErrors,
  getSchema,
  LegalClientBeneficiaryFormType,
} from '../forms/legalClientBeneficiaryForm';
import {
  getLegalClientBeneficiaryInitialValue,
  getLegalClientBeneficiarySubmitData,
} from '../helpers';

export const useLegalClientAddBeneficiary = (
  legalClient: LegalClientEntity,
  onSuccess: (legalClientBeneficiary: LegalClientBeneficiaryEntity) => void
) => {
  const { countries, loading: countriesLoading } = useCountries();
  const { t } = useTranslation();
  const notify = useNotify();

  const {
    loading: submitLoading,
    createLegalClientBeneficiary,
    error: submitError,
  } = useCreateLegalClientBeneficiary();

  const initialValues = getLegalClientBeneficiaryInitialValue(t);

  const validationSchema = getSchema(t);

  const onSubmit: FormSubmit<LegalClientBeneficiaryFormType> = async (
    values
  ) => {
    const params = getLegalClientBeneficiarySubmitData(
      legalClient.id,
      legalClient.versionNumber!,
      values
    );

    const beneficiary = await createLegalClientBeneficiary(params);
    if (beneficiary) {
      onSuccess(beneficiary);
      notify.info(
        t('client.legal.legalClientBeneficiary.addDialog.successAdd')
      );
    }
  };

  const formError = useMemo((): FormErrors | undefined => {
    if (submitError?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return { personalData: { email: submitError?.message } };
    }

    return undefined;
  }, [submitError?.code, submitError?.message]);

  return {
    countries: countries ?? [],
    countriesLoading,
    initialValues,
    validationSchema,
    submitLoading,
    formError,
    onSubmit,
  };
};
