// eslint-disable-next-line no-restricted-imports
import { InMemoryCache } from '@apollo/client';

import { mergeListQuery } from './mergeListQuery';

export const getCache = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          UserController_getNotifications: {
            keyArgs: false,
            merge: mergeListQuery,
          },
        },
      },
    },
  });

  return cache;
};
