import { ReactNode } from 'react';

import { NavigateOptions, useNavigate } from 'libs/navigation';
import { ParamsType } from 'libs/navigation/types';
import { Pressed, Stack } from 'libs/ui';
import { Colors } from 'libs/ui/theme';

import { HeaderStyled } from './ContentHeader.styles';

interface Props<Path extends string, State> {
  header: string;
  canBack?: boolean;
  backPath?: Path;
  backParams?: ParamsType<Path>;
  backOptions?: NavigateOptions<State>;
  forceBackPath?: boolean;
  rightContent?: ReactNode;
  onBack?: () => void;
}

const LeftIcon = () => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 30 30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_853_2237)">
      <path
        d="M18.525 3.525L15 0L0 15L15 30L18.525 26.475L7.075 15L18.525 3.525Z"
        fill={Colors.black}
      />
    </g>
    <defs>
      <clipPath id="clip0_853_2237">
        <rect fill="white" height="30" width="30" />
      </clipPath>
    </defs>
  </svg>
);

export const ContentHeader = <Path extends string, State>({
  header,
  canBack,
  backPath,
  backParams,
  backOptions,
  rightContent,
  forceBackPath,
  onBack,
}: Props<Path, State>) => {
  const { goBack, navigate, canGoBack } = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else if (backPath && (forceBackPath || !canGoBack)) {
      navigate(backPath, backParams, backOptions);
    } else {
      goBack();
    }
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={32}
    >
      <Stack alignItems="center" direction="row" spacing={12}>
        {canBack && (
          <Pressed onClick={handleBack}>
            <LeftIcon />
          </Pressed>
        )}
        <HeaderStyled variant="title">{header}</HeaderStyled>
      </Stack>
      {rightContent}
    </Stack>
  );
};
