import { styled } from '../styled';
import { Colors } from '../theme';

import { DropzoneState } from './types';

const getColor = (props: DropzoneState, isError?: Boolean) => {
  if (props.isDragAccept) {
    return Colors.blue150;
  }
  if (props.isDragReject) {
    return Colors.red100;
  }
  if (props.isFocused) {
    return Colors.blue150;
  }
  if (isError) {
    return Colors.red100;
  }
  return Colors.blue100;
};

export const ContainerStyled = styled('div')<
  DropzoneState & { showFiles: Boolean; isError?: Boolean }
>(({ theme, showFiles, isError, ...props }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  borderWidth: 1,
  borderRadius: 12,
  borderColor: getColor(props, isError),
  borderStyle: 'dashed',
  outline: 'none',

  '.filesUpload-arrowFile': {
    rotate: showFiles ? '-180deg' : 0,
  },
}));

export const LineStyled = styled('div')(() => ({
  backgroundColor: Colors.blue100,
  height: 1,
}));
