import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientBeneficiaryMutation } from 'api/generated/graphql';
import {
  parseLegalClientBeneficiaryEntity,
  parseUpdateLegalClientBeneficiaryParams,
  useError,
} from 'api/helpers';
import { UpdateLegalClientBeneficiaryParams } from 'api/types/params';

export const useUpdateLegalClientBeneficiary = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientBeneficiaryMutation();

  const error = useError(apolloError);

  return {
    legalClientBeneficiary: data?.updateLegalClientBeneficiary
      ? parseLegalClientBeneficiaryEntity(data.updateLegalClientBeneficiary)
      : undefined,
    loading,
    error,
    updateLegalClientBeneficiary: useCallback(
      async (id: string, params: UpdateLegalClientBeneficiaryParams) => {
        const input = parseUpdateLegalClientBeneficiaryParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateLegalClientBeneficiary
          ? parseLegalClientBeneficiaryEntity(
              res.data?.updateLegalClientBeneficiary
            )
          : undefined;
      },
      [handle]
    ),
  };
};
