const getString = (n: number) => (n < 10 ? `0${n}` : n).toString();

export const secondsToTime = (secs: number) => {
  const hours = Math.floor(secs / (60 * 60));

  const divisorForMinutes = secs % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  const obj = {
    hours,
    minutes,
    seconds,

    hoursString: getString(hours),
    minutesString: getString(minutes),
    secondsString: getString(seconds),
  };
  return obj;
};
