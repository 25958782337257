import {
  BusinessRelationshipGoalType,
  BusinessRelationshipNatureType,
  OperationType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  useFormContext,
  Field,
  getPlannedAssetTypesOptions,
  getPlannedOperationTypesOptions,
  getBusinessRelationshipGoalTypeOption,
  getBusinessRelationshipNatureTypeOption,
} from 'modules/client/legal/forms/companyBusinessRelationshipForm';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isOtherPlannedOperationTypes =
    values.businessRelationship.plannedOperationTypes?.some(
      (source) => source.value === OperationType.Other
    );

  const isOtherBusinessRelationshipNatures =
    values.businessRelationship.businessRelationshipNatures?.some(
      (source) => source.value === BusinessRelationshipNatureType.Other
    );

  const isOtherBusinessRelationshipGoals =
    values.businessRelationship.businessRelationshipGoals?.some(
      (source) => source.value === BusinessRelationshipGoalType.Other
    );

  return (
    <Stack spacing={24}>
      <Field.Autocomplete
        multiple
        label={t(
          'client.legal.companyBusinessRelationship.plannedAssetTypes.label'
        )}
        name="businessRelationship.plannedAssetTypes"
        optionLabelKey="label"
        options={getPlannedAssetTypesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyBusinessRelationship.plannedAssetTypes.placeholder'
        )}
        variant="select"
      />

      <Field.Autocomplete
        multiple
        label={t(
          'client.legal.companyBusinessRelationship.plannedOperationTypes.label'
        )}
        name="businessRelationship.plannedOperationTypes"
        optionLabelKey="label"
        options={getPlannedOperationTypesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyBusinessRelationship.plannedOperationTypes.placeholder'
        )}
        variant="select"
      />

      {isOtherPlannedOperationTypes && (
        <Field.Text
          label={t('client.legal.companyBusinessRelationship.other.label')}
          name="businessRelationship.otherPlannedOperationType"
          placeholder={t(
            'client.legal.companyBusinessRelationship.other.placeholder'
          )}
        />
      )}

      <Field.Price
        label={t(
          'client.legal.companyBusinessRelationship.plannedAssetSize.label'
        )}
        name="businessRelationship.plannedAssetSize"
        placeholder={t(
          'client.legal.companyBusinessRelationship.plannedAssetSize.placeholder'
        )}
      />

      <Field.Autocomplete
        multiple
        label={t(
          'client.legal.companyBusinessRelationship.businessRelationshipGoals.label'
        )}
        name="businessRelationship.businessRelationshipGoals"
        optionLabelKey="label"
        options={getBusinessRelationshipGoalTypeOption(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyBusinessRelationship.businessRelationshipGoals.placeholder'
        )}
        variant="select"
      />

      {isOtherBusinessRelationshipGoals && (
        <Field.Text
          label={t('client.legal.companyBusinessRelationship.other.label')}
          name="businessRelationship.otherBusinessRelationshipGoal"
          placeholder={t(
            'client.legal.companyBusinessRelationship.other.placeholder'
          )}
        />
      )}

      <Field.Autocomplete
        multiple
        label={t(
          'client.legal.companyBusinessRelationship.businessRelationshipNatures.label'
        )}
        name="businessRelationship.businessRelationshipNatures"
        optionLabelKey="label"
        options={getBusinessRelationshipNatureTypeOption(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyBusinessRelationship.businessRelationshipNatures.placeholder'
        )}
        variant="select"
      />

      {isOtherBusinessRelationshipNatures && (
        <Field.Text
          label={t('client.legal.companyBusinessRelationship.other.label')}
          name="businessRelationship.otherBusinessRelationshipNature"
          placeholder={t(
            'client.legal.companyBusinessRelationship.other.placeholder'
          )}
        />
      )}
    </Stack>
  );
};
