// eslint-disable-next-line no-restricted-imports
import {
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails as AccordionDetailsBase,
} from '@mui/material';

import { styled } from '../styled';
import { Colors } from '../theme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledAccordion = styled(AccordionBase)(({ theme }) => ({
  border: 'none',
  borderBottom: `1px solid ${Colors.gray100}`,

  '&:last-child': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummaryBase)(() => ({
  padding: 0,

  '& .MuiAccordionSummary-content': {
    margin: '16px 0',
  },
}));

export const StyledAccordionDetails = styled(AccordionDetailsBase)(() => ({
  padding: '0 0 16px 0',
}));
