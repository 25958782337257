// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetById_AccountStatus as LegalClientAccountStatusTypeApi,
  Query_LegalClientController_GetList_AllOf_1_List_Items_AccountStatus as LegalClientAccountStatusTypeApi2,
  Query_LegalClientController_GetAvailableStatuses_Account_Items as LegalClientAccountStatusTypeApi3,
} from 'api/generated/graphql';
import { ClientAccountStatusType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type Status =
  | LegalClientAccountStatusTypeApi
  | LegalClientAccountStatusTypeApi2
  | LegalClientAccountStatusTypeApi3;

export const parseLegalClientAccountStatus = (status: Maybe<Status>) =>
  status ? ClientAccountStatusType[status] : null;
