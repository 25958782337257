import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const HeaderStyled = styled(Stack)(() => ({
  borderBottom: `1px solid ${Colors.gray150}`,
  position: 'sticky',
  backgroundColor: Colors.white,
  zIndex: 10,
  top: 0,
  padding: '24px 48px',
  '.addHeader-title': {
    fontSize: 48,
  },
}));
