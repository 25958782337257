import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMatches as useMatchesBase } from 'react-router-dom';

import { RoutesId } from '../config/routesId';
import { INDEX_PATH_POSTFIX } from '../helpers';
import { ParamsType } from '../types';

export interface UIMatch<Path extends string = string> {
  id: RoutesId;
  pathname: Path;
  params: ParamsType<Path>;
}

export const useMatches = () => {
  const matches = useMatchesBase();

  return useMemo(
    () =>
      // ignoring the index page
      matches.filter(
        ({ id }) => !id.endsWith(INDEX_PATH_POSTFIX)
      ) as unknown as UIMatch[],
    [matches]
  );
};
