import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/common/forms/reasonChangeForm';

import { Section } from '../../Section/Section';

export const ReasonAddSection = () => {
  const { t } = useTranslation();
  return (
    <Section title={t('client.reasonAddSection.title')}>
      <Field.Text
        label={t('client.reasonAddSection.reason.label')}
        name="reasonChange.reason"
        placeholder={t('client.reasonAddSection.reason.placeholder')}
      />
    </Section>
  );
};
