// eslint-disable-next-line no-restricted-imports
import { Popover } from '@mui/material';

import { styled } from '../styled';

import { PopoverClasses } from './types';

export const PopoverStyled = styled(Popover)(() => ({
  marginTop: 100,
  [PopoverClasses.paper]: {
    borderRadius: 8,
    boxShadow: '0px 4px 15.2px 0px rgba(12, 18, 28, 0.29)',
  },
}));
