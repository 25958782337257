import { CountryEntity } from './countryEntity';
import { FileEntity } from './fileEntity';

export enum IdentityDocumentType {
  IdCard = 'IdCard',
  Passport = 'Passport',
}

export interface IdentityDocumentEntity {
  documentType?: IdentityDocumentType;
  number?: string;
  сountryOfIssue?: CountryEntity;
  authority?: string;
  dateOfIssue?: Date;
  expirationDate?: Date;
  files?: FileEntity[];
}
