import { formatToISODate, formatToDateTime } from 'helpers';
import { Typography } from 'libs/ui';

import { DocumentItemType } from '../types';

export const renderDate = (item: DocumentItemType) => (
  <Typography title={formatToDateTime(item.createdAt)}>
    {formatToISODate(item.createdAt)}
  </Typography>
);
