import { isValidName } from 'helpers';
import { TypedForm } from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getOrderSubmissionContactSchema,
  OrderSubmissionContacts,
} from 'modules/client/common/forms/orderSubmissionContactsForm';
import { ObjectSchema, string, object, array } from 'yup';

export { initialOrderSubmissionContactType } from 'modules/client/common/forms/orderSubmissionContactsForm';

export interface CompanyOrderSubmissionContact extends OrderSubmissionContacts {
  firstName: string;
  lastName: string;
  middleName?: string;
  position: string;
}

export interface CompanyOrderSubmissionContactsFormType {
  orderSubmissionContacts: CompanyOrderSubmissionContact[];
}

export const maxLengthName = 20;
export const maxLengthPosition = 200;

export const getCompanyOrderSubmissionContactsSchema = (
  t: TFunc
): ObjectSchema<CompanyOrderSubmissionContactsFormType> =>
  object({
    orderSubmissionContacts: array()
      .of(
        getOrderSubmissionContactSchema(t).concat(
          object({
            firstName: string()
              .max(maxLengthName)
              .required()
              .test(
                'ischars',
                t('validation.noSpecialCharsAndNumber'),
                isValidName
              ),
            lastName: string()
              .max(maxLengthName)
              .required()
              .test(
                'ischars',
                t('validation.noSpecialCharsAndNumber'),
                isValidName
              ),
            middleName: string()
              .max(maxLengthName)
              .test(
                'ischars',
                t('validation.noSpecialCharsAndNumber'),
                isValidName
              ),
            position: string().max(maxLengthPosition).required(),
          })
        )
      )
      .min(1, t('validation.required'))
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyOrderSubmissionContactsFormType>();
