import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';

interface Props {
  index: number;
}

export const Header = ({ index }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="subtitle">
        {t(
          index === 0
            ? 'client.legal.companyBankAccounts.account'
            : 'client.legal.companyBankAccounts.accountOther'
        )}
      </Typography>
    </Stack>
  );
};
