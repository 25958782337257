import { useMemo } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { BranchesItem, BranchesTable } from './BranchesTable';

interface Props {
  branches: LegalClientInnerItemEntity[];
  onClickBranch: (id: string) => void;
}

export const CompanyBranchesSection = ({ branches, onClickBranch }: Props) => {
  const { t } = useTranslation();

  const items: BranchesItem[] = useMemo(() => {
    const result = branches.map((h) => ({
      id: h.id,
      name: h.name,
      location: h.location || '',
      city: h.registrationCity || '',
    }));

    return result;
  }, [branches]);

  return (
    <Section title={t('client.legal.companyBranches.title')}>
      <BranchesTable items={items} onCellClick={(i) => onClickBranch(i.id)} />
    </Section>
  );
};
