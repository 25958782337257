import { useInfiniteScroll } from 'hooks';
import { useTranslation } from 'libs/i18n';
import {
  Box,
  CircularProgress,
  Drawer,
  Pressed,
  Stack,
  Typography,
} from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { NotificationItemFeature } from './NotificationItemFeature';
import { useNotifications } from './hooks';
import { ContentStyled, DelimiterStyled } from './styles';

export const NotificationsFeature = () => {
  const { t } = useTranslation();

  const {
    isNewNotifications,
    drawerProps,
    listData,
    loading,
    hasMore,
    openDrawer,
    onLoadMore,
  } = useNotifications();

  const { lastElementRef } = useInfiniteScroll({
    loading,
    hasMore,
    onLoadMore,
  });

  return (
    <>
      <Pressed onClick={openDrawer}>
        {isNewNotifications ? <Icon.BellMark /> : <Icon.Bell />}
      </Pressed>

      <Drawer isOpen={drawerProps.isOpen} onClose={drawerProps.onClose}>
        <ContentStyled>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="subtitle">
              {t('components.dialogNotifications.title')}
            </Typography>
            <Pressed onClick={drawerProps.onClose}>
              <Icon.Close />
            </Pressed>
          </Stack>
          <Stack flex={1}>
            {listData?.list.map((notification, index) => (
              <Box
                key={notification.id}
                ref={
                  listData.list.length - 1 === index
                    ? (ref) => ref && lastElementRef(ref)
                    : null
                }
              >
                <NotificationItemFeature
                  notification={notification}
                  onClose={drawerProps.onClose}
                />
                {index !== (listData?.total ?? 0) - 1 && <DelimiterStyled />}
              </Box>
            ))}

            {!listData?.list.length && (
              <Stack alignItems="center" flex={1} justifyContent="center">
                <Typography color={Colors.gray250}>
                  {t('components.dialogNotifications.emptyText')}
                </Typography>
              </Stack>
            )}

            {loading && (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            )}
          </Stack>
        </ContentStyled>
      </Drawer>
    </>
  );
};
