import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const ContentStyled = styled(Stack)(() => ({
  gap: 12,

  '.notificationItem-text': {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    'line-clamp': '3',
    '-webkit-box-orient': 'vertical',

    '&.notificationItem-textAll': {
      display: 'inline',
      overflow: 'hidden',
    } as CSSObject,
  } as CSSObject,
}));
