import { CreateLegalClientHeadParams } from 'api/types/params';
import { yesOrNot } from 'helpers';
import {
  parseAddressParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
} from 'modules/client/common/helpers';

import { LegalClientHeadFormType } from '../../forms/legalClientHeadForm';

export const getLegalClientHeadSubmitData = (
  legalClientId: string,
  versionNumber: number,
  values: LegalClientHeadFormType
): CreateLegalClientHeadParams => {
  const addressParam = parseAddressParam(values);

  return {
    versionNumber,
    email: values.personalData?.email!,
    legalClientBodyId: values.headInfo.body?.value!,
    legalClientId,
    reason: values.reasonChange.reason,
    firstName: values.personalData!.firstName!,
    lastName: values.personalData!.lastName!,
    middleName: values.personalData!.middleName ?? null,
    dateOfBirth: values.personalData!.dateOfBirth!,
    citizenships: parseCitizenshipsParam(values),

    registrationAddress: addressParam.registrationAddress,
    residentialAddress: addressParam.residentialAddress,

    taxResidences: parseTaxResidencyParam(values),

    pepConnection: values.pepConnection.relation?.value!,
    connectedPepInfo: parsePepConnectionParam(values),

    isFirstHead: yesOrNot(values.headInfo.firstHead?.value) ?? false,
    positionInCompany: {
      position: values.positionInCompany.position,
      authorityConfirmationDocument: {
        dateOfExpiration: values.positionInCompany.dateOfExpiration,
        fileIds: values.positionInCompany.files.map(({ id }) => id!),
      },
    },
  };
};
