import { useEffect, useRef } from 'react';

export const useWindowUnload = (
  fn: (e: BeforeUnloadEvent) => void,
  onConfirm?: () => void
) => {
  const cb = useRef(fn);
  const confirmRef = useRef(onConfirm);

  useEffect(() => {
    cb.current = fn;
    confirmRef.current = onConfirm;
  }, [fn, onConfirm]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onUnload = (e: BeforeUnloadEvent) => cb.current?.(e);
    const handleOnConfirm = () => confirmRef.current?.();

    window.addEventListener('beforeunload', onUnload);
    window.addEventListener('unload', handleOnConfirm);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
      window.removeEventListener('unload', handleOnConfirm);
    };
  }, []);
};
