// eslint-disable-next-line no-restricted-imports
import { SaveClientOnboardingStatusDto_Input } from 'api/generated/graphql';
import { SaveOnboardingStatusParam } from 'api/types/params';

import { parseClientOnboardingStatusParam } from './parseClientOnboardingStatusParam';

export const parseSaveClientOnboardingStatusParam = (
  param: SaveOnboardingStatusParam
): SaveClientOnboardingStatusDto_Input => ({
  value: parseClientOnboardingStatusParam(param.value),
  versionNumber: param.versionNumber,
  result: param.result,
});
