// eslint-disable-next-line no-restricted-imports
import { BaseLicenseFragment } from 'api/generated/graphql';
import { LegalClientBaseLicenseInfoEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

export const parseLegalClientBranchLicenseInfo = (
  licenseInfo: Maybe<BaseLicenseFragment>
): LegalClientBaseLicenseInfoEntity | undefined => {
  if (!licenseInfo) {
    return undefined;
  }

  return {
    activityType: licenseInfo.activityType,
    issueDate: new Date(licenseInfo.issueDate),
    issuingAuthority: licenseInfo.issuingAuthority,
    licenseNumber: licenseInfo.licenseNumber,
    expirationDate: licenseInfo.expirationDate
      ? new Date(licenseInfo.expirationDate)
      : undefined,
  };
};
