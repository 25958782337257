import { FC } from 'react';

import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';

import { LegalClientHeadForm, LegalClientHeadFormProps } from '../components';

export interface LegalClientHeadViewProps extends LegalClientHeadFormProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
}

export const LegalClientHeadView: FC<LegalClientHeadViewProps> = ({
  breadcrumbsTitles,
  ...formProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <LegalClientHeadForm {...formProps} />
  </LayoutWithNavigationBreadcrumbs>
);
