import { useLockClient as useLockApi, useUnlockClient } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockClient = (client: ClientEntity | null) => {
  const { lockClientIfPossible } = useLockClientIfPossible(client, useLockApi);

  const { unlock } = useUnlockClient();
  const unlockClient = () => unlock(client?.id!);

  return { lockClientIfPossible, unlockClient };
};
