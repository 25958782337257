import { TypedForm, ObjectSchema, string, object } from 'libs/form';

export interface CompanyNameFormType {
  companyName: {
    fullName: string;
    shortName?: string;
    fullNameEng?: string;
    shortNameEng?: string;
  };
}

const maxLengthName = 200;

export const companyNameObject = object({
  fullName: string().max(maxLengthName).required(),
  shortName: string().max(maxLengthName),
  fullNameEng: string().max(maxLengthName),
  shortNameEng: string().max(maxLengthName),
});

export const companyNameSchema: ObjectSchema<CompanyNameFormType> = object({
  companyName: companyNameObject,
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyNameFormType>();
