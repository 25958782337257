// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseRepresentativeClientEntity } from 'api/helpers/client/parseRepresentativeClientEntity';

export const useRepresentativeClient = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useClientRepresentativeQuery({ variables: { id } });

  const error = useError(apolloError);

  const representativeClient = data?.clientRepresentative
    ? parseRepresentativeClientEntity(data.clientRepresentative)
    : null;

  return {
    representativeClient,
    loading,
    error,
    refetch,
  };
};
