import { CountryEntity } from 'api/types/entity';
import { string, object, ObjectSchema, TypedForm } from 'libs/form';
import { Maybe } from 'types/maybe';

export interface AddressFormType {
  country: Maybe<CountryEntity>;
  locality: string;
  region: string;
  district?: string;
  street: string;
  house: string;
  building?: string;
  apartment?: string;
  postalCode: string;
}

export interface RegistrationAddressFormType {
  registrationAddress: AddressFormType;
}

export const registrationAddressSchema: ObjectSchema<RegistrationAddressFormType> =
  object({
    registrationAddress: object({
      country: object({
        id: string().required(),
        name: string().required(),
      })
        .required()
        .default(null),
      locality: string().required().max(200),
      region: string().required().max(200),
      area: string().max(200),
      street: string().required().max(200),
      house: string().required().max(200),
      building: string().max(200),
      apartment: string().max(200),
      postalCode: string().required().max(200),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RegistrationAddressFormType>();
