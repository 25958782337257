// eslint-disable-next-line no-restricted-imports
import { ClientRepresentativeFragment } from 'api/generated/graphql';
import { RepresentativeClientEntity } from 'api/types/entity';

import { parseBaseRepresentativeClientEntity } from './parseBaseRepresentativeClientEntity';
import { parseClientUserEntity } from './parseEntity/parseClientUser';

export const parseRepresentativeClientEntity = (
  data: ClientRepresentativeFragment
): RepresentativeClientEntity => ({
  ...parseBaseRepresentativeClientEntity(data),

  user: parseClientUserEntity(data.user),
});
