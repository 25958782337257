import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/personalDataForm';

export const PersonalDataSection = () => {
  const { t } = useTranslation();

  return (
    <Section title={t('client.client.personalData.title')}>
      <Field.Text
        fullWidth
        label={t('client.client.personalData.lastName.label')}
        name="personalData.lastName"
        placeholder={t('client.client.personalData.lastName.placeholder')}
      />
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.personalData.firstName.label')}
          name="personalData.firstName"
          placeholder={t('client.client.personalData.firstName.placeholder')}
        />
        <Field.Text
          fullWidth
          label={t('client.client.personalData.middleName.label')}
          name="personalData.middleName"
          placeholder={t('client.client.personalData.middleName.placeholder')}
        />
      </Row>
      <Field.DatePicker
        label={t('client.client.personalData.dateOfBirth.label')}
        name="personalData.dateOfBirth"
        placeholder={t('client.client.personalData.dateOfBirth.placeholder')}
      />
    </Section>
  );
};
