import { TypedForm, FormErrors as FormErrorsBase } from 'libs/form';
import { ObjectSchema, string, object } from 'yup';

export interface TwoFactorFormType {
  code: string;
}

export const schema: ObjectSchema<TwoFactorFormType> = object({
  code: string().min(6).required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<TwoFactorFormType>();

export type FormErrors = FormErrorsBase<TwoFactorFormType>;
