import { OrganizationType } from 'api/types/entity';
import { OrganizationalAndLegalFormParam } from 'api/types/params';
import { YesOrNoType } from 'types/yesOrNo';

import { CompanyOrganizationalAndLegalFormFormType } from '../../forms/companyOrganizationalAndLegalFormForm';

export const getOrganizationalAndLegalFormParam = (
  values: CompanyOrganizationalAndLegalFormFormType
): OrganizationalAndLegalFormParam => ({
  organizationalAndLegalForm:
    values.organizationalAndLegalForm.organizationalAndLegalForm,
  organizationType: values.organizationalAndLegalForm.organizationType?.value!,
  isFinancialInstitution:
    values.organizationalAndLegalForm.organizationType!.value ===
    OrganizationType.Commercial
      ? values.organizationalAndLegalForm.isFinancialInstitution?.value ===
        YesOrNoType.Yes
      : undefined,
  isUnincorporatedStructure:
    values.organizationalAndLegalForm.organizationType!.value ===
    OrganizationType.Commercial
      ? values.organizationalAndLegalForm.isUnincorporatedStructure?.value ===
        YesOrNoType.Yes
      : undefined,
});
