import { ServiceContractParam } from 'api/types/params';

import { ServiceContractFormType } from '../../forms/serviceContractForm';

export const parseServiceContractParam = (
  data: ServiceContractFormType
): ServiceContractParam => ({
  conclusionDate: data.serviceContract.conclusionDate,
  number: data.serviceContract.number,
  terminationDate: data.serviceContract.terminationDate,
  type: data.serviceContract.type?.value,
});
