import { useTranslation } from 'libs/i18n';
import { Table } from 'libs/ui';

import { useColumns } from './columns';
import { BranchesItem } from './types';

interface Props {
  items: BranchesItem[];
  onCellClick: (row: BranchesItem) => void;
}

export const BranchesTable = ({ items, onCellClick }: Props) => {
  const columns = useColumns();

  const { t } = useTranslation();

  return (
    <Table
      hideFooter
      columns={columns}
      noRowsLabel={t('client.legal.companyBranches.noRowsLabel')}
      rows={items}
      onCellClick={onCellClick}
    />
  );
};
