import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateClientMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseClientEntity } from 'api/helpers/client/parseClientEntity';
import { parseUpdateClientParams } from 'api/helpers/client/parseUpdateClientParams';
import { UpdateClientParams } from 'api/types/params';

export const useUpdateClient = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateClientMutation();

  const error = useError(apolloError);

  return {
    client: data?.updateClient
      ? parseClientEntity(data.updateClient)
      : undefined,
    loading,
    error,
    updateClient: useCallback(
      async (id: string, params: UpdateClientParams) => {
        const input = parseUpdateClientParams(params);
        const res = await handle({ variables: { id, input } });
        return res.data?.updateClient
          ? parseClientEntity(res.data?.updateClient)
          : undefined;
      },
      [handle]
    ),
  };
};
