// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetList_AllOf_1_List_Items_FinancingSources_IncomeAmount_Currency as CurrencyApi,
  AmountFragment,
} from 'api/generated/graphql';
import { CurrencyType } from 'api/types/entity';
import { PriceEntity } from 'api/types/entity/priceEntity';

const currency: Record<CurrencyApi, CurrencyType> = {
  [CurrencyApi.Aed]: CurrencyType.AED,
  [CurrencyApi.Chf]: CurrencyType.CHF,
  [CurrencyApi.Cny]: CurrencyType.CNY,
  [CurrencyApi.Eur]: CurrencyType.EUR,
  [CurrencyApi.Gbp]: CurrencyType.GBP,
  [CurrencyApi.Hkd]: CurrencyType.HKD,
  [CurrencyApi.Idr]: CurrencyType.IDR,
  [CurrencyApi.Inr]: CurrencyType.INR,
  [CurrencyApi.Jpy]: CurrencyType.JPY,
  [CurrencyApi.Kzt]: CurrencyType.KZT,
  [CurrencyApi.Rub]: CurrencyType.RUB,
  [CurrencyApi.Usd]: CurrencyType.USD,
};
export const parseAmount = (v: AmountFragment): PriceEntity => ({
  currency: currency[v.currency],
  price: v.value,
});

export { CurrencyApi };
