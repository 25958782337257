import { RegistrationAddressEntity } from 'api/types/entity/client/registrationAddressEntity';
import { Maybe } from 'types/maybe';

import { AddressFormType } from '../../forms/registrationAddressForm';

export const parseAddressValue = (
  address?: Maybe<RegistrationAddressEntity>
): AddressFormType => ({
  country: address?.country,
  locality: address?.locality ?? '',
  region: address?.region ?? '',
  district: address?.district || undefined,
  street: address?.street ?? '',
  house: address?.house ?? '',
  building: address?.building || undefined,
  apartment: address?.apartment || undefined,
  postalCode: address?.postalCode ?? '',
});
