import { useTranslation } from 'libs/i18n';

import { CountryFilter, CountryFilterProps } from '../CountryFilter';

interface Props extends Omit<CountryFilterProps, 'title'> {}

export const RegistrationCountryFilter = (props: Props) => {
  const { t } = useTranslation();

  return (
    <CountryFilter {...props} title={t('client.filters.registrationCountry')} />
  );
};
