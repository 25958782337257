/* eslint-disable no-restricted-imports */
import { CreateAdminUserDto_Input } from 'api/generated/graphql';
import { CreateAdminParams } from 'api/types/params';

import { parseRole } from './parseRole';

export const parseCreateAdminParams = (
  params: CreateAdminParams
): CreateAdminUserDto_Input => ({
  ...params,
  roleName: parseRole(params.roleName)!,
});
