// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_LegalClientHeadController_UpdateById_Input_PepConnectionType as PepConnectionTypeApi,
  UpdateLegalClientHeadByAdminDto_Input,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { UpdateLegalClientHeadParams } from 'api/types/params';
import { formatToISODate } from 'helpers';

import {
  parseIdentityDocumentParam,
  parsePositionInCompanyParam,
} from '../parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseUpdateLegalClientHeadParams = (
  params: UpdateLegalClientHeadParams
): UpdateLegalClientHeadByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  firstName: params.firstName,
  lastName: params.lastName,
  legalClientBodyId: params.legalClientBodyId,
  reason: params.reason,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,

  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,

  connectedPepInfo: params.connectedPepInfo,

  pepConnectionType: relationType[params.pepConnection],

  isFirstHead: params.isFirstHead,

  positionInCompany: parsePositionInCompanyParam(params.positionInCompany),
  additionalInformationFileIds: params.additionalInformationFileIds,
  additionalDocumentFileIds: params.additionalDocumentFileIds,

  identityDocument: parseIdentityDocumentParam(params.identityDocument),
});
