// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_LegalClientController_GetList_SortOrder,
  useLegalClientsQuery,
} from 'api/generated/graphql';
import {
  parseLegalClientFiltersParam,
  parseLegalClientsListItemData,
  useError,
} from 'api/helpers';
import {
  LegalClientsFilters,
  LegalClientsListItemData,
  ListDataQueryFunction,
} from 'api/types/data';
import { ListData } from 'api/types/data/listData';
import { SortDirection } from 'api/types/params/listParams';
import { lodash } from 'helpers';

type ClientsSortField = 'fullname' | 'onboardingStatus';

const sortMap: Record<
  NonNullable<SortDirection>,
  QueryInput_LegalClientController_GetList_SortOrder
> = {
  desc: QueryInput_LegalClientController_GetList_SortOrder.Desc,
  asc: QueryInput_LegalClientController_GetList_SortOrder.Asc,
};

export const useLegalClients: ListDataQueryFunction<
  LegalClientsListItemData,
  ClientsSortField,
  LegalClientsFilters
> = (pagination, sort?, filter?) => {
  const {
    data,
    loading,
    error: apolloError,
  } = useLegalClientsQuery({
    variables: {
      limit: pagination.limit,
      page: pagination.page + 1,
      sortBy: sort?.field ? sort?.field : undefined,
      sortOrder: sort?.sort ? sortMap[sort?.sort] : sortMap.desc,
      ...parseLegalClientFiltersParam(filter),
    },
  });

  const error = useError(apolloError);

  const listData: ListData<LegalClientsListItemData> | undefined =
    data?.legalClients
      ? {
          total: data?.legalClients?.total,
          list: parseLegalClientsListItemData(
            lodash.compact(data?.legalClients.list)
          ),
        }
      : undefined;

  return {
    listData,
    loading,
    error,
  };
};
