import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/common/forms/bankAccountsForm';

interface Props {
  index: number;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({ index, countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('client.client.bankAccounts.country.label')}
        loading={countriesLoading}
        name={`bankAccounts.${index}.country`}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('client.client.bankAccounts.country.placeholder')}
      />

      <Field.Text
        label={t('client.client.bankAccounts.swiftCode.label')}
        name={`bankAccounts.${index}.swiftCode`}
        placeholder={t('client.client.bankAccounts.swiftCode.placeholder')}
      />
    </>
  );
};
