// eslint-disable-next-line no-restricted-imports
import {
  LegalClientRepresentativeFragment,
  Query_LegalClientRepresentativeController_GetById_PepConnectionType,
} from 'api/generated/graphql';
import { LegalClientRepresentativeEntity } from 'api/types/entity';

import { parseClientUserEntity } from '../client/parseEntity/parseClientUser';

import { parseBaseLegalClientRepresentative } from './parseBaseLegalClientRepresentative';

export const parseLegalClientRepresentative = (
  data: LegalClientRepresentativeFragment
): LegalClientRepresentativeEntity => ({
  ...parseBaseLegalClientRepresentative({
    ...data,
    pepConnectionType:
      data.pepConnectionType as Query_LegalClientRepresentativeController_GetById_PepConnectionType,
  }),

  user: parseClientUserEntity(data.user),
});
