import { object } from 'libs/form';
import {
  CompanyResidentialAddressFormType as CompanyResidentialAddressFormTypeBase,
  companyRegistrationAddressObject,
} from 'modules/client/common/forms/companyResidentialAddressForm';

export {
  Field,
  Form,
  Submit,
  useFormContext,
} from 'modules/client/common/forms/companyResidentialAddressForm';

export interface CompanyResidentialAddressFormType {
  companyResidentialAddress?: CompanyResidentialAddressFormTypeBase['companyResidentialAddress'];
}

export const companyResidentialAddressSchema = object({
  companyResidentialAddress: companyRegistrationAddressObject
    .optional()
    .default(undefined),
});
