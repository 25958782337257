import { ReactNode, useEffect, useMemo, useState } from 'react';

import { lodash } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Pressed } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { InputStyped } from './styles';

export interface SearchBarProps {
  search: string;
  leftElement?: ReactNode;
  onSearch: (v: string) => void;
}

export const SearchBar = ({
  search,
  leftElement,
  onSearch,
}: SearchBarProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(search);

  const bounce = useMemo(() => lodash.debounce(onSearch, 600), [onSearch]);

  const onChangeText = (value: string) => {
    setValue(value);
    bounce(value);
  };

  const onClear = () => {
    setValue('');
    onSearch('');
  };

  useEffect(() => {
    if (value === undefined) {
      setValue(search);
    }
  }, [search, value]);

  return (
    <InputStyped
      fullWidth
      leftElement={leftElement}
      mode="filled"
      placeholder={t('common.search')}
      rightElement={
        value ? (
          <Pressed onClick={onClear}>
            <Icon.Close color={Colors.gray250} size={20} />
          </Pressed>
        ) : (
          <Icon.Search color={Colors.gray250} size={20} />
        )
      }
      value={value}
      onChangeText={onChangeText}
      onSubmit={() => onSearch(value)}
    />
  );
};
