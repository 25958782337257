import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Refresh = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1752_4293)">
        <path
          d="M10 4.16732V0.833984L5.83337 5.00065L10 9.16732V5.83398C12.7584 5.83398 15 8.07565 15 10.834C15 13.5923 12.7584 15.834 10 15.834C7.24171 15.834 5.00004 13.5923 5.00004 10.834H3.33337C3.33337 14.5173 6.31671 17.5007 10 17.5007C13.6834 17.5007 16.6667 14.5173 16.6667 10.834C16.6667 7.15065 13.6834 4.16732 10 4.16732Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1752_4293">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
