import { FC } from 'react';

import {
  LayoutWithNavigationBreadcrumbs,
  NavigationBreadcrumbsTitles,
} from 'components';

import {
  LegalRepresentativeForm,
  LegalRepresentativeFormProps,
} from '../components';

export interface LegalClientViewProps extends LegalRepresentativeFormProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
}

export const LegalRepresentativeView: FC<LegalClientViewProps> = ({
  breadcrumbsTitles,
  ...formProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <LegalRepresentativeForm {...formProps} />
  </LayoutWithNavigationBreadcrumbs>
);
