import { Skeleton, Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContainerStyled = styled(Stack)(() => ({
  minWidth: 223,
}));
export const SkeletonStyled = styled(Skeleton)(() => ({
  minWidth: '100%',
  minHeight: 223,
}));

export const ImageContainerStyled = styled('div')(() => ({
  backgroundColor: Colors.gray50,
  padding: 22,
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 223,
}));

export const SercetStyled = styled('div')(() => ({
  backgroundColor: Colors.gray50,
  borderRadius: 8,
  border: `1px solid ${Colors.gray150};`,
  width: '100%',
  height: 38,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
