import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Box, Stack } from 'libs/ui';
import {
  useFormContext,
  Field,
} from 'modules/client/common/forms/companyTaxResidencyForm';

import { Row } from '../../../Row';

import { Header, Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { t } = useTranslation();
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.companyTaxResidences.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24}>
            <Header index={index} />
            <Row>
              <Box flex={1}>
                <Field.Autocomplete
                  label={t('client.client.taxResidency.country.label')}
                  loading={countriesLoading}
                  name={`companyTaxResidences.${index}.country`}
                  optionLabelKey="name"
                  options={countries}
                  optionValueKey="id"
                  placeholder={t(
                    'client.client.taxResidency.country.placeholder'
                  )}
                />
              </Box>
              <Box flex={1}>
                <Field.Text
                  fullWidth
                  label={t('client.client.taxResidency.taxNumber.label')}
                  name={`companyTaxResidences.${index}.taxNumber`}
                  placeholder={t(
                    'client.client.taxResidency.taxNumber.placeholder'
                  )}
                />
              </Box>
            </Row>
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
