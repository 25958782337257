import { OnboardingDataStatus } from 'api/types/data';
import { KycEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { KYCFields } from 'modules/client/common/components/KYC/KYCFields';

import { Header, StatusFields } from './components';

export interface FormContentProps {
  kycData?: KycEntity;
  statuses?: OnboardingDataStatus[];
  hideKyc?: boolean;
}

export const FormContent = ({
  kycData,
  statuses,
  hideKyc,
}: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      {!hideKyc && (
        <>
          <Header
            subtitle={t('client.client.resultCheckSection.kycSubtitle')}
          />
          <KYCFields kycData={kycData} />
        </>
      )}

      {statuses?.map(({ value, createdBy, createdAt, result }) => (
        <>
          <Header
            subtitle={t(
              `client.client.resultCheckSection.statuses.${value}.subtitle`
            )}
          />

          <StatusFields
            createdAt={createdAt}
            createdBy={createdBy}
            result={result}
          />
        </>
      ))}
    </Stack>
  );
};
