import { CountryEntity } from 'api/types/entity';
import { TypedForm, ObjectSchema, object, string } from 'libs/form';
import { Maybe } from 'types/maybe';

export interface CompanyRegistrationPlaceFormType {
  registrationPlace: {
    country: Maybe<CountryEntity>;
    locality: string;
    specialRegistrationArea?: string;
  };
}

export const companyRegistrationPlaceSchema: ObjectSchema<CompanyRegistrationPlaceFormType> =
  object({
    registrationPlace: object({
      country: object({
        id: string().required(),
        name: string().required(),
      }).required(),
      locality: string().max(200).required(),
      specialRegistrationArea: string().max(200),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyRegistrationPlaceFormType>();
