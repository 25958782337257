import { IdentityDocumentParam } from 'api/types/params';

import { IdentityDocumentFormType } from '../../forms/identityDocumentForm';

export const parseIdentityDocumentParam = (
  data: IdentityDocumentFormType
): IdentityDocumentParam => ({
  authority: data.identityDocument.authority,
  dateOfIssue: data.identityDocument.dateOfIssue,
  documentType: data.identityDocument.documentType?.value,
  expirationDate: data.identityDocument.expirationDate,
  number: data.identityDocument.number,
  сountryOfIssue: data.identityDocument.countryOfIssue ?? undefined,
});
