import { FC } from 'react';

import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { LayoutWithNavigationBreadcrumbs } from 'components/LayoutWithNavigationBreadcrumbs';
import { Form } from 'libs/form/Form';
import { VersionView } from 'modules/client/common/views';

import {
  LegalClientBranchSections,
  LegalClientBranchSectionsProps,
} from '../components';
import { LegalClientBranchFormType } from '../forms/legalClientBranchForm';

export interface LegalClientBranchVersionViewProps
  extends LegalClientBranchSectionsProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  versions: LegalClientVersionEntity[];
  initialValues?: LegalClientBranchFormType;
  fullName: string;
  versionId: string;
  snapshotLoading?: boolean;
  onChangeVersion: (version: LegalClientVersionEntity) => void;
  onCloseVersionHistory: () => void;
  onBack: () => void;
}

export const LegalClientBranchVersionView: FC<
  LegalClientBranchVersionViewProps
> = ({
  breadcrumbsTitles,
  versions,
  fullName,
  initialValues,
  snapshotLoading,
  versionId,
  onBack,
  onCloseVersionHistory,
  onChangeVersion,
  ...sectionsProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <VersionView
      canBack
      content={
        initialValues && (
          <Form
            initialReadOnly
            initialValues={initialValues}
            onSubmit={() => {}}
          >
            <LegalClientBranchSections {...sectionsProps} />
          </Form>
        )
      }
      loading={snapshotLoading || !initialValues}
      name={fullName}
      versionId={versionId}
      versions={versions}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  </LayoutWithNavigationBreadcrumbs>
);
