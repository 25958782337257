import { FC } from 'react';

import { TableSortProps, Table, TablePaginationProps } from 'libs/ui';

import { AdminsListItem, SortField } from '../../types';

import { useColumns } from './columns';

export interface AdminsTableProps
  extends TableSortProps<SortField>,
    TablePaginationProps {
  admins: AdminsListItem[];
  loading?: boolean;
  noRowsLabel?: string;
  onCellClick?: (row: AdminsListItem) => void;
}

export const AdminsTable: FC<AdminsTableProps> = ({
  admins,
  loading,
  sort,
  limit,
  rowCount,
  page,
  noRowsLabel,
  onCellClick,
  onSortChange,
  onPaginationChange,
}) => {
  const columns = useColumns();
  return (
    <Table
      columns={columns}
      limit={limit}
      loading={loading}
      noRowsLabel={noRowsLabel}
      page={page}
      rowCount={rowCount}
      rows={admins}
      sort={sort}
      onCellClick={onCellClick}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
    />
  );
};
