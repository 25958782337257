import { PropsWithChildren } from 'react';

import { PermissionType } from 'api/types/entity';
import { PermissionCheckType } from 'libs/permissions/types';

import { usePermissions } from '../../hooks';

interface Props {
  permissions: PermissionType[];
  type?: PermissionCheckType;
}

export const PermissionWrapper = ({
  children,
  permissions,
  type = 'and',
}: Props & PropsWithChildren) => {
  const { checkPermissions } = usePermissions();

  if (!checkPermissions(permissions, type)) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
