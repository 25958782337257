import {
  useLegalClient,
  useLegalClientRepresentativeSnapshot,
  useLegalClientRepresentative,
} from 'api/requests';
import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getLegalRepresentativeInitialValue } from '../helpers';

import { useLegalClientRepresentativeVersions } from './useLegalClientRepresentativeVersions';

type ContainerParams = {
  legalClientId: string;
  legalClientRepresentativeId: string;
  versionId: string;
};

export const useLegalClientRepresentativeVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ContainerParams>();

  const { loading: loadingRepresentativeClient, legalClientRepresentative } =
    useLegalClientRepresentative(params.legalClientRepresentativeId!);

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: versionsLoading, versions } =
    useLegalClientRepresentativeVersions(
      params.legalClientId!,
      params.versionId
    );

  const {
    snapshot,
    loading: snapshotLoading,
    error,
  } = useLegalClientRepresentativeSnapshot(params.versionId!, {
    fetchPolicy: 'no-cache',
  });

  const initialValues = snapshot?.value
    ? getLegalRepresentativeInitialValue(t, snapshot.value)
    : undefined;

  const fullName = legalClientRepresentative
    ? parseFullName(legalClientRepresentative)
    : '';

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: legalClient?.fullName,
    [RoutesId.legalClientVersion]: t('client.client.versions.header', {
      name: legalClient?.fullName,
    }),
    [RoutesId.legalClientRepresentativeVersion]: t(
      'client.client.versions.header',
      {
        name: fullName,
      }
    ),
  };

  const loading =
    (!legalClientRepresentative && loadingRepresentativeClient) ||
    (!versions && versionsLoading);

  const onChangeClientType = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalRepresentative.fullPath,
      {
        legalClientId: params.legalClientId!,
        legalClientRepresentativeId: params.legalClientRepresentativeId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: LegalClientVersionEntity) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientRepresentativeVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        legalClientRepresentativeId: params.legalClientRepresentativeId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
    onChangeClientType,
  };
};
