import { useState } from 'react';

import { useTranslation } from 'libs/i18n';
import { Checkbox, LinkLabel, Pressed, Stack, Typography } from 'libs/ui';

interface Item<T extends string | number> {
  value: T;
  label: string;
}
interface Props<T extends string | number> {
  items: Item<T>[];
  selectedItems: T[];
  limit?: number;
  onChange: (value: T, v: boolean) => void;
}

export const FilterCheckBox = <T extends string | number>({
  items,
  selectedItems,
  limit = 4,
  onChange,
}: Props<T>) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();
  return (
    <Stack spacing={12}>
      {items.slice(0, showAll ? items.length : limit).map((item) => (
        <Stack key={item.value as string} direction="row" spacing={8}>
          <Checkbox
            checked={selectedItems.includes(item.value)}
            onChange={(v) => onChange(item.value, v)}
          />
          <Pressed
            onClick={() =>
              onChange(item.value, !selectedItems.includes(item.value))
            }
          >
            <Typography>{item.label}</Typography>
          </Pressed>
        </Stack>
      ))}
      {limit < items.length && (
        <LinkLabel
          label={showAll ? t('common.collapse') : t('common.showAll')}
          onClick={() => setShowAll((v) => !v)}
        />
      )}
    </Stack>
  );
};
