import {
  useClient,
  useClientSnapshot,
  useClientVersions,
} from 'api/requests/client';
import { ClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getClientInitialValue } from '../helpers/getClientInitialValue';

type ClientVersionContainerParams = {
  clientId: string;
  versionId: string;
};

export const useClientVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ClientVersionContainerParams>();

  const { client, loading: clientLoading } = useClient(params.clientId!);

  const {
    snapshot,
    loading: snapshotLoading,
    error,
  } = useClientSnapshot(params.versionId!, { fetchPolicy: 'no-cache' });

  const { loading: versionsLoading, versions } = useClientVersions(
    params.clientId!
  );

  const hasRepresentative = !!client?.representativeId;

  const initialValues = snapshot?.value
    ? getClientInitialValue(snapshot.value, t)
    : undefined;

  const fullName = client ? parseFullName(client) : '';

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.personClient]: fullName,
    [RoutesId.personClientVersion]: t('client.client.versions.header', {
      name: fullName,
    }),
  };

  const loading = (!client && clientLoading) || (!versions && versionsLoading);

  const onChangeClientType = () => {
    if (client?.representativeId) {
      navigate(
        ROUTES.clients.personClients.personClient.personClientVersion
          .representativeClientVersion.fullPath,
        {
          clientId: params.clientId!,
          representativeId: client.representativeId,
          versionId: params.versionId!,
        },
        { replace: true }
      );
    }
  };

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.personClients.personClient.fullPath,
      {
        clientId: params.clientId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: ClientVersionEntity) => {
    navigate(
      ROUTES.clients.personClients.personClient.personClientVersion.fullPath,
      {
        clientId: params.clientId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    hasRepresentative,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    client,
    onChangeVersion,
    onCloseVersionHistory,
    onChangeClientType,
  };
};
