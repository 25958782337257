import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks';

import { useLegalClientRepresentativeVersion } from '../hooks';
import { LegalRepresentativeVersionView } from '../views';

export const LegalRepresentativeVersionContainer = () => {
  const {
    initialValues,
    loading,
    error,
    versions,
    breadcrumbsTitles,
    snapshot,
    fullName,
    snapshotLoading,
    versionId,
    onBack,
    onChangeClientType,
    onChangeVersion,
    onCloseVersionHistory,
  } = useLegalClientRepresentativeVersion();

  useScrollToTop([]);

  if (error) {
    return <ErrorLayout />;
  }

  if (loading) {
    return <LoadingLayout />;
  }

  return (
    <LegalRepresentativeVersionView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      fullName={fullName}
      initialValues={initialValues}
      kycData={snapshot?.value.kyc}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      onBack={onBack}
      onChangeClientType={onChangeClientType}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
