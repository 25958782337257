import { useField } from 'libs/form/fields/useField';
import { useTranslation } from 'libs/i18n';
import { Button, Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { DocumentsTable } from '../../components';
import { LocalFileEntity } from '../../types';

import { useDocumentField } from './hooks';

interface Props {
  readOnly?: boolean;
  title?: string;
  maxDocumentCount?: number;
  name: string;
}

type FileType = LocalFileEntity & { loading?: boolean } & { id: string };

export const DocumentsTableFeature = ({
  readOnly,
  title,
  maxDocumentCount,
  name,
}: Props) => {
  const { t } = useTranslation();

  const { field } = useField<FileType[]>(name);

  const { open, getInputProps, onClickDelete } = useDocumentField({
    name,
    maxDocumentCount,
  });

  const files = field.value ?? [];

  const editable = !readOnly;

  const shownHead = !!title || editable;
  const disabledUpload =
    files.length > 0 && files.length >= (maxDocumentCount ?? Infinity);

  return (
    <Stack spacing={32}>
      <input {...getInputProps()} />

      {shownHead && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={16}
        >
          <Typography variant="headline">{title}</Typography>
          {editable && (
            <Button
              disabled={disabledUpload}
              label={t('common.uploadDocument')}
              mode="medium"
              startIcon={<Icon.Plus />}
              onClick={open}
            />
          )}
        </Stack>
      )}
      <DocumentsTable
        editable={editable}
        files={files}
        onClickDelete={onClickDelete}
      />
    </Stack>
  );
};
