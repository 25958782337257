// eslint-disable-next-line no-restricted-imports
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { CSSObject, styled } from '../styled';

import { InputStyleProps } from './types';

export const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'helperTextAbsolute',
})<TextFieldProps & InputStyleProps>(
  ({ theme, helperTextAbsolute, mode = 'standard' }) => ({
    '& label, & label.Mui-focused': {
      color: theme.palette.inputLabel,
      ...theme.typography.captionSemiBold,
      transform: 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 'inherit',
    },
    '& input': {
      ...theme.typography.bodyBold,
      '&::placeholder': {
        ...theme.typography.bodyMedium,
        color: theme.palette.inputPlaceholder,
        opacity: 1,
      },
    },

    '& .MuiInputBase-root': {
      height: 48,
      textWrap: 'nowrap',
      whiteSpace: 'nowrap',
      '&::before': {
        borderBottomColor: theme.palette.inputBorder,
      },
      '&.Mui-error:before': {
        borderBottomColor: theme.palette.error.main,
      },
      '&:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottomColor: theme.palette.inputBorderHover,
        borderBottomWidth: 1,
      },

      '&:after': {
        borderBottomColor: theme.palette.inputBorderFocused,
        borderBottomWidth: 1,
      },
    },
    'label+.MuiInputBase-root': {
      marginTop: 22,
    },
    ...(helperTextAbsolute && {
      '.MuiFormHelperText-root': {
        position: 'absolute',
        bottom: -22,
      },
    }),

    ...(mode === 'filled' &&
      ({
        background: theme.palette.inputFilledBg,
        borderRadius: 40,
        paddingLeft: 16,
        paddingRight: 16,
        border: `1px solid var(--Gray-150, ${theme.palette.inputFilledBorder});`,

        '.MuiInputBase-root:after, .MuiInputBase-root:before': {
          content: 'none',
        } as CSSObject,
      } as CSSObject)),

    ...(mode === 'compact' &&
      ({
        background: theme.palette.inputCompactBg,
        borderRadius: 8,
        paddingLeft: 16,
        paddingRight: 16,
        border: `1px solid var(--Gray-150, ${theme.palette.inputCompactBorder});`,

        '& .MuiInputBase-root': {
          height: 32,
        },
        '.MuiInputBase-root:after, .MuiInputBase-root:before': {
          content: 'none',
        } as CSSObject,
      } as CSSObject)),
  })
);
