import { useMemo } from 'react';

import { TableColumn } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { renderCheckbox, renderIcon } from './renders';
import { ChangeCheckboxFunction, NotificationSettingsTableItem } from './types';

export const getColumns = (
  onChangeEmailEnabled: ChangeCheckboxFunction,
  onChangeBellEnabled: ChangeCheckboxFunction
): TableColumn<NotificationSettingsTableItem>[] => [
  {
    field: 'isBellEnabled',
    title: '',
    width: 48,
    minWidth: 48,
    align: 'center',
    renderTitle: () => renderIcon(Icon.Computer),
    renderCell: (row) =>
      renderCheckbox(row.isBellEnabled, (v) => onChangeBellEnabled(row, v)),
  },
  {
    field: 'isEmailEnabled',
    title: '',
    width: 48,
    minWidth: 48,
    renderTitle: () => renderIcon(Icon.Mail),
    renderCell: (row) =>
      renderCheckbox(row.isEmailEnabled, (v) => onChangeEmailEnabled(row, v)),
  },
  {
    field: 'name',
    title: '',
    flex: 1,
  },
];

export const useColumns = (
  onChangeEmailEnabled: ChangeCheckboxFunction,
  onChangeBellEnabled: ChangeCheckboxFunction
) =>
  useMemo(
    () => getColumns(onChangeEmailEnabled, onChangeBellEnabled),
    [onChangeEmailEnabled, onChangeBellEnabled]
  );
