import { LegalClientEntity, LegalClientBranchEntity } from 'api/types/entity';
import { usePageTitle } from 'libs/navigation';

export const useBranchPageTitle = (
  legalClient: LegalClientEntity | null,
  legalClientBranch: LegalClientBranchEntity | null
) => {
  let fullName = legalClientBranch?.fullName ?? '';

  fullName += fullName && legalClient ? ` - ${legalClient.fullName}` : '';

  usePageTitle(fullName);
};
