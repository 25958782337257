import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { useTimer } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Box, Button, ButtonMode } from 'libs/ui';

export interface SendCodeTimerRef {
  startTimer(seconds: number): void;
}
interface Props {
  onClickSend: () => void;
  seconds?: number;
  disabled?: boolean;
  reload?: any;
  buttonMode?: ButtonMode;
  buttonLabel?: string;
  className?: string;
}

export const SendCodeTimer = forwardRef(
  (
    {
      onClickSend,
      reload,
      seconds,
      disabled,
      className,
      buttonLabel,
      buttonMode = 'text',
    }: Props,
    ref
  ) => {
    const { t } = useTranslation();
    const { isTimerVisible, reloadTimer, timer } = useTimer(0);

    useEffect(() => {
      if (seconds) {
        reloadTimer(seconds);
      }
    }, [reloadTimer, seconds]);

    useEffect(() => {
      reloadTimer(0);
    }, [reloadTimer, reload]);

    useImperativeHandle(ref, () => ({
      startTimer(seconds: number) {
        reloadTimer(seconds);
      },
    }));

    return (
      <Box className={className}>
        <Button
          className="sendCodeTimer-button"
          disabled={disabled || isTimerVisible}
          label={
            isTimerVisible
              ? t('components.sendCodeTimer.resendCodeTimer', { timer })
              : buttonLabel ?? t('components.sendCodeTimer.buttonLabel')
          }
          mode={buttonMode}
          onClick={onClickSend}
        />
      </Box>
    );
  }
);
