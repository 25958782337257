import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  ChangeOnboardingStatus_Const,
  useUserUpdateNotificationSettingsMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseUserEntity } from 'api/helpers/parseEntity';
import { NotificationSettingsData } from 'api/types/data';

export const useUserUpdateNotificationSettings = () => {
  const [handle, { loading, error: apolloError }] =
    useUserUpdateNotificationSettingsMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    updateNotificationSettings: useCallback(
      async (notifications: NotificationSettingsData[]) => {
        const res = await handle({
          variables: {
            input: {
              notificationSettings: notifications.map((n) => ({
                isBellEnabled: n.isBellEnabled,
                isEmailEnabled: n.isEmailEnabled,
                type: ChangeOnboardingStatus_Const[n.type],
              })),
            },
          },
        });

        if (res.data?.userUpdateNotificationSettings) {
          return parseUserEntity(res.data?.userUpdateNotificationSettings);
        }

        return undefined;
      },
      [handle]
    ),
  };
};
