// eslint-disable-next-line no-restricted-imports
import { Households_Const as EconomicSectorApi } from 'api/generated/graphql';
import { ClientEconomicSectorType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

const economicSector: Record<ClientEconomicSectorType, EconomicSectorApi> = {
  [ClientEconomicSectorType.Households]: EconomicSectorApi.Households,
};

export const parseEconomicSectorTypeParam = (
  data: Maybe<ClientEconomicSectorType>
): EconomicSectorApi | null => (data ? economicSector[data] : null);
