import { ServerError } from 'api/types/error';
import { useAccountCodesFormError } from 'modules/client/common/hooks/useAccountCodesFormError';

import { FormErrors } from '../forms/legalClientForm';

export const useFormError = (
  error: ServerError | undefined
): FormErrors | undefined => {
  const formError = useAccountCodesFormError(error);

  return formError;
};
