import { useCallback, useEffect, useState } from 'react';

import { useNotificationList } from 'api/requests';
import { useUser } from 'libs/providers';
import { useDialog } from 'libs/ui/Dialog/useDialog';

const REFETCH_INTERVAL = 3000;
const LIMIT = 10;

export const useNotifications = () => {
  const { user } = useUser();

  const { open: openDrawer, ...drawerProps } = useDialog();

  const [page, setPage] = useState(0);

  const {
    loading,
    unreadCount = 0,
    refetch,
    listData,
  } = useNotificationList({
    page,
    limit: LIMIT,
  });

  const onLoadMore = useCallback(() => {
    if (listData) {
      if (listData.list.length !== listData.total) {
        setPage((p) => p + 1);
      }
    }
  }, [listData]);

  useEffect(() => {
    if (user?.id && !drawerProps.isOpen) {
      const interval = setInterval(
        () => refetch({ page: 1 }),
        REFETCH_INTERVAL
      );
      return () => clearInterval(interval);
    }
    return undefined;
  }, [drawerProps.isOpen, refetch, user?.id]);

  useEffect(() => {
    if (!drawerProps.isOpen) {
      setPage(0);
    }
  }, [drawerProps.isOpen, refetch, user?.id]);

  return {
    isNewNotifications: unreadCount > 0,
    loading,
    drawerProps,
    listData,
    hasMore: listData ? listData.list.length < listData.total : false,
    openDrawer,
    onLoadMore,
  };
};
