// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientController_UpdateById_Input_RiskLevel as RiskLevelTypeApi,
  MutationInput_LegalClientController_UpdateById_Input_RiskLevel as RiskLevelTypeApi2,
} from 'api/generated/graphql';
import { RiskLevelType } from 'api/types/entity';

const RiskLevelTypePath: Record<
  RiskLevelType,
  RiskLevelTypeApi | RiskLevelTypeApi2
> = {
  [RiskLevelType.High]: RiskLevelTypeApi.High,
  [RiskLevelType.Low]: RiskLevelTypeApi.Low,
  [RiskLevelType.Medium]: RiskLevelTypeApi.Medium,
};

export const parseRiskLevel = <T extends RiskLevelTypeApi | RiskLevelTypeApi2>(
  value: RiskLevelType | null
) => {
  if (!value) return null;

  return RiskLevelTypePath[value] as T;
};
