import { PropsWithChildren } from 'react';

import { PopoverStyled } from './Popover.styles';
import { PopoverClasses, PopoverProps } from './types';

/**
 * Menu component.
 */
export const Popover = ({
  open,
  anchorEl,
  children,
  anchorOrigin,
  transformOrigin,
  className,
  onClose,
}: PopoverProps & PropsWithChildren) => (
  <PopoverStyled
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    classes={{ paper: 'Popover-paper' }}
    className={className}
    open={open}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    {children}
  </PopoverStyled>
);

Popover.classes = PopoverClasses;
