// eslint-disable-next-line no-restricted-imports
import { LegalClientVersionFragment } from 'api/generated/graphql';
import {
  LegalClientVersionEntity,
  LegalClientVersionUpdatedEntityType,
} from 'api/types/entity';
import { parseFullName } from 'helpers';

export const parseLegalClientVersionEntity = (
  data: LegalClientVersionFragment | undefined | null
): LegalClientVersionEntity | undefined =>
  data
    ? {
        legalClientId: data.legalClientId,
        createdAt: new Date(data.createdAt),
        createdBy: data.createdBy
          ? {
              id: data.createdBy.id,
              name: parseFullName(data.createdBy),
            }
          : null,
        id: data.id,
        number: data.number,
        reason: data.reason,
        updatedEntity: {
          fullName: data.updatedEntity.fullName,
          id: data.updatedEntity.id,
          type: LegalClientVersionUpdatedEntityType[data.updatedEntity.type],
        },
      }
    : undefined;
