// eslint-disable-next-line no-restricted-imports
import {
  UpdateClientByAdminDto_Input,
  Query_ClientController_GetList_AllOf_1_List_Items_OrderSubmissionContacts_Items_Type,
  ContactDto_Input,
} from 'api/generated/graphql';
import { OrderSubmissionContactParam } from 'api/types/params';
import { lodash } from 'helpers';

const parseOrderSubmissionContactParam = ({
  type,
  value,
}: OrderSubmissionContactParam): ContactDto_Input => ({
  value,
  type: Query_ClientController_GetList_AllOf_1_List_Items_OrderSubmissionContacts_Items_Type[
    type
  ],
});

export const parseOrderSubmissionContactsParam = (
  orderSubmissionContacts: OrderSubmissionContactParam[]
): UpdateClientByAdminDto_Input['orderSubmissionContacts'] =>
  lodash.compact(orderSubmissionContacts).map(parseOrderSubmissionContactParam);
