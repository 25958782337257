import { CSSObject } from 'libs/ui/styled';
import { Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

import { getOutlinedMode } from './outlined';

export const getMediumMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => ({
  ...getOutlinedMode(theme, colorVariant),
  height: 48,
  borderRadius: 8,
  paddingLeft: 24,
  paddingRight: 24,
});
