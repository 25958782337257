import { DependencyList, useEffect } from 'react';

import { scrollToTop } from 'helpers';

export const useScrollToTop = (deps?: DependencyList) => {
  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
