// eslint-disable-next-line no-restricted-imports
import { TaxResidenceFragment } from 'api/generated/graphql';
import { TaxResidencesEntity } from 'api/types/entity';

import { parseCountryEntity } from '../../parseCountryEntity';

export const parseTaxResidenceEntity = (
  taxResidence: TaxResidenceFragment
): TaxResidencesEntity => ({
  country: parseCountryEntity(taxResidence.country),
  taxId: taxResidence.taxId ?? undefined,
  reasonForMissingTaxId: taxResidence.reasonForMissingTaxId ?? undefined,
});
