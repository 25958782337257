import React, { PropsWithChildren } from 'react';

import { Form, FormProps } from 'libs/form/Form';
import { Dialog, DialogStateProps } from 'libs/ui';

import { Content } from './Content';
import { Header } from './Header';

interface Props<Values extends object>
  extends PropsWithChildren,
    DialogStateProps,
    FormProps<Values> {
  title: string;
  submitLoading?: boolean;
}

export const AddDialogForm = <Values extends object>({
  children,
  isOpen,
  submitLoading,
  title,
  onClose,
  ...formProps
}: Props<Values>) => (
  <Dialog fullScreen isOpen={isOpen} onClose={onClose}>
    <Form {...formProps}>
      <Header submitLoading={submitLoading} title={title} onBack={onClose} />
      <Content>{children}</Content>
    </Form>
  </Dialog>
);
