import { Table } from 'libs/ui';

import { useColumns } from './columns';
import { ChangeCheckboxFunction, NotificationSettingsTableItem } from './types';

interface Props {
  items: NotificationSettingsTableItem[];
  onChangeEmailEnabled: ChangeCheckboxFunction;
  onChangeBellEnabled: ChangeCheckboxFunction;
}

export const NotificationSettingsTable = ({
  items,
  onChangeEmailEnabled,
  onChangeBellEnabled,
}: Props) => {
  const columns = useColumns(onChangeEmailEnabled, onChangeBellEnabled);

  return (
    <Table
      hideFooter
      columns={columns}
      overlayWrapperHeight={48}
      rows={items}
      variant="miniature"
    />
  );
};
