// eslint-disable-next-line no-restricted-imports
import { Pagination } from '@mui/material';

import { CSSObject, styled } from '../styled';
import { Colors } from '../theme';

export const PaginationStyled = styled(Pagination)(() => ({
  '.MuiPaginationItem-outlined': {
    borderRadius: 8,
    border: `1px solid ${Colors.gray150}`,
    background: Colors.gray50,

    height: 32,
    width: 32,
    color: Colors.black,
    fontSize: 14,

    '&.Mui-selected': {
      background: Colors.blue50,
      color: Colors.blue150,
      fontWeight: 700,
    },
  } as CSSObject,

  '.MuiPaginationItem-ellipsis': {
    paddingTop: 6,
  },
}));
