import { ClientEntity, RepresentativeClientEntity } from 'api/types/entity';
import { parseFullName } from 'helpers';
import { usePageTitle } from 'libs/navigation';

export const useClientPageTitle = (
  client: ClientEntity | null,
  representativeClient: RepresentativeClientEntity | null
) => {
  let fullName = representativeClient
    ? parseFullName(representativeClient)
    : '';

  fullName += fullName && client ? ` - ${parseFullName(client)}` : '';

  usePageTitle(fullName);
};
