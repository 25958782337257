import { useEffect, useState } from 'react';

import { LoadingLayout } from 'components/LoadingLayout';
import { ConfirmationDialog } from 'components/dialogs';
import { useTranslation } from 'libs/i18n';
import { Autocomplete, DialogProps, Stack, Switch, Typography } from 'libs/ui';

import { useUserTimezoneSettings } from './hooks/useUserTimezoneSettings';

interface Props extends DialogProps {
  onClose: () => void;
}

export const DialogUserTimezoneSettingsFeature = ({
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const {
    auto,
    setAuto,
    timezone,
    timezonesOptions,
    loading,
    updateLoading,
    setTimezone,
    resetTimezone,
    onUpdateTimezone,
  } = useUserTimezoneSettings(onClose);

  const [timezoneError, setTimezoneError] = useState('');

  const onConfirm = () => {
    if (!auto && !timezone) {
      setTimezoneError(t('validation.required'));
    } else {
      onUpdateTimezone();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      resetTimezone();
    }
  }, [isOpen, resetTimezone]);

  useEffect(() => {
    setTimezoneError('');
  }, [timezone]);

  return (
    <ConfirmationDialog
      headerCentered
      confirmButtonLabel={t('common.save')}
      isOpen={isOpen}
      loading={updateLoading}
      maxWidth="md"
      subtitle={t('components.dialogUserTimezoneSettings.subtitle')}
      title={t('components.dialogUserTimezoneSettings.title')}
      onBack={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      {loading ? (
        <LoadingLayout />
      ) : (
        <Stack spacing={20}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyBold">
              {t('components.dialogUserTimezoneSettings.automaticTimezone')}
            </Typography>
            <Switch checked={auto} onChange={setAuto} />
          </Stack>
          {!auto && (
            <Autocomplete
              helperTextAbsolute
              disabled={auto}
              error={Boolean(timezoneError)}
              helperText={timezoneError}
              label={t('common.timezone')}
              optionLabelKey="label"
              options={timezonesOptions ?? []}
              optionValueKey="name"
              placeholder={t('common.timezone')}
              value={timezone}
              onChange={(e, v) => setTimezone(v)}
            />
          )}
        </Stack>
      )}
    </ConfirmationDialog>
  );
};
