import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientLegalHeadMutation } from 'api/generated/graphql';
import { parseLegalClientLegalHeadEntity, useError } from 'api/helpers';
import { UpdateLegalClientLegalHeadParams } from 'api/types/params';

export const useUpdateLegalClientLegalHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientLegalHeadMutation();

  const error = useError(apolloError);

  return {
    legalClientLegalHead: data?.updateLegalClientLegalHead
      ? parseLegalClientLegalHeadEntity(data.updateLegalClientLegalHead)
      : undefined,
    loading,
    error,
    updateLegalClientLegalHead: useCallback(
      async (id: string, params: UpdateLegalClientLegalHeadParams) => {
        const res = await handle({ variables: { id, input: params } });
        return res.data?.updateLegalClientLegalHead
          ? parseLegalClientLegalHeadEntity(
              res.data?.updateLegalClientLegalHead
            )
          : undefined;
      },
      [handle]
    ),
  };
};
