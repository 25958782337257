import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { useFormErrorFocus } from 'hooks';
import { Stack } from 'libs/ui';
import {
  CitizenshipSection,
  PepConnectionSection,
  TaxResidencySection,
  ReasonAddSection,
  PlaceOfBirthAndRegistrationAddressSection,
} from 'modules/client/common/components/sections';
import { ControlOverSection } from 'modules/client/legalClientBeneficiary/components/sections';

import { PersonalDataSection } from '../sections';

export interface LegalClientAddBeneficiarySectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const LegalClientAddBeneficiarySections: FC<
  LegalClientAddBeneficiarySectionsProps
> = ({ countries, countriesLoading }) => {
  useFormErrorFocus('center');

  return (
    <Stack spacing={48}>
      <ReasonAddSection />
      <PersonalDataSection />
      <CitizenshipSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <TaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <PlaceOfBirthAndRegistrationAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <PepConnectionSection />
      <ControlOverSection />
    </Stack>
  );
};
