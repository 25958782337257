import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const DocumentNumberStyled = styled('div')(() => ({
  position: 'relative',

  '.document-number': {
    position: 'absolute',
    backgroundColor: Colors.blue50,
    bottom: 0,
    right: -12,
    height: 24,
    width: 24,
    borderRadius: 24,
    textAlign: 'center',

    '& > *': {
      color: Colors.blue250,
      lineHeight: 1.8,
    },
  },
}));
