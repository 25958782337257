import { Colors } from '../../../theme';
import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Info = ({
  size = 16,
  color = Colors.black,
  onClick,
}: SvgProps) => (
  <SvgIcon size={size} onClick={onClick}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1C4.13111 1 1 4.13075 1 8C1 11.8688 4.13075 15 8 15C11.8689 15 15 11.8693 15 8C15 4.13116 11.8693 1 8 1ZM8 14.0233C4.67877 14.0233 1.97675 11.3213 1.97675 8C1.97675 4.67875 4.67877 1.97675 8 1.97675C11.3212 1.97675 14.0233 4.67875 14.0233 8C14.0233 11.3213 11.3212 14.0233 8 14.0233Z"
        fill={color}
      />
      <path
        d="M8.0005 6.83496C7.58586 6.83496 7.29102 7.01007 7.29102 7.26806V10.7786C7.29102 10.9998 7.58586 11.2209 8.0005 11.2209C8.39672 11.2209 8.71918 10.9998 8.71918 10.7786V7.268C8.71918 7.01004 8.39672 6.83496 8.0005 6.83496Z"
        fill={color}
      />
      <path
        d="M8.00163 4.66943C7.57777 4.66943 7.24609 4.9735 7.24609 5.32363C7.24609 5.6738 7.5778 5.98707 8.00163 5.98707C8.41627 5.98707 8.748 5.6738 8.748 5.32363C8.748 4.9735 8.41624 4.66943 8.00163 4.66943Z"
        fill={color}
      />
    </svg>
  </SvgIcon>
);
