import { getYesOrNoOptions } from 'helpers/yesOrNo';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/statusesForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Box flex={1}>
        <Field.Autocomplete
          fullWidth
          label={t('client.client.statuses.accreditedInvestorStatus.label')}
          name="statuses.accreditedInvestorStatus"
          optionLabelKey="label"
          options={getYesOrNoOptions(t)}
          optionValueKey="value"
          placeholder={t(
            'client.client.statuses.accreditedInvestorStatus.placeholder'
          )}
          variant="select"
        />
      </Box>
    </Row>
  );
};
