// eslint-disable-next-line no-restricted-imports
import { MutationInput_ClientController_UpdateById_Input_Category as CategoryApi } from 'api/generated/graphql';
import { ClientCategoryType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

const category: Record<ClientCategoryType, CategoryApi> = {
  [ClientCategoryType.NonResident]: CategoryApi.NonResident,
  [ClientCategoryType.Resident]: CategoryApi.Resident,
};

export const parseCategoryTypeParam = (
  data: Maybe<ClientCategoryType>
): CategoryApi | null => (data ? category[data] : null);
