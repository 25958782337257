import { useEffect, useState } from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import { useLogin as useLoginApi, useLoginWithTwoFactor } from 'api/requests';
import {
  VerificationCodeFormFormErrors,
  VerificationCodeFormType,
} from 'components';
import { emptyErrorString } from 'libs/form';
import { useNavigate } from 'libs/navigation';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import { FormErrors, LoginFormType } from '../forms/loginFormForm';

export const useLogin = () => {
  const {
    isOpen: isOpenVerificationDialog,
    onClose: onCloseVerificationDialog,
    open,
  } = useDialog();

  const { login, loading, error } = useLoginApi();

  const { searchParams } = useNavigate();

  const {
    loginWithTwoFactor,
    loading: loadingOtp,
    error: errorOtp,
  } = useLoginWithTwoFactor();

  const [formError, setError] = useState<FormErrors>();
  const [verificationFormError, setVerificationError] =
    useState<VerificationCodeFormFormErrors>();

  const goToMainPage = () => {
    const ret = searchParams.get('ret');
    if (ret && ret.startsWith(window.location.origin)) {
      window.location.replace(ret);
    } else {
      window.location.replace('/');
    }
  };

  const onSubmit = async (values: LoginFormType) => {
    const loginData = await login(values);
    if (loginData) {
      tokenStorage.login(loginData);
      if (loginData.otpRequired) {
        open();
        return;
      }
      goToMainPage();
    }
  };

  const onSubmitVerification = async (values: VerificationCodeFormType) => {
    const loginData = await loginWithTwoFactor(values.code);
    if (loginData) {
      tokenStorage.login(loginData);
      goToMainPage();
    }
  };

  useEffect(() => {
    if (error) {
      setError({ email: emptyErrorString, password: error.message });
    } else {
      setError({ email: undefined, password: undefined });
    }
  }, [error]);

  useEffect(() => {
    if (errorOtp) {
      setVerificationError({ code: emptyErrorString });
    } else {
      setVerificationError({ code: undefined });
    }
  }, [error, errorOtp]);

  return {
    onSubmit,
    formError,
    loading,
    isOpenVerificationDialog,
    onCloseVerificationDialog,
    onSubmitVerification,
    verificationFormError,
    loadingOtp,
    errorOtp,
  };
};
