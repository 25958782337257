import { LegalClientLicenseInfoParam } from 'api/types/params';
import { formatToISODate } from 'helpers';
import { YesOrNoType } from 'types/yesOrNo';

import {
  CompanyLicenseFormType,
  CompanyBaseLicenseFormType,
} from '../../forms/companyLicenseForm';

export const parseBaseLicenseParam = (values: CompanyBaseLicenseFormType) => {
  const isLicensed =
    values.companyLicense.companyActivityIsLicensed?.value === YesOrNoType.Yes;

  if (!isLicensed) {
    return { isLicensed };
  }
  return {
    isLicensed,
    licenseInfo: {
      activityType: values.companyLicense.licensedActivityType!,
      issueDate: formatToISODate(values.companyLicense.dateOfIssue!),
      issuingAuthority: values.companyLicense.authorityName!,
      licenseNumber: values.companyLicense.licenseNumber!,
      expirationDate: values.companyLicense.dateOfExpiry
        ? formatToISODate(values.companyLicense.dateOfExpiry)
        : undefined,
    },
  };
};

export const parseLicenseParam = (
  values: CompanyLicenseFormType
): LegalClientLicenseInfoParam => {
  const baseData = parseBaseLicenseParam(values);

  return {
    ...baseData,
    licenseInfo: baseData.licenseInfo
      ? {
          ...baseData.licenseInfo,
          fileIds: values.companyLicense.files?.map(({ id }) => id!)!,
        }
      : undefined,
  };
};
