// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_Category as CategoryApi,
  Query_ClientController_GetList_AllOf_1_List_Items_Category as CategoryApi1,
} from 'api/generated/graphql';
import { ClientCategoryType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

const category: Record<CategoryApi, ClientCategoryType> = {
  [CategoryApi.NonResident]: ClientCategoryType.NonResident,
  [CategoryApi.Resident]: ClientCategoryType.Resident,
};

export const parseCategoryType = (
  data: Maybe<CategoryApi | CategoryApi1>
): ClientCategoryType | undefined => (data ? category[data] : undefined);
