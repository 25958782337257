import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Plus = ({ size = 24, color, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 4V20" stroke="currentColor" strokeWidth="1.5" />
      <path d="M4 12H20" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  </SvgIcon>
);
