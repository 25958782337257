import {
  LegalClientEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { usePageTitle } from 'libs/navigation';

export const useLegalHeadPageTitle = (
  legalClient: LegalClientEntity | null,
  legalClientLegalHead: LegalClientLegalHeadEntity | null
) => {
  let fullName = legalClientLegalHead?.fullName ?? '';

  fullName += fullName && legalClient ? ` - ${legalClient.fullName}` : '';

  usePageTitle(fullName);
};
