import { Stack, Button, Box } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

interface Props {
  index: number;
  onAdd: () => void;
  onRemove: (index: number) => void;
  maxCount?: number;
  counts: number;
  removeLabel: string;
  addLabel: string;
}

export const AddAndRemoveButtons = ({
  counts,
  index,
  onAdd,
  onRemove,
  removeLabel,
  addLabel,
  maxCount = 10,
}: Props) => {
  const isHideButtons = counts > 1 && index === 0;
  const isShowAddButton = counts < maxCount && index === counts - 1;
  const isShowRemoveButton = index > 0;

  if (isHideButtons) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between" spacing={16}>
      {isShowAddButton && (
        <Box flex={1}>
          <Button
            fullWidth
            label={addLabel}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={onAdd}
          />
        </Box>
      )}
      {isShowRemoveButton && (
        <Box flex={1}>
          <Button
            fullWidth
            colorVariant="red"
            label={removeLabel}
            mode="medium"
            startIcon={<Icon.Minus />}
            onClick={() => onRemove(index)}
          />
        </Box>
      )}
    </Stack>
  );
};
