import { BaseDebtsEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers/yesOrNo';
import { TFunc } from 'libs/i18n';

import { BaseDebtsFormType } from '../../forms/baseDebtsForm';

export const parseBaseDebtsInitialValue = (
  debsEntity: BaseDebtsEntity | undefined,
  t: TFunc
) => {
  const bankruptcyProceedings = yesOrNotOption(
    t,
    debsEntity?.hasBankruptcyProceedings
  );
  const delaysOrNonFulfillmentOfObligations = yesOrNotOption(
    t,
    debsEntity?.hasDelaysOrNonFulfillmentOfObligations
  );

  const values: BaseDebtsFormType['debts'] = {
    ...debsEntity,

    bankruptcyProceedings,
    delaysOrNonFulfillmentOfObligations,
  };

  return values;
};
