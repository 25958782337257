// eslint-disable-next-line no-restricted-imports
import { LegalClientRepresentativeSnapshotFragment } from 'api/generated/graphql';
import { LegalClientRepresentativeSnapshotEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { parseBaseLegalClientRepresentative } from './parseBaseLegalClientRepresentative';

export const parseLegalClientRepresentativeSnapshotEntity = (
  data: Maybe<LegalClientRepresentativeSnapshotFragment>
): LegalClientRepresentativeSnapshotEntity | undefined =>
  data
    ? {
        createdAt: new Date(data.createdAt),
        id: data.id,
        value: parseBaseLegalClientRepresentative(data.value),
      }
    : undefined;
