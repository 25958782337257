import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const User = ({ size = 44, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1313_2692)">
        <g clipPath="url(#clip1_1313_2692)">
          <circle cx="22" cy="22" fill="#D6F4FF" r="22" />
          <path
            d="M30.6722 27.9C28.3001 26.0837 25.2828 24.9941 21.4015 24.9941V47.4993L35.8124 40.0987V38.068C35.8124 37.6314 35.7892 37.1998 35.7448 36.7742C35.3721 33.1993 33.4718 30.0435 30.6722 27.9Z"
            fill="#0079A5"
          />
          <path
            d="M13.3321 27.9001C10.5326 30.0436 8.63223 33.1994 8.25947 36.7742C8.21506 37.2 8.19189 37.6315 8.19189 38.068V40.0987L22.0028 49V24.9941C18.7215 24.9942 15.7043 26.0838 13.3321 27.9001Z"
            fill="#0079A5"
          />
          <path
            d="M30.5 14.8437C30.5 10.1571 26.6869 6.34375 21.4015 6.34375V23.3438C26.6869 23.3438 30.5 19.5315 30.5 14.8437Z"
            fill="#0079A5"
          />
          <path
            d="M21.9995 23.3438V6.34375C17.3131 6.34375 13.5 10.1571 13.5 14.8437C13.5 19.5315 17.3131 23.3438 21.9995 23.3438Z"
            fill="#0079A5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1313_2692">
          <rect fill="white" height="44" rx="22" width="44" />
        </clipPath>
        <clipPath id="clip1_1313_2692">
          <rect fill="white" height="44" rx="22" width="44" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
