// eslint-disable-next-line no-restricted-imports
import { LegalClientHeadSnapshotFragment } from 'api/generated/graphql';
import { LegalClientHeadSnapshotEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { parseLegalClientHeadEntity } from './parseLegalClientHeadEntity';

export const parseLegalClientHeadSnapshotEntity = (
  data: Maybe<LegalClientHeadSnapshotFragment>
): LegalClientHeadSnapshotEntity | undefined =>
  data
    ? {
        createdAt: new Date(data.createdAt),
        id: data.id,
        value: parseLegalClientHeadEntity(data.value),
      }
    : undefined;
