import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Right = ({
  size = 18,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_853_2185)">
        <path
          d="M7.27869 4.5L6.22119 5.5575L9.65619 9L6.22119 12.4425L7.27869 13.5L11.7787 9L7.27869 4.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_853_2185">
          <rect fill="white" height="18" rx="9" width="18" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
