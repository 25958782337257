import { yesOrNotSelectValidation } from 'helpers/yesOrNo';
import { ObjectSchema, object, TypedForm } from 'libs/form';
import { PriceFieldValue } from 'libs/form/fields/PriceField';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoType } from 'types/yesOrNo';

import { BaseDebtsFormType, getBaseDebtsItemSchema } from './baseDebtsForm';

export type DebtsOptionalPrice = Maybe<Partial<PriceFieldValue>>;

export interface DebtsFormType {
  debts: BaseDebtsFormType['debts'] & {
    marginTrades: Maybe<SelectType<YesOrNoType>>;
  };
}

export const getDebtsSchema = (
  isEntrepreneur?: boolean
): ObjectSchema<DebtsFormType> =>
  object({
    debts: object({
      marginTrades: yesOrNotSelectValidation.default(null).required(),
    }).concat(getBaseDebtsItemSchema(isEntrepreneur)),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<DebtsFormType>();
