import { FC } from 'react';

import { useFormErrorFocus } from 'hooks';
import { FormContentWrapper } from 'modules/client/common/components';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { useFormContext } from '../../../forms/legalClientBranchForm';
import {
  LegalClientBranchSections,
  LegalClientBranchSectionsProps,
} from '../../LegalClientBranchSections';

export interface FormContentProps
  extends LockAndUnlockReadOnlyProps,
    LegalClientBranchSectionsProps {
  submitLoading?: boolean;
  onShowVersionHistory?: () => void;
}

export const FormContent: FC<FormContentProps> = ({
  submitLoading,
  lockClientIfPossible,
  unlockClient,
  onShowVersionHistory,
  ...sectionsProps
}) => {
  const { resetForm, values, dirty, setReadOnly } = useFormContext();

  const { fullName } = values.companyName;

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  useFormErrorFocus();

  return (
    <FormContentWrapper
      content={<LegalClientBranchSections {...sectionsProps} />}
      dirty={dirty}
      fullName={fullName}
      resetForm={resetForm}
      shownTabBar={false}
      submitLoading={submitLoading}
      onEdit={onDisableReadOnly}
      onEnableReadOnly={onEnableReadOnly}
      onShowVersionHistory={onShowVersionHistory}
    />
  );
};
