import { useCallback } from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import { useLoginByToken as useLoginByTokenApi } from 'api/requests';
import { ROUTES, useNavigate } from 'libs/navigation';

export const useLoginByToken = () => {
  const { loading, data, error, login } = useLoginByTokenApi();

  const { navigate } = useNavigate();

  const loginByToken = useCallback(
    async (token: string) => {
      const loginData = await login(token);

      if (loginData) {
        tokenStorage.login(loginData);
        navigate(
          ROUTES.setPassword.fullPath,
          {},
          { state: { otpRequired: loginData.otpRequired } }
        );
      } else {
        throw Error();
      }
    },
    [login, navigate]
  );

  return {
    loginByToken,
    error,
    data,
    loading,
  };
};
