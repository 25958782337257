import { useCallback, useEffect, useState } from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import {
  useLoginWithTwoFactor,
  useSetPassword as useSetPasswordApi,
} from 'api/requests';
import { getValidationErrors } from 'helpers';
import { useNavigate } from 'libs/navigation';
import { useNotify } from 'libs/notify';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import { FormSubmit, FormErrors } from '../forms/passwordForm';

export const useSetPassword = (otpRequired: boolean) => {
  const { state } = useNavigate();
  const notify = useNotify();

  const { setPassword, error } = useSetPasswordApi();
  const { loginWithTwoFactor } = useLoginWithTwoFactor();

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    isOpen: isOpenVerificationDialog,
    onClose: onCloseVerificationDialog,
    open,
  } = useDialog();

  if (!state) {
    window.location.replace('/');
  }

  const onSubmit: FormSubmit = useCallback(
    async (values) => {
      if (otpRequired && !isOpenVerificationDialog) {
        open();
        return false;
      }

      setSubmitLoading(true);

      if (otpRequired) {
        const loginData = await loginWithTwoFactor(values.otpCode!).catch(
          () => undefined
        );
        if (loginData) {
          tokenStorage.login(loginData);
          onCloseVerificationDialog();
        } else {
          setFormErrors({ otpCode: 'error' });
          setSubmitLoading(false);
          return false;
        }
      }

      const res = await setPassword(values.password).catch(() => false);

      if (res) {
        window.location.replace('/');
      } else {
        setSubmitLoading(false);
      }

      return res;
    },
    [
      otpRequired,
      isOpenVerificationDialog,
      setPassword,
      open,
      loginWithTwoFactor,
      onCloseVerificationDialog,
      setFormErrors,
    ]
  );

  // handle error
  useEffect(() => {
    if (error) {
      const validationErrors = getValidationErrors<'password'>(error);
      if (validationErrors?.password) {
        setFormErrors({ password: validationErrors.password[0].description });
      } else {
        notify.error(error);
        setFormErrors({ password: undefined });
      }
    } else {
      setFormErrors({ password: undefined });
    }
  }, [error, notify, setFormErrors]);

  return {
    submitLoading,
    formErrors,
    isOpenVerificationDialog,
    onCloseVerificationDialog,
    onSubmit,
  };
};
