import { LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { useLegalClientLegalHeadVersion } from '../hooks';
import { LegalClientLegalHeadVersionView } from '../views';

export const LegalClientLegalHeadVersionContainer = () => {
  const {
    initialValues,
    loading,
    error,
    isError,
    versions,
    breadcrumbsTitles,
    snapshot,
    fullName,
    snapshotLoading,
    versionId,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
    onClickFirstHead,
  } = useLegalClientLegalHeadVersion();

  const { t } = useTranslation();

  useScrollToTop([]);

  if (loading) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientLegalHeadVersionView
      bodies={[]}
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      error={
        isError
          ? error?.localizedDescription ??
            t('client.legal.legalClientLegalHead.version.notVersionMessage')
          : undefined
      }
      firstHeads={snapshot?.value.firstHeads ?? []}
      fullName={fullName}
      initialValues={initialValues}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onClickHead={onClickFirstHead}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
