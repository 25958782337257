import { CSSObject } from 'libs/ui/styled';
import { Colors, Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

const getColorPrimary = () => ({
  textColor: Colors.black,

  hoverTextColor: Colors.gray300,

  focusTextColor: Colors.black,

  disableTextColor: Colors.gray250,
});

const getColorRed = () => ({
  textColor: Colors.black,

  hoverTextColor: Colors.gray300,

  focusTextColor: Colors.black,

  disableTextColor: Colors.gray250,
});

export const getTextMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => {
  const colors = colorVariant === 'primary' ? getColorPrimary() : getColorRed();
  return {
    ...theme.typography.bodyBold,
    height: '34px',
    paddingLeft: '8px',
    paddingRight: '8px',
    color: colors.textColor,

    '&:hover': {
      background: 'none',
      color: colors.hoverTextColor,
    } as CSSObject,

    '&:active': {
      background: 'none',
      color: colors.focusTextColor,
    } as CSSObject,

    '&:disabled': {
      background: 'none',
      color: colors.disableTextColor,
    } as CSSObject,
  };
};
