import { TypedForm, ObjectSchema, string, object, mixed } from 'libs/form';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface HeadTypeFormType {
  headInfo: {
    firstHead: Maybe<YesOrNoSelectType>;
    body?: Maybe<SelectType<string>>;
  };
}

export const headTypeFormSchema: ObjectSchema<HeadTypeFormType> = object({
  headInfo: object({
    body: object({
      value: string().required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    firstHead: object({
      value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
      label: string().required(),
    })
      .default(null)
      .required(),
  }),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<HeadTypeFormType>();
