import { PermissionType } from './permissionType';

export enum RoleType {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
}
export interface UserRoleEntity {
  id: string;
  name: RoleType;
  permissions: PermissionType[];
}
