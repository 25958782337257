import { LegalClientTaxResidenceParam } from 'api/types/params';
import { lodash } from 'helpers';

import { CompanyTaxResidencyFormType } from '../../forms/companyTaxResidencyForm';

export const parseCompanyTaxResidencyParam = (
  values: CompanyTaxResidencyFormType
): LegalClientTaxResidenceParam[] => {
  const result: LegalClientTaxResidenceParam[] = lodash.compact(
    values.companyTaxResidences.map((v) => ({
      country: v.country?.id!,
      taxId: v.taxNumber,
    }))
  );

  return result;
};
