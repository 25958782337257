// eslint-disable-next-line no-restricted-imports
import { ToggleButton as ToggleButtonBase } from '@mui/material';

import { ToggleButtonGroupStyled } from './ToggleButton.styles';
import { ToggleButtonProps } from './types';

/**
 * ToggleButton component.
 */
export const ToggleButton = <T extends string | number>({
  buttons,
  value,
  onChange,
}: ToggleButtonProps<T>) => (
  <ToggleButtonGroupStyled exclusive value={value} onChange={onChange}>
    {buttons.map((button) => (
      <ToggleButtonBase
        key={button.value}
        disableRipple
        className="toggleButton-button"
        disabled={button.value === value}
        value={button.value}
      >
        {button.label}
      </ToggleButtonBase>
    ))}
  </ToggleButtonGroupStyled>
);
