// eslint-disable-next-line no-restricted-imports
import { useLegalClientQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClient } from 'api/helpers/legal/parseLegalClient';

export const useLegalClient = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientQuery({ variables: { id } });

  const error = useError(apolloError);

  const legalClient = data?.legalClient
    ? parseLegalClient(data.legalClient)
    : null;

  return {
    legalClient,
    loading,
    error,
    refetch,
  };
};
