import {
  LegalClientEntity,
  LegalClientBeneficiaryEntity,
} from 'api/types/entity';
import { parseFullName } from 'helpers';
import { usePageTitle } from 'libs/navigation';

export const useBeneficiaryPageTitle = (
  legalClient: LegalClientEntity | null,
  legalClientBeneficiary: LegalClientBeneficiaryEntity | null
) => {
  let fullName = legalClientBeneficiary
    ? parseFullName(legalClientBeneficiary)
    : '';

  fullName += fullName && legalClient ? ` - ${legalClient.fullName}` : '';

  usePageTitle(fullName);
};
