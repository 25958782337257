import { FC } from 'react';

import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';

import { useLogin } from '../hooks';
import { LoginView } from '../views/LoginView';

export type LoginContainerProps = {};

export const LoginContainer: FC<LoginContainerProps> = () => {
  const { t } = useTranslation();

  const {
    formError,
    onSubmit,
    loading,
    isOpenVerificationDialog,
    verificationFormError,
    onSubmitVerification,
    onCloseVerificationDialog,
  } = useLogin();

  usePageTitle(t('auth.login.pageTitle'));

  return (
    <LoginView
      formError={formError}
      isOpenVerificationDialog={isOpenVerificationDialog}
      loading={loading}
      verificationFormError={verificationFormError}
      onCloseVerificationDialog={onCloseVerificationDialog}
      onSubmit={onSubmit}
      onSubmitVerification={onSubmitVerification}
    />
  );
};
