import { PropsWithChildren } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ModalProps } from '@mui/material';

import { DialogStyled } from './Dialog.styles';
import { DialogProps } from './types';

/**
 * Dialog component.
 */
export const Dialog = ({
  isOpen,
  children,
  className,
  maxWidth,
  fullWidth,
  fullScreen,
  disableBackdropClick,
  onClose,
}: DialogProps & PropsWithChildren) => {
  const handleClose: ModalProps['onClose'] = (e, reason) => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return;
    }
    onClose?.();
  };
  return (
    <DialogStyled
      className={className}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
    >
      {children}
    </DialogStyled>
  );
};
