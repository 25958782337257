import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLoginWithTwoFactorMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LoginData } from 'api/types/data';

export const useLoginWithTwoFactor = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLoginWithTwoFactorMutation();

  const error = useError(apolloError);

  return {
    data: data?.loginWithTwoFactor as LoginData | undefined,
    loading,
    error,
    loginWithTwoFactor: useCallback(
      async (otp: string) => {
        const res = await handle({ variables: { otp } });
        return res.data?.loginWithTwoFactor as LoginData | undefined;
      },
      [handle]
    ),
  };
};
