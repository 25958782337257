import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContentStyled = styled(Stack)(() => ({
  width: 484,
  padding: 32,
  gap: 24,
  flex: 1,
}));

export const DelimiterStyled = styled(Stack)(() => ({
  height: 1,
  width: '100%',
  backgroundColor: Colors.gray100,
  margin: '20px 0',
}));
