// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_LegalClientRepresentativeController_UpdateById_Input_PepConnectionType as PepConnectionTypeApi,
  UpdateLegalClientRepresentativeByAdminDto_Input,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { UpdateLegalClientRepresentativeParams } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseBiographyParam } from '../client/parseParam';
import { parseConstitutionalDocumentParam } from '../legal/parseParam';
import { parseIdentityDocumentParam } from '../parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseUpdateLegalClientRepresentativeParams = (
  params: UpdateLegalClientRepresentativeParams
): UpdateLegalClientRepresentativeByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  reason: params.reason,

  firstName: params.firstName,
  lastName: params.lastName,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,
  biography: parseBiographyParam(params.biography),

  placeOfBirth: params.placeOfBirth,
  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,

  connectedPepInfo: params.connectedPepInfo,

  pepConnectionType: relationType[params.pepConnection],

  company: params.company
    ? {
        actualAddress: params.company.actualAddress,
        legalAddress: params.company.legalAddress,
        fullName: params.company.fullName,
        shortName: params.company.shortName,
        constitutionalDocument: parseConstitutionalDocumentParam(
          params.company.constitutionalDocument
        ),
        stateRegistration: params.company.stateRegistration,
        taxResidences: params.company.taxResidences,
      }
    : undefined,

  roleInCompany: {
    authorityConfirmationDocument: {
      dateOfExpiration: formatToISODate(
        params.roleInCompany.authorityConfirmationDocument.dateOfExpiration
      ),
      dateOfIssue: formatToISODate(
        params.roleInCompany.authorityConfirmationDocument.dateOfIssue
      ),
      fileIds: params.roleInCompany.authorityConfirmationDocument.fileIds,
      number: params.roleInCompany.authorityConfirmationDocument.number,
    },
  },
  additionalInformationFileIds: params.additionalInformationFileIds,
  additionalDocumentFileIds: params.additionalDocumentFileIds,

  identityDocument: parseIdentityDocumentParam(params.identityDocument),
});
