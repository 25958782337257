/* eslint-disable no-restricted-imports */
import { useActivateAdminMutation } from 'api/generated/graphql';
import { parseAdminEntity, useError } from 'api/helpers';

export const useActivateAdmin = () => {
  const [activateAdmin, { data, loading, error: serverError }] =
    useActivateAdminMutation();

  const error = useError(serverError);

  return {
    admin: data?.activateAdmin
      ? parseAdminEntity(data.activateAdmin)
      : undefined,
    loading,
    error,
    activateAdmin: async (id: string) => {
      const res = await activateAdmin({ variables: { id } });

      return res.data?.activateAdmin
        ? parseAdminEntity(res.data?.activateAdmin)
        : undefined;
    },
  };
};
