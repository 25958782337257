import { useCallback } from 'react';

import { openLink } from '../../helpers';

import { useGetFileLinkPreview } from './useGetFileLinkPreview';

export const usePreviewFile = () => {
  const { getFileLink, loading } = useGetFileLinkPreview();

  const handleDownloadFile = useCallback(
    async (fileId: string) => {
      const link = await getFileLink(fileId);

      if (!link) {
        return;
      }

      openLink(link);
    },
    [getFileLink]
  );

  return {
    previewFile: handleDownloadFile,
    loading,
  };
};
