import {
  VerificationCodeProps,
  VerificationCode,
} from 'libs/ui/VerificationCode';

import { FieldType } from '../types';
import { useField } from '../useField';

interface Props
  extends FieldType,
    Omit<
      VerificationCodeProps,
      'onChange' | 'onChangeText' | 'value' | 'type'
    > {}

export const VerificationCodeField = ({
  name,
  allowSubmit,
  onComplete,
  ...props
}: Props) => {
  const { field, helpers, inputProps } = useField<string | undefined>(name, {
    allowSubmit,
  });

  return (
    <VerificationCode
      {...props}
      isError={inputProps.error}
      value={field.value}
      onBlur={field.onBlur(name)}
      onChange={() => helpers.setError('')}
      onComplete={(v) => {
        helpers.setValue(v);
        onComplete?.(v);
      }}
      onFocus={() => helpers.setError('')}
    />
  );
};
