import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { useLegalClientFirstHead } from '../hooks';
import { LegalClientFirstHeadView } from '../views/LegalClientFirstHeadView';

export const LegalClientFirstHeadContainer = () => {
  const {
    loading,
    legalClientFirstHead,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
  } = useLegalClientFirstHead();

  useScrollToTop([]);

  if (error || (!legalClientFirstHead && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientFirstHead) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientFirstHeadView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      kycData={legalClientFirstHead.kyc}
      lockClientIfPossible={lockClientIfPossible}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
