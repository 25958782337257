import { PropsWithChildren } from 'react';

import { cx } from 'libs/ui/theme';
// eslint-disable-next-line no-restricted-imports
import { NavLink } from 'react-router-dom';

import { NavigationLinkClasses } from './types';

interface Props {
  to: string;
  className?: string;
}

export const NavigationLink = ({
  children,
  className,
  to,
}: Props & PropsWithChildren) => (
  <NavLink
    className={({ isActive }) =>
      cx(isActive && NavigationLinkClasses.activeLink, className)
    }
    to={to}
  >
    {children}
  </NavLink>
);
