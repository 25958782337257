// eslint-disable-next-line no-restricted-imports
import { MenuItem } from '@mui/material';

import { CSSObject, styled } from '../styled';
import { Colors } from '../theme';

export const MenuItemStyled = styled(MenuItem)(({ color }) => ({
  color: color ?? Colors.black,

  '&:hover, &.Mui-selected:hover, &:hover .menu-item-icon': {
    color: Colors.blue150,
  } as CSSObject,
}));
