import { useMemo } from 'react';

import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { HeadItem } from './types';

export const getColumns = (t: TFunc): TableColumn<HeadItem>[] => [
  {
    field: 'id',
    title: t('client.legal.legalClientLegalHead.firstHeads.columns.id'),
    width: 110,
    align: 'center',
  },
  {
    field: 'email',
    title: t('client.legal.legalClientLegalHead.firstHeads.columns.email'),
    flex: 1,
  },
  {
    field: 'name',
    title: t('client.legal.legalClientLegalHead.firstHeads.columns.name'),
    flex: 1,
  },
  {
    field: 'city',
    title: t('client.legal.legalClientLegalHead.firstHeads.columns.city'),
    flex: 1,
  },
  {
    field: 'location',
    title: t('client.legal.legalClientLegalHead.firstHeads.columns.location'),
    flex: 1,
  },
];

export const useColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t), [t]);
};
