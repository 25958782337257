import { RepresentativeClientEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import { parseActivityInitialValue } from 'modules/client/common/helpers';

import { RepresentativeFormType } from '../../forms/representativeForm';

import { getBaseRepresentativeInitialValue } from './getBaseRepresentativeInitialValue';

export const getRepresentativeInitialValue = (
  client: RepresentativeClientEntity,
  t: TFunc
): RepresentativeFormType => ({
  ...getBaseRepresentativeInitialValue(client, t),

  activity: parseActivityInitialValue(client),
});
