import { string, object, ObjectSchema, TypedForm, boolean } from 'libs/form';

import { AddressFormType } from './registrationAddressForm';

export interface ResidentialAddressFormType {
  residentialAddress: Partial<AddressFormType> & {
    residentialAddressIsSame?: boolean;
  };
}

export const residentialAddressSchema: ObjectSchema<ResidentialAddressFormType> =
  object({
    residentialAddress: object({
      country: object({
        id: string().required(),
        name: string().required(),
      })
        .when('residentialAddressIsSame', {
          is: false,
          then: (schema) => schema.required(),
        })
        .default(null)
        .nullable(),

      locality: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      region: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      area: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      street: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      house: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      building: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      apartment: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      postalCode: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),

      residentialAddressIsSame: boolean().default(false),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ResidentialAddressFormType>();
