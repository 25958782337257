// eslint-disable-next-line no-restricted-imports
import { Households_Const as EconomicSectorApi } from 'api/generated/graphql';
import { ClientEconomicSectorType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

const economicSector: Record<EconomicSectorApi, ClientEconomicSectorType> = {
  [EconomicSectorApi.Households]: ClientEconomicSectorType.Households,
};

export const parseEconomicSectorType = (
  data: Maybe<EconomicSectorApi>
): ClientEconomicSectorType | undefined =>
  data ? economicSector[data] : undefined;
