// eslint-disable-next-line no-restricted-imports
import { RadioGroup as RadioGroupBase } from '@mui/material';

import { Radio } from '../Radio';

import { LineStyled } from './RadioGroup.styles';
import { RadioGroupProps } from './types';

/**
 * RadioGroup component.
 */
export const RadioGroup = <Value extends string = string>({
  onChange,
  groups,
  value,
}: RadioGroupProps<Value>) => (
  <RadioGroupBase value={value} onChange={(e, v) => onChange?.(e, v as Value)}>
    {groups.map((g, i) => (
      <>
        <Radio label={g.label} value={g.value} />
        {i !== groups.length - 1 && <LineStyled />}
      </>
    ))}
  </RadioGroupBase>
);
