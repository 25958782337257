// Define an enumeration for route identifiers
export enum RoutesId {
  root = 'root', // root route

  notFound = 'notFound',

  home = 'home',
  // auth
  login = 'login',
  loginByToken = 'loginByToken',
  setPassword = 'setPassword',
  twoFactorConnection = 'twoFactorConnection',
  // admins
  admins = 'admins',
  admin = 'admin',
  // clients
  clients = 'clients',

  personClients = 'personClients',
  personClient = 'personClient',
  personClientVersion = 'personClientVersion',

  representativeClient = 'representativeClient',
  representativeClientVersion = 'representativeClientVersion',

  legalClients = 'legalClients',
  legalClient = 'legalClient',
  legalClientVersion = 'legalClientVersion',
  legalClientRepresentativeVersion = 'legalClientRepresentativeVersion',
  legalClientHeadVersion = 'legalClientHeadVersion',
  legalClientLegalHeadVersion = 'legalClientLegalHeadVersion',
  legalClientFirstHeadVersion = 'legalClientFirstHeadVersion',

  legalRepresentative = 'legalRepresentative',
  legalClientHead = 'legalClientHead',
  legalClientLegalHead = 'legalClientLegalHead',
  legalClientFirstHead = 'legalClientFirstHead',
  legalClientBeneficiary = 'legalClientBeneficiary',
  legalClientBeneficiaryVersion = 'legalClientBeneficiaryVersion',
  legalClientBranch = 'legalClientBranch',
  legalClientBranchVersion = 'legalClientBranchVersion',
}
