import {
  InformationUpdateFrequencyType,
  RiskLevelType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/riskLevelForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Box flex={1}>
          <Field.Autocomplete
            label={t('client.riskLevel.riskLevel.label')}
            name="riskLevel.riskLevel"
            optionLabelKey="label"
            options={Object.values(RiskLevelType).map((v) => ({
              label: t(`client.riskLevel.riskLevelType.${v}`),
              value: v,
            }))}
            optionValueKey="value"
            placeholder={t('client.riskLevel.riskLevel.placeholder')}
            variant="select"
          />
        </Box>
        <Box flex={1}>
          <Field.Autocomplete
            fullWidth
            label={t('client.riskLevel.informationUpdateFrequency.label')}
            name="riskLevel.informationUpdateFrequency"
            optionLabelKey="label"
            options={Object.values(InformationUpdateFrequencyType).map((v) => ({
              label: t(`client.riskLevel.informationUpdateFrequencyType.${v}`),
              value: v,
            }))}
            optionValueKey="value"
            placeholder={t(
              'client.riskLevel.informationUpdateFrequency.placeholder'
            )}
            variant="select"
          />
        </Box>
      </Row>

      <Field.DatePicker
        disableFuture={false}
        label={t('client.riskLevel.riskLevelReviewDate.label')}
        name="riskLevel.riskLevelReviewDate"
        placeholder={t('client.riskLevel.riskLevelReviewDate.placeholder')}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('client.riskLevel.lastInformationUpdateDate.label')}
        name="riskLevel.lastInformationUpdateDate"
        placeholder={t(
          'client.riskLevel.lastInformationUpdateDate.placeholder'
        )}
      />

      <Field.Text
        label={t('client.riskLevel.lastOperationMonitoringResults.label')}
        name="riskLevel.lastOperationMonitoringResults"
        placeholder={t(
          'client.riskLevel.lastOperationMonitoringResults.placeholder'
        )}
      />
      <Field.Text
        label={t('client.riskLevel.usedServices.label')}
        name="riskLevel.usedServices"
        placeholder={t('client.riskLevel.usedServices.placeholder')}
      />
    </>
  );
};
