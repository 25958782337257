import { useEffect, useState } from 'react';

import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { DialogProps, Input } from 'libs/ui';

export interface DialogReasonChangeProps
  extends Pick<DialogProps, 'isOpen' | 'onClose'> {
  loading?: boolean;
  submitLoading?: boolean;
  onSave: (reason: string) => void;
}

export const DialogReasonChange = ({
  loading,
  submitLoading,
  isOpen,
  onSave,
  onClose,
}: DialogReasonChangeProps) => {
  const { t } = useTranslation();

  const [reasonChange, setReasonChange] = useState('');
  const [reasonChangeError, setReasonChangeError] = useState('');

  const onConfirm = () => {
    if (!reasonChange) {
      setReasonChangeError(t('validation.required'));
      return;
    }
    onSave(reasonChange);
  };

  useEffect(() => {
    if (!isOpen) {
      setReasonChange('');
    }
  }, [isOpen]);

  useEffect(() => {
    setReasonChangeError('');
  }, [reasonChange]);

  return (
    <ConfirmationDialog
      headerCentered
      confirmButtonLabel={t('common.save')}
      isOpen={isOpen}
      loading={submitLoading || loading}
      maxWidth="lg"
      subtitle={t('client.dialogReasonChange.subtitle')}
      title={t('client.dialogReasonChange.title')}
      onBack={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Input
        helperTextAbsolute
        error={Boolean(reasonChangeError)}
        helperText={reasonChangeError}
        label={t('client.dialogReasonChange.reason.label')}
        placeholder={t('client.dialogReasonChange.reason.placeholder')}
        value={reasonChange}
        onChangeText={setReasonChange}
      />
    </ConfirmationDialog>
  );
};
