import { RoleType } from 'api/types/entity';
import { isValidName } from 'helpers';
import {
  ObjectSchema,
  string,
  object,
  FormErrors as FormErrorsBase,
  mixed,
  email,
  TypedForm,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';

export interface CreateAdminFormType {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  position?: string;
  role: SelectType<RoleType>;
}

const NGDEM_EMAIL_DOMAINS = ['ngdem.kz', 'ngdem.com', 'ngdem.io'];

export const getSchema = (t: TFunc): ObjectSchema<CreateAdminFormType> =>
  object({
    email: email(t)
      .test('adminEmail', t('admin.fields.email.validEmail'), (v) => {
        if (!v) return true;

        const [, emailDomain] = v.split('@');
        return NGDEM_EMAIL_DOMAINS.some((v) => v === emailDomain);
      })
      .required(),
    firstName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    lastName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    middleName: string()
      .max(20)
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    position: string().max(200),
    role: object({
      value: mixed<RoleType>().oneOf(Object.values(RoleType)).required(),
      label: string().required(),
    })
      .default(null)
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CreateAdminFormType>();

export type FormErrors = FormErrorsBase<CreateAdminFormType>;
