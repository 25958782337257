// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_ClientController_GetList_OnboardingStatuses_Items as ClientOnboardingStatusTypeApi,
  Query_ClientController_GetList_AllOf_1_List_Items_OnboardingStatus as ClientOnboardingStatusTypeApi2,
  Query_ClientController_GetAvailableStatuses_Onboarding_Items as ClientOnboardingStatusTypeApi3,
  Query_ClientController_GetById_OnboardingStatus as ClientOnboardingStatusTypeApi4,
  Query_ClientController_GetById_Onboarding_Statuses_Items_Value as ClientOnboardingStatusTypeApi5,
  Query_ClientController_GetList_AllOf_1_List_Items_Onboarding_Statuses_Items_Value as ClientOnboardingStatusTypeApi6,
} from 'api/generated/graphql';
import { ClientOnboardingStatusType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type Status =
  | ClientOnboardingStatusTypeApi
  | ClientOnboardingStatusTypeApi2
  | ClientOnboardingStatusTypeApi3
  | ClientOnboardingStatusTypeApi4
  | ClientOnboardingStatusTypeApi5
  | ClientOnboardingStatusTypeApi6;

const statusMap: Record<Status, ClientOnboardingStatusType> = {
  [ClientOnboardingStatusTypeApi.FillingOut]:
    ClientOnboardingStatusType.FillingOut,
  [ClientOnboardingStatusTypeApi.AccountOpened]:
    ClientOnboardingStatusType.AccountOpened,
  [ClientOnboardingStatusTypeApi.ComplianceCheck]:
    ClientOnboardingStatusType.ComplianceCheck,
  [ClientOnboardingStatusTypeApi.AccountOpeningDenied]:
    ClientOnboardingStatusType.AccountOpeningDenied,
  [ClientOnboardingStatusTypeApi.AmlCftCheck]:
    ClientOnboardingStatusType.AmlCftCheck,
  [ClientOnboardingStatusTypeApi.AccountOpening]:
    ClientOnboardingStatusType.AccountOpening,
  [ClientOnboardingStatusTypeApi.AwaitingAccountOpening]:
    ClientOnboardingStatusType.AwaitingAccountOpening,
  [ClientOnboardingStatusTypeApi.AwaitingAmlCftCheck]:
    ClientOnboardingStatusType.AwaitingAmlCftCheck,
  [ClientOnboardingStatusTypeApi.AwaitingComplianceCheck]:
    ClientOnboardingStatusType.AwaitingComplianceCheck,
  [ClientOnboardingStatusTypeApi.AwaitingValidation]:
    ClientOnboardingStatusType.AwaitingValidation,
  [ClientOnboardingStatusTypeApi.BoardReview]:
    ClientOnboardingStatusType.BoardReview,
  [ClientOnboardingStatusTypeApi.ValidationCheck]:
    ClientOnboardingStatusType.ValidationCheck,
  [ClientOnboardingStatusTypeApi.PendingBoardReview]:
    ClientOnboardingStatusType.PendingBoardReview,
  [ClientOnboardingStatusTypeApi.SigningDocuments]:
    ClientOnboardingStatusType.SigningDocuments,
};

export const parseClientOnboardingStatus = (
  status: Maybe<Status>
): ClientOnboardingStatusType =>
  status ? statusMap[status] : ClientOnboardingStatusType.FillingOut;
