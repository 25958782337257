import { useMemo } from 'react';

import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { HeadItem, HeadType } from './types';

export const getColumns = (t: TFunc): TableColumn<HeadItem>[] => [
  {
    field: 'id',
    title: t('client.legal.companyHeads.columns.id'),
    width: 110,
    align: 'center',
  },
  {
    field: 'type',
    title: t('client.legal.companyHeads.columns.type'),
    flex: 1,
    renderValue: (item) =>
      item.type === HeadType.person
        ? t('client.legal.companyHeads.personType')
        : t('client.legal.companyHeads.legalType'),
  },
  {
    field: 'name',
    title: t('client.legal.companyHeads.columns.name'),
    flex: 1,
  },
  {
    field: 'city',
    title: t('client.legal.companyHeads.columns.city'),
    flex: 1,
  },
];

export const useColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t), [t]);
};
