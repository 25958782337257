import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientsCountriesQuery } from 'api/generated/graphql';
import { parseCountryEntity, useError } from 'api/helpers';
import { lodash } from 'helpers';

export const useClientsCountries = () => {
  const { data, loading, error: apolloError } = useClientsCountriesQuery();

  const error = useError(apolloError);

  return {
    countriesData: useMemo(
      () =>
        data?.clientsCountries
          ? {
              registration: lodash
                .compact(data?.clientsCountries.registration)
                .map(parseCountryEntity),
              location: lodash
                .compact(data?.clientsCountries.residential)
                .map(parseCountryEntity),
            }
          : undefined,
      [data?.clientsCountries]
    ),
    loading,
    error,
  };
};
