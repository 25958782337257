import { useMemo } from 'react';

import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { BeneficiaryItem } from './types';

export const getColumns = (t: TFunc): TableColumn<BeneficiaryItem>[] => [
  {
    field: 'id',
    title: t('client.legal.companyBeneficiaries.columns.id'),
    width: 110,
    align: 'center',
  },
  {
    field: 'name',
    title: t('client.legal.companyBeneficiaries.columns.name'),
    flex: 1,
  },
  {
    field: 'city',
    title: t('client.legal.companyBeneficiaries.columns.city'),
    flex: 1,
  },
  {
    field: 'location',
    title: t('client.legal.companyBeneficiaries.columns.location'),
    flex: 1,
  },
];

export const useColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t), [t]);
};
