import {
  useLegalClient,
  useLegalClientLegalHead,
  useLegalClientLegalHeadSnapshot,
} from 'api/requests';
import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getLegalClientLegalHeadInitialValue } from '../helpers';

import { useLegalClientLegalHeadVersions } from './useLegalClientLegalHeadVersions';

type ContainerParams = {
  legalClientId: string;
  headId: string;
  versionId?: string;
};

export const useLegalClientLegalHeadVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ContainerParams>();

  const { loading: loadingLegalHead, legalClientLegalHead } =
    useLegalClientLegalHead(params.headId!);

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: versionsLoading, versions } =
    useLegalClientLegalHeadVersions(
      params.legalClientId!,
      params.headId!,
      params.versionId!
    );

  const {
    snapshot,
    loading: snapshotLoading,
    error,
    isError,
  } = useLegalClientLegalHeadSnapshot(params.headId!, params.versionId!, {
    fetchPolicy: 'no-cache',
  });

  const initialValues = snapshot?.value
    ? getLegalClientLegalHeadInitialValue(snapshot.value, t)
    : undefined;

  const fullName = legalClientLegalHead?.fullName ?? '';

  const clientFullName = legalClient?.fullName;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: clientFullName,
    [RoutesId.legalClientVersion]: t('client.client.versions.header', {
      name: clientFullName,
    }),
    [RoutesId.legalClientLegalHeadVersion]: t('client.client.versions.header', {
      name: fullName,
    }),
  };

  const loading =
    (!legalClientLegalHead && loadingLegalHead) ||
    (!versions && versionsLoading);

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientLegalHead.fullPath,
      {
        legalClientId: params.legalClientId!,
        headId: params.headId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: LegalClientVersionEntity) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientLegalHeadVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        headId: params.headId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  const onClickFirstHead = (firstHeadId: string) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientLegalHeadVersion.legalClientFirstHeadVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
        firstHeadId,
        headId: params.headId!,
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    isError,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
    onClickFirstHead,
  };
};
