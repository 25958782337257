import { useLegalClientsCountries } from 'api/requests';
import { usePageTitleTranslation } from 'libs/navigation';

import { useLegalClients } from '../hooks';
import { LegalClientsView } from '../views/LegalClientsView';

export const LegalClientsContainer = () => {
  const {
    clients,
    loading,
    sort,
    page,
    limit,
    rowCount,
    filter,
    setFilter,
    onCellClick,
    onSortChange,
    onPaginationChange,
  } = useLegalClients();

  const { countriesData } = useLegalClientsCountries();

  usePageTitleTranslation('client.clients.pageTitle');

  return (
    <LegalClientsView
      clients={clients}
      filter={filter}
      limit={limit}
      loading={!clients?.length && loading}
      locationCountries={countriesData?.location}
      page={page}
      registrationCountries={countriesData?.registration}
      rowCount={rowCount}
      setFilter={setFilter}
      sort={sort}
      onCellClick={onCellClick}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
    />
  );
};
