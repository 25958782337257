import { formatToDateTimeShort } from 'helpers';
import { useConvertDateToUserTimezone } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Box, CircularProgress, Pressed, Stack, Typography } from 'libs/ui';

import { ReadIcon, UnreadIcon } from './icons';

export interface NotificationHeaderProps {
  isRead: boolean;
  date: Date;
  title: string;
  loadingToggleRead?: boolean;
  onToggleRead: () => void;
}

export const NotificationHeader = ({
  isRead,
  title,
  date,
  loadingToggleRead,
  onToggleRead,
}: NotificationHeaderProps) => {
  const { t } = useTranslation();

  const { convertDateToUserTimezone } = useConvertDateToUserTimezone();

  const dateTime = formatToDateTimeShort(convertDateToUserTimezone(date));

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack spacing={4}>
        <Typography variant="bodyBold">{title}</Typography>
        <Typography variant="captionSemiBold">{dateTime}</Typography>
      </Stack>
      {loadingToggleRead ? (
        <CircularProgress size={16} />
      ) : (
        <>
          {!isRead && <Box className="notificationItem-readMark" />}
          <Pressed
            className="notificationItem-checkBox"
            title={
              !isRead
                ? t('components.dialogNotifications.readMarkTitle')
                : t('components.dialogNotifications.unreadMarkTitle')
            }
            onClick={onToggleRead}
          >
            {!isRead ? <ReadIcon /> : <UnreadIcon />}
          </Pressed>
        </>
      )}
    </Stack>
  );
};
